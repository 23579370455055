import * as FORM_LABELS from "../labels/form";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.FORM_PAYMENT_TYPE_TITLE]: "결제 수단 선택",
    [FORM_LABELS.FORM_PAYMENT_METHOD]: "결제 수단",
    [FORM_LABELS.FORM_USED_PAYMENT_METHOD]: "결제 수단 {method}",

    [FORM_LABELS.FORM_ADDRESS_TITLE]: "청구지 주소",

    [FORM_LABELS.FORM_CONFIRMATION_TITLE]: "인증 번호",

    [FORM_LABELS.FORM_FIELDS_NAME]: "야마다 타로",
    [FORM_LABELS.FORM_FIELDS_EMAIL]: "example@email.com",
    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER]: "80 1234 5678",

    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL]: "전화 번호",
    [FORM_LABELS.FORM_FIELDS_EMAIL_LABEL]: "이메일 주소",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_LABEL]: "보안 코드",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL]: "만료일 (월/년)",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION]: "만료일",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL]: "카드 이름",
    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL]: "카드 번호",

    [FORM_LABELS.FORM_PAYMENT_TYPE_CARD]: "신용 카드 지불",
    [FORM_LABELS.FORM_PAYMENT_TYPE_KONBINI]: "편의점 결제",
    [FORM_LABELS.FORM_PAYMENT_TYPE_PAIDY]: "Paidy",
    [FORM_LABELS.FORM_PAYMENT_TYPE_ONLINE]: "온라인",
    [FORM_LABELS.FORM_PAYMENT_TYPE_BANK_TRANSFER]: "은행 계좌 이체",

    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY]: "Alipay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_PAY_PAY]: "PayPay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_WE_CHAT]: "WeChat",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY_PLUS]: "Alipay+",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE]: "PayPay (테스트 만 해당)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE]: "Alipay (테스트 만 해당)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE]: "Alipay+(테스트 만 해당)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE]: "WeChat (테스트)",

    [FORM_LABELS.FORM_ADDRESS_FIELDS_CITY]: "시, 구, 군",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_COUNTRY]: "국가",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE1]: "번지",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE2]: "건물 명, 방 번호",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME]: "이름",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME_KANA]: "이름 (KANA)",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_STATE]: "도, 시",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_ZIP]: "우편 번호",

    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER]: "4444 3333 2222 1111",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER]: "TARO YAMADA",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC]: "123",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_INFO]: "서명 열의 상단에 설명 된 숫자 3 (또는 4) 숫자",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_MONTH]: "월",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_YEAR]: "년",

    [FORM_LABELS.FORM_KONBINI_FIELDS_NAME]: "타로 스즈키",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND]: "편의점",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT]: "편의점 선택",

    [FORM_LABELS.FORM_FIELDS_ACCEPT_RECURRING]: "개인 정보를 얻는 데 동의합니다",

    [FORM_LABELS.FORM_CARD_SELECTOR_TITLE]: "카드 선택",
    [FORM_LABELS.FORM_CARD_FIELDS_NEW_CARD]: "새로운 카드",
    [FORM_LABELS.FORM_CARD_SELECTOR_DELETE]: "카드 정보 삭제",

    [FORM_LABELS.FORM_KONBINI_SELECTOR_TITLE]: "편의점 선택",
    [FORM_LABELS.FORM_KONBINI_FIELDS_OTHER]: "또 다른 편의점",
    [FORM_LABELS.FORM_KONBINI_SELECTOR_DELETE]: "편의점 정보 삭제",

    [FORM_LABELS.FORM_PAIDY_SELECTOR_TITLE]: "이전에 사용한 데이터 세트 선택 :",
    [FORM_LABELS.FORM_PAIDY_FIELDS_NEW]: "새로운",
    [FORM_LABELS.FORM_PAIDY_SELECTOR_DELETE]: "데이터 세트 삭제",

    [FORM_LABELS.FORM_CONFIRMATION_CODE]: "이메일 또는 SMS를 확인하고 확인 코드를 입력하십시오.",

    [FORM_LABELS.FORM_INSTALLMENT_SELECT_LABEL]: "할부 회수",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_ONE_TIME]: "일시불",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_THREE_TIMES]: "3회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIVE_TIMES]: "5회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_SIX_TIMES]: "6회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TEN_TIMES]: "10회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWELVE_TIMES]: "12회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES]: "15회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES]: "18회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_TIMES]: "20회",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES]: "24회",
    [FORM_LABELS.FORM_INSTALLMENT_REVOLVING]: "리볼빙",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_HELPER]: "{brand}에서는 할부 결제가 불가능합니다. ",
};

export default LABELS;
