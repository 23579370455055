import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardAmex = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="amex-card-icon" viewBox="0 0 780 500" {...props}>
        <path
            d="M48.75,0h682.5C758.16,0,780,19.48,780,43.5v413c0,24-21.84,43.48-48.75,43.48H48.75C21.81,500,0,480.52,0,456.53V43.5C0,19.48,21.84,0,48.75,0Z"
            fill="#016fd0"
        />
        <path
            d="M157.65,173.91,89,325.92h82.24l10.19-24.24h23.31l10.19,24.24H305.4v-18.5l8.07,18.5h46.82L368.35,307v18.89H556.61l22.89-23.61,21.43,23.61,96.69.2-68.91-75.78,68.91-76.43H602.43l-22.28,23.17-20.76-23.17H354.59L337,213.14l-18-39.23H237v17.87l-9.13-17.87Zm15.91,21.58h40.08l45.57,103.09V195.49h43.91l35.19,73.91,32.44-73.91h43.69V304.57H387.85l-.21-85.47-38.76,85.47H325.1l-39-85.47v85.47h-54.7l-10.36-24.45H165l-10.35,24.43h-29.3Zm265.2,0H546.87l33.06,35.72,34.13-35.72h33.07l-50.24,54.83,50.24,54.19H612.56L579.5,268.38l-34.31,36.13H438.76v-109ZM193.06,214,174.6,257.51h36.89Zm272.4,4.13V238h59v22.2h-59v21.73h66.15l30.74-32-29.44-31.84H465.46Z"
            fill="#fff"
        />
    </Icon>
);

export default CardAmex;
