import React from "react";
import { render } from "react-dom";
import { hot } from "react-hot-loader";
import { IntlProvider } from "react-intl-redux";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import "cross-fetch/polyfill";
import "isomorphic-form-data";

import { App } from "./components/layout/App";
import { history, store } from "./redux/store";
import { polyfillIntl } from "./utils/intlPolyfills";

const AppHot = hot(module)(App);

polyfillIntl();

render(
    <Provider store={store}>
        <IntlProvider locale="ja">
            <ConnectedRouter history={history}>
                <AppHot />
            </ConnectedRouter>
        </IntlProvider>
    </Provider>,
    document.getElementById("root")
);
