import React from "react";
import { NewCardBrand, PatchedCardBrand } from "common/types";
import { CardBrand } from "univapay-node";

import * as CardBrandIcons from "../common/payment-icons";

type OwnProps = {
    brand: PatchedCardBrand;
};

export const CardBrandIcon = ({ brand }: OwnProps) => {
    switch (brand) {
        case CardBrand.AMEX:
            return <CardBrandIcons.CardAmex />;
        case CardBrand.DINERS:
            return <CardBrandIcons.CardDiners />;
        case CardBrand.DISCOVER:
            return <CardBrandIcons.CardDiscover />;
        case CardBrand.JCB:
            return <CardBrandIcons.CardJCB />;
        case CardBrand.MAESTRO:
            return <CardBrandIcons.CardMaestro />;
        case CardBrand.MASTERCARD:
            return <CardBrandIcons.CardMastercard />;
        case CardBrand.UNIONPAY:
            return <CardBrandIcons.CardUnionpay />;
        case CardBrand.VISA:
            return <CardBrandIcons.CardVisa />;
        case NewCardBrand.PRIVATE_LABEL:
            return null;
        default:
            return <CardBrandIcons.CardUnknown />;
    }
};
