import React from "react";
import { useIntl } from "react-intl";

type ErrorMessagesProps = {
    fieldLabel: string;
    errors: string | string[];
    style?: Record<string, string>;
};

export const ErrorMessages = ({ fieldLabel, errors, style }: ErrorMessagesProps) => {
    const { formatMessage } = useIntl();

    if (!errors) {
        return null;
    }

    const errorCodes = Array.isArray(errors) ? errors : [errors];

    if (!errorCodes.length) {
        return null;
    }

    return (
        <div className="field-error" style={style}>
            {errorCodes.map((code, index) => (
                // the code can be duplicated. The index is the only unique key for the error messages
                <div key={index}>{formatMessage({ id: code }, { name: fieldLabel })}</div>
            ))}
        </div>
    );
};
