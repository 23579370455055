import React, { Component, HTMLProps } from "react";
import TextMask from "react-text-mask";
import { fullWidthToHalfWidth } from "checkout/ts/utils/numbers";

const CARD_EXP_MASK: (string | RegExp)[] = [/[0-9０-９]/, /[0-9０-９]/, "/", /[0-9０-９]/, /[0-9０-９]/];

export type CardExpInputProps = Omit<HTMLProps<React.InputHTMLAttributes<string>>, "onChange"> & {
    showPlaceholder?: boolean;
    onChange?: (value: string) => void;
};

export class CardExpInput extends Component<CardExpInputProps> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: eventValue } = event.target as HTMLInputElement;
        const formattedValue = fullWidthToHalfWidth(eventValue);

        this.props.onChange?.(formattedValue);
    };

    render() {
        const { value = "", showPlaceholder = true, ...props } = this.props;
        const year = new Date().getFullYear().toString().substr(-2);

        return (
            <TextMask
                {...props}
                onChange={this.handleChange}
                autoComplete="cc-exp"
                placeholder={showPlaceholder ? `12/${year}` : undefined}
                type="tel"
                maxLength="5"
                pattern="[0-9０-９]*"
                inputMode="numeric"
                guide={false}
                keepCharPositions={false}
                mask={CARD_EXP_MASK}
                placeholderChar={"\u2000"}
                value={fullWidthToHalfWidth(String(value))}
            />
        );
    }
}
