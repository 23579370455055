import React from "react";
import CSSTransition, { CSSTransitionProps } from "react-transition-group/CSSTransition";

const TRANSITION_TIMEOUT = 300;

export type DialogTransitionProps = Omit<CSSTransitionProps, "timeout"> & {
    timeout?: CSSTransitionProps["timeout"];
};

export const DialogTransition = (props: DialogTransitionProps) => (
    <CSSTransition
        in={props.in || false}
        classNames="dialog"
        timeout={props.timeout || TRANSITION_TIMEOUT}
        addEndListener={() => undefined}
        {...props}
    />
);
