import React from "react";
import { ControlLabel, FormControl, FormControlProps, FormGroup, InputGroup } from "react-bootstrap";
import classnames from "classnames";
import { Field, WrappedFieldProps } from "redux-form";

import { generate } from "../../utils/random";

import { ErrorMessages } from "./components/ErrorMessages";
import { RequiredIndicator } from "./components/RequiredIndicator";

export type SelectProps = FormControlProps & {
    addons?: React.ReactNode;
    showErrors?: boolean;
    validationLabel?: string;
    isDark?: boolean;
    isHorizontal?: boolean;

    labelStyle?: Record<string, string>;
    errorStyle?: Record<string, string>;
    invalidStyle?: Record<string, string>;
    focusStyle?: Record<string, string>;
    inputStyle?: Record<string, string>;
    style?: Record<string, string>;
};

export const Select = ({
    addons,
    input,
    label,
    meta: { active, touched, invalid, error },
    showErrors = true,
    required,
    width,
    validationLabel = label,
    isDark,
    isHorizontal,
    labelStyle,
    errorStyle,
    invalidStyle,
    focusStyle,
    inputStyle,
    style,
    ...props
}: SelectProps & WrappedFieldProps) => {
    const isInvalid = (invalid || error) && touched;

    const currentFocusStyle = active ? focusStyle : {};
    const fieldStyle = isInvalid
        ? { width, ...inputStyle, ...invalidStyle, ...currentFocusStyle }
        : { width, ...inputStyle, ...currentFocusStyle };

    return (
        <FormGroup
            controlId={generate()}
            className={classnames({
                "keep-color-on-error": showErrors,
                "dark-theme": isDark,
                "row": isHorizontal,
            })}
            validationState={invalid && touched ? "error" : null}
            bsSize="small"
            style={{ ...style }}>
            {label && (
                <div className={classnames({ "col-xs-3 col-sm-2 inline-horizontal-content": isHorizontal })}>
                    <ControlLabel style={{ ...labelStyle }} className={classnames({ "full-width": !width })}>
                        {label}

                        <RequiredIndicator required={required} />
                    </ControlLabel>
                </div>
            )}

            <div className={classnames({ "col-xs-9 col-sm-10 inline-horizontal-content": isHorizontal && label })}>
                <InputGroup className={active ? "input-group-focus" : ""} style={{ width: fieldStyle.width }}>
                    {addons && <InputGroup.Addon>{addons}</InputGroup.Addon>}

                    <span className="form-control form-select-wrapper">
                        <FormControl
                            {...input}
                            {...props}
                            required={required}
                            componentClass="select"
                            bsClass="form-select"
                            style={fieldStyle}
                        />
                    </span>
                </InputGroup>

                {showErrors && (
                    <ErrorMessages
                        fieldLabel={validationLabel}
                        errors={touched ? error : undefined}
                        style={{ ...errorStyle }}
                    />
                )}
            </div>
        </FormGroup>
    );
};

type SelectFieldProps = any & SelectProps;

export const SelectField = (props: SelectFieldProps) => <Field component={Select} {...props} />;
