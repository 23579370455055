import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { isEmail } from "common/validation/card";
import { isEmpty } from "lodash";

// HACK: Until we get the blacklist from the API that will reject token creation we need a way to ensure¥
// the blacklisted customers can not create charges.
const BANNED_EMAILS = [
    "dunping37@gmail.com", // Created too many chargeback on STF, risking the whole platform to be blacklisted by the gateway for fraud
];

export const validateEmail = (email: string, requireEmail = false) => ({
    [LOCALE_LABELS.VALIDATION_REQUIRED]: requireEmail && isEmpty(email),
    [LOCALE_LABELS.VALIDATION_EMAIL]: email && !isEmail(email),
    [LOCALE_LABELS.VALIDATION_BANNED_EMAIL]: email && BANNED_EMAILS.includes(email),
});
