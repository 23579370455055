import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { useSelector } from "react-redux";
import reactCSS, { hover, HoverProps } from "reactcss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getThemeColors } from "checkout/ts/utils/colors";
import Color from "color";
import { isNil } from "lodash";
import { CheckoutColors } from "univapay-node";

import { defaultColors } from "../../colors";
import { StateShape } from "../../redux/store";

interface StateProps {
    dark: boolean;
    colors: CheckoutColors;
}

interface CustomProps extends ButtonProps {
    id: string;
    icon: IconDefinition;
}

type OwnProps = CustomProps & HoverProps<void>;

const stateSelector = (state: StateShape): StateProps => {
    const applicationParamsTheme = isNil(state.application.params?.params?.dark)
        ? null
        : state.application.params?.params?.dark
        ? "dark"
        : "light";

    const colorsOverwrite = getThemeColors(state.configuration.data, applicationParamsTheme);

    return {
        dark: state.application.params.params.dark,
        colors: { ...defaultColors, ...colorsOverwrite },
    };
};

export const DialogToolbarButton = hover(({ hover, icon, ...props }: OwnProps) => {
    const { dark, colors } = useSelector(stateSelector);

    const colorHover: string = dark
        ? Color(colors.mainText).darken(0.2).hex()
        : Color(colors.mainText).lighten(0.1).hex();

    const styles = reactCSS(
        {
            "default": {
                button: {
                    color: colors.mainText,
                },
            },

            hover: {
                button: {
                    color: colorHover,
                },
            },
        },
        { hover }
    );

    return (
        <Button {...props} className="dialog-toolbar-button" style={styles.button}>
            <FontAwesomeIcon icon={icon} fixedWidth />
        </Button>
    );
});
