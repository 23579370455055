import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getCardLabel } from "checkout/ts/locale/selectors";
import { TransactionTokenCardDataItem } from "univapay-node";

import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { StepTitle } from "../../common/StepTitle";
import { Button } from "../common/FormData";

const stateSelector = (state: StateShape) => ({ token: state.checkout.token });

const Content = () => {
    const { formatMessage } = useIntl();

    const {
        checkout: { complete: createCharge },
    } = useDispatch<Dispatch>();

    const { token } = useSelector(stateSelector);

    const handleSubmitForm = () =>
        createCharge({ data: { accept: true, failsOnValidationError: true, token: token?.id } });

    const { brand, expMonth, expYear, lastFour, cardholder } = (token.data as TransactionTokenCardDataItem).card;

    return (
        <>
            <Row>
                <Col xs={12}>
                    <StepTitle className="title">
                        {formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_CARD_TITLE })}
                    </StepTitle>

                    <div>
                        {formatMessage(
                            { id: LOCALE_LABELS.PAYMENT_REVIEW_BRAND_WITH_LAST_FOUR },
                            { brand: formatMessage({ id: getCardLabel(brand) }), lastFour }
                        )}
                    </div>

                    <div>
                        {formatMessage({ id: LOCALE_LABELS.PAYMENT_REVIEW_EXPIRY }, { month: expMonth, year: expYear })}
                    </div>

                    <div>{cardholder}</div>
                </Col>
            </Row>

            <Button label={formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_NEXT })} onClick={handleSubmitForm} />
        </>
    );
};

export default Content;
