import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import reactCSS from "reactcss";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { sdk } from "checkout/ts/SDK";
import { raisePCIError } from "checkout/ts/utils/monitoring";
import { getErrorUrl } from "checkout/ts/utils/StepName";
import classnames from "classnames";
import Color from "color";
import { detectExternalScripts, detectUnknownExternalScripts } from "common/utils/browser";
import { OnlineBrand } from "univapay-node";

import { DARK_COLOR, LIGHT_COLOR } from "../../../../common/constants";
import { objectPath } from "../../../../common/utils/object";
import { Dispatch, StateShape, store } from "../../redux/store";
import { DialogTransition } from "../transitions/DialogTransition";

import { Dialog } from "./Dialog";
import { InlineForm } from "./InlineForm";
import { Spinner } from "./Spinner";

const stateSelector = (state: StateShape) => {
    const {
        application: {
            params: {
                params: { dark, inline = false },
            },
        },
        configuration: { data },
    } = state;

    const configurationDark = objectPath<boolean>(data, "theme.dark");

    return {
        applicationError: state.application.globalError,
        open: state.application.open,
        connector: state.application.connector,
        fetched: !!state.tokens.tokens && !!state.configuration.data,
        dark: configurationDark !== null ? configurationDark : dark,
        paymentMethods: state.configuration.paymentMethods,
        inline,
    };
};

export const App = () => {
    const { locale } = useIntl();
    const { push: redirect } = useHistory();
    const {
        application: { connectOrigin },
        tokens: { list: listTokens },
        configuration: { get: getCheckoutInfo, getGatewayInfo },
        product: { get: getProducts, getByCodes: getProductsByCode },
    } = useDispatch<Dispatch>();

    const { amount, currency } = useCheckoutParams();
    const { applicationError, connector, fetched, open, dark, inline, paymentMethods } = useSelector(stateSelector);

    // Fetch initial data
    useEffect(() => {
        if (!connector) {
            (async () => {
                const connector = await connectOrigin();
                if (!connector) {
                    return;
                }

                const configuration = await getCheckoutInfo();
                if (!configuration) {
                    return;
                }

                // The state are brought by the connector, this ensures we get the list of products
                const { products: productIds, productCodes } = store.getState().application.params?.params || {};
                if (productIds?.length) {
                    await getProducts({ ids: productIds });
                }

                if (productCodes?.length) {
                    await getProductsByCode({ codes: productCodes });
                }

                await listTokens();
            })();
        }
    }, []);

    // Ensure app redirects to error page on global errors
    useEffect(() => {
        if (applicationError) {
            redirect(getErrorUrl());
        }
    }, [applicationError]);

    // Ensure we get the promotions and logos. Only alipay plus online has promotions/logos for now
    useEffect(() => {
        if (fetched && paymentMethods?.some(({ method }) => method === OnlineBrand.ALIPAY_PLUS_ONLINE)) {
            getGatewayInfo({ brand: OnlineBrand.ALIPAY_PLUS_ONLINE, amount, currency });
        }
    }, [paymentMethods, fetched]);

    const styles = reactCSS(
        {
            default: {
                overlay: {
                    backgroundColor: Color(DARK_COLOR).alpha(0.6).toString(),
                },
            },
            overlayLight: {
                overlay: {
                    backgroundColor: Color(LIGHT_COLOR).alpha(0.6).toString(),
                },
            },
            closed: {
                overlay: {
                    backgroundColor: "transparent",
                },
            },
        },
        {
            overlayLight: dark,
            closed: !open,
        }
    );

    useEffect(() => {
        const unknownOriginScripts = detectUnknownExternalScripts([
            // Whitelisted external hosts
            "fonts.googleapis.com", // Font download
            "cdn.marmot-cloud.com", // Alipay+ logo
            "gopay-images.s3.ap-northeast-1.amazonaws.com", // Store logo

            // Checkout hosts
            "open.weixin.qq.com",
            "api.weixin.qq.com",
            "apps.paidy.com",

            // Bundle host
            window.location.host,

            // API hosts
            `api.${window.location.hostname}`,
            new URL(sdk.api.endpoint).host,
        ]);

        if (Object.keys(unknownOriginScripts).length) {
            raisePCIError(unknownOriginScripts);
        }
    }, [detectExternalScripts().length]);

    return (
        <div className={classnames("univapay-checkout", { inline })} style={inline ? undefined : styles.overlay}>
            <Helmet htmlAttributes={{ lang: locale }}>
                <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Muli" />
            </Helmet>

            {inline ? (
                <InlineForm />
            ) : (
                <Spinner loading={!fetched && !applicationError} color={dark ? DARK_COLOR : LIGHT_COLOR}>
                    <div>
                        <DialogTransition in={open} appear>
                            <Dialog />
                        </DialogTransition>
                    </div>
                </Spinner>
            )}
        </div>
    );
};
