import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { UserData } from "checkout/ts/redux/models/user-data";
import { parsePhone } from "checkout/ts/redux/utils/intl";
import { isDarkTheme } from "checkout/ts/utils/colors";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { isEmpty } from "lodash";
import { compose } from "recompose";
import { Form, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { PaymentType } from "univapay-node";

import { FORM_CUSTOMER_INFO, FORM_TOKEN_SELECT_NAME } from "../../../../../common/constants";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { validationToErrors } from "../../../utils/errors";
import { Regexes } from "../../../utils/Regexes";
import { StepTitle } from "../../common/StepTitle";
import { PhoneNumberField } from "../../forms/PhoneNumberInput";
import { TextField } from "../../forms/Text";
import { DialogButton } from "../../layout/DialogButton";
import { validateEmail } from "../card/utils/validateEmail";

import { CustomFields } from "./components/CustomFields";
import { getRequiredFields } from "./utils/customerInfo";
import { isValidDate } from "./utils/date";
import { PrivacyLink } from "./FormData";

type OwnProps = {
    title?: string;
    onSubmit?: (values: Partial<UserData>) => void | Promise<void>;
    nextButtonLabel: React.ReactNode;
    showPrivacyLink?: boolean;
};

type ComposedProps = ReturnType<typeof stateSelector> & InjectedFormProps<Partial<UserData>> & OwnProps;

const stateSelector = (state: StateShape) => {
    const { checkout, application, userData, configuration } = state;
    const {
        email,
        phoneNumber,
        customerName,
        customerNameKana,
        submitButtonText,
        requirePhoneNumber: appParamsRequirePhoneNumber,
        requireEmail: appParamsRequireEmail,
        customFields,
        instantChargeTokenId,
    } = application.params.params;

    const { requireEmail, requirePhoneNumber, requireBillingAddress, requireName, requireNameKana } = getRequiredFields(
        checkout.paymentType,
        application.params.params,
        configuration?.data
    );

    const isKonbini = checkout.paymentType === PaymentType.KONBINI;

    const customerIdToken = formValueSelector(FORM_TOKEN_SELECT_NAME)(state, "selectedTokenId");

    return {
        paymentType: checkout.paymentType,
        paymentMethodKey: checkout.paymentMethodKey,
        submitButtonText,
        requireEmail: !customerIdToken && (isKonbini ? requireEmail || appParamsRequireEmail !== false : requireEmail),
        requirePhoneNumber:
            !customerIdToken &&
            (isKonbini
                ? requirePhoneNumber || appParamsRequirePhoneNumber !== false || !userData.phoneNumber?.localNumber
                : requirePhoneNumber),
        requireBillingAddress: !customerIdToken && requireBillingAddress,
        processing: ((state.loading.models.userData || state.loading.models.checkout) as unknown) as boolean,
        requireName: !customerIdToken && requireName,
        requireNameKana: !customerIdToken && requireNameKana,
        initialValues: {
            email,
            phoneNumber: parsePhone(phoneNumber),
            name: customerName,
            nameKana: customerNameKana,
        },
        customerIdToken,
        darkTheme: isDarkTheme(state),
        customFields,
        instantChargeTokenId,
    };
};

export const validate = (
    values: Partial<UserData>,
    { requireEmail, requirePhoneNumber, requireName, customFields, customerIdToken }: ComposedProps
) => {
    const { email, name, phoneNumber, nameKana, birthday } = values;

    const validationToErrorsUserData = {
        "email": validateEmail(email, !customerIdToken && requireEmail),
        "phoneNumber.localNumber": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]:
                !customerIdToken &&
                requirePhoneNumber &&
                (!phoneNumber?.localNumber || isEmpty(phoneNumber.localNumber)),
            [LOCALE_LABELS.VALIDATION_PHONE_NUMBER]:
                phoneNumber?.localNumber && !/^[0-9]{4,}$/.test(phoneNumber.localNumber),
        },
        "name": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !customerIdToken && requireName && !name,
        },
        "nameKana": {
            [LOCALE_LABELS.VALIDATION_INVALID_CHARACTERS]: nameKana && !Regexes.Katakana.test(nameKana),
        },
        "birthday": {
            [LOCALE_LABELS.VALIDATION_DATE]: birthday && !isValidDate(birthday),
        },
    };

    const validationErrorsWithCustomFields = (() => {
        if (!customFields) {
            return validationToErrorsUserData;
        }

        return customFields.reduce((acc, { key, required }) => {
            acc[`customFields.${key}`] = {
                [LOCALE_LABELS.VALIDATION_REQUIRED]: required && !values.customFields?.[key],
            };
            return acc;
        }, validationToErrorsUserData);
    })();

    return validationToErrors(validationErrorsWithCustomFields);
};

export const Content = compose<ComposedProps, OwnProps>(
    connect(stateSelector),
    reduxForm({ destroyOnUnmount: false, forceUnregisterOnUnmount: true, form: FORM_CUSTOMER_INFO, validate })
)(
    ({
        paymentType,
        paymentMethodKey,
        processing,
        requireBillingAddress,
        requireEmail,
        requirePhoneNumber,
        requireName,
        requireNameKana,
        submitButtonText,
        handleSubmit,
        title,
        onSubmit,
        darkTheme,
        nextButtonLabel,
        showPrivacyLink,
        customFields,
        instantChargeTokenId,
    }) => {
        const { push: redirect, replace: replaceUrl } = useHistory();
        const { formatMessage } = useIntl();

        const {
            userData: { setUserData },
        } = useDispatch<Dispatch>();

        const isPaidy = paymentType === PaymentType.PAIDY;

        const redirectToAddress = requireBillingAddress || paymentType === PaymentType.BANK_TRANSFER;
        const stepName = instantChargeTokenId
            ? StepName.TOKEN_REVIEW
            : redirectToAddress
            ? StepName.ADDRESS
            : isPaidy
            ? StepName.PROCESSING
            : StepName.DATA;

        const handleFormSubmit = async (values: Partial<UserData>) => {
            setUserData(values);

            const submitCallback = onSubmit || (() => redirect(getStepUrl(paymentType, paymentMethodKey, stepName)));
            await submitCallback(values);
        };

        useEffect(() => {
            if (!paymentType) {
                return;
            }

            const shouldSkipCustomerInfo =
                !requireName && !requireEmail && !requirePhoneNumber && !requireNameKana && !customFields?.length;

            if (shouldSkipCustomerInfo) {
                replaceUrl(getStepUrl(paymentType, paymentMethodKey, stepName));
            }
        }, []);

        return (
            <Form
                data-name="form-info"
                noValidate
                onSubmit={handleSubmit(handleFormSubmit)}
                className="info content-form">
                <Row>
                    <Col xs={12}>
                        <StepTitle>
                            {title || formatMessage({ id: LOCALE_LABELS.ONLINE_CUSTOMER_DETAIL_TITLE })}
                        </StepTitle>
                    </Col>

                    <Col xs={12}>
                        {requireName && (
                            <TextField
                                label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_NAME })}
                                bsSize="sm"
                                name="name"
                                autoComplete="name1"
                                tabIndex={3}
                                required
                                isDark={darkTheme}
                            />
                        )}

                        {requireNameKana && (
                            <TextField
                                label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_NAME_KANA })}
                                bsSize="sm"
                                name="nameKana"
                                autoComplete="name2"
                                tabIndex={3}
                                isDark={darkTheme}
                            />
                        )}

                        {requireEmail && (
                            <TextField
                                label={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_EMAIL_LABEL })}
                                placeholder={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_EMAIL })}
                                tabIndex={3}
                                bsSize="sm"
                                name="email"
                                autoComplete="email"
                                type="email"
                                required
                                isDark={darkTheme}
                            />
                        )}

                        {requirePhoneNumber && (
                            <PhoneNumberField
                                label={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL })}
                                tabIndex={3}
                                bsSize="sm"
                                name="phoneNumber"
                                autoComplete="phone"
                                type="phone"
                                required
                                isDark={darkTheme}
                                forcedCountryCode={isPaidy ? "81" : null}
                            />
                        )}

                        <CustomFields disabled={false} isDark={darkTheme} />
                    </Col>
                </Row>

                {showPrivacyLink && <PrivacyLink isDark={darkTheme} />}

                <DialogButton disabled={processing} tabIndex={10}>
                    {nextButtonLabel || submitButtonText || (isPaidy && !requireBillingAddress)
                        ? formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_SUBMIT })
                        : formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_NEXT })}
                </DialogButton>
            </Form>
        );
    }
);

export default Content;
