import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { useIntl } from "react-intl";
import { hover, HoverProps } from "reactcss";
import { useButtonOutlinedStyles, useButtonStyles, useTheme } from "checkout/ts/hooks/useButtonStyles";
import { getMethodBrandLabelId, getPaymentMethodLabel } from "checkout/ts/locale";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { PaymentMethodBrand } from "checkout/ts/resources/CheckoutInfoBrand";
import { amountForCurrency } from "checkout/ts/utils/money";
import classnames from "classnames";
import { PatchedCardBrand } from "common/types";
import { PaymentType } from "univapay-node";

import { CardBrandIcon } from "../icons/CardBrandIcon";

type OwnProps = HoverProps<void> &
    ButtonProps & {
        paymentType: PaymentType;
        brands: PaymentMethodBrand[];
        currency: string;

        isSubmitButton?: boolean;
        hasAllBrands?: boolean;
        fees?: number;
    };

const getPaymentMethodIcon = (paymentType: PaymentType, brands: PaymentMethodBrand[]) => {
    switch (paymentType) {
        case PaymentType.CARD: {
            return (
                <div className="card-brand-logo-wrapper">
                    <div className="card-brand-logo">
                        {brands.map((brand) => CardBrandIcon({ brand: brand as PatchedCardBrand }))}
                    </div>
                </div>
            );
        }

        case PaymentType.KONBINI:
        case PaymentType.PAIDY:
        case PaymentType.ONLINE:
        case PaymentType.BANK_TRANSFER:
            return null;
    }
};

export const PaymentMethodButton = hover(
    ({ hover, paymentType, brands, isSubmitButton = false, hasAllBrands, fees, currency, ...props }: OwnProps) => {
        const { formatMessage, formatNumber } = useIntl();
        const style = isSubmitButton ? useButtonStyles(hover) : useButtonOutlinedStyles(hover);
        const dark = useTheme();

        return (
            <Button
                {...props}
                data-name={`payment-method-${paymentType}${hasAllBrands ? "" : `-${fees}`}`}
                style={style}
                className="payment-method"
                block>
                <div>
                    <div data-name="payment-method-brands" className="payment-method-brands">
                        {hasAllBrands
                            ? formatMessage({ id: getPaymentMethodLabel(paymentType) })
                            : brands
                                  .map((brand) => formatMessage({ id: getMethodBrandLabelId(paymentType, brand) }))
                                  .join(formatMessage({ id: LOCALE_LABELS.COMMON_COMMA }))}
                    </div>

                    {!!fees && !!currency && (
                        <div className={classnames("payment-method-fees", { dark })} data-name="payment-method-fees">
                            {`+${formatNumber(amountForCurrency(fees, currency), { style: "currency", currency })}`}
                        </div>
                    )}
                </div>

                {getPaymentMethodIcon(paymentType, brands)}
            </Button>
        );
    }
);
