import React from "react";
import { Alert, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { faCreditCard, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TokensMap } from "checkout/ts/redux/models/tokens";
import { PaymentMethodBrand } from "checkout/ts/resources/CheckoutInfoBrand";
import { cardBrandAPIName } from "checkout/ts/utils/cards";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { isDarkTheme } from "checkout/ts/utils/colors";
import classnames from "classnames";
import { NewCardBrand, PatchedCardBrand } from "common/types";
import {
    hasBlankSpace,
    hasCardHolderValidLength,
    hasValidCardHolderNameCharacter,
    trimConsecutiveSpaces,
} from "common/validation/card";
import Payment from "payment";
import { compose } from "recompose";
import { Form, FormSection, InjectedFormProps, reduxForm } from "redux-form";
import {
    PaymentType,
    ResponseError,
    ResponseErrorCode,
    TransactionTokenCardData,
    TransactionTokenItem,
} from "univapay-node";

import { FORM_CHECKOUT_NAME } from "../../../../../common/constants";
import { generate } from "../../../../../common/random";
import { errorLabel } from "../../../errors/utils";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { validationToErrors } from "../../../utils/errors";
import { FormCheckoutData } from "../../checkout/FormCheckout";
import { StepTitle } from "../../common/StepTitle";
import { TextField } from "../../forms/Text";
import { CardCVVInput } from "../../forms/text/CardCVVInput";
import { CardExpInput } from "../../forms/text/CardExpInput";
import { CardNumberInput } from "../../forms/text/CardNumberInput";
import { CardBrandIcon } from "../../icons/CardBrandIcon";
import { getCardBrandConfiguration } from "../card/utils/cardBrandConfiguration";
import { validateEmail } from "../card/utils/validateEmail";
import { AcceptField, Button, PrivacyLink } from "../common/FormData";

import { FormDataCard } from "./index";

export type CardData = TransactionTokenCardData & {
    accept: boolean;
    cvvAuthorize: {
        enabled: boolean;
    };
};

export type CardDataRaw = Omit<CardData, "expMonth" | "expYear"> & {
    exp: string;
};

export type FullCardData = CardData;

export interface FormDataCardData<Data = FullCardData> {
    email?: string;
    /**
     * User information such as email, phone number and billing address
     */
    data: Data;
}

const getLatestRegisteredMerchantToken = (tokens: TokensMap) =>
    Object.values(tokens || {}).reduce(
        (acc, curr) => (new Date(acc?.createdOn) > new Date(curr?.createdOn) ? acc : curr),
        null
    );

const stateSelector = (state: StateShape) => {
    const { checkout, application, configuration, product, tokens } = state;
    const { error } = checkout;

    const {
        currency,
        params: { confirmationRequired, requireEmail, ...params },
        univapayCustomerId,
    } = getCheckoutParams(application.params, product.products, configuration.data);

    const brands = configuration.paymentMethods.find(({ key }) => key === checkout.paymentMethodKey)?.brands || [];

    // For this flow merchants should register one card per merchant.
    // We can just assume it's the first token if there was any token registered before.
    const existingToken = getLatestRegisteredMerchantToken(tokens.tokens);

    const supportsPrivateLabel = brands.some((brand) => brand === NewCardBrand.PRIVATE_LABEL);

    return {
        currency,
        configuration,
        initialValues: {
            email: params?.email,
            data: {
                cardholder: params?.cardholder,
                exp: params?.exp,
            },
        },
        univapayCustomerId,
        errorCVV:
            error instanceof ResponseError && error.errorResponse.code === ResponseErrorCode.CVVRequired ? error : null,
        useConfirmation: confirmationRequired,
        darkTheme: isDarkTheme(state),
        supportsPrivateLabel,
        brands,
        selectedToken: existingToken,
        paymentMethods: state.configuration.paymentMethods,
        requireEmail,
        isAcceptRequired: !application.params.params.hideRecurringCheckbox,
        hidePrivacyLink: application.params.params.hidePrivacyLink,
    };
};

type OwnProps = InjectedFormProps<FormDataCardData<CardDataRaw>> & ReturnType<typeof stateSelector>;

const getPaymentTypeIcon = (brands: PatchedCardBrand[]) => (
    <div className="card-brand-logo">{brands.map((brand) => CardBrandIcon({ brand }))}</div>
);

export const generateValidation = (
    values: Partial<FormDataCardData<CardDataRaw>>,
    props: {
        supportsPrivateLabel: boolean;
        brands: PaymentMethodBrand[];
        requireEmail: boolean;
        isAcceptRequired: boolean;
    }
) => {
    const { supportsPrivateLabel, brands, requireEmail, isAcceptRequired } = props;
    const { data, email } = values;
    const { cardholder, cardNumber, exp, cvv, accept } = data || {};

    const { cardBrandSupported, requiresFullName } = getCardBrandConfiguration(values, supportsPrivateLabel);
    const cardType = Payment.fns.cardType(cardNumber);

    return {
        "data.cardholder": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !cardholder,
            [LOCALE_LABELS.VALIDATION_INVALID_CHARACTERS]: cardholder && !hasValidCardHolderNameCharacter(cardholder),
            [LOCALE_LABELS.VALIDATION_INVALID_LENGTH]: cardholder && !hasCardHolderValidLength(cardholder),
            [LOCALE_LABELS.VALIDATION_MISSING_SPACE]: cardholder && requiresFullName && !hasBlankSpace(cardholder),
        },
        "data.cardNumber": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !cardNumber,
            [ResponseErrorCode.CardBrandNotSupported]: !cardBrandSupported,
            [LOCALE_LABELS.VALIDATION_CARD_NUMBER]:
                !!cardNumber &&
                (cardType
                    ? !Payment.fns.validateCardNumber(cardNumber) || !brands?.includes(cardBrandAPIName(cardType))
                    : !supportsPrivateLabel),
        },
        "data.exp": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !exp,
            [LOCALE_LABELS.VALIDATION_DATE]: (() => {
                if (!exp) {
                    return false;
                }

                const { month, year } = Payment.fns.cardExpiryVal(exp);
                // year < 100 means that one digit is missing so parsing can not kick in
                return !month || !year || month > 12 || year < 100;
            })(),
            [LOCALE_LABELS.VALIDATION_CARD_DATE_EXPIRED]: exp && !Payment.fns.validateCardExpiry(exp),
        },
        "email": validateEmail(email, requireEmail),
        "data.cvv": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !cvv,
            [ResponseErrorCode.InvalidCVV]: cvv && !Payment.fns.validateCardCVC(cvv),
        },
        "data.accept": { [LOCALE_LABELS.VALIDATION_REQUIRED]: isAcceptRequired && !accept },
    };
};

const validate = (values: Partial<FormDataCardData<CardDataRaw>>, props: OwnProps) => {
    const validationObject = generateValidation(values, props);

    return validationToErrors(validationObject);
};

export const normalizeValues = (raw: FormDataCard<CardDataRaw>, selectedToken: TransactionTokenItem) => {
    const { data, ...values } = raw;
    const { exp = "", cardholder: rawCardholder = "", ...rawData } = data || {};
    const cardholder = trimConsecutiveSpaces(rawCardholder);
    const [expMonth, expYear] = exp.split("/");

    const dataWithNormalization = {
        ...rawData,
        expMonth,
        expYear,
        cardholder,
        ...(Boolean(selectedToken) && { token: selectedToken.id }),
    };

    return { ...values, data: dataWithNormalization };
};

const Content = compose<OwnProps, OwnProps>(
    connect(stateSelector),
    reduxForm({
        form: FORM_CHECKOUT_NAME,
        validate,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(
    ({
        handleSubmit,
        errorCVV,
        useConfirmation,
        darkTheme,
        supportsPrivateLabel,
        brands,
        selectedToken,
        isAcceptRequired,
        hidePrivacyLink,
    }) => {
        const { formatMessage } = useIntl();

        const renderCVVError = Boolean(errorCVV);

        const {
            checkout: { process: processCheckout },
            userData: { setUserData },
        } = useDispatch<Dispatch>();

        const handleSubmitForm = (values: FormDataCard<CardDataRaw>) => {
            setUserData(values);
            const normalizedValues = normalizeValues(values, selectedToken) as FormCheckoutData;

            processCheckout({ values: normalizedValues });
        };

        const tooltipCvv = (
            <Tooltip id={generate()} className="tooltip-small">
                {formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC_INFO })}
            </Tooltip>
        );

        return (
            <Form
                noValidate
                onSubmit={handleSubmit(handleSubmitForm)}
                className="register-credit-card data content-form">
                <Row>
                    <Col xs={12}>
                        <StepTitle className="title">
                            <div className="title-labels-wrapper">
                                <div className="title-label">
                                    {formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_CARD_TITLE })}
                                </div>

                                {supportsPrivateLabel && (
                                    <div
                                        className={classnames("title-subtext-label", {
                                            "dark-theme": darkTheme,
                                        })}>
                                        {formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS })}
                                    </div>
                                )}
                            </div>

                            {getPaymentTypeIcon(brands as PatchedCardBrand[])}
                        </StepTitle>
                    </Col>
                    <Col xs={12}>
                        <FormSection name="data">
                            <TextField
                                label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL })}
                                placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARDHOLDER })}
                                normalize={(value) => value?.replace("　", " ")?.replace(/[0-9]/g, "")}
                                bsSize="sm"
                                name="cardholder"
                                autoComplete="cc-name"
                                tabIndex={2}
                                required
                                isDark={darkTheme}
                            />

                            <TextField
                                name="cardNumber"
                                label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL })}
                                placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARD_NUMBER })}
                                bsSize="sm"
                                tabIndex={3}
                                componentClass={CardNumberInput}
                                addons={<FontAwesomeIcon icon={faCreditCard} />}
                                required
                                isDark={darkTheme}
                            />

                            <TextField
                                label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL })}
                                validationLabel={formatMessage({
                                    id: LOCALE_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION,
                                })}
                                bsSize="sm"
                                name="exp"
                                tabIndex={4}
                                width={76}
                                componentClass={CardExpInput}
                                showPlaceholder
                                required
                                isDark={darkTheme}
                            />

                            <TextField
                                label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC_LABEL })}
                                placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC })}
                                bsSize="sm"
                                name="cvv"
                                tabIndex={5}
                                width={64}
                                componentClass={CardCVVInput}
                                addons={{
                                    header: (
                                        <OverlayTrigger placement="left" overlay={tooltipCvv}>
                                            <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                                        </OverlayTrigger>
                                    ),
                                }}
                                required
                                isDark={darkTheme}
                            />

                            {renderCVVError && (
                                <Alert bsStyle="error">
                                    {formatMessage({ id: errorLabel(errorCVV, PaymentType.CARD) })}
                                </Alert>
                            )}
                        </FormSection>

                        <TextField
                            label={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_EMAIL_LABEL })}
                            placeholder={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_EMAIL })}
                            tabIndex={6}
                            bsSize="sm"
                            name="email"
                            autoComplete="email"
                            type="email"
                            required
                            isDark={darkTheme}
                        />
                    </Col>

                    {isAcceptRequired && (
                        <FormSection name="data">
                            <AcceptField isDark={darkTheme} />
                        </FormSection>
                    )}
                </Row>

                {!hidePrivacyLink && <PrivacyLink isDark={darkTheme} />}

                <Button label={useConfirmation && formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_NEXT })} />
            </Form>
        );
    }
);

export default Content;
