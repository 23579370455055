import React from "react";

export const AlipayOnlineIcon = () => (
    <svg width="20" height="18" viewBox="0 0 980 980" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-10 -10)">
            <rect width="980" height="980" fill="#1f9fde" rx="120" transform="translate(10 10)" />
            <path
                fill="#fff"
                d="M437.4 185.2l-2 62H188v47.3h250.7v82H231.8v45l210-2 210.5-1.9-3.8 15.1c-9.4 36.7-49.5 134.6-57 137.8-4.4 1.2-35.1-5.7-69.6-15.8C417.2 522.5 365.2 513 294.4 513c-72 .7-119 13.9-164.2 46.1-87.7 63.1-94.6 187-13.2 255.8 86.5 73.3 228.2 80.3 356.6 17 45.8-22 127.9-82 155.5-113l11.3-13.3 35.6 17.7c35.7 18.2 256.4 121.3 294 137l20 8.9V707l-54.5-14.5a3424 3424 0 0 1-216.8-69l-15.7-6.2 20.7-43c22-44.2 65.8-169.3 65.8-187.6 0-8.8-12.6-10.1-109.8-10.1H570.2v-82.1h263.2v-41.7c0-2-59.5-3.2-131.6-2.5L570 251V124h-131zm-24.5 412c32.5 11.4 75.8 29 95.8 39.8l36.4 19.6-20 25.2a389.9 389.9 0 0 1-58.3 53.7c-71.5 53.7-138 74.5-223.1 69.5-89-5.1-136.7-41.7-142.3-110-3.8-42.2 5.6-66.3 34.4-88.4 36.4-27.8 65.8-34.1 146.1-32.2 61.4 2 80.9 5.7 131 22.8z"
            />
        </g>
    </svg>
);
