import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardVisa = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="visa-card-icon" viewBox="0 0 780 501" {...props}>
        <path
            d="M780,470.59A29.17,29.17,0,0,1,751.07,500H28.93A29.17,29.17,0,0,1,0,470.59V29.41A29.17,29.17,0,0,1,28.93,0H751.07A29.17,29.17,0,0,1,780,29.41Z"
            fill="#1a1f71"
        />
        <path
            d="M296.46,146.49l-86.79,207.1H153L110.35,188.33c-2.63-10.19-4.87-13.9-12.75-18.22-12.91-7-34.13-13.53-52.88-17.6l1.31-6h91.14c11.62,0,22.07,7.76,24.7,21.12l22.57,119.86,55.74-141ZM518.34,285.94c.25-54.63-75.59-57.6-75-82,.15-7.46,7.22-15.35,22.7-17.35,7.69-1,28.85-1.77,52.84,9.24l9.4-43.89a143.92,143.92,0,0,0-50.11-9.17c-52.95,0-90.22,28.16-90.54,68.46-.34,29.8,26.6,46.43,46.92,56.35,20.86,10.16,27.84,16.64,27.77,25.75-.14,13.9-16.65,20-32,20.24-26.94.42-42.57-7.28-55.05-13.09l-9.7,45.41c12.5,5.73,35.65,10.72,59.6,11,56.27,0,93.08-27.86,93.26-70.92m139.83,67.65h49.56l-43.25-207.1H618.73a24.44,24.44,0,0,0-22.81,15.19L515.58,353.59h56.24L583,322.66h68.74Zm-59.78-73.38,28.21-77.76,16.23,77.76ZM373,146.49l-44.33,207.1H275.11l44.34-207.1Z"
            fill="#fff"
        />
    </Icon>
);

export default CardVisa;
