import { IntlShape } from "react-intl";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { StateShape } from "checkout/ts/redux/store";
import { sdk } from "checkout/ts/SDK";
import { amountForCurrency } from "checkout/ts/utils/money";
import { ParametersError } from "common/errors/ParametersError";
import { SubscriptionPeriod } from "univapay-node";

export const formatMoney = (amount: number, currency: string, formatNumber: IntlShape["formatNumber"]) =>
    formatNumber(amountForCurrency(amount, currency), { style: "currency", currency });

export const validatedCheckoutConfig = (configuration: StateShape["configuration"], allowCardInstallments: boolean) => {
    const { paymentMethods, supportedPaymentMethods, data } = configuration;

    if (!paymentMethods.length) {
        // No supported payment methods (payment type / online brand / test online brand) are available
        return supportedPaymentMethods.length
            ? new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE)
            : new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE);
    }

    if (allowCardInstallments && !data.installmentsConfiguration?.enabled) {
        // Allow card installment but the store does not support it
        return new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT);
    }

    return null;
};

export const isDevelop = () =>
    sdk.api.endpoint?.includes("api.gyro-n.money") ||
    sdk.api.endpoint?.includes("localhost") ||
    sdk.api.endpoint?.includes("api.test");

/**
 * Convert to subscription period when possible. Otherwise returns `null`
 */
export const convertCyclicalToSubscriptionPeriod = (period: string) => {
    switch (period) {
        case "P1D":
            return SubscriptionPeriod.DAILY;

        case "P7D":
        case "P1W":
            return SubscriptionPeriod.WEEKLY;

        case "P14D":
        case "P2W":
            return SubscriptionPeriod.BIWEEKLY;

        case "P1M":
            return SubscriptionPeriod.MONTHLY;

        case "P2M":
            return SubscriptionPeriod.BIMONTHLY;

        case "P3M":
            return SubscriptionPeriod.QUARTERLY;

        case "P6M":
            return SubscriptionPeriod.SEMIANNUALLY;

        case "P12M":
        case "P1Y":
            return SubscriptionPeriod.ANNUALLY;

        default:
            return null;
    }
};

/**
 * Convert to subscription cyclical period when possible. Otherwise returns `null`
 */
export const convertSubscriptionPeriodToCyclical = (period: string) => {
    switch (period) {
        case SubscriptionPeriod.DAILY:
            return "P1D";

        case SubscriptionPeriod.WEEKLY:
            return "P1W";

        case SubscriptionPeriod.BIWEEKLY:
            return "P2W";

        case SubscriptionPeriod.MONTHLY:
            return "P1M";

        case SubscriptionPeriod.BIMONTHLY:
            return "P2M";

        case SubscriptionPeriod.QUARTERLY:
            return "P3M";

        case SubscriptionPeriod.SEMIANNUALLY:
            return "P6M";

        case SubscriptionPeriod.ANNUALLY:
            return "P1Y";

        default:
            return period;
    }
};
