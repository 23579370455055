import React, { useEffect, useState } from "react";
import { Col, FormGroup, Radio, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getPaymentMethodLabel } from "checkout/ts/locale";
import { getDefaultSelectedTokenId, TokensMap } from "checkout/ts/redux/models/tokens";
import { isDarkTheme } from "checkout/ts/utils/colors";
import { UnivaMetadata } from "checkout/ts/utils/metadata";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { formatTokenData } from "checkout/ts/utils/tokens";
import classnames from "classnames";
import { NewCardBrand, PatchedCardBrand } from "common/types";
import {
    change,
    Field,
    Form,
    getFormValues,
    InjectedFormProps,
    reduxForm,
    registerField as register,
} from "redux-form";
import { PaymentType, TransactionTokenItem } from "univapay-node";

import { FORM_TOKEN_SELECT_NAME } from "../../../../../common/constants";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { StepTitle } from "../../common/StepTitle";
import { CardBrandIcon } from "../../icons/CardBrandIcon";
import { DialogButton } from "../../layout/DialogButton";

import { getRequiredFields } from "./utils/customerInfo";
import { RegisteredTokenSelect } from "./RegisteredTokenSelect";

export const getTokens = (tokens: TokensMap, paymentType: PaymentType) =>
    Object.keys(tokens || {}).reduce((acc: TransactionTokenItem[], tokenId: string) => {
        const token = tokens[tokenId];
        return acc.concat(token.paymentType === paymentType ? [token] : []);
    }, []);

export const showCustomerIdScreen = (tokens: TokensMap, paymentType: PaymentType) =>
    getTokens(tokens, paymentType)?.length > 0;

const stateSelector = (state: StateShape) => {
    const { selectedTokenId } = (getFormValues(FORM_TOKEN_SELECT_NAME)(state) || {}) as { selectedTokenId };
    const { tokens } = state.tokens;
    const { horizontalInlineLayout, customFields } = state.application.params.params;
    const { paymentType, paymentMethodKey } = state.checkout;
    const { data: configurationData, paymentMethods } = state.configuration;

    const brands = paymentMethods.find(({ key }) => key === paymentMethodKey)?.brands || [];

    const supportsPrivateLabel = brands.some((brand) => brand === NewCardBrand.PRIVATE_LABEL);

    const { requireBillingAddress } = getRequiredFields(
        paymentType,
        state.application.params.params,
        configurationData
    );

    return {
        tokens,
        selectedTokenId,
        initialValues: { selectedTokenId },
        darkTheme: isDarkTheme(state),
        horizontalInlineLayout,
        paymentMethodKey,
        customFields,
        brands,
        supportsPrivateLabel,
        requireBillingAddress,
    };
};

type OwnProps = InjectedFormProps<OwnProps> & {
    paymentType: PaymentType;
    inline?: boolean;
    nextStepUri?: (selectedTokenId: string, paymentMethodKey: string) => string;

    inlineStyles?: Record<
        "textFieldCustomStyles" | "toggleFieldCustomStyles" | "selectFieldCustomStyles" | "radioFieldCustomStyles",
        Record<string, Record<string, string>>
    >;
};

enum RadioSelection {
    REGISTERED = "registered",
    NEW = "new",
}

const ADDRESS_REQUIRED_FIELDS = new Set([
    UnivaMetadata.ADDRESS_CITY,
    UnivaMetadata.ADDRESS_COUNTRY,
    UnivaMetadata.ADDRESS_ZIP,
    UnivaMetadata.ADDRESS_STATE,
    UnivaMetadata.ADDRESS_LINE1,
]);

export const Content = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    form: FORM_TOKEN_SELECT_NAME,
})(({ handleSubmit, paymentType, inline, inlineStyles, nextStepUri }: OwnProps) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const dispatch = useDispatch<Dispatch>();
    const { push: redirect } = useHistory();

    const [activeRadio, setActiveRadio] = useState<string>(null);

    const {
        darkTheme,
        horizontalInlineLayout,
        tokens,
        selectedTokenId,
        paymentMethodKey,
        customFields,
        brands,
        supportsPrivateLabel,
        requireBillingAddress,
    } = useSelector(stateSelector);

    const savedTokens = getTokens(tokens, paymentType);

    const [selectedToken, setSelectedToken] = useState(savedTokens.length > 0 ? savedTokens[0].id : "");
    const [radioSelection, setRadioSelection] = useState(
        savedTokens.length > 0 ? RadioSelection.REGISTERED : RadioSelection.NEW
    );

    const isAddressRegisteredWithToken =
        tokens?.[selectedTokenId] &&
        Object.keys(tokens[selectedTokenId]?.metadata).some((key: UnivaMetadata) => ADDRESS_REQUIRED_FIELDS.has(key));

    useEffect(() => {
        if (savedTokens.length > 0) {
            dispatch(register(FORM_TOKEN_SELECT_NAME, "selectedTokenId", "Field"));
            dispatch(change(FORM_TOKEN_SELECT_NAME, "selectedTokenId", getDefaultSelectedTokenId(savedTokens)));
        }
    }, []);

    useEffect(() => {
        dispatch(change(FORM_TOKEN_SELECT_NAME, "selectedTokenId", getDefaultSelectedTokenId(savedTokens)));
    }, []);

    const handleFormSubmit = () => {
        redirect(
            nextStepUri
                ? nextStepUri(selectedTokenId, paymentMethodKey)
                : getStepUrl(
                      paymentType,
                      paymentMethodKey,
                      !customFields?.length && selectedTokenId
                          ? !isAddressRegisteredWithToken && requireBillingAddress
                              ? StepName.ADDRESS
                              : StepName.DATA
                          : StepName.INFO
                  )
        );
    };

    const [titleLabel, newLabel] = (() => {
        switch (paymentType) {
            case PaymentType.CARD:
                return [LOCALE_LABELS.FORM_CARD_SELECTOR_TITLE, LOCALE_LABELS.FORM_CARD_FIELDS_NEW_CARD];
            case PaymentType.KONBINI:
                return [LOCALE_LABELS.FORM_KONBINI_SELECTOR_TITLE, LOCALE_LABELS.FORM_KONBINI_FIELDS_OTHER];
            case PaymentType.PAIDY:
                return [LOCALE_LABELS.FORM_PAIDY_SELECTOR_TITLE, LOCALE_LABELS.FORM_PAIDY_FIELDS_NEW];
        }
    })();

    const getPaymentTypeIcon = (brands: PatchedCardBrand[]) => (
        <div className="card-brand-logo">{brands.map((brand) => CardBrandIcon({ brand }))}</div>
    );

    const RadioGroup = ({ input }) => {
        const handleRadioChange = (event) => {
            setRadioSelection(event.target.value);
            input.onChange(event.target.value === RadioSelection.REGISTERED ? selectedToken : "");

            // HACK: Focus can not be set without making the change function bugged
            // As the tab index for the radio do not make sense now it should only be focused on click
            // which covers most of the cases
            setActiveRadio(RadioSelection.NEW);
        };

        const handleDropdownChange = (event) => {
            setSelectedToken(event.target.value);
            setRadioSelection(RadioSelection.REGISTERED);
            input.onChange(event.target.value);

            // HACK: Focus can not be set without making the change function bugged
            // As the tab index for the radio do not make sense now it should only be focused on click
            // which covers most of the cases
            setActiveRadio(RadioSelection.REGISTERED);
        };

        const currentMethodTokens = getTokens(tokens, paymentType);
        const hasSingleToken = currentMethodTokens?.length === 1 && !!currentMethodTokens[0];

        const getInputStyle = (value: RadioSelection) =>
            inlineStyles
                ? {
                      ...inlineStyles.radioFieldCustomStyles?.style,
                      ...inlineStyles.radioFieldCustomStyles?.inputStyle,
                      ...(activeRadio === value ? inlineStyles.radioFieldCustomStyles?.focusStyle : {}),
                  }
                : {};

        return inline ? (
            <>
                <Radio
                    name="token-selection-radio"
                    value={RadioSelection.NEW}
                    checked={radioSelection === RadioSelection.NEW}
                    style={getInputStyle(RadioSelection.NEW)}
                    onChange={handleRadioChange}
                    onBlur={() => {
                        if (activeRadio === RadioSelection.NEW) {
                            setActiveRadio(null);
                        }
                    }}>
                    <>
                        <span className="checkmark" />

                        <span style={inlineStyles?.radioFieldCustomStyles?.labelStyle}>
                            {formatMessage({ id: newLabel })}
                        </span>
                    </>
                </Radio>

                <Radio
                    name="token-selection-radio"
                    value={RadioSelection.REGISTERED}
                    checked={radioSelection === RadioSelection.REGISTERED}
                    style={getInputStyle(RadioSelection.REGISTERED)}
                    onChange={handleRadioChange}
                    onBlur={() => {
                        if (activeRadio === RadioSelection.REGISTERED) {
                            setActiveRadio(null);
                        }
                    }}>
                    <>
                        <span className="checkmark" />

                        <span style={inlineStyles?.radioFieldCustomStyles?.labelStyle}>
                            {hasSingleToken
                                ? formatTokenData(currentMethodTokens[0], formatMessage)
                                : formatMessage({ id: titleLabel })}
                        </span>
                    </>
                </Radio>

                {!hasSingleToken && radioSelection === RadioSelection.REGISTERED && (
                    <div className="token-selection-form">
                        <RegisteredTokenSelect
                            value={selectedToken}
                            label={formatMessage({ id: getPaymentMethodLabel(paymentType) })}
                            required
                            onChange={handleDropdownChange}
                            inline={inline}
                            isDark={darkTheme}
                            isHorizontal={horizontalInlineLayout}
                            labelStyle={inlineStyles?.selectFieldCustomStyles?.labelStyle}
                            focusStyle={inlineStyles?.selectFieldCustomStyles?.focusStyle}
                            inputStyle={inlineStyles?.selectFieldCustomStyles?.inputStyle}>
                            {currentMethodTokens.map((token) => (
                                <option key={token.id} value={token.id} className="card-selector-option">
                                    {formatTokenData(token, formatMessage)}
                                </option>
                            ))}
                        </RegisteredTokenSelect>
                    </div>
                )}
            </>
        ) : (
            <>
                <Radio
                    name="token-selection-radio"
                    value={RadioSelection.REGISTERED}
                    checked={radioSelection === RadioSelection.REGISTERED}
                    style={getInputStyle(RadioSelection.REGISTERED)}
                    onChange={handleRadioChange}
                    onBlur={() => {
                        if (activeRadio === RadioSelection.REGISTERED) {
                            setActiveRadio(null);
                        }
                    }}>
                    <>
                        <span className="checkmark" />

                        <span style={inlineStyles?.radioFieldCustomStyles?.labelStyle}>
                            {hasSingleToken ? (
                                formatTokenData(currentMethodTokens[0], formatMessage)
                            ) : (
                                <RegisteredTokenSelect
                                    label={formatMessage({ id: titleLabel })}
                                    value={selectedToken}
                                    required
                                    onChange={handleDropdownChange}
                                    inline={inline}
                                    isDark={darkTheme}
                                    isHorizontal={horizontalInlineLayout}
                                    labelStyle={inlineStyles?.selectFieldCustomStyles?.labelStyle}
                                    focusStyle={inlineStyles?.selectFieldCustomStyles?.focusStyle}
                                    inputStyle={inlineStyles?.selectFieldCustomStyles?.inputStyle}>
                                    {getTokens(tokens, paymentType).map((token) => (
                                        <option key={token.id} value={token.id} className="card-selector-option">
                                            {formatTokenData(token, formatMessage)}
                                        </option>
                                    ))}
                                </RegisteredTokenSelect>
                            )}
                        </span>
                    </>
                </Radio>

                <Radio
                    name="token-selection-radio"
                    value={RadioSelection.NEW}
                    checked={radioSelection === RadioSelection.NEW}
                    style={getInputStyle(RadioSelection.NEW)}
                    onChange={handleRadioChange}
                    onBlur={() => {
                        if (activeRadio === RadioSelection.NEW) {
                            setActiveRadio(null);
                        }
                    }}>
                    <>
                        <span className="checkmark" />

                        <span style={inlineStyles?.radioFieldCustomStyles?.labelStyle}>
                            {formatMessage({ id: newLabel })}
                        </span>
                    </>
                </Radio>
            </>
        );
    };

    return (
        <Form noValidate onSubmit={handleSubmit(handleFormSubmit)} className="card-selector content-form">
            <div className="token-selection-form">
                <Row>
                    {!inline && (
                        <Col xs={12}>
                            <StepTitle>
                                <div className="title-labels-wrapper">
                                    <div className="payment-type-title">
                                        {formatMessage({
                                            id:
                                                paymentType === PaymentType.CARD
                                                    ? LOCALE_LABELS.PAYMENT_DATA_CARD_TITLE
                                                    : getPaymentMethodLabel(paymentType),
                                        })}
                                    </div>

                                    {paymentType === PaymentType.CARD && supportsPrivateLabel && (
                                        <div className={classnames("title-subtext-label", { "dark-theme": darkTheme })}>
                                            {formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS })}
                                        </div>
                                    )}
                                </div>

                                {paymentType === PaymentType.CARD && (
                                    <div>{getPaymentTypeIcon(brands as PatchedCardBrand[])}</div>
                                )}
                            </StepTitle>
                        </Col>
                    )}

                    <Col xs={12} className={classnames({ "selector-inline": inline, "dark-theme": darkTheme })}>
                        <FormGroup>
                            <Field name="selectedTokenId" component={RadioGroup} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>

            {!inline && <DialogButton>{formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_NEXT })}</DialogButton>}
        </Form>
    );
});

export default Content;
