import { useSelector } from "react-redux";
import { isNil } from "lodash";

import { StateShape } from "../redux/store";
import { getButtonColors, getOutlineStyle, getPrimaryStyle, isDarkTheme } from "../utils/colors";

const stateSelector = (state: StateShape) => ({
    dark: isDarkTheme(state),
    configurationData: state.configuration.data,
    applicationParamsTheme: isNil(state.application.params.params.dark)
        ? null
        : state.application.params.params.dark
        ? "dark"
        : "light",
});

export const useTheme = () => {
    const { dark } = useSelector(stateSelector);

    return dark;
};

export const useButtonStyles = (hover: boolean) => {
    const { configurationData, dark, applicationParamsTheme } = useSelector(stateSelector);

    const colors = getButtonColors(configurationData, dark, applicationParamsTheme);
    const styles = getPrimaryStyle(colors, hover);

    return styles?.button;
};

export const useButtonOutlinedStyles = (hover: boolean) => {
    const { configurationData, dark, applicationParamsTheme } = useSelector(stateSelector);

    const colors = getButtonColors(configurationData, dark, applicationParamsTheme);
    const styles = getOutlineStyle(colors, hover, dark);

    return styles?.button;
};
