import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardUnionpay = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="unionpay-card-icon" viewBox="0 0 780 500" {...props}>
        <g>
            <path
                d="M152.6,0h195C375,0,391.4,22.4,385,50.5L294.8,449.6c-6.4,28-33.7,50.5-61.1,50.5H38.7C11.4,500-5,477.6,1.4,449.5l91.1-399C98,22.4,125.3,0,152.6,0"
                transform="translate(-0.02)"
                fill="#ed171f"
            />
            <path
                d="M331.2,0H555.4c27.3,0,14.6,22.4,8.2,50.5L473.4,449.6c-6.4,28-4.6,50.5-31.9,50.5H217.3c-27.3,0-43.7-22.4-37.4-50.5L270.1,50.5C276.6,22.4,303.9,0,331.2,0"
                transform="translate(-0.02)"
                fill="#082f67"
            />
            <path
                d="M546.3,0h195c27.3,0,43.7,22.4,37.4,50.5L688.5,449.6c-6.4,28-33.7,50.5-61.1,50.5h-195c-27.3,0-43.7-22.4-37.4-50.5L485.2,50.5C491.6,22.4,518.9,0,546.3,0"
                transform="translate(-0.02)"
                fill="#006a65"
            />
            <path
                d="M513.5,375.7h18.2l5.5-17.8H519ZM528,325.2l-6.4,21.5s7.3-3.7,10.9-4.7,9.1-1.9,9.1-1.9l4.6-15H528Zm9.2-30.8L530.8,315s6.4-2.8,10.9-4.7a47,47,0,0,1,9.1-.9l4.6-15Zm41,0-23.7,80.4h6.4l-5.5,16.8H549l-1.8,5.6H524.4l1.8-5.6H480.6l4.6-15h4.6l23.7-81.3,4.6-16.8h22.8l-2.7,8.4s6.4-4.7,11.8-6.5,39.2-1.9,39.2-1.9l-4.6,16.8h-6.4v-.9Z"
                transform="translate(-0.02)"
                fill="#fff"
                fillRule="evenodd"
            />
            <path
                d="M588.2,278.5h24.6v9.3c0,1.9.9,1.9,3.6,1.9H621l-4.6,15.9H602.7c-11.8.9-15.5-4.7-15.5-10.3Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M590.9,352.3H567.2l3.6-14h26.4l3.6-12.1H574.4l4.6-15.9h73.8l-4.6,15.9H623.6L620,338.3h24.6l-3.6,14H614.6l-4.6,5.6h11l2.7,17.8c0,1.9,0,2.8.9,3.7s3.6.9,5.5.9h3.6l-5.5,15.9H614.5c-2.7,0-4.6-1.9-6.4-2.8a9.85,9.85,0,0,1-4.6-5.6L600.8,371,589,387.8c-3.6,5.6-9.1,9.3-18.2,9.3H553.5l4.6-15h6.4a6.93,6.93,0,0,0,4.6-1.9c.9-.9,2.7-.9,3.6-2.8Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M334.9,314h62l-4.6,15H367.7l-3.6,13.1h25.5L385,358H359.5l-6.4,21.5c-.9,1.9,6.4,2.8,8.2,2.8l12.8-1.9-5.5,17.8H340.4a18.35,18.35,0,0,1-6.4-.9,6.63,6.63,0,0,1-4.6-3.7c-.9-1.9-2.7-2.8-1.8-6.5l8.2-28H322.1l4.6-15.9h14.6l3.6-13.1H331.2Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M375.9,286.9h25.5l-4.6,15.9H362.2l-3.6,3.7c-1.8,1.9-1.8.9-4.6,1.9a27.88,27.88,0,0,1-11.8,2.8H331.3l4.6-15h3.6c2.7,0,4.6,0,5.5-.9s2.7-1.9,3.6-4.7l6.4-12.1h25.5Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M425.1,313.1a40.66,40.66,0,0,1,19.1-8.4c2.7-.9,20,0,20,0l2.7-8.4H431.4Zm33.7,6.5H423.3l-1.8,7.5h31c3.6-.9,4.6,0,4.6,0Zm-46.5-41.1h21.9l-2.7,11.2s6.4-5.6,11.8-7.5c4.6-1.9,15.5-3.7,15.5-3.7h34.6l-11.8,41.1c-1.8,6.5-4.6,11.2-5.5,14-.9,1.9-2.7,3.7-6.4,5.6a14.22,14.22,0,0,1-8.2,2.8H416.9l-9.1,32.7a17.09,17.09,0,0,0-.9,5.6c.9.9,1.8,1.9,3.6,1.9l14.6-1.9-5.5,17.8H391.4c-2.7,0-5.5,0-7.3-1.9s-3.6-2.8-3.6-4.7.9-4.7,1.8-8.4Z"
                transform="translate(-0.02)"
                fill="#fff"
                fillRule="evenodd"
            />
            <path
                d="M476.1,343.9l-1.8,9.3c-.9,2.8-1.8,5.6-3.6,7.5-2.7,1.9-5.5,3.7-11.8,3.7l-12.8,1.9v11.2c0,2.8.9,2.8.9,3.7.9.9.9.9,1.8.9h3.6l11.8-.9-4.6,15.9H446c-9.1,0-16.4,0-18.2-1.9s-2.7-2.8-2.7-6.5l.9-43h21v8.4h5.5c1.8,0,2.7,0,3.6-.9s.9-.9,1.8-1.9l1.8-6.5h16.4v-.9Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M175.4,127.1c-.9,3.7-14.6,67.3-14.6,67.3-2.7,13.1-4.6,22.4-11.8,28-3.6,3.7-9.1,4.7-14.6,4.7-9.1,0-13.7-4.7-14.6-13.1v-2.8l2.7-17.8s14.6-57.9,16.4-66.4v-.9H106.1a22.09,22.09,0,0,1-.9,3.7L90.6,195.2l-.9,5.6L87,218.7a28.28,28.28,0,0,0,2.7,13.1c6.4,12.1,25.5,14,36.4,14,13.7,0,26.4-2.8,35.5-8.4,15.5-9.3,19.1-23.4,22.8-36.4l1.8-6.5s14.6-60.7,17.3-68.2v-.9c-19.9,2.6-26.3,2.6-28.1,1.7"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M256.5,247.7H231.9v-.9c.9-4.7,1.8-8.4,2.7-13.1l1.8-6.5c1.8-9.3,4.6-20.6,4.6-24.3,0-1.9.9-7.5-4.6-7.5-2.7,0-4.6.9-7.3,2.8-1.8,4.7-4.6,19.6-5.5,26.2-2.7,14-2.7,15-4.6,21.5l-.9.9H192.6l-.9-.9c1.8-8.4,3.6-15.9,5.5-24.3,4.6-22.4,6.4-30.8,7.3-41.1l.9-.9c10.9-1.9,13.7-1.9,26.4-4.7l.9.9-1.8,6.5c1.8-.9,3.6-2.8,6.4-3.7a36.69,36.69,0,0,1,15.5-3.7c5.5,0,10.9,1.9,13.7,7.5s.9,13.1-1.8,26.2l-1.8,6.5c-2.7,15-3.6,17.8-5.5,29Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M295.7,247.7H282a71,71,0,0,0-12.8.9v-1.8c1.8-5.6,2.7-7.5,2.7-10.3.9-1.9,1.8-3.7,2.7-10.3,1.8-7.5,2.7-13.1,3.6-17.8s.9-8.4,1.8-13.1h1.8c5.5-.9,10-1.9,13.7-1.9,3.6-.9,7.3-.9,13.7-2.8v1.8c-.9,4.7-2.7,9.3-3.6,14a80.06,80.06,0,0,1-3.6,14c-1.8,10.3-2.7,14-3.6,16.8s-.9,3.7-1.8,9.3l-.9,1.2Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M358.6,212.1c-.9,2.8-2.7,12.1-5.5,16.8-1.8,2.8-4.6,4.7-7.3,4.7-.9,0-5.5,0-5.5-7.5a48.43,48.43,0,0,1,1.8-11.2c2.7-12.1,5.5-21.5,13.7-21.5,4.6-.9,5.5,6.6,2.8,18.7m25.5,1c3.6-15.9.9-22.4-2.7-27.1-5.5-6.5-13.7-8.4-23.7-8.4-5.5,0-19.1.9-29.2,10.3-7.3,7.5-10.9,16.8-12.8,26.2s-4.6,26.2,10.9,32.7c4.6,1.9,11.8,2.8,15.5,2.8,10.9,0,22.8-2.8,31-12.1a68.07,68.07,0,0,0,11-24.4"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M623.7,248.6H597.3l-.9-.9c2.7-11.2,6.4-23.4,8.2-34.6,3.6-15,4.6-21.5,5.5-29.9l.9-.9c11.8-1.9,14.6-1.9,27.3-4.7v.9c-1.8,9.3-4.6,18.7-6.4,28-4.6,19.6-6.4,29.9-8.2,40.2v1.9Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M605.5,213.1c-.9,2.8-2.7,12.1-5.5,15.9-1.8,2.8-6.4,4.7-9.1,4.7-.9,0-5.5,0-5.5-7.5a48.43,48.43,0,0,1,1.8-11.2c2.7-11.2,5.5-21.5,13.7-21.5,4.6.9,7.3,8.4,4.6,19.6m23.7.9c3.6-15-10-.9-12.8-6.5-3.6-7.5-.9-24.3-14.6-29-5.5-1.9-17.3.9-28.2,10.3-7.3,7.5-10.9,16.8-12.8,26.2s-4.6,26.2,10.9,32.7a35.35,35.35,0,0,0,13.7,2.8c15.5-.9,27.3-24.3,35.5-33.6,5.6-8.5,7.4,1.8,8.3-2.9"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M450.6,247.7H426v-.9c.9-4.7,1.8-8.4,2.7-13.1l1.8-6.5c1.8-9.3,4.6-20.6,4.6-24.3,0-1.9.9-7.5-4.6-7.5-2.7,0-4.6.9-7.3,2.8-.9,4.7-4.6,19.6-5.5,26.2-2.7,14-2.7,15-4.6,21.5l-.9.9H386.7l-.9-.9c1.8-8.4,3.6-15.9,5.5-24.3,4.6-22.4,6.4-30.8,7.3-41.1l.9-.9c10.9-1.9,13.7-1.9,26.4-4.7l1.8,2.8-1.8,6.5c1.8-.9,3.6-2.8,6.4-3.7a36.69,36.69,0,0,1,15.5-3.7c5.5,0,10.9,1.9,13.7,7.5s.9,13.1-2.7,26.2L457,217c-3.6,15-3.6,17.8-5.5,29Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M535.3,127.1h-41a19.61,19.61,0,0,1-.9,4.7l-7.3,36.4s-18.2,76.6-19.1,80.4h29.2c.9-3.7,5.5-25.2,5.5-25.2l3.6-15.9a3.36,3.36,0,0,1,2.7-1.9h1.8c15.5,0,33.7,0,48.3-10.3,10-7.5,16.4-17.8,19.1-30.8.9-2.8.9-7.5.9-11.2,0-4.7-.9-10.3-3.6-14-8.2-11.3-21.9-12.2-39.2-12.2m11,37.4c-1.8,8.4-6.4,15-13.7,17.8-5.5,2.8-11.8,2.8-19.1,2.8h-3.6v-1.9s8.2-36.4,8.2-35.5v-3.8h3.6s16.4,1.9,17.3,1.9c6.4,3.7,9.1,10.3,7.3,18.7"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M720.3,177.6l-.9-.9c-11.8,2.8-14.6,2.8-25.5,4.7l-.9.9v.9h0c-8.2,19.6-8.2,15-14.6,30.8v-1.9l-1.8-34.6-.9-.9c-12.8,2.8-12.8,2.8-24.6,4.7l-.9.9v.9h0c1.8,7.5.9,5.6,2.7,17.8a118,118,0,0,1,1.8,17.8c.9,9.3,1.8,15,3.6,29.9-8.2,13.1-10,18.7-17.3,29.9v.9h22.8l1.8-1.9c5.5-13.9,54.7-99.9,54.7-99.9"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M306.6,186c6.4-4.7,7.3-11.2,1.8-14-5.5-3.7-15.5-1.9-21.9,2.8s-7.3,11.2-1.8,14,15.5,1.9,21.9-2.8"
                transform="translate(-0.02)"
                fill="#fff"
            />
            <path
                d="M663.8,278.5l-9.1,16.8c-2.7,5.6-8.2,10.3-17.3,10.3H621.9l4.6-15h2.7c1.8,0,2.7,0,3.6-.9.9,0,.9-.9,1.8-1.9l5.5-9.3Z"
                transform="translate(-0.02)"
                fill="#fff"
            />
        </g>
    </Icon>
);

export default CardUnionpay;
