import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import { concatPhoneNumber } from "checkout/ts/redux/utils/intl";
import classnames from "classnames";
import intlTelInput, { Iti, SomeOptions } from "intl-tel-input";
import { noop } from "lodash";
import { PhoneNumber } from "univapay-node";

type ItiProps = {
    initialValue?: PhoneNumber;
    onChange?: (value: PhoneNumber) => void;
    initOptions?: SomeOptions;
    inputProps?: Partial<React.InputHTMLAttributes<HTMLInputElement>>;
    disabled?: boolean;
    tabIndex?: number;
    className?: string;
    style?: React.CSSProperties;
    value?: PhoneNumber;
};

export type IntlTelInputRef = {
    getInstance: () => Iti | null;
    getInput: () => HTMLInputElement | null;
};

export const IntlTelInput = forwardRef(function IntlTelInput(
    {
        initialValue,
        tabIndex,
        onChange = noop,
        className,
        style,
        initOptions = {},
        inputProps = {},
        disabled = false,
    }: ItiProps,
    ref: React.ForwardedRef<IntlTelInputRef>
) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const itiRef = useRef<Iti | null>(null);

    useImperativeHandle(ref, () => ({ getInstance: () => itiRef.current, getInput: () => inputRef.current }));

    const update = useCallback((): void => {
        const localNumber: string = itiRef.current?.telInput?.value || "";
        const countryCode: string = itiRef.current?.getSelectedCountryData().dialCode || "";

        if (countryCode) {
            onChange({ localNumber, countryCode });
        }
    }, [onChange]);

    useEffect(() => {
        if (inputRef.current) {
            itiRef.current = intlTelInput(inputRef.current, initOptions);
        }

        return () => itiRef.current?.destroy();
    }, []);

    useEffect(() => {
        const inputRefCurrent = inputRef.current;
        if (inputRefCurrent) {
            inputRefCurrent.addEventListener("countrychange", update);
            itiRef.current.promise.then(update);
        }

        return () => inputRefCurrent?.removeEventListener("countrychange", update);
    }, [update]);

    return (
        <input
            type="tel"
            className={classnames(className, "intl-tel-input form-control")}
            ref={inputRef}
            onInput={update}
            defaultValue={concatPhoneNumber(initialValue)}
            disabled={disabled}
            tabIndex={tabIndex}
            style={style}
            {...inputProps}
        />
    );
});
