import { SEPARATOR } from "../../../forms/text/DateOfBirthInput";

export const isValidDate = (date: string) => {
    const splitDate = date.split(SEPARATOR).map((d) => parseInt(d, 10));
    const [year, month, day] = splitDate;

    return (
        year > 1900 &&
        month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= 31 &&
        !isNaN(new Date(date) as any) &&
        new Date() > new Date(date)
    );
};
