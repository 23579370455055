import { useSelector } from "react-redux";
import { CheckoutType } from "common/types";
import {
    ChargeCreateParams,
    ECFormCustomField,
    ProcessingMode,
    SubscriptionPeriod,
    TransactionTokenType,
} from "univapay-node";

import { PatchProductItem } from "../redux/models/product";
import { StateShape } from "../redux/store";
import { getCheckoutParams, SubscriptionParams } from "../utils/checkout-params";

export type QueryParsedInformation = {
    name?: string;
    email?: string;
    phoneNumber?: number;
};

export type FormattedCheckoutParams = {
    checkoutType: CheckoutType;
    mode: ProcessingMode;
    subscriptionId?: string;
    tokenType: TransactionTokenType;
    univapayCustomerId?: string;

    tokenOnly: boolean;

    amount: number;
    currency: string;
    isRecurring: boolean;
    chargeParams: Omit<ChargeCreateParams, "amount" | "currency" | "transactionTokenId">;
    subscriptionParams: SubscriptionParams;
    onlyDirectCurrency: boolean;

    bankTransferExpirationPeriod?: string;
    bankTransferExpirationTimeShift?: string;
    convenienceStoreExpirationPeriod?: string;
    convenienceStoreExpirationTimeShift?: string;

    metadata: Record<string, unknown>;

    products?: PatchProductItem[];
    shippingFees: number;
    customFields: ECFormCustomField[];

    cvvAuthorize: boolean;
};

const stateSelector = (state: StateShape) => ({
    checkoutParams: getCheckoutParams(state.application.params, state.product.products, state.configuration.data),
    products: state.product.products,
});

export const useCheckoutParams = (): FormattedCheckoutParams => {
    const { checkoutParams, products } = useSelector(stateSelector);

    const periodParams = Object.values(SubscriptionPeriod).includes(checkoutParams.period as SubscriptionPeriod)
        ? { period: checkoutParams.period as SubscriptionPeriod }
        : { cyclicalPeriod: checkoutParams.period };

    return {
        checkoutType: checkoutParams.checkoutType,
        mode: checkoutParams?.mode,
        metadata: checkoutParams.params.metadata,
        tokenType: checkoutParams.tokenType,
        univapayCustomerId: checkoutParams.univapayCustomerId,

        amount: checkoutParams.amount,
        currency: checkoutParams.currency,
        isRecurring: checkoutParams.tokenType === TransactionTokenType.RECURRING,
        onlyDirectCurrency: checkoutParams.params.onlyDirectCurrency,
        subscriptionId: checkoutParams?.subscriptionId,
        tokenOnly: checkoutParams?.tokenOnly,

        chargeParams: {
            capture: !checkoutParams.auth,
            captureAt: checkoutParams.captureOn,
        },

        subscriptionParams: {
            ...periodParams,
            scheduleSettings: checkoutParams.scheduleSettings,
            initialAmount: checkoutParams.initialAmount,
            installmentPlan: checkoutParams.installmentPlan,
            subscriptionPlan: checkoutParams.subscriptionPlan,
        },

        bankTransferExpirationPeriod: checkoutParams.bankTransferExpirationPeriod,
        bankTransferExpirationTimeShift: checkoutParams.bankTransferExpirationTimeShift,

        convenienceStoreExpirationPeriod: checkoutParams.convenienceStoreExpirationPeriod,
        convenienceStoreExpirationTimeShift: checkoutParams.convenienceStoreExpirationTimeShift,

        products,
        shippingFees: checkoutParams.shippingFees,
        customFields: checkoutParams.params.customFields,
        cvvAuthorize: checkoutParams.cvvAuthorize,
    };
};
