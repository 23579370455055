import React from "react";
import { useIntl } from "react-intl";
import { FormSection } from "redux-form";

import { Countries } from "../../../../../../common/locale/constants";
import { LOCALE_LABELS } from "../../../../locale/labels";
import { TextField } from "../../../forms/Text";
import { CountrySelect } from "../components/CountrySelect";

type OwnProps = {
    staticCountry?: keyof typeof Countries;
    isDark?: boolean;
    textFieldCustomStyles?: Record<string, string>;
};

export const BillingAddressEn = ({ staticCountry, isDark, textFieldCustomStyles = {} }: OwnProps) => {
    const { formatMessage } = useIntl();

    return (
        <FormSection name="data" data-name="billing">
            <TextField
                label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_LINE1 })}
                tabIndex={4}
                bsSize="sm"
                autoComplete="address-line-1"
                name="line1"
                required
                isDark={isDark}
                {...textFieldCustomStyles}
            />

            <TextField
                label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_LINE2 })}
                tabIndex={5}
                bsSize="sm"
                autoComplete="address-line-2"
                name="line2"
                isDark={isDark}
                {...textFieldCustomStyles}
            />

            <TextField
                label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_ZIP })}
                tabIndex={6}
                bsSize="sm"
                autoComplete="postal-code"
                name="zip"
                required
                isDark={isDark}
                {...textFieldCustomStyles}
            />

            <TextField
                label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_CITY })}
                tabIndex={7}
                bsSize="sm"
                autoComplete="city"
                name="city"
                required
                isDark={isDark}
                {...textFieldCustomStyles}
            />

            <TextField
                label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_STATE })}
                tabIndex={8}
                bsSize="sm"
                autoComplete="state"
                name="state"
                required
                isDark={isDark}
                {...textFieldCustomStyles}
            />

            <CountrySelect tabIndex={8} staticCountry={staticCountry} required isDark={isDark} />
        </FormSection>
    );
};
