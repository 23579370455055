import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import reactCSS from "reactcss";
import { StateShape } from "checkout/ts/redux/store";
import { getStepTitleColors } from "checkout/ts/utils/colors";
import classnames from "classnames";
import { isNil } from "lodash";

const stateSelector = (state: StateShape) => ({
    configurationData: state.configuration.data,
    applicationParamsTheme: isNil(state.application.params.params.dark)
        ? null
        : state.application.params.params.dark
        ? "dark"
        : "light",
});

type OwnProps = PropsWithChildren<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
        colored?: boolean;
    }
>;

export const StepTitle = ({ className, colored = true, ...props }: OwnProps) => {
    const { configurationData, applicationParamsTheme } = useSelector(stateSelector);

    const { textColor } = getStepTitleColors(configurationData, applicationParamsTheme);
    const styles = colored
        ? reactCSS({
              default: {
                  title: {
                      color: textColor,
                  },
              },
          })
        : { title: {} };

    return <h4 className={classnames(className, "step-title")} {...props} style={styles.title} />;
};
