import React, { Component, PropsWithChildren } from "react";
import { raiseTsError } from "checkout/ts/utils/monitoring";

import { CheckoutError } from "./CheckoutError";

export type ErrorBoundaryProps = PropsWithChildren<void>;

type OwnState = {
    hasError?: boolean;
    memorizedChildren?: unknown;
};

/**
 * This component can not be functional as there is no hooks for `componentDidCatch`
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, OwnState> {
    constructor(props) {
        super(props);

        this.state = { hasError: false, memorizedChildren: props.children };
    }

    static getDerivedStateFromProps({ children }, { memorizedChildren }) {
        if (children !== memorizedChildren) {
            return { hasError: false, memorizedChildren: children };
        }

        return null;
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        raiseTsError(error, errorInfo);
    }

    render() {
        return this.state.hasError ? <CheckoutError /> : this.props.children;
    }
}
