import { CheckoutColors } from "univapay-node";

export const defaultColors: CheckoutColors = {
    // Header & Button
    mainColor: "#4c5f85",
    mainText: "#F9FAFB",

    // Sub Header
    secondaryBackground: "#f5f8fc",
    primaryText: "#262626",

    // Content
    mainBackground: "#FFFFFF",
    secondaryText: "#262626", // title
    baseText: "#262626",
};

export const darkThemeColors: CheckoutColors = {
    // Header & Button
    mainColor: "#96CBE5",
    mainText: "#262626",

    // Sub Header
    secondaryBackground: "#272727",
    primaryText: "#F9FAFB",

    // Content
    mainBackground: "#121212",
    secondaryText: "#F9FAFB", // title
    baseText: "#F9FAFB",
};
