import React from "react";
import { useIntl } from "react-intl";
import { format as formatDateFns } from "date-fns";

type OwnProps = {
    dateTime: Date | number | string;
    noTime?: boolean;
    noYear?: boolean;
};

export const FormattedShortDate = ({ dateTime, noYear = false, noTime = false }: OwnProps) => {
    const { formatDate, locale } = useIntl();
    const date = new Date(dateTime);

    switch (locale.toLowerCase()) {
        case "ja-jp":
        case "zh-cn":
        case "zh-tw":
            return (
                <time>
                    {formatDate(date, { year: noYear ? undefined : "numeric", month: "short", day: "2-digit" })}
                    {!noTime && formatDateFns(date, "HH'時'mm'分'")}
                </time>
            );

        case "en-us":
        default:
            return (
                <time>
                    {formatDate(date, {
                        year: noYear ? undefined : "numeric",
                        month: "short",
                        day: "2-digit",
                        hour12: false,
                        hour: noTime ? undefined : "2-digit",
                        minute: noTime ? undefined : "2-digit",
                    })}
                </time>
            );
    }
};
