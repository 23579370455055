import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { useSubscriptionPlan } from "checkout/ts/hooks/use-subscription-plan";
import { StateShape } from "checkout/ts/redux/store";
import { CheckoutType } from "univapay-node";

import { LOCALE_LABELS } from "../../locale/labels";
import { amountForCurrency } from "../../utils/money";
import { FormattedPeriod } from "../common/FormattedPeriod";

import { convertCyclicalToSubscriptionPeriod, formatMoney } from "./utils";

const getPeriodLabel = (period: string) =>
    LOCALE_LABELS[`SUBSCRIPTIONS_INFO_INSTALLMENT_${period?.toUpperCase()}`] || LOCALE_LABELS.GLOBAL_UNKNOWN;

const stateSelector = (state: StateShape) => ({
    fees: state.configuration.paymentMethods.find(({ key }) => key === state.checkout.paymentMethodKey)?.fees || 0,
});

type OwnProps = { style: Record<string, string> };

export const SubscriptionHeader = ({ style }: OwnProps) => {
    const intl = useIntl();
    const { formatMessage, formatNumber, formatDate } = intl;

    const { fees } = useSelector(stateSelector);

    const {
        checkoutType,
        amount,
        subscriptionParams: {
            period,
            cyclicalPeriod,
            installmentPlan,
            subscriptionPlan,
            initialAmount,
            scheduleSettings,
        },
        currency,
    } = useCheckoutParams();

    const planValues = useSubscriptionPlan();

    const isPaymentCheckout = checkoutType === CheckoutType.PAYMENT;
    const isTokenCheckout = checkoutType === CheckoutType.TOKEN;

    const showPeriod =
        period &&
        ((subscriptionPlan?.planType && !planValues?.amountPerCycle) || installmentPlan?.planType || isTokenCheckout);

    const subscriptionPeriod = period || convertCyclicalToSubscriptionPeriod(cyclicalPeriod);
    const formattedPeriod = subscriptionPeriod ? (
        formatMessage({ id: LOCALE_LABELS[`SUBSCRIPTIONS_PERIOD_${subscriptionPeriod?.toUpperCase()}`] })
    ) : (
        <FormattedPeriod period={cyclicalPeriod} />
    );

    const paymentLabel = (() => {
        if (isTokenCheckout) {
            return null;
        }

        if (planValues) {
            return planValues.label;
        }

        if (initialAmount === undefined && !scheduleSettings?.startOn) {
            return null;
        }

        return formatMessage(
            {
                id: cyclicalPeriod
                    ? LOCALE_LABELS.SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD
                    : LOCALE_LABELS.SUBSCRIPTIONS_INFO_PAY,
            },
            {
                period: formattedPeriod,
                money: intl.formatNumber(amountForCurrency(amount + fees, currency), {
                    style: "currency",
                    currency,
                }),
            }
        );
    })();

    return (
        <div data-name="subscription-info">
            {showPeriod && (
                <h5 data-name="header-subscription-period" style={style}>
                    {formatMessage({ id: getPeriodLabel(period) })}
                </h5>
            )}

            <h5 data-name="header-subscription-info" style={style}>
                {isPaymentCheckout &&
                    (initialAmount || (initialAmount === undefined && !!scheduleSettings.startOn)
                        ? formatMessage(
                              { id: LOCALE_LABELS.SUBSCRIPTIONS_INFO_INITIAL },
                              {
                                  money: formatMoney(
                                      planValues?.initialAmount ?? initialAmount ?? amount,
                                      currency,
                                      formatNumber
                                  ),
                              }
                          )
                        : initialAmount === 0
                        ? formatMessage(
                              { id: LOCALE_LABELS.SUBSCRIPTIONS_CVV_AUTH },
                              { money: formatMoney(initialAmount, currency, formatNumber) }
                          )
                        : initialAmount === undefined &&
                          !planValues &&
                          formatMessage(
                              { id: LOCALE_LABELS.SUBSCRIPTIONS_PAY_WITH_CUSTOM_PERIOD },
                              { money: formatMoney(amount, currency, formatNumber), period: formattedPeriod }
                          ))}

                {isPaymentCheckout && <div className="header-info">{paymentLabel}</div>}

                {!!scheduleSettings?.startOn && (
                    <div className="header-info">
                        {formatMessage(
                            { id: LOCALE_LABELS.SUBSCRIPTIONS_INFO_START },
                            { date: formatDate(scheduleSettings.startOn) }
                        )}
                    </div>
                )}

                {planValues?.cycles && planValues?.amountPerCycle && (
                    <div className="header-info">
                        {formatMessage(
                            { id: LOCALE_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PAYMENTS },
                            { cycles: planValues?.cycles }
                        )}
                    </div>
                )}
            </h5>
        </div>
    );
};
