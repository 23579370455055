import { RequestErrorCode, ResponseErrorCode } from "univapay-node";

import * as ERRORS_LABELS from "../labels/errors";

export const LABELS: Record<string, string> = {
    [ERRORS_LABELS.ERRORS_TITLE]: "Error",

    [ERRORS_LABELS.ERRORS_ALERTS_CONNECTOR]: "Could not connect checkout with origin application.",
    [ERRORS_LABELS.ERRORS_ALERTS_PARAMETERS]: "Could not initialize checkout due to incorrect parameters.",
    [ERRORS_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT]: "Could not initialize checkout. Please check your internet connection and try again",
    [ERRORS_LABELS.ERRORS_ALERTS_TIMEOUT]: "Timeout error",
    [ERRORS_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT]: "Session has timed out",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN]: "Global error occurred. Could not load checkout.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE]: "Could not initialize checkout due to the unknown payment type",
    [ERRORS_LABELS.ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE]: "No payment types are supported by the store.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE]: "The payment types are not supported by the store.",
    [ERRORS_LABELS.ERRORS_ALERTS_PRIVILEGES]: "Account does not have recurring token privileges.",
    [ERRORS_LABELS.ERRORS_ALERTS_USAGE]: "Usage limit is required on recurring token.",
    [ERRORS_LABELS.ERRORS_ALERTS_FORBIDDEN_CARD]: "This card brand is not supported.",
    [ERRORS_LABELS.ERRORS_ALERTS_DOMAIN]: "Cannot run checkout from this domain.",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_FAILED]: "Could not create the transaction token.",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED]: "Unable to complete CVV authorization",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT]: "Card installments are not supported by the store.",

    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE]: "Service Unavailable",
    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO]: "Service is temporarily unavailable and payment cannot be processed. Please try again later.",

    [ERRORS_LABELS.ERRORS_ALERTS_CAPTURE_CREDIT_ONLY]: "Debit and prepaid cards are not supported for authorization, only sales.",

    [ERRORS_LABELS.ERROR_CHECKOUT_CHARGE]: "Charge Failed.",
    [ERRORS_LABELS.ERROR_CHECKOUT_SUBSCRIPTION]: "Subscription Failed.",
    [ERRORS_LABELS.ERROR_CHECKOUT_PARAMS]: "Checkout parameters are invalid",
    [ERRORS_LABELS.PROCESSING_ERROR]: "Processing error",
    [ERRORS_LABELS.ERROR_ALERTS_PAIDY_PAYMENT_REJECTED]: "Charge could not be registered by Paidy.",
    [ERRORS_LABELS.EMAIL_EXISTS_FOR_CARD]: "The email you entered doesn't match the email registered for this card.",
    [ERRORS_LABELS.SERVICE_RESTRICTED]: "Too many failures. Please try again later.",
    [ERRORS_LABELS.EXPIRATION_DATE_ERROR]: "Expiration date error",

    /* request */
    [RequestErrorCode.RequestError]: "Invalid request",

    /* generic */
    [ResponseErrorCode.BadRequest]: "Bad request error",
    [ResponseErrorCode.Forbidden]: "Operation forbidden",
    [ResponseErrorCode.NotFound]: "Resource not found",
    [ResponseErrorCode.NotAllowed]: "Operation not allowed",
    [ResponseErrorCode.Conflicted]: "Resource is in conflict with another resource",
    [ResponseErrorCode.TooManyRequests]: "Too many requests to the API",
    [ResponseErrorCode.InternalServerError]: "Internal server error",
    [ResponseErrorCode.ServiceUnavailable]: "Service unavailable",
    [ResponseErrorCode.NotAuthorized]: "Not authorized",

    /* global */
    [ResponseErrorCode.UnknownError]: "An error occurred. Please contact support for details",
    [ResponseErrorCode.Timeout]: "Timeout error",
    [ResponseErrorCode.DBError]: "Database error",
    [ResponseErrorCode.InvalidRequest]: "Invalid request",
    [ResponseErrorCode.UnableToGetIdempotentResult]: "Unable to get idempotent result",
    [ResponseErrorCode.ServiceUnavailableTryAgain]: "Service unavailable try again",
    [ResponseErrorCode.RateLimitExceeded]: "API request rate exceeded",

    /* auth */
    [ResponseErrorCode.InvalidIpAddress]: "Invalid IP address",
    [ResponseErrorCode.InvalidDomain]: "Invalid domain",
    [ResponseErrorCode.InvalidCredentials]: "Invalid login data",
    [ResponseErrorCode.AuthHeaderMissing]: "Authorization header missing",
    [ResponseErrorCode.InvalidPermissions]: "You do not have the required permissions to access this resource.",
    [ResponseErrorCode.ImproperAuth]: "Improper authorization",
    [ResponseErrorCode.CouldNotRefreshAuth]: "Could not refresh authorization token",
    [ResponseErrorCode.UserBanned]: "This user is banned.",
    [ResponseErrorCode.InvalidLoginToken]: "Invalid login token",
    [ResponseErrorCode.InvalidAppToken]: "Invalid app token",
    [ResponseErrorCode.ExpiredLoginToken]: "Expired login token",
    [ResponseErrorCode.OutdatedAppToken]: "Outdated app token",
    [ResponseErrorCode.ChargeTooQuick]: "Another charge request not yet allowed",

    /* file upload */
    [ResponseErrorCode.FileUploadError]: "File upload error",
    [ResponseErrorCode.FileMaxSizeExceeded]: "File max size exceeded",
    [ResponseErrorCode.FileInvalidType]: "Invalid file type",
    [ResponseErrorCode.FileNotFound]: "File not found",

    /* crud actions */
    [ResponseErrorCode.TokenGenerationException]: "Token generation exception",
    [ResponseErrorCode.TokenForWrongStore]: "Invalid token",
    [ResponseErrorCode.NonSubscriptionPayment]: "Non subscription payment",
    [ResponseErrorCode.BankAccountExists]: "Bank account exists",
    [ResponseErrorCode.EmailExists]: "Email exists",
    [ResponseErrorCode.StoreExists]: "Store exists",
    [ResponseErrorCode.GatewayCredentialsExists]: "Gateway credentials exists",
    [ResponseErrorCode.WebhookURLExists]: "Webhook URL exists",
    [ResponseErrorCode.NonUniqueRuleForMerchant]: "Rule for merchant is not unique",
    [ResponseErrorCode.NonUniqueRuleSetForMerchant]: "Rule set for merchant is not unique",
    [ResponseErrorCode.NonUniqueRule]: "Rule is not unique",
    [ResponseErrorCode.RulePriorityMustBePositive]: "Rule priority must be positive",
    [ResponseErrorCode.NonExistingRule]: "Non existing rule",
    [ResponseErrorCode.MustContainUniqueElementsOnly]: "Must contain unique elements only",
    [ResponseErrorCode.GroupExists]: "Group exists",
    [ResponseErrorCode.PrimaryBankAccountNotFound]: "Primary bank account not found",
    [ResponseErrorCode.MustHaveAPrimaryBankAccount]: "Must have a primary bank account",
    [ResponseErrorCode.VerificationDataExists]: "Verification data exists",
    [ResponseErrorCode.TemplateExists]: "Template exists",
    [ResponseErrorCode.StratusCredentialsExists]: "Stratus credentials exists",
    [ResponseErrorCode.StratusRuleStillInUse]: "Stratus rule still in use",
    [ResponseErrorCode.ResourceLimitReached]: "Resource limit reached",
    [ResponseErrorCode.GatewayConfigurationExists]: "Gateway configuration exists",
    [ResponseErrorCode.RequiresValidMerchantGatewayConfig]: "No valid configuration found",
    [ResponseErrorCode.MergedConfigurationNotFound]: "No valid configuration found",

    /* payments and charges */
    [ResponseErrorCode.InvalidTokenType]: "Invalid token type",
    [ResponseErrorCode.InvalidToken]: "Invalid token",
    [ResponseErrorCode.InvalidCard]: "Invalid card",
    [ResponseErrorCode.TransactionTokenIsNotRecurring]: "Transaction token is a not recurring token",
    [ResponseErrorCode.ForbiddenIP]: "Address IP forbidden",
    [ResponseErrorCode.InvalidUserData]: "Invalid user data",
    [ResponseErrorCode.NonUniqueActiveToken]: "Active token is not unique",
    [ResponseErrorCode.TransactionAlreadyProcessed]: "Transaction already processed",
    [ResponseErrorCode.TransactionTokenExpired]: "Transaction token expired",
    [ResponseErrorCode.NoTestCardInLiveMode]: "No test card in live mode",
    [ResponseErrorCode.ProcessingModeMismatch]: "Processing mode mismatch",
    [ResponseErrorCode.PaymentTypeNotAllowed]: "Payment type not allowed",
    [ResponseErrorCode.RecurringTokenNotAllowed]: "Recurring token not allowed",
    [ResponseErrorCode.RecurringTokenDisabled]: "Recurring token disabled",
    [ResponseErrorCode.RecurringUsageLimitRequired]: "Recurring usage limit required",
    [ResponseErrorCode.RecurringUsageRequiresCVV]: "Recurring usage requires CVV",
    [ResponseErrorCode.CvvAuthorizationNotCompleted]: "CVV authorization not completed",
    [ResponseErrorCode.UsageLimitNotApplicable]: "Usage limit not applicable",
    [ResponseErrorCode.CardProcessingDisabled]: "Card processing disabled",
    [ResponseErrorCode.QRProcessingDisabled]: "QR code processing disabled",
    [ResponseErrorCode.PaidyProcessingDisabled]: "Paidy processing disabled",
    [ResponseErrorCode.MerchantQRProcessingDisabled]: "Merchant QR processing disabled",
    [ResponseErrorCode.OnlineProcessingDisabled]: "Online processing disabled",
    [ResponseErrorCode.ConvenienceProcessingDisabled]: "Konbini processing disabled",
    [ResponseErrorCode.NotOneTimeToken]: "Not a one-time token",
    [ResponseErrorCode.NotSubscriptionToken]: "Not a subscription token",
    [ResponseErrorCode.NotRecurringToken]: "Not a recurring token",
    [ResponseErrorCode.CurrencyMustMatchCharge]: "Currency must match charge",
    [ResponseErrorCode.NoDirectCurrencyGateway]: "Not direct gateway for this currency",
    [ResponseErrorCode.TokenMustBeConfirmed]: "Token must be confirmed",
    [ResponseErrorCode.ConfirmationRequiresEmail]: "Confirmation required email",
    [ResponseErrorCode.WrongConfirmationCode]: "Wrong confirmation code",
    [ResponseErrorCode.RefundNotWithinBounds]: "Refund not within bounds",
    [ResponseErrorCode.PartialRefundNotSupported]: "Partial refund not supported",
    [ResponseErrorCode.InvalidTransfer]: "Invalid transfer",
    [ResponseErrorCode.TransferAlreadyExists]: "Transfer already exists",
    [ResponseErrorCode.NoLedgers]: "No ledgers",
    [ResponseErrorCode.FailedToAssociateLedgers]: "Failed to associate Ledgers",
    [ResponseErrorCode.LiveModeNotEnabledWhenUnverified]: "Live mode not enabled when unverified",
    [ResponseErrorCode.SelfTransferNotPermitted]: "Self-transfer not permitted",
    [ResponseErrorCode.CardLocked]: "Card locked",
    [ResponseErrorCode.SubscriptionProcessing]: "Subscriptions is processing",
    [ResponseErrorCode.CannotUpdateTransactionToken]: "Cannot update transaction token",
    [ResponseErrorCode.AlreadyCaptured]: "Already captured",
    [ResponseErrorCode.CaptureAmountTooLarge]: "Capture amount is too large",
    [ResponseErrorCode.PartialCaptureNotSupported]: "Partial capture not supported",

    [ResponseErrorCode.NoGatewaysAvailable]: "No gateway is available",
    [ResponseErrorCode.NoGatewayCredentialsAvailable]: "No gateway credentials available",
    [ResponseErrorCode.NoGatewayTransactionIdAvailable]: "No gateway transaction ID available",
    [ResponseErrorCode.PaymentTypeNotSupportedForCheck]: "Payment type not supported",
    [ResponseErrorCode.NoGatewayCredentialsForSelectedPaymentType]: "No gateway credentials for selected payment type",
    [ResponseErrorCode.DisabledPaymentType]: "Disabled payment type",
    [ResponseErrorCode.CardBrandNotSupported]: "Card brand is not supported",
    [ResponseErrorCode.CardCountryNotSupported]: "Card country is not supported",
    [ResponseErrorCode.CVVRequired]: "CVV is required",
    [ResponseErrorCode.LastNameRequired]: "Last name is required",
    [ResponseErrorCode.AuthNotSupported]: "Auth is not supported",
    [ResponseErrorCode.GatewayConfigurationRequired]: "Gateway configuration required",
    [ResponseErrorCode.GatewayConfigurationNotRequired]: "Gateway configuration not required",

    [ResponseErrorCode.InvalidBinRange]: "Invalid BIN range",
    [ResponseErrorCode.OverlappingStratusTerminalIdRange]: "Overlapping stratus terminal ID range",
    [ResponseErrorCode.InvalidStratusTerminalIdRange]: "Invalid stratus terminal ID range",
    [ResponseErrorCode.InvalidCardCompany]: "Invalid card company",
    [ResponseErrorCode.VerificationRequired]: "Verification ID required",
    [ResponseErrorCode.DebitAuthorizationDisabled]: "Debit authorization disabled",
    [ResponseErrorCode.PrePaidAuthorizationDisabled]: "Prepaid authorization disabled",

    /* validation responses */
    [ResponseErrorCode.ChangeProhibited]: "Change prohibited",
    [ResponseErrorCode.ForbiddenParameter]: "Forbidden parameter",
    [ResponseErrorCode.ValidationError]: "Validation error",
    [ResponseErrorCode.RequiredValue]: "Value required",
    [ResponseErrorCode.MustBeFutureTime]: "Must be future time",
    [ResponseErrorCode.InvalidFormat]: "Invalid format",
    [ResponseErrorCode.InvalidPercentFee]: "Invalid percent fee",
    [ResponseErrorCode.InvalidCardNumber]: "Invalid card number",
    [ResponseErrorCode.InvalidCardExpiration]: "Invalid card expiration",
    [ResponseErrorCode.InvalidCVV]: "Invalid CVV",
    [ResponseErrorCode.InvalidFormatLength]: "Invalid length",
    [ResponseErrorCode.InvalidFormatUUID]: "Invalid format UUID",
    [ResponseErrorCode.InvalidFormatBase64]: "Invalid format base64",
    [ResponseErrorCode.InvalidFormatEmail]: "Invalid email",
    [ResponseErrorCode.InvalidCardDescriptor]: "Invalid card descriptor",
    [ResponseErrorCode.InvalidFormatCurrency]: "Invalid currency",
    [ResponseErrorCode.InvalidCurrency]: "Invalid currency",
    [ResponseErrorCode.InvalidAmount]: "Invalid amount",
    [ResponseErrorCode.InvalidEventForStore]: "Invalid event for store",
    [ResponseErrorCode.InvalidEventForPlatform]: "Invalid event for platform",
    [ResponseErrorCode.InvalidEventForMerchant]: "Invalid event for merchant",
    [ResponseErrorCode.InvalidFormatDomain]: "Invalid domain format",
    [ResponseErrorCode.InvalidFormatUrl]: "Invalid URL format",
    [ResponseErrorCode.InvalidFormatObject]: "Invalid object format",
    [ResponseErrorCode.InvalidFormatCountry]: "Invalid country",
    [ResponseErrorCode.InvalidPhoneNumber]: "Invalid phone number",
    [ResponseErrorCode.InvalidFormatSwiftCode]: "Invalid BIC/SWIFT code",
    [ResponseErrorCode.InvalidFormatRoutingNumber]: "Invalid routing number",
    [ResponseErrorCode.InvalidFormatRoutingCode]: "Invalid routing code",
    [ResponseErrorCode.InvalidFormatIfcsCode]: "Invalid IFSC code",
    [ResponseErrorCode.InvalidFormatBankAccountNumber]: "Invalid bank account number",
    [ResponseErrorCode.InvalidPasswords]: "Invalid passwords",
    [ResponseErrorCode.InvalidTimePeriod]: "Invalid time period",
    [ResponseErrorCode.InvalidDayOfWeek]: "Invalid day of week",
    [ResponseErrorCode.InvalidWeekOfMonth]: "Invalid week of month",
    [ResponseErrorCode.InvalidDayOfMonth]: "Invalid day of month",
    [ResponseErrorCode.InvalidColorsSize]: "Invalid colors size",
    [ResponseErrorCode.NestedJsonNotAllowed]: "Nested JSON not allowed",
    [ResponseErrorCode.InvalidFormatDate]: "Invalid date format",
    [ResponseErrorCode.InvalidChargeStatus]: "Invalid charge status",
    [ResponseErrorCode.InvalidQRScanGateway]: "Invalid QR scan gateway",
    [ResponseErrorCode.NotQRCharge]: "Not a QR charge",
    [ResponseErrorCode.NotOnlineCharge]: "Not an online charge",
    [ResponseErrorCode.IssuerTokenEmpty]: "Empty issuer token",
    [ResponseErrorCode.CardLimitExceededForStore]: "Card limit exceeded for store",
    [ResponseErrorCode.InvalidLanguage]: "Invalid language",
    [ResponseErrorCode.SubscriptionNotAllowed]: "Subscription not allowed",
    [ResponseErrorCode.OneTimeOnlyAllowed]: "Only one-time token allowed",
    [ResponseErrorCode.AuthExpired]: "Authorization expired",
    [ResponseErrorCode.InvalidTemplateKey]: "Invalid template key",
    [ResponseErrorCode.NonPublicTemplate]: "Non-public template",
    [ResponseErrorCode.OnlyJapanesePhoneNumberAllowed]: "Only Japanese phone number allowed",
    [ResponseErrorCode.ExpirationDateOutOfBounds]: "Expiration date out of bounds",
    [ResponseErrorCode.UnsupportedLanguage]: "Unsupported language",
    [ResponseErrorCode.DefaultLanguageNotSupported]: "Default language not supported",
    [ResponseErrorCode.OnlyForCardPayment]: "Only for card payment",
    [ResponseErrorCode.CaptureOnlyForCardPayment]: "Capture only allowed for card payments",
    [ResponseErrorCode.InvalidCardTypeForCapture]: "Invalid card for capture",
    [ResponseErrorCode.InvalidScheduledCaptureDate]: "Invalid scheduled capture date",
    [ResponseErrorCode.IncoherentDateRange]: "Incoherent date range",
    [ResponseErrorCode.InvalidMerchantStatus]: "Invalid merchant status",
    [ResponseErrorCode.MustHaveOneElement]: "Must have at least one element",
    [ResponseErrorCode.MustHaveAtLeastOneElement]: "Must have at least one element",
    [ResponseErrorCode.ThresholdMustBeNull]: "Threshold must be null",
    [ResponseErrorCode.IllegalNumberOfItems]: "Illegal number of items",
    [ResponseErrorCode.UnableToReadCredentials]: "Unable to read credentials",
    [ResponseErrorCode.GatewayError]: "Gateway error",
    [ResponseErrorCode.GatewayNoLongerSupported]: "Gateway no longer supported",
    [ResponseErrorCode.MediaCannotBeShortened]: "Media cannot be shortened",
    [ResponseErrorCode.InvalidChargeAmountLimit]: "Invalid charge amount limit",
    [ResponseErrorCode.PlatformCurrencyRequiredInCvvAmount]: "Platform currency required in CVV amount",
    [ResponseErrorCode.TransferScheduleWaitPeriodAndPeriodRequired]: "Transfer schedule wait period and period required",
    [ResponseErrorCode.ChargeAmountTooLow]: "Charge amount too low",
    [ResponseErrorCode.ChargeAmountTooHigh]: "Charge amount too high",
    [ResponseErrorCode.MustContainPlatformCurrency]: "Platform currency required",
    [ResponseErrorCode.CurrencyMustBeInAmountsList]: "Currency is not in amounts list",
    [ResponseErrorCode.InvalidJapanesePostalCode]: "Invalid japanese postal code",

    [ResponseErrorCode.BrandNotDefined]: "Brand not defined",

    /* Used when creating a new merchant */
    [ResponseErrorCode.OnlyASCII]: "Only ASCII characters are supported",
    [ResponseErrorCode.UniqueCharacters]: "Unique characters",
    [ResponseErrorCode.AtLeastOneDigit]: "Must contain at least one digit",
    [ResponseErrorCode.AtLeastOneLetter]: "Must contain at least one letter",
    [ResponseErrorCode.EmptyRoles]: "Cannot have empty roles",
    [ResponseErrorCode.EditOwnRolesNotAllowed]: "Editing own roles not allowed",
    [ResponseErrorCode.EditOwnStatusNotAllowed]: "Editing own status not allowed",
    [ResponseErrorCode.InvalidPathValue]: "Invalid path value",
    [ResponseErrorCode.InvalidCardBrand]: "The supplied card brand is not supported",
    [ResponseErrorCode.UnsupportedCountry]: "The supplied country is not supported",
    [ResponseErrorCode.UnsupportedCurrency]: "The supplied currency is not supported",
    [ResponseErrorCode.CannotBanSelf]: "Cannot ban self",
    [ResponseErrorCode.CannotSelfTerminate]: "Cannot self terminate",
    [ResponseErrorCode.NoDuplicateCurrencies]: "No currency duplicates allowed",

    [ResponseErrorCode.PlatformNotFound]: "Platform not found",
    [ResponseErrorCode.InvalidPlatform]: "Invalid platform",
    [ResponseErrorCode.InvalidInvoiceFeeConfiguration]: "Invalid invoice fee configuration",
    [ResponseErrorCode.InvalidPlatformRole]: "Invalid platform role",

    /* verification */
    [ResponseErrorCode.DataNotSubmitted]: "Date not submitted",
    [ResponseErrorCode.NoBankAccount]: "No bank account",
    [ResponseErrorCode.PercentFeeNotSubmitted]: "Percent fee not submitted",
    [ResponseErrorCode.InsufficientSystemManagerInfo]: "Insufficient system manager info",

    /* gateway credentials */
    [ResponseErrorCode.CredentialsExist]: "Credentials exist",
    [ResponseErrorCode.OnlyOnePaidyCredentialsAllowed]: "Only one paidy credentials allowed",
    [ResponseErrorCode.OnlyOneDBaraiCredentialsAllowed]: "Only one DBarai credentials allowd",
    [ResponseErrorCode.OnlyMerchantOrStoreCredentialsAllowed]: "Only allowed for merchant or store credentials",
    [ResponseErrorCode.RefundExceedsChargeAmount]: "Refund exceeds charge amount",
    [ResponseErrorCode.CannotRefundUnsuccessfulCharge]: "Cannot refund unsuccessful charge",
    [ResponseErrorCode.RefundNotAllowed]: "Refund not allowed",
    [ResponseErrorCode.RefundPercentageExceeded]: "Refund percentage exceeded",
    [ResponseErrorCode.CancelNotAllowed]: "Cancel not allowed",

    [ResponseErrorCode.PaidyShippingAddressNeedsOneOptionalField]: "Paidy shipping address needs one optional field",

    [ResponseErrorCode.RevertNotAllowed]: "Revert not allowed",

    /* apple pay */
    [ResponseErrorCode.ApplePayNotEnabled]: "Apple Pay not enabled",
    [ResponseErrorCode.ApplePayAlreadyEnabled]: "Apple Pay already enabled",
    [ResponseErrorCode.ApplePayCertificateAlreadyUpdated]: "Apple Pay certificate already updated",
    [ResponseErrorCode.ApplePayUnsupportedAlgorithm]: "Apple Pay unsupported algorithm",
    [ResponseErrorCode.ApplePayCertificateNotFound]: "Apple Pay certificate not found",
    [ResponseErrorCode.ApplePayUnableToGenerateCertificateRequest]: "Apple Pay unable to generate certificate request",
    [ResponseErrorCode.ApplePayInvalidConfiguration]: "Apple Pay invalid configuration",
    [ResponseErrorCode.ApplePayInvalidCertificate]: "Apple Pay invalid certificate",
    [ResponseErrorCode.ApplePayInvalidAlgorithm]: "Apple Pay invalid algorithm",
    [ResponseErrorCode.ApplePayInvalidCertificateFormat]: "Apple Pay invalid certificate format",
    [ResponseErrorCode.ApplePayInvalidSignature]: "Apple Pay invalid signature",
    [ResponseErrorCode.ApplePayError]: "Apple Pay error",
    [ResponseErrorCode.ApplePayCertificateAlreadySet]: "Apple Pay certificate already set",
    [ResponseErrorCode.ApplePayCertificateStillInUse]: "Apple Pay certificate still in use",

    /* Charge errors */
    [ResponseErrorCode.CardNumberNotValid]: "The card number is not valid",
    [ResponseErrorCode.CardExpirationMonthInvalid]: "The card's expiration month is invalid",
    [ResponseErrorCode.CardExpirationYearInvalid]: "The card's expiration year is invalid",
    [ResponseErrorCode.CardExpired]: "The card is expired",
    [ResponseErrorCode.CardCVVInvalid]: "The card's CVV is incorrect",
    [ResponseErrorCode.CardRejected]: "The card was rejected",
    [ResponseErrorCode.CardInvalid]: "The card is invalid",
    [ResponseErrorCode.ChargeInvalidData]: "The charge contains invalid data",
    [ResponseErrorCode.TooManyChargeRequests]: "Too many charge requests for the same card in a short time span. Please wait for a few moments before trying again",
    [ResponseErrorCode.CancelUnavailable]: "Cancel operation is not available for this charge",
    [ResponseErrorCode.ChargeExpired]: "Charge expired",
    [ResponseErrorCode.SeizeCard]: "Seize card immediately",
    [ResponseErrorCode.ContactBank]: "Contact card issuer bank",
    [ResponseErrorCode.FraudSuspected]: "Fraud suspected",
    [ResponseErrorCode.BankSystemFailure]: "Error on the bank end of the transaction",
    [ResponseErrorCode.DynamicDescriptorNotSupported]: "Dynamic descriptor is not supported",
    [ResponseErrorCode.PaymentCodeInvalid]: "The barcode/QR code is invalid",
    [ResponseErrorCode.PaymentCodeExpired]: "The barcode/QR code is expired",
    [ResponseErrorCode.PaymentCodeAlreadyUsed]: "The barcode/QR code has already been used",
    [ResponseErrorCode.PaymentCodeStillInUse]: "The barcode/QR code is currently in use",
    [ResponseErrorCode.RejectedHighRisk]: "Rejected due to exceeding high risk threshold",
    [ResponseErrorCode.ConfirmationPeriodExpired]: "Confirmation period has expired",
    [ResponseErrorCode.RevertFailed]: "Revert failed. Manual intervention required.",
    [ResponseErrorCode.RefundFailed]: "Refund failed",
    [ResponseErrorCode.PaymentWalletInsufficientFunds]: "Insufficient funds in customer wallet",
    [ResponseErrorCode.InvalidMetadataFieldValue]: "Invalid or missing metadata field value",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingId]: "Cross border transaction not allowed: Missing identity card",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber]: "Cross border transaction not allowed: Missing phone number",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod]: "Cross border transaction not allowed: Unaccepted payment method",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingName]: "Cross border transaction not allowed: Missing name",
    [ResponseErrorCode.LimitExceededForPaymentType]: "Transaction limit exceeded for payment type",
    [ResponseErrorCode.LimitExceededForMerchant]: "Transaction limit exceeded for the merchant",
    [ResponseErrorCode.TransactionNotFound]: "Transaction not found",
    [ResponseErrorCode.DuplicateTransaction]: "Duplicate transaction",
    [ResponseErrorCode.PaymentWalletRejected]: "Payment wallet was rejected by the gateway",
    [ResponseErrorCode.InsufficientMerchantInformation]: "Merchant is missing certain information required by the gateway",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency]: "Cross border transaction not allowed: Unaccepted currency",
    [ResponseErrorCode.GatewayServerError]: "Failed to process the payment due to a server error on the gateway",
    [ResponseErrorCode.PaymentMethodTemporarilyUnavailable]: "The payment selected is temporarily unavailable from the gateway",
    [ResponseErrorCode.PaymentCanceled]: "The payment was canceled",
    [ResponseErrorCode.ExceededPendingThreshold]: "Payment took too long to start processing",
    [ResponseErrorCode.InternalError]: "Internal system error",

    /* online */
    [ResponseErrorCode.AlipayOnlineInvalidSignatureType]: "Alipay Online invalid signature type",
    [ResponseErrorCode.PayPayOnlineInvalidPayMethod]: "PayPay Online invalid payment method",
    [ResponseErrorCode.PayPayOnlineInvalidMerchantId]: "PayPay Onlinei nvalid merchant ID",

    [ResponseErrorCode.UnableToGenerateCertificateRequest]: "Unable to generate certificate request",
    [ResponseErrorCode.InvalidMerchantSettings]: "Invalid merchant settings",
    [ResponseErrorCode.UnsupportedAlgorithm]: "Unsupported algorithm",
    [ResponseErrorCode.InvalidPaymentToken]: "Invalid payment token",
    [ResponseErrorCode.ExpiredPaymentToken]: "Expired payment token",
    [ResponseErrorCode.InvalidCertificate]: "Invalid certificate",
    [ResponseErrorCode.InvalidLeafCertificate]: "Invalid leaf certificate",
    [ResponseErrorCode.InvalidIntermediateCertificate]: "Invalid intermediate certificate",
    [ResponseErrorCode.InvalidChainOfTrust]: "Invalid chain of trust",
    [ResponseErrorCode.InvalidSignature]: "Invalid signature",

    /** platform */
    [ResponseErrorCode.PlatformKeyExists]: "Platform key exists",
    [ResponseErrorCode.MerchantConsoleURIExists]: "Merchant console URI exists",

    /* idempotency */
    [ResponseErrorCode.IdempotencyKeyConflict]: "Idempotency key conflict",

    /* bannedCards */
    [ResponseErrorCode.CardBanned]: "Card banned",
    [ResponseErrorCode.CardAlreadyBanned]: "Card already banned",
    [ResponseErrorCode.CardNotBanned]: "Card not banned",
    [ResponseErrorCode.TestCardCannotBeBanned]: "Test card cannot be banned",

    /* Stats */
    [ResponseErrorCode.InvalidMetric]: "Invalid metric",
    [ResponseErrorCode.InvalidResource]: "Invalid resource",

    /* installments */
    [ResponseErrorCode.UseStartOn]: "Use start on",
    [ResponseErrorCode.PaymentInTimePeriod]: "Payment in time period",
    [ResponseErrorCode.SecondChargeAlreadyMade]: "Second charge already made",
    [ResponseErrorCode.NotSupportedByProcessor]: "Not supported by processor",
    [ResponseErrorCode.SubscriptionAlreadyCanceled]: "Subscription already canceled",
    [ResponseErrorCode.SubscriptionNotFound]: "Subscription not found",
    [ResponseErrorCode.MustBeLowerThanFullAmount]: "Must be lower than full amount",
    [ResponseErrorCode.InstallmentPlanNotFound]: "Installment plan not found",
    [ResponseErrorCode.InstallmentAlreadySet]: "Installment already set",
    [ResponseErrorCode.InstallmentInvalidPlan]: "Invalid installment plan",
    [ResponseErrorCode.InstallmentInvalidPlanType]: "Invalid installment plan type",
    [ResponseErrorCode.InstallmentPaymentTypeNotAllowedForPlan]: "Installment payment type is not allowed for plan",
    [ResponseErrorCode.CardProcessorDisabledForInstallmentPlan]: "Card processor disabled for installment plan",
    [ResponseErrorCode.CardProcessorInstallmentFutureDated]: "Card processor installment future dated",
    [ResponseErrorCode.InstallmentInvalidInitialAmount]: "Invalid installment initial amount",
    [ResponseErrorCode.InstallmentMaxPayoutPeriodExceeded]: "Installment max payout period exceeded",
    [ResponseErrorCode.InstallmentInsufficientAmountPerCharge]: "Insufficient installment amount per charge",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetInitialAmount]: "Installment revolving plan cannot set initial amount",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetSubsequentCyclesStart]: "Installment revolving plan cannot set subsequent cycles start",
    [ResponseErrorCode.InstallmentProcessorInitialAmountsNotSupported]: "Installment processor initial amounts is not supported",
    [ResponseErrorCode.InstallmentProcessorPeriodNotSupported]: "Installment processor period is not supported",
    [ResponseErrorCode.CannotChangeToken]: "Cannot change token",
    [ResponseErrorCode.SubscriptionNotEnabled]: "Subscription not enabled",
    [ResponseErrorCode.SubscriptionAlreadyEnded]: "Subscription already enabled",
    [ResponseErrorCode.SubscriptionUnsupportedForInstallment]: "Subscription unsupported for installment",
    [ResponseErrorCode.InstallmentsNotEnabled]: "Installments not enabled",
    [ResponseErrorCode.CannotChangeInstallmentAmount]: "Cannot change installment amount",
    [ResponseErrorCode.CannotChangeToInstallment]: "Cannot change to installment",
    [ResponseErrorCode.MustBeMonthBasedToSet]: "Must be month based to set",
    [ResponseErrorCode.MustBeWithinTwoCycles]: "Must be within two cycles",
    [ResponseErrorCode.Deprecated]: "Deprecated",
    [ResponseErrorCode.CannotBeZero]: "Cannot be zero",
    [ResponseErrorCode.NeedAtLeastTwoCycles]: "Need at least two cycles",
    [ResponseErrorCode.InstallmentInvalidCyclesCount]: "Invalid installment cycles count",

    [ResponseErrorCode.CannotSetInitialAmountToZero]: "Cannot set initial amount to zero",
    [ResponseErrorCode.CannotSetUntilSubscriptionStarted]: "Cannot set until subscription started",
    [ResponseErrorCode.CannotSetAfterSubscriptionStarted]: "Cannot set after subscription started",
    [ResponseErrorCode.CannotChangeCanceledSubscription]: "Cannot change canceled subscription",

    /* Stratus merchant & store configuration */
    [ResponseErrorCode.StratusMerchantAlreadyExists]: "Stratus merchant already exists",
    [ResponseErrorCode.StratusStoreAlreadyExists]: "Stratus store already exists",
    [ResponseErrorCode.StratusStoreAllocationLimitReached]: "Stratus store allocation limit reached",

    /* path bindables */
    [ResponseErrorCode.InvalidElasticIndex]: "Invalid elastic index",
    [ResponseErrorCode.InvalidDynamoTable]: "Invalid dynamo table",
    [ResponseErrorCode.InvalidDateHistogramInterval]: "Invalid date histogram interval",
    [ResponseErrorCode.InvalidSqsEndpointKey]: "Invalid SQS endpoint",

    /* invalid card errors */
    [ResponseErrorCode.BinNotFound]: "BIN not found",
    [ResponseErrorCode.LuhnCheckFailed]: "Luhn check failed",
    [ResponseErrorCode.InvalidCardNumberLength]: "Invalid card number length",
    [ResponseErrorCode.CardPaymentDisabled]: "Card payment disabled",
    [ResponseErrorCode.DebitDisabled]: "Debit cards disabled",
    [ResponseErrorCode.PrepaidDisabled]: "Prepaid cards disabled",
    [ResponseErrorCode.CountryNotSupported]: "Card country is nod supported",
    [ResponseErrorCode.Unspecified]: "Invalid card",
};

export default LABELS;
