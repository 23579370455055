import { RequestErrorCode, ResponseErrorCode } from "univapay-node";

import * as ERRORS_LABELS from "../labels/errors";

export const LABELS: Record<string, string> = {
    [ERRORS_LABELS.ERRORS_TITLE]: "ข้อผิดพลาด",

    [ERRORS_LABELS.ERRORS_ALERTS_CONNECTOR]: "คุณไม่สามารถเชื่อมต่อกับเช็คเอาต์จากแอปพลิเคชันดั้งเดิมได้",
    [ERRORS_LABELS.ERRORS_ALERTS_PARAMETERS]: "เนื่องจากพารามิเตอร์เป็นการฉ้อโกงตรวจสอบ -ไม่สามารถเริ่มต้นได้",
    [ERRORS_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT]: "การสื่อสารล้มเหลว ตรวจสอบสถานะการสื่อสารและเริ่มต้นใหม่อีกครั้ง",
    [ERRORS_LABELS.ERRORS_ALERTS_TIMEOUT]: "หมดเวลาเกิดขึ้นเนื่องจากการประมวลผลภายในโปรดติดต่อฝ่ายสนับสนุน",
    [ERRORS_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT]: "เกิดการหมดเวลา",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE]: "เนื่องจากวิธีการชำระเงินนั้นเป็นการฉ้อโกงตรวจสอบ -ไม่สามารถเริ่มต้นได้",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE]: "วิธีการชำระเงินนี้ไม่ได้รับการสนับสนุน",
    [ERRORS_LABELS.ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE]: "วิธีการชำระเงินนี้ไม่ได้รับการสนับสนุน",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN]: "เกิดข้อผิดพลาด. ขออภัยที่ทำให้คุณเดือดร้อน แต่โปรดลองอีกครั้งหลังจากนั้นไม่นาน",
    [ERRORS_LABELS.ERRORS_ALERTS_PRIVILEGES]: "บัญชีไม่มีอำนาจในการใช้โทเค็นการเรียกเก็บเงินอย่างต่อเนื่อง",
    [ERRORS_LABELS.ERRORS_ALERTS_USAGE]: "โทเค็นการเรียกเก็บเงินต่อเนื่องต้องการข้อ จำกัด การใช้งาน",
    [ERRORS_LABELS.ERRORS_ALERTS_FORBIDDEN_CARD]: "ไม่รองรับแบรนด์การ์ดนี้",
    [ERRORS_LABELS.ERRORS_ALERTS_DOMAIN]: "คุณไม่สามารถเรียกใช้เช็ค -ออกจากโดเมนนี้",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_FAILED]: "ล้มเหลวในการสร้างโทเค็น",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED]: "หมดเวลาระหว่างการรับรอง CVV",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT]: "ไม่รองรับการชำระเงินการติดตั้ง",

    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE]: "ไม่สามารถใช้บริการได้",
    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO]: "ปัจจุบันบริการไม่สามารถใช้งานได้ชั่วคราว ขออภัยที่ทำให้คุณเดือดร้อน แต่โปรดลองอีกครั้งหลังจากนั้นไม่นาน",

    [ERRORS_LABELS.ERRORS_ALERTS_CAPTURE_CREDIT_ONLY]: "บัตรเดบิตและบัตรเติมเงินได้รับการสนับสนุนเฉพาะในการขายจริงและไม่สนับสนุนการขายชั่วคราว",

    [ERRORS_LABELS.ERROR_CHECKOUT_CHARGE]: "การประมวลผลการชำระเงินล้มเหลว",
    [ERRORS_LABELS.ERROR_CHECKOUT_SUBSCRIPTION]: "กระบวนการเรียกเก็บเงินปกติล้มเหลว",
    [ERRORS_LABELS.ERROR_CHECKOUT_PARAMS]: "พารามิเตอร์ไม่ถูกต้อง",
    [ERRORS_LABELS.PROCESSING_ERROR]: "ข้อผิดพลาดในการประมวลผล",
    [ERRORS_LABELS.ERROR_ALERTS_PAIDY_PAYMENT_REJECTED]: "การอนุมัติไม่หายไปกว่าการจ่ายเงิน กรุณาติดต่อ Payy สำหรับรายละเอียด",
    [ERRORS_LABELS.SERVICE_RESTRICTED]: "เนื่องจากการชำระเงินล้มเหลวจำนวนมาก บริการจะไม่สามารถใช้งานได้ในช่วงระยะเวลาหนึ่ง",
    [ERRORS_LABELS.EXPIRATION_DATE_ERROR]: "การประมวลผลการชำระเงินล้มเหลว",

    /* request */
    [RequestErrorCode.RequestError]: "คำขอที่ไม่ได้รับอนุญาต",

    /* generic */
    [ResponseErrorCode.BadRequest]: "ข้อผิดพลาดคำขอที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.Forbidden]: "การดำเนินการต้องห้าม",
    [ResponseErrorCode.NotFound]: "ฉันไม่พบทรัพยากร",
    [ResponseErrorCode.NotAllowed]: "การดำเนินการที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.Conflicted]: "เรากำลังแข่งขันกับทรัพยากรอื่น ๆ ",
    [ResponseErrorCode.TooManyRequests]: "คำขอสำหรับ API มีความเข้มข้น",
    [ResponseErrorCode.InternalServerError]: "เกิดข้อผิดพลาดภายใน (กรุณาติดต่อศูนย์สนับสนุน 0570-035-672)",
    [ResponseErrorCode.ServiceUnavailable]: "บริการไม่สามารถใช้ได้",
    [ResponseErrorCode.NotAuthorized]: "การดำเนินการที่ไม่ได้รับอนุญาต",

    /* global */
    [ResponseErrorCode.UnknownError]: "ข้อผิดพลาดที่ไม่คาดคิดโปรดติดต่อฝ่ายสนับสนุน",
    [ResponseErrorCode.Timeout]: "การตอบสนองต่อคำขอคือ t -time t (กรุณาติดต่อศูนย์สนับสนุน 0570-035-672)",
    [ResponseErrorCode.DBError]: "ข้อผิดพลาดของฐานข้อมูลภายในโปรดติดต่อฝ่ายสนับสนุน",
    [ResponseErrorCode.InvalidRequest]: "คำขอที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.UnableToGetIdempotentResult]: "เนื่องจากพบแคชที่สอดคล้องกับคีย์冪冪เนื้อหาที่ร้องขอจึงไม่ได้ดำเนินการ แต่ไม่สามารถรับผลการประมวลผลก่อนหน้านี้ได้",
    [ResponseErrorCode.ServiceUnavailableTryAgain]: "บริการไม่สามารถใช้ชั่วคราว ปล่อยเวลาและลองอีกครั้ง",
    [ResponseErrorCode.RateLimitExceeded]: "ข้อ จำกัด การร้องขอ API เกินกว่า",

    /* auth */
    [ResponseErrorCode.InvalidIpAddress]: "ที่อยู่ IP ที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.InvalidDomain]: "โดเมนส่วนหัวต้นกำเนิดที่ร้องขอไม่ได้ลงทะเบียนในโดเมนโทเค็นแอปพลิเคชันที่ระบุ",
    [ResponseErrorCode.InvalidCredentials]: "ข้อมูลการรับรองความถูกต้องไม่ถูกต้อง",
    [ResponseErrorCode.AuthHeaderMissing]: "ไม่ได้ระบุส่วนหัวการอนุญาต",
    [ResponseErrorCode.InvalidPermissions]: "ประเภทของโทเค็นแอปพลิเคชันไม่ถูกต้องหรือการอนุญาตบัญชีไม่เพียงพอ",
    [ResponseErrorCode.ImproperAuth]: "ผู้มีอำนาจไม่ถูกต้องโปรดติดต่อฝ่ายสนับสนุน",
    [ResponseErrorCode.CouldNotRefreshAuth]: "ฉันล้มเหลวในการอัปเดตโทเค็นเข้าสู่ระบบโปรดติดต่อฝ่ายสนับสนุน",
    [ResponseErrorCode.UserBanned]: "ผู้ใช้ที่มีการใช้งานถูกห้าม",
    [ResponseErrorCode.InvalidLoginToken]: "โทเค็นเข้าสู่ระบบไม่ถูกต้อง",
    [ResponseErrorCode.InvalidAppToken]: "ข้อกำหนดของโทเค็นแอปพลิเคชันไม่ถูกต้อง",
    [ResponseErrorCode.ExpiredLoginToken]: "วันหมดอายุของโทเค็นเข้าสู่ระบบหมดอายุแล้ว",
    [ResponseErrorCode.OutdatedAppToken]: "รุ่นโทเค็นแอปพลิเคชันเก่า สร้างโทเค็นแอปพลิเคชันใหม่",
    [ResponseErrorCode.ChargeTooQuick]: "เราพยายามสร้างตั๋วเงินหลายใบภายในระยะเวลาที่กำหนดด้วยโทเค็นเกษียณที่ จำกัด",

    /* file upload */
    [ResponseErrorCode.FileUploadError]: "ข้อผิดพลาดในการอัปโหลดไฟล์",
    [ResponseErrorCode.FileMaxSizeExceeded]: "ขนาดของไฟล์ที่อัปโหลดมีขนาดใหญ่เกินไป",
    [ResponseErrorCode.FileInvalidType]: "ประเภท MIME ของไฟล์ที่อัปโหลดไม่ถูกต้อง",
    [ResponseErrorCode.FileNotFound]: "ไม่พบไฟล์",

    /* crud actions */
    [ResponseErrorCode.TokenGenerationException]: "ข้อยกเว้นเกิดขึ้นเมื่อโทเค็นถูกสร้างขึ้น",
    [ResponseErrorCode.TokenForWrongStore]: "ร้านค้าโทเค็นธุรกรรมนั้นแตกต่างจากร้านเรียกเก็บเงินปกติ",
    [ResponseErrorCode.NonSubscriptionPayment]: "ระบุโทเค็นหนึ่งครั้งหรือโทเค็นเกษียณอายุเพื่อสร้างการเรียกเก็บเงิน",
    [ResponseErrorCode.BankAccountExists]: "มันเป็นบัญชีธนาคารที่มีอยู่แล้ว",
    [ResponseErrorCode.EmailExists]: "เป็นที่อยู่อีเมลที่มีอยู่แล้ว",
    [ResponseErrorCode.StoreExists]: "มันเป็นร้านค้าที่มีอยู่แล้ว",
    [ResponseErrorCode.GatewayCredentialsExists]: "มันเป็นการส่งมอบเกตเวย์ที่มีอยู่แล้ว",
    [ResponseErrorCode.NonUniqueRuleForMerchant]: "กฎสำหรับพ่อค้าไม่ซ้ำกัน",
    [ResponseErrorCode.NonUniqueRuleSetForMerchant]: "กฎที่กำหนดไว้สำหรับพ่อค้านั้นไม่ซ้ำกัน",
    [ResponseErrorCode.NonUniqueRule]: "กฎไม่เป็นเอกลักษณ์",
    [ResponseErrorCode.RulePriorityMustBePositive]: "ลำดับความสำคัญของกฎจะต้องเป็นค่าบวก",
    [ResponseErrorCode.NonExistingRule]: "มันเป็นกฎที่ไม่มีอยู่จริง",
    [ResponseErrorCode.MustContainUniqueElementsOnly]: "องค์ประกอบต้องไม่ซ้ำกัน",
    [ResponseErrorCode.WebhookURLExists]: "URL ที่ระบุได้รับการลงทะเบียนแล้ว",
    [ResponseErrorCode.GroupExists]: "มันเป็นกลุ่มที่มีอยู่แล้ว",
    [ResponseErrorCode.PrimaryBankAccountNotFound]: "ฉันไม่พบบัญชีธนาคารหลัก",
    [ResponseErrorCode.MustHaveAPrimaryBankAccount]: "ฉันต้องการบัญชีธนาคารหลัก",
    [ResponseErrorCode.VerificationDataExists]: "มีข้อมูลสำหรับการตรวจสอบ",
    [ResponseErrorCode.TemplateExists]: "มีแม่แบบ",
    [ResponseErrorCode.StratusCredentialsExists]: "มีหนังสือรับรอง CAFIS",
    [ResponseErrorCode.StratusRuleStillInUse]: "มีการใช้กฎของ CAFIS",
    [ResponseErrorCode.ResourceLimitReached]: "ขีด จำกัด สูงสุดของการ จำกัด ทรัพยากร",
    [ResponseErrorCode.GatewayConfigurationExists]: "มีการตั้งค่าเกตเวย์",
    [ResponseErrorCode.RequiresValidMerchantGatewayConfig]: "ฉันไม่พบการตั้งค่าเกตเวย์",
    [ResponseErrorCode.MergedConfigurationNotFound]: "ฉันไม่พบการตั้งค่าเกตเวย์",

    /* payments and charges */
    [ResponseErrorCode.InvalidTokenType]: "ประเภทของโทเค็นการทำธุรกรรมไม่ถูกต้อง",
    [ResponseErrorCode.InvalidToken]: "โทเค็นที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.InvalidCard]: "การ์ดที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.TransactionTokenIsNotRecurring]: "โทเค็นไม่ใช่โทเค็นการเรียกเก็บเงินอย่างต่อเนื่อง",
    [ResponseErrorCode.ForbiddenIP]: "ประเทศที่กำหนดจากที่อยู่ IP ของแหล่งที่มาไม่รวมอยู่ในประเทศที่กำหนดไว้",
    [ResponseErrorCode.InvalidUserData]: "ข้อมูลผู้ใช้หรือข้อมูลคำขอไม่ถูกต้อง",
    [ResponseErrorCode.NonUniqueActiveToken]: "มีโทเค็นธุรกรรมที่ใช้งานอยู่แล้ว",
    [ResponseErrorCode.TransactionAlreadyProcessed]: "ไม่สามารถระบุโทเค็นธุรกรรมที่ใช้แล้วได้",
    [ResponseErrorCode.TransactionTokenExpired]: "โทเค็นธุรกรรมหมดอายุแล้ว",
    [ResponseErrorCode.NoTestCardInLiveMode]: "ไม่สามารถใช้การ์ดทดสอบในโหมดการผลิต (สด)",
    [ResponseErrorCode.ProcessingModeMismatch]: "โหมดการประมวลผลไม่ตรงกัน",
    [ResponseErrorCode.PaymentTypeNotAllowed]: "ไม่อนุญาตให้ใช้วิธีการชำระเงิน",
    [ResponseErrorCode.RecurringTokenNotAllowed]: "ไม่อนุญาตให้ใช้โทเค็นการเรียกเก็บเงินต่อเนื่อง",
    [ResponseErrorCode.RecurringTokenDisabled]: "โทเค็นการทำธุรกรรมไม่ถูกต้องหรือบัญชีไม่มีอำนาจในการใช้โทเค็น retrieling",
    [ResponseErrorCode.RecurringUsageLimitRequired]: "จำเป็นต้องใช้พารามิเตอร์ USAGE_LIMIT",
    [ResponseErrorCode.RecurringUsageRequiresCVV]: "ฉันต้องให้ CVV",
    [ResponseErrorCode.CvvAuthorizationNotCompleted]: "ฉันไม่สามารถยืนยัน CVV ได้",
    [ResponseErrorCode.UsageLimitNotApplicable]: "usage_limit ไม่สามารถระบุได้",
    [ResponseErrorCode.CardProcessingDisabled]: "บัตรถูกปิดใช้งานโดยวิธีการชำระเงิน",
    [ResponseErrorCode.QRProcessingDisabled]: "รหัส QR ถูกปิดใช้งานโดยวิธีการชำระเงิน",
    [ResponseErrorCode.PaidyProcessingDisabled]: "การประมวลผลแบบจ่ายเงินถูกปิดใช้งาน",
    [ResponseErrorCode.MerchantQRProcessingDisabled]: "การประมวลผล MerchantQR ถูกปิดใช้งาน",
    [ResponseErrorCode.OnlineProcessingDisabled]: "การประมวลผลออนไลน์ไม่ถูกต้อง",
    [ResponseErrorCode.ConvenienceProcessingDisabled]: "การชำระเงินร้านสะดวกซื้อถูกปิดใช้งานด้วยวิธีการชำระเงิน",
    [ResponseErrorCode.NotOneTimeToken]: "ไม่ได้รับการสนับสนุนยกเว้นหนึ่งโทเค็น tent",
    [ResponseErrorCode.NotSubscriptionToken]: "ระบุโทเค็นการเรียกเก็บเงินปกติหรือโทเค็นแบบรีแพ็ท",
    [ResponseErrorCode.NotRecurringToken]: "มันไม่ใช่โทเค็นการเรียกเก็บเงินอย่างต่อเนื่อง",
    [ResponseErrorCode.CurrencyMustMatchCharge]: "สกุลเงินในเวลาที่คืนเงินจะต้องเหมือนกับสกุลเงินในเวลาที่เรียกเก็บเงิน",
    [ResponseErrorCode.NoDirectCurrencyGateway]: "ไม่มีเกตเวย์ที่สามารถใช้งานได้โดยไม่ต้องแปลงสกุลเงิน",
    [ResponseErrorCode.TokenMustBeConfirmed]: "มันเป็นโทเค็นที่ไม่ได้รับการยืนยัน T",
    [ResponseErrorCode.ConfirmationRequiresEmail]: "จำเป็นต้องมีอีเมลสำหรับการยืนยัน",
    [ResponseErrorCode.WrongConfirmationCode]: "รหัสยืนยันที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.RefundNotWithinBounds]: "จำนวนเงินคืนไม่ใช่ช่วงที่ถูกต้อง มันใหญ่กว่า 0 และต้องน้อยกว่าจำนวนเงินการเรียกเก็บเงิน",
    [ResponseErrorCode.PartialRefundNotSupported]: "มันไม่สนับสนุนการคืนเงินบางส่วน",
    [ResponseErrorCode.InvalidTransfer]: "การถ่ายโอนที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.TransferAlreadyExists]: "มีการถ่ายโอนอยู่แล้ว",
    [ResponseErrorCode.NoLedgers]: "ไม่มีบัญชีแยกประเภท",
    [ResponseErrorCode.FailedToAssociateLedgers]: "บัญชีแยกประเภทไม่เกี่ยวข้อง",
    [ResponseErrorCode.LiveModeNotEnabledWhenUnverified]: "ในการใช้โหมดการผลิต (สด) คุณต้องทำการคัดกรองบัญชีให้เสร็จสมบูรณ์",
    [ResponseErrorCode.SelfTransferNotPermitted]: "ไม่อนุญาตให้โอนตัวเอง",
    [ResponseErrorCode.CardLocked]: "การ์ดใบนี้ถูกล็อคชั่วคราวเนื่องจากจำนวนความล้มเหลวภายในระยะเวลาหนึ่งเกินกว่าค่าที่น่าตื่นเต้น",
    [ResponseErrorCode.SubscriptionProcessing]: "เรากำลังดำเนินการเรียกเก็บเงินปกติ",
    [ResponseErrorCode.AlreadyCaptured]: "การเรียกเก็บเงินเป้าหมายถูกจับหรืออำนาจยังไม่เสร็จ",
    [ResponseErrorCode.CannotUpdateTransactionToken]: "ฉันไม่สามารถอัปเดตโทเค็นการทำธุรกรรมได้",
    [ResponseErrorCode.CaptureAmountTooLarge]: "จำนวนการจับกุมมีขนาดใหญ่กว่าอำนาจ",
    [ResponseErrorCode.PartialCaptureNotSupported]: "ไม่รองรับการจับบางส่วน",
    [ResponseErrorCode.DebitAuthorizationDisabled]: "ผู้มีอำนาจบัตรเดบิตไม่ถูกต้อง",
    [ResponseErrorCode.PrePaidAuthorizationDisabled]: "อำนาจของบัตรเติมเงินไม่ถูกต้อง",

    [ResponseErrorCode.NoGatewaysAvailable]: "ฉันไม่พบเกตเวย์ที่มีอยู่",
    [ResponseErrorCode.NoGatewayCredentialsAvailable]: "ไม่มีข้อมูลรับรองเกตเวย์ที่ถูกต้อง",
    [ResponseErrorCode.NoGatewayTransactionIdAvailable]: "ไม่มีรหัสธุรกรรมที่ถูกต้อง",
    [ResponseErrorCode.PaymentTypeNotSupportedForCheck]: "วิธีการชำระเงินไม่ได้รับการสนับสนุน",
    [ResponseErrorCode.NoGatewayCredentialsForSelectedPaymentType]: "ไม่มีข้อมูลรับรองของเกตเวย์ของวิธีการชำระเงินที่เลือก",
    [ResponseErrorCode.DisabledPaymentType]: "วิธีการชำระเงินถูกปิดใช้งาน",
    [ResponseErrorCode.CardBrandNotSupported]: "ไม่มีเกตเวย์ที่สอดคล้องกับแบรนด์การ์ดที่ระบุ",
    [ResponseErrorCode.CardCountryNotSupported]: "ไม่มีเกตเวย์ที่สอดคล้องกับผู้ออกบัตรที่ระบุ",
    [ResponseErrorCode.CVVRequired]: "ฉันต้องให้ CVV",
    [ResponseErrorCode.LastNameRequired]: "ฉันต้องการนามสกุลคั่นด้วยอวกาศในชื่อการ์ด",
    [ResponseErrorCode.AuthNotSupported]: "ไม่มีเกตเวย์ที่สอดคล้องกับผู้มีอำนาจ",
    [ResponseErrorCode.GatewayConfigurationRequired]: "จำเป็นต้องมีการตั้งค่าเกตเวย์",
    [ResponseErrorCode.GatewayConfigurationNotRequired]: "ไม่จำเป็นต้องตั้งค่าเกตเวย์",

    [ResponseErrorCode.InvalidBinRange]: "หมายเลข bin อยู่ในช่วงที่ไม่ถูกต้อง",
    [ResponseErrorCode.OverlappingStratusTerminalIdRange]: "ID เทอร์มินัล Stratus ถูกทำซ้ำ",
    [ResponseErrorCode.InvalidStratusTerminalIdRange]: "ID เทอร์มินัลของ Stratus ผิดกฎหมาย",
    [ResponseErrorCode.InvalidCardCompany]: "บริษัท บัตรที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.VerificationRequired]: "จำเป็นต้องมีรหัสการคัดกรอง",

    /* validation responses */
    [ResponseErrorCode.ChangeProhibited]: "การเปลี่ยนแปลงในฟิลด์นี้เป็นสิ่งต้องห้าม",
    [ResponseErrorCode.ForbiddenParameter]: "อำนาจที่ไม่เพียงพอในการใช้พารามิเตอร์นี้หรือไม่เป็นไปตามข้อกำหนด",
    [ResponseErrorCode.ValidationError]: "มีข้อผิดพลาดการตรวจสอบความถูกต้องในพารามิเตอร์ของเนื้อหาคำขอ ดูข้อผิดพลาดสำหรับรายละเอียด",
    [ResponseErrorCode.RequiredValue]: "มันเป็นพารามิเตอร์ที่ต้องการ",
    [ResponseErrorCode.MustBeFutureTime]: "ฉันต้องการวันและเวลาในอนาคต",
    [ResponseErrorCode.InvalidFormat]: "มันเป็นรูปแบบที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidPercentFee]: "มันเป็นอัตราค่าธรรมเนียมที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidCardNumber]: "รูปแบบของหมายเลขบัตรไม่ถูกต้อง",
    [ResponseErrorCode.InvalidCardExpiration]: "วันหมดอายุของบัตรผ่านไปแล้ว",
    [ResponseErrorCode.InvalidCVV]: "CVV ต้องเป็นจำนวนเต็ม 3 ถึง 5 หลัก",
    [ResponseErrorCode.InvalidFormatLength]: "รูปแบบความยาวนั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatUUID]: "รูปแบบ uuid เป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatBase64]: "รูปแบบ Base64 นั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatEmail]: "รูปแบบที่อยู่อีเมลไม่ถูกต้อง",
    [ResponseErrorCode.InvalidCardDescriptor]: "รูปแบบของศิษย์ไม่ถูกต้อง",
    [ResponseErrorCode.InvalidFormatCurrency]: "มันเป็นรหัสสกุลเงินที่ไม่สามารถรับรู้ได้",
    [ResponseErrorCode.InvalidCurrency]: "มันเป็นสกุลเงินที่ไม่สามารถรับรู้ได้",
    [ResponseErrorCode.InvalidAmount]: "จำนวนเงินจะต้องเป็นจำนวนเต็มที่ใหญ่กว่า 0",
    [ResponseErrorCode.InvalidEventForStore]: "มันเป็นเหตุการณ์ที่ผิดกฎหมายสำหรับร้านค้านั้น",
    [ResponseErrorCode.InvalidEventForPlatform]: "เหตุการณ์ไม่ถูกต้องสำหรับแพลตฟอร์ม",
    [ResponseErrorCode.InvalidEventForMerchant]: "เหตุการณ์ไม่ถูกต้องสำหรับผู้ค้า",
    [ResponseErrorCode.InvalidFormatDomain]: "รูปแบบของชื่อโดเมนนั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatUrl]: "URL เป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatObject]: "รูปแบบของวัตถุนั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatCountry]: "มันเป็นรหัสประเทศที่ไม่สามารถรับรู้ได้",
    [ResponseErrorCode.InvalidPhoneNumber]: "รูปแบบหมายเลขโทรศัพท์เป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatSwiftCode]: "Slifcodes เป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatRoutingNumber]: "หมายเลขการกำหนดเส้นทางเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatRoutingCode]: "รหัสการกำหนดเส้นทางเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatIfcsCode]: "รหัส IFCS นั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidFormatBankAccountNumber]: "รูปแบบของหมายเลขบัญชีธนาคารไม่ถูกต้อง",
    [ResponseErrorCode.InvalidPasswords]: "รหัสผ่านเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidTimePeriod]: "มันเป็นรูปแบบของช่วงเวลาที่ไม่สามารถรับรู้ได้",
    [ResponseErrorCode.InvalidDayOfWeek]: "มันเป็นวันที่ผิดกฎหมายของสัปดาห์",
    [ResponseErrorCode.InvalidWeekOfMonth]: "มันเป็นการฉ้อโกงในอีกไม่กี่สัปดาห์",
    [ResponseErrorCode.InvalidDayOfMonth]: "วันของเดือนนั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.InvalidColorsSize]: "ขนาดสีฉ้อโกง",
    [ResponseErrorCode.NestedJsonNotAllowed]: "ไม่สามารถใช้รูปแบบ JSON ที่ซ้อนกันได้",
    [ResponseErrorCode.InvalidFormatDate]: "รูปแบบวันที่ฉ้อโกง",
    [ResponseErrorCode.InvalidChargeStatus]: "มันเป็นสถานะของการเรียกเก็บเงินที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidQRScanGateway]: "เกตเวย์การชำระเงินรหัส QR ไม่ได้ตั้งค่าหรือไม่ถูกต้อง",
    [ResponseErrorCode.NotQRCharge]: "มันไม่ใช่การเรียกเก็บเงิน QR",
    [ResponseErrorCode.NotOnlineCharge]: "ไม่ใช่การเรียกเก็บเงินออนไลน์",
    [ResponseErrorCode.IssuerTokenEmpty]: "โทเค็น Isshuer ว่างเปล่า",
    [ResponseErrorCode.CardLimitExceededForStore]: "จำนวนเงินทั้งหมดของบัตรภายในระยะเวลาหนึ่งเกินขีด จำกัด ",
    [ResponseErrorCode.InvalidLanguage]: "มันเป็นรหัสภาษาที่ไม่สามารถรับรู้ได้",
    [ResponseErrorCode.SubscriptionNotAllowed]: "ไม่อนุญาตให้ใช้การเรียกเก็บเงินปกติ",
    [ResponseErrorCode.OneTimeOnlyAllowed]: "อนุญาตให้ใช้โทเค็นเพียงครั้งเดียวเท่านั้น",
    [ResponseErrorCode.AuthExpired]: "ผู้มีอำนาจหมดอายุแล้ว",
    [ResponseErrorCode.InvalidTemplateKey]: "คีย์เทมเพลตนั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.NonPublicTemplate]: "ไม่ใช่เทมเพลตสาธารณะ",
    [ResponseErrorCode.OnlyJapanesePhoneNumberAllowed]: "รองรับหมายเลขโทรศัพท์ญี่ปุ่นเท่านั้น",
    [ResponseErrorCode.ExpirationDateOutOfBounds]: "วันหมดอายุไม่ได้ออกใบอนุญาต",
    [ResponseErrorCode.UnsupportedLanguage]: "มันเป็นภาษาที่ไม่รองรับ",
    [ResponseErrorCode.DefaultLanguageNotSupported]: "ไม่มีการตั้งค่าภาษาเริ่มต้น",
    [ResponseErrorCode.OnlyForCardPayment]: "วิธีการชำระเงินสามารถใช้กับบัตรได้เท่านั้น",
    [ResponseErrorCode.CaptureOnlyForCardPayment]: "การจับภาพเป็นไปได้เฉพาะในกรณีของการชำระเงินด้วยบัตร",
    [ResponseErrorCode.InvalidCardTypeForCapture]: "ฉันไม่มีอำนาจเพราะประเภทบัตรไม่ได้กำหนด",
    [ResponseErrorCode.InvalidScheduledCaptureDate]: "วันที่และเวลาการจับต้องใช้เวลาหนึ่งชั่วโมงและน้อยกว่า 7 วันนับจากปัจจุบัน",
    [ResponseErrorCode.IncoherentDateRange]: "ช่วงวันที่ไม่ถูกต้อง",
    [ResponseErrorCode.InvalidMerchantStatus]: "สถานะของผู้ค้าไม่ถูกต้อง",
    [ResponseErrorCode.MustHaveOneElement]: "ฉันต้องการองค์ประกอบอย่างน้อยหนึ่งองค์ประกอบ",
    [ResponseErrorCode.MustHaveAtLeastOneElement]: "ฉันต้องการองค์ประกอบอย่างน้อยหนึ่งองค์ประกอบ",
    [ResponseErrorCode.ThresholdMustBeNull]: "ธรณีประตูจะต้องเป็นโมฆะ",
    [ResponseErrorCode.IllegalNumberOfItems]: "จำนวนรายการนั้นเป็นการฉ้อโกง",
    [ResponseErrorCode.UnableToReadCredentials]: "ฉันอ่านเครดิตไม่ได้",
    [ResponseErrorCode.GatewayError]: "ข้อผิดพลาดของเกตเวย์",
    [ResponseErrorCode.GatewayNoLongerSupported]: "เกตเวย์ไม่รองรับ",
    [ResponseErrorCode.InvalidChargeAmountLimit]: "ขีด จำกัด ของจำนวนเงินการเรียกเก็บเงินไม่ถูกต้อง",
    [ResponseErrorCode.PlatformCurrencyRequiredInCvvAmount]: "โปรดระบุจำนวน CVV ในสกุลเงินแพลตฟอร์ม",
    [ResponseErrorCode.ChargeAmountTooLow]: "จำนวนเงินการเรียกเก็บเงินน้อยกว่าใบเรียกเก็บเงินขั้นต่ำ",
    [ResponseErrorCode.ChargeAmountTooHigh]: "จำนวนเงินการเรียกเก็บเงินเกินจำนวนเงินสูงสุด",
    [ResponseErrorCode.MustContainPlatformCurrency]: "โปรดระบุด้วยสกุลเงินแพลตฟอร์ม",
    [ResponseErrorCode.CurrencyMustBeInAmountsList]: "โปรดระบุในสกุลเงิน",
    [ResponseErrorCode.InvalidJapanesePostalCode]: "รหัสไปรษณีย์ญี่ปุ่นไม่ถูกต้อง",
    [ResponseErrorCode.BrandNotDefined]: "แบรนด์ที่ไม่อยู่ในรายการ",

    /* Used when creating a new merchant */
    [ResponseErrorCode.OnlyASCII]: "รองรับอักขระ ASCII",
    [ResponseErrorCode.UniqueCharacters]: "ตัวละครที่ไม่เหมือนใคร",
    [ResponseErrorCode.AtLeastOneDigit]: "หมายเลขขั้นต่ำ",
    [ResponseErrorCode.AtLeastOneLetter]: "ตัวละครอย่างน้อยหนึ่งตัว",
    [ResponseErrorCode.EmptyRoles]: "คุณไม่สามารถมีบทบาทที่ว่างเปล่าได้",
    [ResponseErrorCode.EditOwnRolesNotAllowed]: "ฉันไม่สามารถแก้ไขบทบาทของฉันได้",
    [ResponseErrorCode.EditOwnStatusNotAllowed]: "ฉันไม่สามารถแก้ไขสถานะของฉันได้",
    [ResponseErrorCode.InvalidPathValue]: "เส้นทางที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.InvalidCardBrand]: "มันเป็นแบรนด์การ์ดที่ไม่สามารถจดจำได้",
    [ResponseErrorCode.UnsupportedCountry]: "ประเทศไม่ได้รับการสนับสนุน",
    [ResponseErrorCode.UnsupportedCurrency]: "ไม่รองรับสกุลเงิน",
    [ResponseErrorCode.CannotBanSelf]: "คุณไม่สามารถทำให้ตัวเองเป็นผู้ใช้ที่ต้องห้ามได้",
    [ResponseErrorCode.CannotSelfTerminate]: "ไม่สามารถยุติตนเองได้",
    [ResponseErrorCode.NoDuplicateCurrencies]: "คุณไม่สามารถลงทะเบียนการผ่านเดียวกันด้วยซ้ำ",

    [ResponseErrorCode.PlatformNotFound]: "ฉันไม่พบแพลตฟอร์ม",
    [ResponseErrorCode.InvalidPlatform]: "ข้อกำหนดของแพลตฟอร์มไม่ถูกต้อง",
    [ResponseErrorCode.InvalidInvoiceFeeConfiguration]: "การตั้งค่าใบแจ้งหนี้ที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidPlatformRole]: "ม้วนของแพลตฟอร์มนั้นเป็นการฉ้อโกง",

    /* verification */
    [ResponseErrorCode.DataNotSubmitted]: "ข้อมูลไม่ได้ส่ง",
    [ResponseErrorCode.NoBankAccount]: "ไม่มีบัญชีธนาคาร",
    [ResponseErrorCode.PercentFeeNotSubmitted]: "อัตราค่าธรรมเนียมไม่ได้ส่ง",
    [ResponseErrorCode.InsufficientSystemManagerInfo]: "ข้อมูลผู้ดูแลระบบไม่เพียงพอ",

    /* gateway credentials */
    [ResponseErrorCode.CredentialsExist]: "เครดิตมีอยู่แล้ว",
    [ResponseErrorCode.OnlyOnePaidyCredentialsAllowed]: "มีเพียงหนึ่งเดียวที่จ่ายเงินให้ได้",
    [ResponseErrorCode.OnlyOneDBaraiCredentialsAllowed]: "มีเครดิตเพียงหนึ่ง D -Payable Credit",
    [ResponseErrorCode.OnlyMerchantOrStoreCredentialsAllowed]: "จำเป็นต้องมีเครดิตของพ่อค้าและร้านค้า",
    [ResponseErrorCode.RefundExceedsChargeAmount]: "จำนวนเงินคืนได้เกินค่าใช้จ่าย",
    [ResponseErrorCode.CannotRefundUnsuccessfulCharge]: "การชำระเงินในรัฐนอกเหนือจากความสำเร็จไม่สามารถคืนเงินได้",
    [ResponseErrorCode.RefundNotAllowed]: "ไม่อนุญาตให้ใช้วิธีการชำระเงินที่ไม่สนับสนุนการคืนเงินหรือคืนเงิน",
    [ResponseErrorCode.RefundPercentageExceeded]: "เกินขีด จำกัด การคืนเงิน",
    [ResponseErrorCode.CancelNotAllowed]: "วิธีการชำระเงินนี้ไม่สนับสนุนการยกเลิก หรือไม่สามารถยกเลิกสถานะการเรียกเก็บเงินเป้าหมายได้",

    [ResponseErrorCode.PaidyShippingAddressNeedsOneOptionalField]: "จำเป็นต้องมีฟิลด์เสริมหนึ่งสำหรับปลายทางการจัดส่งของ Paidy",

    [ResponseErrorCode.RevertNotAllowed]: "ไม่อนุญาตให้ส่งคืน",

    /* apple pay */
    [ResponseErrorCode.ApplePayNotEnabled]: "Apple Pay ไม่ถูกต้อง",
    [ResponseErrorCode.ApplePayAlreadyEnabled]: "Apple Pay ใช้ได้แล้ว",
    [ResponseErrorCode.ApplePayCertificateAlreadyUpdated]: "ใบรับรอง Apple Pay ได้รับการอัปเดตแล้ว",
    [ResponseErrorCode.ApplePayUnsupportedAlgorithm]: "มันเป็นอัลกอริทึมที่ไม่ได้รับการสนับสนุนโดย Apple Pay",
    [ResponseErrorCode.ApplePayCertificateNotFound]: "ฉันไม่พบใบรับรอง Apple Pay",
    [ResponseErrorCode.ApplePayUnableToGenerateCertificateRequest]: "Apple Pay ไม่สามารถสร้างคำขอใบรับรองได้",
    [ResponseErrorCode.ApplePayInvalidConfiguration]: "การกำหนดค่า Apple Pay ไม่ถูกต้อง",
    [ResponseErrorCode.ApplePayInvalidCertificate]: "Apple Pay ใบรับรองไม่ถูกต้อง",
    [ResponseErrorCode.ApplePayInvalidAlgorithm]: "อัลกอริทึม Apple Pay ไม่ถูกต้อง",
    [ResponseErrorCode.ApplePayInvalidCertificateFormat]: "รูปแบบใบรับรอง Apple Pay ไม่ถูกต้อง",
    [ResponseErrorCode.ApplePayInvalidSignature]: "Apple Pay ลายเซ็นไม่ถูกต้อง",
    [ResponseErrorCode.ApplePayError]: "ข้อผิดพลาดของ Apple Pay",
    [ResponseErrorCode.ApplePayCertificateAlreadySet]: "ใบรับรอง Apple Pay ได้รับการตั้งค่าแล้ว",
    [ResponseErrorCode.ApplePayCertificateStillInUse]: "ใบรับรอง Apple Pay ยังคงใช้",

    /* Charge errors */
    [ResponseErrorCode.CardNumberNotValid]: "ข้อผิดพลาดหมายเลขบัตร",
    [ResponseErrorCode.CardExpirationMonthInvalid]: "ดวงจันทร์ที่ถูกต้องของการ์ดไม่ถูกต้อง",
    [ResponseErrorCode.CardExpirationYearInvalid]: "ปีที่ถูกต้องของการ์ดไม่ถูกต้อง",
    [ResponseErrorCode.CardExpired]: "วันหมดอายุของบัตรหมดอายุ",
    [ResponseErrorCode.CardCVVInvalid]: "ข้อผิดพลาดรหัสความปลอดภัย",
    [ResponseErrorCode.CardRejected]: "ข้อผิดพลาดในการตรวจสอบการรับรอง (โปรดติดต่อผู้ออกบัตร)",
    [ResponseErrorCode.CardInvalid]: "การ์ดไม่ถูกต้อง",
    [ResponseErrorCode.ChargeInvalidData]: "ข้อมูลคำขอไม่ถูกต้อง",
    [ResponseErrorCode.TooManyChargeRequests]: "มีคำขอเรียกเก็บเงินมากเกินไปสำหรับการ์ดใบเดียวกันในเวลาอันสั้น โปรดลองอีกครั้งหลังจากนั้นไม่นานลองอีกครั้ง",
    [ResponseErrorCode.CancelUnavailable]: "การเรียกเก็บเงินนี้ไม่สามารถยกเลิกได้",
    [ResponseErrorCode.ChargeExpired]: "หมดอายุการเรียกเก็บเงิน",
    [ResponseErrorCode.SeizeCard]: "ข้อผิดพลาดในการตรวจสอบการรับรอง (การสูญเสียบัตร, ขโมย, ไม่ถูกต้อง, ข้อ จำกัด , ฯลฯ )",
    [ResponseErrorCode.ContactBank]: "ข้อผิดพลาดในการตรวจสอบการรับรอง (โปรดติดต่อผู้ออกบัตร)",
    [ResponseErrorCode.FraudSuspected]: "ข้อผิดพลาดในการตรวจสอบการรับรอง (สงสัยว่ามีข้อ จำกัด การใช้งาน / ความปลอดภัยที่ผิดกฎหมาย)",
    [ResponseErrorCode.BankSystemFailure]: "ข้อผิดพลาดในการทำธุรกรรมของ บริษัท บัตร",
    [ResponseErrorCode.DynamicDescriptorNotSupported]: "สาวกแบบไดนามิกไม่ได้รับการสนับสนุน",
    [ResponseErrorCode.PaymentCodeInvalid]: "รหัสบาร์โค้ด/QR ไม่ถูกต้อง",
    [ResponseErrorCode.PaymentCodeExpired]: "วันหมดอายุของรหัสบาร์โค้ด/QR หมดอายุแล้ว",
    [ResponseErrorCode.PaymentCodeAlreadyUsed]: "รหัสบาร์โค้ด/QR ถูกนำมาใช้แล้ว",
    [ResponseErrorCode.PaymentCodeStillInUse]: "รหัสบาร์โค้ด/QR กำลังใช้งานอยู่",
    [ResponseErrorCode.RejectedHighRisk]: "ฉันถูกปฏิเสธเพราะฉันเกินมูลค่าความเสี่ยงสูง",
    [ResponseErrorCode.ConfirmationPeriodExpired]: "ระยะเวลาการยืนยันสิ้นสุดลงแล้ว",
    [ResponseErrorCode.RevertFailed]: "ฉันล้มเหลวในการกลับมาฉันต้องการการดำเนินการด้วยตนเอง",
    [ResponseErrorCode.RefundFailed]: "ไม่สามารถคืนเงินได้",
    [ResponseErrorCode.PaymentWalletInsufficientFunds]: "เราขาดเงินทุนลูกค้า",
    [ResponseErrorCode.InvalidMetadataFieldValue]: "ค่าฟิลด์ข้อมูลเมตาไม่ถูกต้องหรือหายไป",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingId]: "ไม่อนุญาตให้ทำธุรกรรมข้ามพรมแดนไม่มีบัตรประจำตัวประชาชน",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber]: "ไม่อนุญาตให้ทำธุรกรรมข้ามพรมแดน ฉันไม่มีหมายเลขโทรศัพท์",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod]: "ไม่อนุญาตให้ทำธุรกรรมข้ามพรมแดน วิธีการชำระเงินที่ยอมรับไม่ได้",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingName]: "ไม่อนุญาตให้ทำธุรกรรมข้ามพรมแดนไม่มีชื่อ",
    [ResponseErrorCode.LimitExceededForPaymentType]: "นอกเหนือจากการ จำกัด การทำธุรกรรมของวิธีการชำระเงิน",
    [ResponseErrorCode.LimitExceededForMerchant]: "ฉันเกินข้อ จำกัด บัญชี",
    [ResponseErrorCode.TransactionNotFound]: "ฉันหาธุรกรรมไม่ได้",
    [ResponseErrorCode.DuplicateTransaction]: "การซื้อขายซ้ำกัน",
    [ResponseErrorCode.PaymentWalletRejected]: "กระเป๋าเงินชำระเงินถูกปฏิเสธโดยระบบเชื่อมต่อ",
    [ResponseErrorCode.InsufficientMerchantInformation]: "บัญชีไม่เพียงพอในบัญชี",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency]: "ไม่อนุญาตให้ทำธุรกรรมข้ามพรมแดน สกุลเงินที่ยอมรับไม่ได้",
    [ResponseErrorCode.GatewayServerError]: "ข้อผิดพลาดของระบบปลายทางการเชื่อมต่อ (โปรดติดต่อศูนย์สนับสนุน 0570-035-672)",
    [ResponseErrorCode.PaymentMethodTemporarilyUnavailable]: "การชำระเงินที่เลือกไม่สามารถใช้ชั่วคราวขึ้นอยู่กับระบบปลายทางการเชื่อมต่อ",
    [ResponseErrorCode.PaymentCanceled]: "การชำระเงินถูกยกเลิก",
    [ResponseErrorCode.ExceededPendingThreshold]: "ต้องใช้เวลาในการเริ่มต้นการประมวลผลการชำระเงิน",
    [ResponseErrorCode.InternalError]: "ข้อผิดพลาดของระบบภายใน",

    /* online */
    [ResponseErrorCode.AlipayOnlineInvalidSignatureType]: "ประเภทสัญญาณ Alipayonline ที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.PayPayOnlineInvalidPayMethod]: "วิธีการชำระเงิน PayPayOnline ที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.PayPayOnlineInvalidMerchantId]: "PaypayOnline Perchantid ที่ไม่ได้รับอนุญาต",

    [ResponseErrorCode.UnableToGenerateCertificateRequest]: "ฉันไม่สามารถขอใบรับรองได้",
    [ResponseErrorCode.InvalidMerchantSettings]: "การกำหนดค่าของผู้ค้าไม่ถูกต้อง",
    [ResponseErrorCode.UnsupportedAlgorithm]: "มันไม่ใช่อัลกอริทึมที่รองรับ",
    [ResponseErrorCode.InvalidPaymentToken]: "โทเค็นการชำระเงินเป็นการฉ้อโกง",
    [ResponseErrorCode.ExpiredPaymentToken]: "โทเค็นการชำระเงินหมดอายุแล้ว",
    [ResponseErrorCode.InvalidCertificate]: "มันเป็นใบรับรองที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidLeafCertificate]: "มันเป็นใบรับรองแนวปะการังที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidIntermediateCertificate]: "มันเป็นใบรับรองชั่วคราวที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidChainOfTrust]: "มันเป็นห่วงโซ่ความไว้วางใจที่ผิดกฎหมาย",
    [ResponseErrorCode.InvalidSignature]: "มันเป็นลายเซ็นที่ผิดกฎหมาย",

    /** platform */
    [ResponseErrorCode.PlatformKeyExists]: "มีคีย์แพลตฟอร์ม",
    [ResponseErrorCode.MerchantConsoleURIExists]: "มี URI ของคอนโซลพ่อค้า",

    /* idempotency */
    [ResponseErrorCode.IdempotencyKeyConflict]: "ในช่วงเวลาของการร้องขอที่รับประกัน冪คีย์冪ที่ระบุจะใช้ใน API และวิธีการที่แตกต่างกัน",

    /* bannedCards */
    [ResponseErrorCode.CardBanned]: "การ์ดต้องห้าม",
    [ResponseErrorCode.CardAlreadyBanned]: "การ์ดห้ามแล้ว",
    [ResponseErrorCode.CardNotBanned]: "การ์ดไม่ห้าม",
    [ResponseErrorCode.TestCardCannotBeBanned]: "ไม่สามารถห้ามการ์ดทดสอบได้",

    /* Stats */
    [ResponseErrorCode.InvalidMetric]: "หน่วยที่ไม่ได้รับอนุญาต",
    [ResponseErrorCode.InvalidResource]: "ทรัพยากรที่ไม่ได้รับอนุญาต",

    /* installments */
    [ResponseErrorCode.UseStartOn]: "ใช้วันเริ่มต้นการเรียกเก็บเงินปกติ",
    [ResponseErrorCode.PaymentInTimePeriod]: "การชำระเงินภายในกำหนดเวลา",
    [ResponseErrorCode.SecondChargeAlreadyMade]: "การเรียกเก็บเงินครั้งที่สองกำลังดำเนินการ",
    [ResponseErrorCode.NotSupportedByProcessor]: "ไม่ได้รับการสนับสนุนจากเกตเวย์",
    [ResponseErrorCode.SubscriptionAlreadyCanceled]: "การเรียกเก็บเงินปกติถูกยกเลิก",
    [ResponseErrorCode.SubscriptionNotFound]: "ฉันไม่พบการเรียกเก็บเงินปกติ",
    [ResponseErrorCode.MustBeLowerThanFullAmount]: "มันจะต้องน้อยกว่าจำนวนเงินทั้งหมด",
    [ResponseErrorCode.InstallmentPlanNotFound]: "ไม่พบการชำระเงินแบบผ่อนชำระ",
    [ResponseErrorCode.InstallmentAlreadySet]: "ฉันไม่สามารถเปลี่ยนแปลงได้เพราะการเรียกเก็บเงินปกติเริ่มขึ้นแล้ว",
    [ResponseErrorCode.InstallmentInvalidPlan]: "นี่เป็นแผนการชำระเงินแบบผ่อนชำระที่ไม่ได้รับการสนับสนุน",
    [ResponseErrorCode.InstallmentInvalidPlanType]: "มันเป็นแผนการชำระเงินแบบผ่อนชำระที่ไม่สามารถรับรู้ได้",
    [ResponseErrorCode.InstallmentPaymentTypeNotAllowedForPlan]: "นี่เป็นวิธีการชำระเงินที่ยอมรับไม่ได้เป็นวิธีการชำระเงินสำหรับการชำระเงินแบบผ่อนชำระ",
    [ResponseErrorCode.CardProcessorDisabledForInstallmentPlan]: "มันเป็น บริษัท บัตรที่ไม่สนับสนุนการชำระเงินแบบผ่อนชำระ",
    [ResponseErrorCode.CardProcessorInstallmentFutureDated]: "เป็น บริษัท บัตรที่ไม่สามารถตั้งค่าวันเริ่มต้นของการชำระเงินได้",
    [ResponseErrorCode.InstallmentInvalidInitialAmount]: "มันเป็นจำนวนเริ่มต้นของการชำระเงินงวดที่ฉ้อโกง",
    [ResponseErrorCode.InstallmentMaxPayoutPeriodExceeded]: "ระยะเวลาการชำระเงินของการชำระเงินงวดเกินระยะเวลาการชำระเงินสูงสุด",
    [ResponseErrorCode.InstallmentInsufficientAmountPerCharge]: "ปริมาณของแต่ละงวดนั้นเล็กเกินไป",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetInitialAmount]: "ด้วยการชำระเงินหมุนเวียนจำนวนเงินการชำระเงินครั้งแรกไม่สามารถตั้งค่าได้",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetSubsequentCyclesStart]: "ด้วยการชำระเงินหมุนเวียนวันเริ่มต้นของการเรียกเก็บเงินปกติไม่สามารถตั้งค่าได้",
    [ResponseErrorCode.InstallmentProcessorInitialAmountsNotSupported]: "เกตเวย์นี้ไม่รองรับข้อกำหนดจำนวนเงินเริ่มต้น",
    [ResponseErrorCode.InstallmentProcessorPeriodNotSupported]: "เกตเวย์ไม่รองรับระยะเวลาการผ่อนชำระ",
    [ResponseErrorCode.CannotChangeToken]: "โทเค็นการทำธุรกรรมสำหรับการเรียกเก็บเงินปกติในรัฐนี้ไม่สามารถเปลี่ยนแปลงได้",
    [ResponseErrorCode.SubscriptionNotEnabled]: "การเรียกเก็บเงินปกติไม่ถูกต้อง",
    [ResponseErrorCode.SubscriptionAlreadyEnded]: "การเรียกเก็บเงินปกติสิ้นสุดลงแล้ว",
    [ResponseErrorCode.SubscriptionUnsupportedForInstallment]: "การเรียกเก็บเงินปกติไม่รองรับการผ่อนชำระ",
    [ResponseErrorCode.InstallmentsNotEnabled]: "การชำระเงินการติดตั้งไม่ถูกต้อง",
    [ResponseErrorCode.CannotChangeInstallmentAmount]: "ฉันไม่สามารถเปลี่ยนจำนวนที่แยกได้",
    [ResponseErrorCode.CannotChangeToInstallment]: "คุณไม่สามารถเปลี่ยนเป็นการชำระเงินภาค",
    [ResponseErrorCode.MustBeMonthBasedToSet]: "ความถี่ของการชาร์จจะต้องเป็นรายเดือน",
    [ResponseErrorCode.MustBeWithinTwoCycles]: "ฉันต้องอยู่ภายใน 2 ครั้ง",
    [ResponseErrorCode.Deprecated]: "มันเป็นพารามิเตอร์ที่ไม่แนะนำ",
    [ResponseErrorCode.CannotBeZero]: "ฉันไม่สามารถตั้งค่า 0",
    [ResponseErrorCode.NeedAtLeastTwoCycles]: "ฉันต้องการอย่างน้อยสองครั้ง",
    [ResponseErrorCode.InstallmentInvalidCyclesCount]: "จำนวนหน่วยงานที่ไม่สามารถใช้งานได้",

    [ResponseErrorCode.CannotSetInitialAmountToZero]: "จำนวนเงินเริ่มต้นไม่สามารถตั้งค่าเป็น 0",
    [ResponseErrorCode.CannotSetUntilSubscriptionStarted]: "ไม่สามารถตั้งค่าได้จนกว่าการเรียกเก็บเงินปกติจะเริ่ม",
    [ResponseErrorCode.CannotSetAfterSubscriptionStarted]: "หลังจากการเรียกเก็บเงินปกติไม่สามารถตั้งค่าได้",
    [ResponseErrorCode.CannotChangeCanceledSubscription]: "การยกเลิกใบเรียกเก็บเงินปกติไม่สามารถเปลี่ยนแปลงได้",

    /* Stratus merchant & store configuration */
    [ResponseErrorCode.StratusMerchantAlreadyExists]: "ร้านค้าสมาชิก CAFIS ได้ถูกนำมาใช้แล้ว",
    [ResponseErrorCode.StratusStoreAlreadyExists]: "ร้าน Cafis ใช้ไปแล้ว",
    [ResponseErrorCode.StratusStoreAllocationLimitReached]: "ร้านกาแฟถึงขีด จำกัด สูงสุดของหมายเลขที่กำหนด",

    /* path bindables */
    [ResponseErrorCode.InvalidElasticIndex]: "ดัชนียืดหยุ่นไม่ถูกต้อง",
    [ResponseErrorCode.InvalidDynamoTable]: "ตารางไดนาโมไม่ถูกต้อง",
    [ResponseErrorCode.InvalidDateHistogramInterval]: "ช่วงเวลาฮิสโตแกรมวันที่ไม่ถูกต้อง",
    [ResponseErrorCode.InvalidSqsEndpointKey]: "จุดสิ้นสุดของ SQS ไม่ถูกต้อง",

    /* invalid card errors */
    [ResponseErrorCode.BinNotFound]: "ฉันหาหมายเลขถังไม่พบ",
    [ResponseErrorCode.LuhnCheckFailed]: "Luhn Check ล้มเหลว",
    [ResponseErrorCode.InvalidCardNumberLength]: "จำนวนตัวเลขของหมายเลขบัตรไม่เหมาะสม",
    [ResponseErrorCode.CardPaymentDisabled]: "ไม่มีการ์ด",
    [ResponseErrorCode.DebitDisabled]: "บัตรเดบิตไม่พร้อมใช้งาน",
    [ResponseErrorCode.PrepaidDisabled]: "ไม่สามารถใช้การ์ดล่วงหน้าได้",
    [ResponseErrorCode.CountryNotSupported]: "มันเป็นการ์ดจากประเทศที่ไม่ได้รับการสนับสนุน",
    [ResponseErrorCode.Unspecified]: "การ์ดที่ไม่ได้รับอนุญาต",
};

export default LABELS;
