import React from "react";
import { FormGroup } from "react-bootstrap";
import { Field, GenericField, WrappedFieldProps } from "redux-form";
import { BaseFieldProps } from "redux-form/lib/Field";

export interface CustomProps {
    size?: number;
    onComplete?: (authCode: string) => any;
    disabled?: boolean;
    error?: any;
}

export type AuthCodeInputProps = WrappedFieldProps & CustomProps;

const AuthCodeInput: React.StatelessComponent<AuthCodeInputProps> = ({
    size = 4,
    input: { value, onChange },
    onComplete,
    disabled,
    error,
}) => {
    const values = value.split("");
    const inputRefs = [];

    const getElement = (index: number): any => inputRefs[index];

    function handleChange(index: number) {
        return function (event) {
            const input = event.target.value;

            if (values.length >= size) {
                return;
            }

            if (!/^[0-9]$/.test(input)) {
                getElement(index).value = "";

                return;
            }

            values[index] = input;
            const newValue = values.join("").substr(0, size);

            onChange(newValue);
            updateFocus();

            if (newValue.length >= size && onComplete) {
                onComplete(newValue);
            }
        };
    }

    function handleKeyPress(event) {
        const BACKSPACE_KEY_CODE = 8;
        const keyCode = event.which || event.keyCode;

        if (keyCode === BACKSPACE_KEY_CODE) {
            for (let i = 0; i < size; i++) {
                getElement(i).value = "";
            }

            values.splice(0);
            onChange("");
            updateFocus();
        }
    }

    function updateFocus() {
        const i = values.length;

        getElement(i < size ? i : size - 1).focus();
    }

    const inputs = [];
    for (let i = 0; i < size; i++) {
        inputs.push(
            <input
                className="form-control auth-code-input"
                ref={(elem) => (inputRefs[i] = elem)}
                value={values[i]}
                onFocus={updateFocus}
                onChange={!disabled && handleChange(i)}
                onKeyDown={!disabled && handleKeyPress}
                inputMode="numeric"
                pattern="\d*"
            />
        );
    }

    return (
        <FormGroup className="auth-code" validationState={!disabled && error ? "error" : null} bsSize="small">
            {inputs}
        </FormGroup>
    );
};

export type AuthCodeFieldProps = BaseFieldProps & CustomProps;

export const AuthCodeField = (props: AuthCodeFieldProps) => {
    const F = Field as new () => GenericField<CustomProps>;

    return <F component={AuthCodeInput} {...props} />;
};
