import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { PaymentType } from "univapay-node";

import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { DialogButton } from "../../layout/DialogButton";

const stateSelector = (state: StateShape) => {
    const {
        paidy: { widget },
        checkout: { paymentMethodKey },
    } = state;

    return {
        loading: !widget.readyToOpen || !widget.open,
        paymentMethodKey,
    };
};

export const ContentPaidy = () => {
    const {
        paidy: { loadCheckout },
    } = useDispatch<Dispatch>();
    const { replace: replaceUrl } = useHistory();
    const { formatMessage } = useIntl();

    useEffect(() => {
        loadCheckout();
    }, []);

    const { loading, paymentMethodKey } = useSelector(stateSelector);
    const handleClick = () => replaceUrl(getStepUrl(PaymentType.PAIDY, paymentMethodKey, StepName.INFO));

    return (
        <div className="content-form">
            <Alert bsStyle="info">
                <FormattedMessage id={LOCALE_LABELS.PAIDY_PROCESSING_INFO} />
            </Alert>

            <DialogButton disabled={loading} onClick={handleClick}>
                {formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_BACK })}
            </DialogButton>
        </div>
    );
};

export default ContentPaidy;
