import { IntlState } from "react-intl-redux";
import { FormCheckoutData } from "checkout/ts/components/checkout/FormCheckout";
import { FormDataCard } from "checkout/ts/components/flows/card";
import { CardData } from "checkout/ts/components/flows/card/FormData";
import { FormDataKonbini } from "checkout/ts/components/flows/konbini";
import { TokenError } from "checkout/ts/errors/TokenError";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { sdk } from "checkout/ts/SDK";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { UnivaMetadata } from "checkout/ts/utils/metadata";
import { POLLING_INTERVAL } from "common/constants";
import { ParametersError } from "common/errors/ParametersError";
import { CheckoutType } from "common/types";
import { needsCvv } from "common/validation/card";
import { omit } from "lodash";
import {
    CvvAuthorizedStatus,
    PaymentType,
    TimeoutError,
    TransactionTokenBankTransferCreateData,
    TransactionTokenCardData,
    TransactionTokenConvenienceData,
    TransactionTokenCreateParams,
    TransactionTokenItem,
    TransactionTokenOnlineData,
    TransactionTokenType,
} from "univapay-node";

import { ApplicationParams } from "../models/application";
import { PatchedCheckoutInfo } from "../models/configuration";
import { PatchProductItem } from "../models/product";
import { UserDataStateShape } from "../models/user-data";
import { rateLimit } from "../rate-limiter";
import { StateShape } from "../store";

import { concatPhoneNumber, getIntlMessage, parsePhone } from "./intl";

type PatchedTransactionTokenConvenienceData = TransactionTokenConvenienceData & {
    expirationTimeShift?: string;
    expirationPeriod?: string;
};

type PatchedTransactionTokenBankTransferData = TransactionTokenBankTransferCreateData & {
    phoneNumber?: string;
    expirationTimeShift?: string;
    expirationPeriod?: string;
};

export const needsCvvAuth = (state: StateShape) => {
    const checkoutParams = getCheckoutParams(
        state.application.params,
        state.product.products,
        state.configuration.data
    );
    const isCvvShown = needsCvv(state);

    if (!state.application.params.params.cvvAuthorize) {
        // cvv authorized parameter forced the authorization
        return false;
    }

    if (state.checkout.paymentType !== PaymentType.CARD || !isCvvShown) {
        // Only accepts card payment with CVV data
        return false;
    }

    return checkoutParams.checkoutType === CheckoutType.TOKEN || hasDeferredCharges(checkoutParams);
};

export const isChargeInstallments = (installmentCycles: "revolving" | number) =>
    installmentCycles === "revolving" || installmentCycles > 1;

export const hasDeferredCharges = (params: ReturnType<typeof getCheckoutParams>) =>
    params.isSubscription ? params.initialAmount === 0 : false;

const pollTokenAuthorization = async (
    intl: IntlState,
    storeId: string,
    id: string,
    maxRetry = 40,
    interval = POLLING_INTERVAL
) => {
    let retryCount = 0;
    let token: TransactionTokenItem;

    do {
        try {
            token = await sdk.transactionTokens.get(storeId, id);
        } catch (error) {
            console.warn("Could not get transaction token. Waiting for next poll", error);
        }

        // Wait for interval. Prevent flooding the API
        await new Promise((resolve) => setTimeout(resolve, interval));

        retryCount++;
    } while (retryCount < maxRetry && token.data.cvvAuthorize.status === CvvAuthorizedStatus.PENDING);

    if (token.data.cvvAuthorize.status === CvvAuthorizedStatus.PENDING) {
        throw new TimeoutError(maxRetry * interval);
    }

    if (token.data.cvvAuthorize?.status === CvvAuthorizedStatus.FAILED) {
        throw new TokenError(
            token.id,
            "chargeId" in token.data.cvvAuthorize ? token.data.cvvAuthorize.chargeId : null,
            token.storeId,
            intl.messages.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED
        );
    }

    return token;
};

const getTokenParams = (
    data: FormCheckoutData,
    applicationParams: ApplicationParams,
    paymentType: PaymentType,
    userData: UserDataStateShape,
    checkoutInfo: PatchedCheckoutInfo,
    products: PatchProductItem[],
    withCvvAuth: boolean,
    intl: IntlState
): TransactionTokenCreateParams => {
    const {
        tokenType: type,
        bankTransferExpirationPeriod,
        bankTransferExpirationTimeShift,
        convenienceStoreExpirationPeriod,
        convenienceStoreExpirationTimeShift,
        univapayCustomerId,
        currency,
        params: {
            usageLimit,
            metadata = {},
            metadataToken = {},
            confirmationRequired,
            phoneNumber: paramsPhoneNumber,
            univapayReferenceId,
            hideRecurringCheckbox,
        },
    } = getCheckoutParams(applicationParams, products, checkoutInfo);

    const saveCard =
        data.data?.accept ??
        (hideRecurringCheckbox ? !!univapayCustomerId || type === TransactionTokenType.RECURRING : false); // This parameter is only available to card data

    const tokenMetadata = {
        // Common information metadata
        [UnivaMetadata.PHONE_NUMBER]:
            concatPhoneNumber(userData.phoneNumber || parsePhone(paramsPhoneNumber)) || undefined,
        [UnivaMetadata.NAME]: userData.name || undefined,
        [UnivaMetadata.NAME_KANA]: userData.nameKana || undefined,
        [UnivaMetadata.ADDRESS_CITY]: userData.city || undefined,
        [UnivaMetadata.ADDRESS_COUNTRY]: userData.country || undefined,
        [UnivaMetadata.ADDRESS_ZIP]: userData.zip || undefined,
        [UnivaMetadata.ADDRESS_STATE]: userData.state || undefined,
        [UnivaMetadata.ADDRESS_LINE1]: userData.line1 || undefined,
        [UnivaMetadata.ADDRESS_LINE2]: userData.line2 || undefined,
        [UnivaMetadata.CUSTOMER_ID]: univapayCustomerId,
        [UnivaMetadata.LEGACY_CUSTOMER_ID]: univapayCustomerId,
        [UnivaMetadata.REFERENCE_ID]: univapayReferenceId,
        [UnivaMetadata.PRODUCT_NAMES]: products
            ?.map((product) => product.name)
            ?.join(getIntlMessage(LOCALE_LABELS.COMMON_COMMA, intl)),

        ...metadata,
        ...metadataToken,
        ...userData.customFields,
        ...(products || []).reduce((acc, product) => ({ ...acc, ...product.metadata }), {}),
    };

    const tokenType = (() => {
        if (withCvvAuth || saveCard) {
            // Saving a card requires a recurring token independently on the type
            return TransactionTokenType.RECURRING;
        }

        // For one time with installment switch to a subscription as several payments are needed
        const isOneTimeWithInstallment =
            isChargeInstallments(data.installmentCycles) && type === TransactionTokenType.ONE_TIME;
        return isOneTimeWithInstallment ? TransactionTokenType.SUBSCRIPTION : type;
    })();

    const commonParams = {
        type: tokenType,
        paymentType: data.paymentType || paymentType,
        email: userData.email || ("email" in data ? (data as FormDataCard | FormDataKonbini).email : undefined),
        metadata: tokenMetadata,
        useConfirmation: confirmationRequired,
    };

    switch (data.paymentType || paymentType) {
        case PaymentType.CARD:
        case PaymentType.APPLE_PAY: {
            const params = {
                ...commonParams,
                usageLimit,
                data: {
                    line1: userData.line1,
                    line2: userData.line2,
                    state: userData.state,
                    city: userData.city,
                    country: userData.country,
                    zip: userData.zip,
                    ...data.data,
                    phoneNumber: userData.phoneNumber,
                    cvvAuthorize: {
                        enabled: withCvvAuth,
                        currency,
                    },
                } as CardData,
            };

            return tokenType === TransactionTokenType.RECURRING ? params : omit(params, "usageLimit");
        }

        case PaymentType.QR_SCAN:
        case PaymentType.KONBINI:
            return {
                ...commonParams,
                data: {
                    ...data.data,
                    phoneNumber: userData.phoneNumber,
                    expirationPeriod: convenienceStoreExpirationPeriod,
                    expirationTimeShift: convenienceStoreExpirationTimeShift,
                } as PatchedTransactionTokenConvenienceData,
            };

        case PaymentType.PAIDY:
            return {
                ...commonParams,
                data: { ...data.data, phoneNumber: userData.phoneNumber } as TransactionTokenCardData,
            };

        case PaymentType.ONLINE:
            return {
                ...commonParams,
                data: { brand: null, ...data.data } as TransactionTokenOnlineData,
            };

        case PaymentType.BANK_TRANSFER:
            return {
                ...commonParams,
                data: ({
                    ...data.data,
                    phoneNumber: userData.phoneNumber,
                    expirationPeriod: bankTransferExpirationPeriod,
                    expirationTimeShift: bankTransferExpirationTimeShift,
                } as unknown) as PatchedTransactionTokenBankTransferData,
            };

        default:
            throw new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE);
    }
};

export const createTransactionToken = async (data: FormCheckoutData, state: StateShape) => {
    const cvvAuth = needsCvvAuth(state);

    const tokenParams = getTokenParams(
        data,
        state.application.params,
        state.checkout.paymentType,
        state.userData,
        state.configuration.data,
        state.product.products,
        cvvAuth,
        state.intl
    );

    // Create a new token when no prior token was selected
    const newToken = (await rateLimit(() => sdk.transactionTokens.create(tokenParams))) as TransactionTokenItem;
    if (!cvvAuth || newToken.data.cvvAuthorize?.status === CvvAuthorizedStatus.CURRENT) {
        return newToken;
    }

    // Authorize token when empty cvv is allowed
    return pollTokenAuthorization(state.intl, newToken.storeId, newToken.id);
};
