import { ReactNode } from "react";

export type TextInputAddons = {
    before?: ReactNode;
    after?: ReactNode;
    header?: ReactNode;
};

const isObjectAddons = (value: unknown): value is TextInputAddons =>
    value && typeof value === "object" && ("before" in value || "after" in value || "header" in value);

export const formatAddons = (addons: ReactNode | TextInputAddons) =>
    isObjectAddons(addons) ? addons : { before: addons, after: undefined, header: undefined };
