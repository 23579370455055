import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { PatchedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { StepName } from "checkout/ts/utils/StepName";
import { OnlineBrand, PaymentType } from "univapay-node";

import { Dispatch, StateShape } from "../../../redux/store";
import { SpinnerLoader } from "../../common/SpinnerLoader";
import FormAddress from "../common/FormAddress";
import FormCustomerInfo from "../common/FormCustomerInfo";
import { FormPending } from "../common/FormPending";

import FormConfirm from "./FormConfirm";
import OpenCheckout from "./OpenCheckout";
import Processing from "./Processing";

const stateSelector = (state: StateShape) => ({
    onlineBrands: state.configuration.paymentMethods.filter(({ type }) => type === PaymentType.ONLINE),
    brand: state.online.brand,
    mode: state.configuration.data.mode,
});

export const Content = () => {
    const { path } = useRouteMatch();
    const { onlineBrands, brand } = useSelector(stateSelector);

    const {
        online: { setBrand },
    } = useDispatch<Dispatch>();

    // In the case of Online and we have only 1 brand set it by default
    useEffect(() => {
        if (onlineBrands.length === 1 && !brand) {
            setBrand({ brand: onlineBrands?.[0].method as OnlineBrand | PatchedOnlineBrand });
        }
    }, []);

    if (!brand) {
        return <SpinnerLoader />;
    }

    return (
        <Switch>
            <Route path={path} exact component={FormCustomerInfo} />

            <Route path={`${path}/${StepName.INFO}`} component={FormCustomerInfo} />
            <Route path={`${path}/${StepName.ADDRESS}`} exact component={FormAddress} />
            <Route path={`${path}/${StepName.DATA}`} exact component={OpenCheckout} />
            <Route path={`${path}/${StepName.PROCESSING}`} exact component={Processing} />
            <Route path={`${path}/${StepName.CONFIRM}`} component={FormConfirm} />
            <Route path={`${path}/${StepName.PENDING}`} component={FormPending} />
        </Switch>
    );
};

export default Content;
