import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useRouteMatch } from "react-router";
import { StepName } from "checkout/ts/utils/StepName";
import { change, unregisterField } from "redux-form";
import { PaymentType, TransactionTokenConvenienceData } from "univapay-node";

import { FORM_CHECKOUT_NAME } from "../../../../../common/constants";
import { Dispatch, StateShape } from "../../../redux/store";
import { SwitchContent } from "../../router/SwitchContent";
import FormAddress from "../common/FormAddress";
import FormCustomerInfo from "../common/FormCustomerInfo";
import { FormPending } from "../common/FormPending";
import { showCustomerIdScreen } from "../common/FormTokenSelector";

import FormCodeConfirmation from "./FormCodeConfirmation";
import FormConfirm from "./FormConfirm";
import FormData, { FormDataKonbiniData } from "./FormData";
import FormKonbiniSelector from "./FormKonbiniSelector";
import FormTokenReview from "./FormTokenReview";

export type FormDataKonbini<Data = TransactionTokenConvenienceData> = FormDataKonbiniData<Data>;

const stateSelector = (state: StateShape) => ({
    showTokenSelectScreen: showCustomerIdScreen(state.tokens.tokens, PaymentType.KONBINI),
});

export const Content = () => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch<Dispatch>();
    const { showTokenSelectScreen } = useSelector(stateSelector);

    useEffect(() => {
        dispatch(unregisterField(FORM_CHECKOUT_NAME, "data.phoneNumber"));
        dispatch(change(FORM_CHECKOUT_NAME, "data.phoneNumber", undefined));
    }, []);

    return (
        <SwitchContent>
            <Route path={path} exact component={showTokenSelectScreen ? FormKonbiniSelector : FormCustomerInfo} />
            <Route path={`${path}/${StepName.INFO}`} component={FormCustomerInfo} />
            <Route path={`${path}/${StepName.DATA}`} component={FormData} />
            <Route path={`${path}/${StepName.ADDRESS}`} component={FormAddress} />
            <Route path={`${path}/${StepName.CONFIRM}`} component={FormConfirm} />
            <Route path={`${path}/${StepName.CODE}`} component={FormCodeConfirmation} />
            <Route path={`${path}/${StepName.PENDING}`} component={FormPending} />
            <Route path={`${path}/${StepName.TOKEN_REVIEW}`} component={FormTokenReview} />
            <Route path={`${path}/${StepName.TOKEN_SELECT}`} component={FormKonbiniSelector} />
        </SwitchContent>
    );
};

export default Content;
