import React from "react";
import Icon from "react-icon-base";

import UnivapayLogo from "./icon.svg";

export const DefaultImage = () => (
    <Icon size={56}>
        <UnivapayLogo />
    </Icon>
);
