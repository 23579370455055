import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardMastercard = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="mastercard-card-icon" viewBox="0 0 780 500" {...props}>
        <g>
            <rect width="780" height="500" fill="#000000" />
            <g>
                <rect x="316.9" y="119" width="145.8" height="262.1" fill="#ff5f00" />
                <path
                    d="M326.2,250a166.23,166.23,0,0,1,63.7-131,166.64,166.64,0,1,0,0,262A165.93,165.93,0,0,1,326.2,250Z"
                    fill="#eb001b"
                />
                <path
                    d="M659.5,250A166.73,166.73,0,0,1,389.8,381.1c72.4-56.9,84.9-161.7,28-234.1a168.57,168.57,0,0,0-28-28A166.71,166.71,0,0,1,659.5,250Z"
                    fill="#f79e1b"
                />
                <path
                    d="M643.6,353.3v-5.4h2.2v-1.1h-5.5v1.1h2.2v5.4Zm10.7,0v-6.5h-1.7l-1.9,4.4-1.9-4.4H647v6.5h1.2v-4.9l1.8,4.2h1.2l1.8-4.2v4.9Z"
                    fill="#f79e1b"
                />
            </g>
        </g>
    </Icon>
);

export default CardMastercard;
