import React, { ReactNode } from "react";
import { ControlLabel, FormControl, FormControlProps, FormGroup, InputGroup } from "react-bootstrap";
import classnames from "classnames";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";

import { generate } from "../../utils/random";

import { ErrorMessages } from "./components/ErrorMessages";
import { RequiredIndicator } from "./components/RequiredIndicator";
import { formatAddons, TextInputAddons } from "./utils/addons";

type CustomProps = {
    addons?: ReactNode | TextInputAddons;
    showErrors?: boolean;
    validationLabel: string;
    isDark?: boolean;
    isHorizontal?: boolean;

    labelStyle?: Record<string, string>;
    errorStyle?: Record<string, string>;
    invalidStyle?: Record<string, string>;
    focusStyle?: Record<string, string>;
    inputStyle?: Record<string, string>;

    showPlaceholder?: boolean;
    initialValue?: string;
};

export type TextProps = CustomProps & FormControlProps;

export const Text = ({
    addons,
    input,
    disabled,
    meta: { active, touched, invalid, submitting, error, initial },
    showErrors = true,
    label,
    required,
    labelStyle,
    errorStyle,
    invalidStyle,
    focusStyle,
    inputStyle,
    style,
    width,
    validationLabel = label,
    isDark,
    isHorizontal,
    ...props
}: TextProps & WrappedFieldProps) => {
    const { before, after, header: headerAddon } = formatAddons(addons);

    const isInvalid = (invalid || error) && touched;

    const currentFocusStyle = active ? focusStyle : {};
    const fieldStyle = isInvalid
        ? { width, ...inputStyle, ...invalidStyle, ...currentFocusStyle }
        : { width, ...inputStyle, ...currentFocusStyle };

    const isFullWidth = !fieldStyle.width;

    return (
        <FormGroup
            controlId={generate()}
            className={classnames({
                "keep-color-on-error": showErrors && isInvalid,
                "dark-theme": isDark,
                "row": isHorizontal,
            })}
            validationState={isInvalid ? "error" : null}
            bsSize="small"
            style={{ ...style }}>
            {label && (
                <div className={classnames({ "col-xs-3 col-sm-2 inline-horizontal-content": isHorizontal })}>
                    <ControlLabel style={{ ...labelStyle }} className={classnames({ "full-width": isFullWidth })}>
                        {label}

                        {headerAddon && <span className="header-addon">{headerAddon}</span>}

                        <RequiredIndicator required={required} />
                    </ControlLabel>
                </div>
            )}

            <div className={classnames({ "col-xs-9 col-sm-10 inline-horizontal-content": isHorizontal && label })}>
                <InputGroup className={active ? "input-group-focus" : ""} style={{ width: fieldStyle.width }}>
                    {before && <InputGroup.Addon>{before}</InputGroup.Addon>}

                    <FormControl
                        {...props}
                        {...input}
                        value={input.value ?? initial}
                        required={required}
                        disabled={submitting || disabled}
                        style={fieldStyle}
                    />

                    {after && <InputGroup.Addon>{after}</InputGroup.Addon>}
                </InputGroup>

                {showErrors && isInvalid && (
                    <ErrorMessages fieldLabel={validationLabel} errors={error} style={{ ...errorStyle }} />
                )}
            </div>
        </FormGroup>
    );
};

type FieldProps = BaseFieldProps<TextProps>;
type TextFieldProps = Partial<Omit<Omit<TextProps, "ref">, keyof FieldProps>> & FieldProps;

export const TextField = (props: TextFieldProps) => <Field component={Text} {...props} />;
