import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { LOCALE_LABELS } from "../../../locale/labels";
import { DialogButton } from "../DialogButton";
import { DialogContent } from "../DialogContent";

import { ErrorAlert } from "./ErrorAlert";

export const CheckoutError = () => {
    const dispatch = useDispatch();

    const handleClose = () => dispatch.application.close();

    return (
        <div className="dialog-wrapper dialog-error">
            <DialogContent>
                <ErrorAlert />

                <DialogButton onClick={handleClose}>
                    <FormattedMessage id={LOCALE_LABELS.COMMON_BUTTONS_CLOSE} />
                </DialogButton>
            </DialogContent>
        </div>
    );
};
