import React, { useEffect, useState } from "react";
import ObserveSize from "react-observe-size";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { Connector } from "checkout/ts/Connector";
import { Dispatch, StateShape } from "checkout/ts/redux/store";
import { getErrorUrl } from "checkout/ts/utils/StepName";
import { PaymentType } from "univapay-node";

import InlineFormData from "../flows/card/InlineFormData";

import { ErrorAlert } from "./CheckoutError/ErrorAlert";

const stateSelector = (state: StateShape) => ({
    paymentMethods: state.configuration.paymentMethods,
});

export const InlineForm = () => {
    const [memorizedHeight, setMemorizedHeight] = useState(0);
    const { paymentMethods } = useSelector(stateSelector);

    const {
        checkout: { setPaymentMethod },
    } = useDispatch<Dispatch>();

    useEffect(() => {
        if (paymentMethods?.length) {
            const method = paymentMethods.find(({ type }) => type === PaymentType.CARD);

            setPaymentMethod({ type: PaymentType.CARD, key: method?.key });
        }
    }, [paymentMethods]);

    return (
        <ObserveSize>
            {({ height }) => {
                if (height !== memorizedHeight) {
                    setMemorizedHeight(height);
                    Connector.resizeFrame(height);
                }

                return (
                    <Switch>
                        <Route path={getErrorUrl()} component={ErrorAlert} />

                        <Route component={InlineFormData} />
                    </Switch>
                );
            }}
        </ObserveSize>
    );
};
