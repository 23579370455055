import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { UserData } from "checkout/ts/redux/models/user-data";
import { isDarkTheme } from "checkout/ts/utils/colors";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { isEmpty } from "lodash";
import { compose } from "recompose";
import { Form, InjectedFormProps, reduxForm } from "redux-form";
import { PaymentType } from "univapay-node";

import { FORM_ADDRESS } from "../../../../../../common/constants";
import { Countries } from "../../../../../../common/locale/constants";
import { LOCALE_LABELS } from "../../../../locale/labels";
import { Dispatch, StateShape } from "../../../../redux/store";
import { validationToErrors } from "../../../../utils/errors";
import { StepTitle } from "../../../common/StepTitle";
import { DialogButton } from "../../../layout/DialogButton";
import { PrivacyLink } from "../FormData";
import { getRequiredFields } from "../utils/customerInfo";

import { BillingAddressEn } from "./BillingAddressEn";
import { BillingAddressJa } from "./BillingAddressJa";

type OwnProps = {
    title?: string;
    onSubmit?: (values: Partial<UserData>) => void | Promise<void>;
    nextButtonLabel: React.ReactNode;
    showPrivacyLink?: boolean;
};

type ComposedProps = ReturnType<typeof stateSelector> & InjectedFormProps<Partial<UserData>> & OwnProps;

const stateSelector = (state: StateShape) => {
    const { checkout, application, configuration } = state;
    const {
        shippingAddressLine1,
        shippingAddressLine2,
        shippingAddressCity,
        shippingAddressState,
        shippingAddressZip,
        shippingAddressCountryCode,
        submitButtonText,
    } = application.params.params;

    const { requireBillingAddress } = getRequiredFields(
        checkout.paymentType,
        application.params.params,
        configuration?.data
    );

    const countryCode = checkout.paymentType === PaymentType.PAIDY ? "JP" : shippingAddressCountryCode?.toUpperCase();

    return {
        paymentType: checkout.paymentType,
        submitButtonText,
        requireBillingAddress,
        processing: ((state.loading.models.userData || state.loading.models.checkout) as unknown) as boolean,
        initialValues: {
            data: {
                line1: shippingAddressLine1,
                line2: shippingAddressLine2,
                city: shippingAddressCity,
                state: shippingAddressState,
                zip: shippingAddressZip,
                country: Countries[countryCode] ? countryCode : undefined,
            },
        },
        darkTheme: isDarkTheme(state),
        paymentMethodKey: state.checkout.paymentMethodKey,
    };
};

export const validate = (values: Partial<UserData>, { requireBillingAddress }: ComposedProps) => {
    const { data: { line1, city, state, zip, country } = {} } = values;

    return validationToErrors({
        "data.line1": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireBillingAddress && isEmpty(line1),
        },
        "data.city": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireBillingAddress && isEmpty(city),
        },
        "data.state": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireBillingAddress && isEmpty(state),
        },
        "data.zip": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireBillingAddress && isEmpty(zip),
            [LOCALE_LABELS.VALIDATION_ZIP]: zip && !/^[-0-9]{7}/.test(zip),
        },
        "data.country": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !country,
        },
    });
};

export const Content = compose<ComposedProps, OwnProps>(
    connect(stateSelector),
    reduxForm({ destroyOnUnmount: false, forceUnregisterOnUnmount: true, form: FORM_ADDRESS, validate })
)(
    ({
        paymentType,
        processing,
        requireBillingAddress,
        submitButtonText,
        handleSubmit,
        title,
        onSubmit,
        darkTheme,
        nextButtonLabel,
        showPrivacyLink,
        paymentMethodKey,
    }) => {
        const { push: redirect } = useHistory();
        const { locale, formatMessage } = useIntl();

        const {
            userData: { setUserData },
        } = useDispatch<Dispatch>();

        const isPaidy = paymentType === PaymentType.PAIDY;

        const handleFormSubmit = async (values: Partial<UserData>) => {
            await setUserData(values);

            const submitCallback =
                onSubmit ||
                (() =>
                    redirect(getStepUrl(paymentType, paymentMethodKey, isPaidy ? StepName.PROCESSING : StepName.DATA)));
            await submitCallback(values);
        };

        const AddressComponent = (() => {
            switch (locale.substring(0, 2).toLowerCase()) {
                case "ja":
                    return BillingAddressJa;

                default:
                    return BillingAddressEn;
            }
        })();

        return (
            <Form
                data-name="form-address"
                noValidate
                onSubmit={handleSubmit(handleFormSubmit)}
                className="address content-form">
                <Row>
                    <Col xs={12}>
                        <StepTitle>{title || formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_TITLE })}</StepTitle>
                    </Col>

                    <Col xs={12}>
                        {requireBillingAddress && (
                            <AddressComponent
                                staticCountry={paymentType === PaymentType.PAIDY ? "JP" : undefined}
                                isDark={darkTheme}
                            />
                        )}
                    </Col>
                </Row>

                {showPrivacyLink && <PrivacyLink isDark={darkTheme} />}

                <DialogButton disabled={processing} tabIndex={10}>
                    {nextButtonLabel || submitButtonText || isPaidy
                        ? formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_SUBMIT })
                        : formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_NEXT })}
                </DialogButton>
            </Form>
        );
    }
);

export default Content;
