import React from "react";
import { HTMLAttributes } from "react";
import { useSelector } from "react-redux";
import reactCSS from "reactcss";
import classnames from "classnames";
import { isNil } from "lodash";

import { StateShape } from "../../redux/store";
import { getContentColors } from "../../utils/colors";

type DialogContentProps = HTMLAttributes<HTMLDivElement>;

const stateSelector = (state: StateShape) => ({
    configurationData: state.configuration.data,
    applicationParamsTheme: isNil(state.application.params.params.dark)
        ? null
        : state.application.params.params.dark
        ? "dark"
        : "light",
});

export const DialogContent = ({ className, ...props }: DialogContentProps) => {
    const { configurationData, applicationParamsTheme } = useSelector(stateSelector);

    const { textColor, backgroundColor } = getContentColors(configurationData, applicationParamsTheme);

    const styles = reactCSS({
        "default": {
            content: {
                backgroundColor,
                color: textColor,
            },
        },
    });

    return <div {...props} className={classnames("dialog-content", className)} style={styles.content} />;
};
