import React from "react";
import { useIntl } from "react-intl";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { parse as parsePeriod } from "iso8601-duration";

export type FormattedDurationProps = {
    period: string;
};

export const FormattedPeriod = ({ period }) => {
    const { formatMessage } = useIntl();

    const formattedPeriod = (() => {
        try {
            return parsePeriod(period) ?? {};
        } catch (e) {
            console.warn(`Could not parse ${period} as ISO8601 period`);
            return null;
        }
    })();

    const { years, months, weeks, days } = formattedPeriod ?? {};

    return (
        <>
            {[
                { id: LOCALE_LABELS.DURATION_YEARS, value: years },
                { id: LOCALE_LABELS.DURATION_MONTHS, value: months },
                { id: LOCALE_LABELS.DURATION_WEEKS, value: weeks },
                { id: LOCALE_LABELS.DURATION_DAYS, value: days },
            ]
                .filter(({ value }) => !!value && !isNaN(value))
                .map(({ id, value }) => formatMessage({ id }, { value }))
                .join(formatMessage({ id: LOCALE_LABELS.DURATION_SPACER }))}
        </>
    );
};
