import React from "react";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { PaymentType } from "univapay-node";

import { Content as SelectorContent } from "../common/FormTokenSelector";

const getNextStepUri = (selectedTokenId: string, paymentMethodKey: string) =>
    selectedTokenId
        ? getStepUrl(PaymentType.PAIDY, paymentMethodKey, StepName.PREVIEW)
        : getStepUrl(PaymentType.PAIDY, paymentMethodKey, StepName.INFO);

export const Content = (props) => (
    <SelectorContent {...props} paymentType={PaymentType.PAIDY} nextStepUri={getNextStepUri} />
);

export default Content;
