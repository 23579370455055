import React from "react";
import { useIntl } from "react-intl";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import classnames from "classnames";

type OwnProps = {
    required: boolean;
    showRequired?: boolean;
    showOptional?: boolean;
};

export const RequiredIndicator = ({ required, showRequired = false, showOptional = true }: OwnProps) => {
    const { formatMessage } = useIntl();

    if ((required && !showRequired) || (!required && !showOptional)) {
        return null;
    }

    return (
        <span className={classnames("required-indicator", { "required": required, "optional": !required })}>
            {required
                ? formatMessage({ id: LOCALE_LABELS.COMMON_REQUIRED })
                : formatMessage({ id: LOCALE_LABELS.COMMON_OPTIONAL })}
        </span>
    );
};
