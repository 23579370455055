import React from "react";
import {
    Checkbox as BootstrapCheckbox,
    CheckboxProps as BootstrapCheckboxProps,
    ControlLabel,
    FormGroup,
} from "react-bootstrap";
import { useIntl } from "react-intl";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import classnames from "classnames";
import { Field, GenericField, WrappedFieldProps } from "redux-form";

import { generate } from "../../utils/random";

export type CheckboxProps = BootstrapCheckboxProps & {
    showErrors?: boolean;
    validationLabel?: string;

    labelStyle?: Record<string, string>;
    errorStyle?: Record<string, string>;
    invalidStyle?: Record<string, string>;
    focusStyle?: Record<string, string>;
    inputStyle?: Record<string, string>;
    style?: Record<string, string>;

    isDark?: boolean;
};

export const CheckboxComponent = ({
    input,
    meta: { active, touched, invalid, error },
    label,
    showErrors = true,
    width,
    labelStyle,
    errorStyle,
    invalidStyle,
    focusStyle,
    inputStyle,
    isDark,
    style,
    ...props
}: CheckboxProps & WrappedFieldProps) => {
    const { formatMessage } = useIntl();

    const isInvalid = (invalid || error) && touched;

    const currentFocusStyle = active ? focusStyle : {};
    const fieldStyle = isInvalid
        ? { width, ...inputStyle, ...invalidStyle, ...currentFocusStyle }
        : { width, ...inputStyle, ...currentFocusStyle };

    return (
        <FormGroup
            controlId={generate()}
            className={classnames({ "keep-color-on-error": showErrors, "dark-theme": isDark })}
            validationState={invalid && touched ? "error" : null}
            bsSize="small"
            style={{ ...style }}>
            {label && <ControlLabel>{label}</ControlLabel>}

            <span>
                <BootstrapCheckbox {...props} {...input} checked={input.value} style={fieldStyle}>
                    <span style={{ ...labelStyle }}>{props.children}</span>
                </BootstrapCheckbox>
            </span>

            {showErrors && isInvalid && (
                // The only possible error for checkout is checked or unchecked
                <div className="field-error" style={errorStyle}>
                    <div>{formatMessage({ id: LOCALE_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED })}</div>
                </div>
            )}
        </FormGroup>
    );
};

type CheckboxFieldProps = any & CheckboxProps;

export const CheckboxField = (props: CheckboxFieldProps) => {
    const F = Field as new () => GenericField<CheckboxProps>;

    return <F component={CheckboxComponent} {...props} />;
};
