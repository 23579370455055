import React from "react";

export const WeChatOnlineIcon = () => (
    <svg width="20" height="18" viewBox="0 0 980 980" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-10 -10)">
            <rect width="980" height="980" fill="#1aad19" rx="120" transform="translate(10 10)" />
            <path
                fill="#fff"
                d="M414.5 585.8c-43.1 24-49.5-13.5-49.5-13.5l-54-125.5c-20.8-59.7 18-27 18-27s33.2 25.1 58.4 40.4 54 4.5 54 4.5l353.1-162.4c-65-80.6-172.7-133.5-294.5-133.5-198.8 0-360 140.5-360 313.8 0 99.7 53.3 188.5 136.5 246l-15 85.8s-7.3 25 18 13.5c17.3-8 61.3-36.3 87.4-53.7A404.4 404.4 0 0 0 500 796.5c198.8 0 360-140.5 360-313.8 0-50.2-13.6-97.6-37.6-139.7-112.6 67.4-374.2 224-408 242.8z"
            />
        </g>
    </svg>
);
