import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DialogButton } from "checkout/ts/components/layout/DialogButton";
import { getProcessState, ProcessLoader } from "checkout/ts/components/ProcessLoader";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { getPaymentMethodLabel } from "checkout/ts/locale";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { Dispatch, StateShape } from "checkout/ts/redux/store";
import { PaymentType } from "univapay-node";

import { ConfirmText } from "./ConfirmText";

export const stateSelector = (state: StateShape) => ({
    processed: state.checkout.processed,
    error: state.checkout.error,
    transaction: state.checkout.data ?? state.checkout.charge,
    application: state.application,
    brand: state.online.brand,
    checkout: state.checkout,
    processing: (state.loading.models.checkout as unknown) as boolean,
});

type OwnProps = { paymentType?: PaymentType };

export const Content = ({ paymentType = PaymentType.CARD }: OwnProps) => {
    const { formatMessage } = useIntl();
    const {
        application: { close: closeApplication },
    } = useDispatch<Dispatch>();

    const { brand, processing, processed, error, transaction } = useSelector(stateSelector);
    const { checkoutType } = useCheckoutParams();
    const checkoutState = getProcessState(!processing, error, transaction, checkoutType);

    useEffect(() => {
        if (!processed && !processing) {
            console.warn("Transaction was created but not marked as processed", transaction?.id);
        }
    }, [processed, processing]);

    const handleClick = () => closeApplication();

    return (
        <>
            <Row className="confirm content-form">
                <Col xs={12} className="text-center">
                    <ProcessLoader state={checkoutState} />

                    <div className="message">
                        <ConfirmText paymentType={paymentType} />

                        {!processing && (
                            <>
                                <br />

                                {formatMessage(
                                    { id: LOCALE_LABELS.FORM_USED_PAYMENT_METHOD },
                                    { method: formatMessage({ id: getPaymentMethodLabel(paymentType, brand) }) }
                                )}
                            </>
                        )}
                    </div>
                </Col>
            </Row>

            <DialogButton disabled={processing} onClick={handleClick}>
                {formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_FINISH })}
            </DialogButton>
        </>
    );
};
