import React from "react";

export const DBaraiOnlineIcon = () => (
    <svg width="20" height="18" viewBox="0 0 81.495 81.5" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.25 0 0 -1.25 -489.09 559.52)">
            <g transform="translate(456.47 396.64)">
                <path
                    d="m0 0c0-7.818-6.398-14.217-14.211-14.217h-36.772c-7.82 0-14.213 6.399-14.213 14.217v36.771c0 7.817 6.393 14.212 14.213 14.212h36.772c7.813 0 14.211-6.395 14.211-14.212z"
                    fill="#c03"
                />
            </g>
            <g transform="translate(401.82 419.31)">
                <path
                    d="m0 0c0 1.741 1.182 3.455 3.373 3.455 2.199 0 3.344-1.64 3.385-3.366v-0.179c-0.041-1.728-1.186-3.367-3.385-3.367-2.191 0-3.373 1.715-3.373 3.457m6.709 13.295v-7.024c-0.949 0.551-2.092 0.881-3.416 0.881-4.725 0-7.234-3.679-7.234-7.156 0-3.469 2.509-7.15 7.234-7.15 3.553 0 4.881 1.715 7.352 2.849v17.6h-3.936"
                    fill="#fff"
                />
            </g>
            <g transform="translate(430.54 412.7)">
                <path
                    d="m0 0c-0.197 0.754-0.395 1.455-0.594 2.104-1.904-0.649-4.17-1.151-8.014-1.727l-0.486 2.301c0.414 0.037 0.934 0.127 1.223 0.162 1.346 4.081 2.443 8.484 3.252 13.158l2.373-0.395c-0.846-4.405-1.869-8.377-3.039-12.349 1.726 0.305 2.967 0.629 3.99 0.988-0.412 1.24-0.916 2.391-1.455 3.667l1.906 1.007c1.096-2.48 1.959-4.477 2.965-7.82zm-13.407 2.391h0.198c1.814 0 1.851 0.017 1.851 0.953v2.408c-0.684-0.197-1.492-0.397-2.266-0.576l-0.484 2.212c0.971 0.179 1.922 0.431 2.75 0.665v3.128h-2.301v2.157h2.301v2.767h2.139v-2.767h1.992v-2.157h-1.992v-2.41c0.969 0.361 1.67 0.684 1.992 0.828v-2.175c-0.339-0.18-1.039-0.522-1.992-0.899v-3.326c0-2.463-0.127-3.002-3.326-3.092z"
                    fill="#fff"
                />
            </g>
            <g transform="translate(446.69 414.94)">
                <path
                    d="m0 0c0 4.585-0.521 8.126-3.129 12.08h2.715c1.779-2.84 2.857-6.327 2.857-12.08zm-4.262-0.09c-0.646-0.484-2.246-1.492-3.883-1.492-2.353 0-4.169 1.906-4.169 7.227 0 2.606 0.396 5.177 0.701 6.848h2.445c-0.486-2.39-0.773-4.745-0.773-7.118 0-2.822 0.718-4.547 2.103-4.547 1.258 0 2.625 1.096 3.576 1.887z"
                    fill="#fff"
                />
            </g>
            <g transform="translate(413.63 400.03)">
                <path
                    d="m0 0c0-2.486-2.02-4.51-4.512-4.51-2.48 0-4.502 2.024-4.502 4.51 0 2.488 2.022 4.51 4.502 4.51 2.492 0 4.512-2.022 4.512-4.51"
                    fill="#eee562"
                />
            </g>
            <g transform="translate(428.38 400.03)">
                <path
                    d="m0 0c0-2.486-2.02-4.51-4.508-4.51-2.491 0-4.508 2.024-4.508 4.51 0 2.488 2.017 4.51 4.508 4.51 2.488 0 4.508-2.022 4.508-4.51"
                    fill="#eee562"
                />
            </g>
            <g transform="translate(443.14 400.03)">
                <path
                    d="m0 0c0-2.486-2.023-4.51-4.51-4.51-2.49 0-4.511 2.024-4.511 4.51 0 2.488 2.021 4.51 4.511 4.51 2.487 0 4.51-2.022 4.51-4.51"
                    fill="#eee562"
                />
            </g>
        </g>
    </svg>
);
