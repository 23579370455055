import { parse, ParseOptions, stringify, StringifyOptions } from "query-string";

type ValueType = string | string[] | number | boolean;

export const ARRAY_PARSE: ParseOptions | StringifyOptions = { arrayFormat: "bracket" };

const isEmptyQuery = (value) =>
    value === undefined || value === null || (typeof value === "string" && value.trim() === "");

const stringifyValue = (value: ValueType) =>
    value === undefined || value === null || Array.isArray(value) ? value : `${value}`;

export const updateSearchParams = (search: string, keyValues: Partial<Record<string, ValueType>>) => {
    const queryData = parse(search, ARRAY_PARSE);
    const keys = Object.keys(keyValues) as string[];

    const filteredQuery = keys.reduce(
        (acc, key) => {
            const newValue = stringifyValue(keyValues[key]);
            const checkValue = Array.isArray(newValue) ? newValue.join(",") : newValue;

            if (!isEmptyQuery(checkValue)) {
                acc[key] = ["number", "boolean"].includes(typeof newValue)
                    ? `${newValue}`
                    : (newValue as string | string[]);
            }

            return acc;
        },
        { ...queryData }
    );

    return stringify(filteredQuery, ARRAY_PARSE);
};
