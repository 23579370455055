import React from "react";
import { useSelector } from "react-redux";
import { Route, useRouteMatch } from "react-router";
import { UserData } from "checkout/ts/redux/models/user-data";
import { StateShape } from "checkout/ts/redux/store";
import { StepName } from "checkout/ts/utils/StepName";
import { PaymentType } from "univapay-node/dist/cjs";

import { SwitchContent } from "../../router/SwitchContent";
import FormAddress from "../common/FormAddress";
import FormCustomerInfo from "../common/FormCustomerInfo";
import { FormPending } from "../common/FormPending";
import { showCustomerIdScreen } from "../common/FormTokenSelector";

import FormCardSelector from "./FormCardSelector";
import FormCodeConfirmation from "./FormCodeConfirmation";
import FormConfirm from "./FormConfirm";
import FormData, { FormDataCardData, FullCardData } from "./FormData";
import FormTokenReview from "./FormTokenReview";

export type FormDataCard<Data = FullCardData> = FormDataCardData<Data> & UserData;

const stateSelector = (state: StateShape) => ({
    showTokenSelectScreen: showCustomerIdScreen(state.tokens.tokens, PaymentType.CARD),
});

export const Content = () => {
    const { path } = useRouteMatch();
    const { showTokenSelectScreen } = useSelector(stateSelector);

    return (
        <SwitchContent>
            <Route path={path} exact component={showTokenSelectScreen ? FormCardSelector : FormCustomerInfo} />
            <Route path={`${path}/${StepName.INFO}`} component={FormCustomerInfo} />
            <Route path={`${path}/${StepName.ADDRESS}`} component={FormAddress} />
            <Route path={`${path}/${StepName.DATA}`} component={FormData} />
            <Route path={`${path}/${StepName.CODE}`} component={FormCodeConfirmation} />
            <Route path={`${path}/${StepName.CONFIRM}`} component={FormConfirm} />
            <Route path={`${path}/${StepName.PENDING}`} component={FormPending} />
            <Route path={`${path}/${StepName.TOKEN_REVIEW}`} component={FormTokenReview} />
            <Route path={`${path}/${StepName.TOKEN_SELECT}`} component={FormCardSelector} />
        </SwitchContent>
    );
};

export default Content;
