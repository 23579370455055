export const validateDomain = (domain: string, apiEndpointDomain: string, supportedDomains: string[] = []): boolean => {
    if (supportedDomains.includes("*")) {
        // Allow wildcard domain
        return true;
    }

    return supportedDomains
        .map((supportedDomain: string) =>
            supportedDomain.toLowerCase().replace(/^/, "^").replace(".", ".").replace("*", ".*").concat("$")
        )
        .concat([`.*${apiEndpointDomain}`])
        .some((domainStringRegexp: string) => new RegExp(domainStringRegexp).test(domain));
};
