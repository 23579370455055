import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import reactCSS from "reactcss";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { getThemeColors, isDarkTheme } from "checkout/ts/utils/colors";
import classnames from "classnames";
import { isNil } from "lodash";

import { StateShape } from "../../redux/store";
import { DefaultImage } from "../DefaultImage";

import { ChargeHeader } from "./ChargeHeader";
import { SubscriptionHeader } from "./SubscriptionHeader";

const IMAGE_SIZE = 64;

const stateSelector = (state: StateShape) => {
    const { application, configuration, product } = state;

    const { isSubscription } = getCheckoutParams(application.params, product.products, configuration.data);

    const applicationParamsTheme = isNil(application.params?.params?.dark)
        ? null
        : application.params?.params?.dark
        ? "dark"
        : "light";

    return {
        colors: getThemeColors(state.configuration.data, applicationParamsTheme),
        logoImage: state.configuration.data?.logoImage,
        name: state.configuration.data?.name,
        description: state.application.params.params.description,
        title: state.application.params.params.title,

        currency: state.application.params.params.currency,
        showLogo: state.application.params.params.showLogo,
        fees: state.configuration.paymentMethods.find(({ key }) => key === state.checkout.paymentMethodKey)?.fees || 0,
        hasExternalFees: state.configuration.paymentMethods.some(({ fees }) => !!fees),
        darkTheme: isDarkTheme(state),
        isSubscription,
    };
};

export const CheckoutHeader = () => {
    const [isLogoVisible, setIsLogoVisible] = useState(true);

    const {
        colors,
        logoImage,
        name,
        description,
        title,
        currency,
        fees,
        hasExternalFees,
        darkTheme,
        isSubscription,
        showLogo,
    } = useSelector(stateSelector);
    const { formatMessage } = useIntl();

    const styles = reactCSS({
        "default": {
            header: {
                backgroundColor: colors.secondaryBackground,
                color: colors.primaryText,
            },
            image: {
                borderColor: colors.mainBackground,
            },
            imageDefault: {
                borderColor: colors.mainBackground,
                borderStyle: "solid",
                borderWidth: "4px",
                borderRadius: "5px",
                display: "inline-block",
            },
            description: {
                color: colors.secondaryText,
            },
        },
    });

    const handleLogoLoading = (event) =>
        setIsLogoVisible(showLogo === true || (event.target.naturalWidth > 5 && event.target.naturalHeight > 5));

    return (
        <div data-name="dialog-header" className="dialog-header" style={styles.header}>
            {showLogo !== false &&
                (logoImage ? (
                    <Image
                        className={classnames({ hidden: !isLogoVisible }, "header-logo")}
                        src={logoImage}
                        style={styles.image}
                        width={IMAGE_SIZE}
                        height={IMAGE_SIZE}
                        onLoad={handleLogoLoading}
                        rounded
                    />
                ) : (
                    <div className="header-logo" style={styles.imageDefault}>
                        <DefaultImage />
                    </div>
                ))}

            <div>
                <h4 data-name="header-title">{title || name}</h4>

                {description && (
                    <h5 data-name="header-description" style={styles.description}>
                        {description}
                    </h5>
                )}

                {isSubscription ? (
                    <SubscriptionHeader style={styles.description} />
                ) : (
                    <ChargeHeader style={styles.description} />
                )}

                {hasExternalFees && !fees && !!currency && (
                    <div
                        className={classnames("dialog-header-other-external-fees", { dark: darkTheme })}
                        data-name="header-other-external-fees">
                        {formatMessage({ id: LOCALE_LABELS.COMMON_HEADER_EXTERNAL_FEES })}
                    </div>
                )}
            </div>
        </div>
    );
};
