import { useMemo } from "react";
import { useSelector } from "react-redux";
import { StateShape } from "checkout/ts/redux/store";
import { PatchedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { isDarkTheme } from "checkout/ts/utils/colors";
import { OnlineBrand } from "univapay-node";

const stateSelector = (state: StateShape) => ({
    brandsToInfo: state.configuration.brandsToInfo,
    darkTheme: isDarkTheme(state),
});

export const useOnlineLogo = (brand: OnlineBrand | PatchedOnlineBrand, hover = false) => {
    const { brandsToInfo, darkTheme } = useSelector(stateSelector);

    const { logos, name, loading } = brandsToInfo[brand] || {};
    const logo = useMemo(() => {
        const logoTheme = darkTheme || hover ? "dark" : "light";
        const logo = (logos || []).find((logo) => logo.logoPattern.toLowerCase().includes(`wig-${logoTheme}`));

        if (!logo) {
            return (logos || []).find((logo) => {
                const pattern = logo.logoPattern.toLowerCase();
                return pattern.includes("default") || pattern.includes("web") || pattern.includes("app");
            });
        }

        return logo;
    }, [logos, hover]);

    return { logo, name, loading };
};
