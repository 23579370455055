import * as VALIDATION_LABELS from "../labels/validation";

export const LABELS: Record<string, string> = {
    [VALIDATION_LABELS.VALIDATION_RULE_ARRAY]: ":attribute должено быть списком.",
    [VALIDATION_LABELS.VALIDATION_RULE_BETWEEN]: ":attribute должно использовать символы от :min до :max.",
    [VALIDATION_LABELS.VALIDATION_RULE_BOOLEAN]: ":attribute должно быть логическим значением.",
    [VALIDATION_LABELS.VALIDATION_RULE_DATE]: ":attribute должно быть в правильном формате даты.",
    [VALIDATION_LABELS.VALIDATION_RULE_DIFFERENT]: ":attribute и :different не должно быть одинаковыми.",
    [VALIDATION_LABELS.VALIDATION_RULE_EMAIL]: ":attribute должено содержать правильный адрес электронной почты.",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_NUMERIC]: ":attribute должно быть введено ниже макс.",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_STRING]: ":attribute должно быть введено не более MAX символов",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_NUMERIC]: ":attribute должно быть не менее MIN.",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_STRING]: ":attribute должно быть не менее чем MIN символов.",
    [VALIDATION_LABELS.VALIDATION_RULE_NOT_IN]: "Выбранное значение :attribute недействительно.",
    [VALIDATION_LABELS.VALIDATION_RULE_NUMERIC]: "Пожалуйста, введите :attribute числом.",
    [VALIDATION_LABELS.VALIDATION_RULE_REGEX]: ":attribute значение :value должно соответствовать шаблону. ',",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED]: ":attribute обязательно к заполнению.",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_IF]: ":attribute требуется, если :other становится :value.",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_WITH]: "Поле :attribute является обязательным, если для :field установлено значение.",
    [VALIDATION_LABELS.VALIDATION_RULE_SAME]: ":attribute и: same должны быть одинаковыми.",
    [VALIDATION_LABELS.VALIDATION_RULE_STRING]: ":attribute должно быть строкой.",
    [VALIDATION_LABELS.VALIDATION_RULE_URL]: "Пожалуйста, введите правильный URL для :attribute.",

    [VALIDATION_LABELS.VALIDATION_REQUIRED]: "{name} требуется",
    [VALIDATION_LABELS.VALIDATION_EMAIL]: "{name} должен ввести правильный адрес электронной почты",
    [VALIDATION_LABELS.VALIDATION_PHONE_NUMBER]: "{name} должено быть правильным адресом электронной почты",
    [VALIDATION_LABELS.VALIDATION_CARD_NUMBER]: "{name} должено быть правильным номером карты.",
    [VALIDATION_LABELS.VALIDATION_ZIP]: "{name} должено быть правильным почтовым кодом.",
    [VALIDATION_LABELS.VALIDATION_DATE]: "{name} не правильно",
    [VALIDATION_LABELS.VALIDATION_CARD_DATE_EXPIRED]: "Истек срок действия карты",

    [VALIDATION_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED]: "Это поле обязательно для заполнения.",

    [VALIDATION_LABELS.VALIDATION_INVALID_CHARACTERS]: "{name} содержит недопустимые символы",
    [VALIDATION_LABELS.VALIDATION_INVALID_LENGTH]: "{name} имеет неверный формат",
    [VALIDATION_LABELS.VALIDATION_MISSING_SPACE]: "{name} должно содержать хотя бы одн пробел.",
};

export default LABELS;
