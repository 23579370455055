import React from "react";
import { Alert, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { faCreditCard, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PatchedSupportedBrand } from "checkout/ts/redux/models/configuration";
import { PaymentMethodBrand } from "checkout/ts/resources/CheckoutInfoBrand";
import { cardBrandAPIName } from "checkout/ts/utils/cards";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { isDarkTheme } from "checkout/ts/utils/colors";
import classnames from "classnames";
import { NewCardBrand, PatchedCardBrand } from "common/types";
import {
    hasBlankSpace,
    hasCardHolderValidLength,
    hasValidCardHolderNameCharacter,
    needsCvv,
    trimConsecutiveSpaces,
} from "common/validation/card";
import Payment from "payment";
import { compose } from "recompose";
import { Form, FormSection, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import {
    PaymentType,
    ResponseError,
    ResponseErrorCode,
    TransactionTokenCardData,
    TransactionTokenItem,
    TransactionTokenType,
} from "univapay-node";

import { FORM_CHECKOUT_NAME, FORM_TOKEN_SELECT_NAME } from "../../../../../common/constants";
import { generate } from "../../../../../common/random";
import { errorLabel } from "../../../errors/utils";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { validationToErrors } from "../../../utils/errors";
import { amountForCurrency } from "../../../utils/money";
import { formatTokenData } from "../../../utils/tokens";
import { FormCheckoutData } from "../../checkout/FormCheckout";
import { StepTitle } from "../../common/StepTitle";
import { TextField } from "../../forms/Text";
import { CardCVVInput } from "../../forms/text/CardCVVInput";
import { CardExpInput } from "../../forms/text/CardExpInput";
import { CardNumberInput } from "../../forms/text/CardNumberInput";
import { CardBrandIcon } from "../../icons/CardBrandIcon";
import { AcceptField, Button, PrivacyLink } from "../common/FormData";

import { InstallmentSelectField } from "./components/InstallmentSelectField";
import { getCardBrandConfiguration } from "./utils/cardBrandConfiguration";
import { FormDataCard } from "./index";

export type CardData = TransactionTokenCardData & {
    accept: boolean;
    cvvAuthorize: {
        enabled: boolean;
        currency?: string;
    };
};

export type CardDataRaw = Omit<CardData, "expMonth" | "expYear"> & {
    exp: string;
};

export interface SavedTokenData {
    token: string;
    cvv?: string;
}

export type FullCardData = CardData | SavedTokenData;

export interface FormDataCardData<Data = FullCardData> {
    email?: string;
    /**
     * User information such as email, phone number and billing address
     */
    data: Data;

    /**
     * For recurring token the user needs to accept the condition as the paymennt information can be used for future payment.
     */
    accept: boolean;

    /**
     * For one time charges and recurring shows the installment select to divide the payment.
     * This will create a monthly subscription with card installment.
     *
     * @see checkout/ts/components/flows/card/FormData.tsx#stateSelector - Shows the installment select
     * @see checkout/ts/redux/utils/token.ts#getTokenParams - Transforms one time token with installment to subscription
     * @see checkout/ts/redux/models/checkout.ts#complete - Creates subscription for recurring and one time with installment
     */
    installmentCycles?: number | "revolving";
}

const stateSelector = (state: StateShape) => {
    const { checkout, application, configuration, tokens, product } = state;
    const applicationParams = application.params.params;
    const { error } = checkout;
    const { allowCardInstallments, hideRecurringCheckbox } = applicationParams;

    const {
        currency,
        initialAmount,
        tokenType,
        univapayCustomerId,
        subscriptionId,
        params: { confirmationRequired },
        isSubscription,
    } = getCheckoutParams(application.params, product.products, configuration.data);

    const tokenSelector = formValueSelector(FORM_TOKEN_SELECT_NAME);
    const selectedTokenId = tokenSelector(state, "selectedTokenId");

    const cardInfoSelector = formValueSelector(FORM_CHECKOUT_NAME);
    const cardNumber = cardInfoSelector(state, "data.cardNumber");
    const cardType = cardNumber ? cardBrandAPIName(Payment.fns.cardType(cardNumber)) : null;

    const brands = configuration.paymentMethods.find(({ key }) => key === checkout.paymentMethodKey)?.brands || [];

    const hasInstallmentCapableBrands = (configuration.data.supportedBrands as PatchedSupportedBrand[]).some(
        ({ brand, cardBrand, installmentCapable }) => brands.includes(brand || cardBrand) && installmentCapable
    );

    const showInstallmentSelect =
        hasInstallmentCapableBrands &&
        !isSubscription &&
        allowCardInstallments &&
        configuration.data.installmentsConfiguration?.enabled;

    const supportsPrivateLabel = brands.some((brand) => brand === NewCardBrand.PRIVATE_LABEL);

    const forceRecurringToken = tokenType === TransactionTokenType.RECURRING;

    const isCurrentBrandInstallmentCapable =
        !cardType ||
        ((configuration.data.supportedBrands || []).find(
            ({ brand, cardBrand }) => (brand || cardBrand) === cardType
        ) as PatchedSupportedBrand)?.installmentCapable;

    return {
        cardBrand: cardType,
        disableInstallments: !isCurrentBrandInstallmentCapable,
        currency,
        subscriptionInitialAmount: initialAmount,
        showInstallmentSelect,
        subscriptionId,
        tokenType,
        univapayCustomerId,
        configuration,
        selectedToken: selectedTokenId ? tokens.tokens[selectedTokenId] : undefined,
        renderCVV: needsCvv(state, selectedTokenId),
        selectedTokenId,
        initialValues: {
            installmentCycles: showInstallmentSelect ? 1 : undefined,
        },
        errorCVV:
            error instanceof ResponseError && error.errorResponse.code === ResponseErrorCode.CVVRequired ? error : null,
        useConfirmation: confirmationRequired,
        darkTheme: isDarkTheme(state),
        supportsPrivateLabel,
        brands,
        isSubscription,
        isAcceptRequired: !hideRecurringCheckbox && !selectedTokenId && forceRecurringToken,
        isAcceptVisible: !hideRecurringCheckbox && !selectedTokenId && (forceRecurringToken || !!univapayCustomerId),
        hidePrivacyLink: application.params.params.hidePrivacyLink,
    };
};

type OwnProps = InjectedFormProps<FormDataCardData<CardDataRaw>> & ReturnType<typeof stateSelector>;

const getPaymentTypeIcon = (brands: PatchedCardBrand[]) => (
    <div className="card-brand-logo">{brands.map((brand) => CardBrandIcon({ brand }))}</div>
);

export const generateValidation = (
    values: Partial<FormDataCardData<CardDataRaw>>,
    props: {
        selectedTokenId: string;
        renderCVV: boolean;
        supportsPrivateLabel: boolean;
        brands: PaymentMethodBrand[];
        isAcceptRequired: boolean;
    }
) => {
    const { selectedTokenId, renderCVV, supportsPrivateLabel, brands, isAcceptRequired } = props;
    const { data } = values;
    const { cardholder, cardNumber, exp, cvv, accept } = data || {};

    const { cardBrandSupported, requiresFullName } = getCardBrandConfiguration(values, supportsPrivateLabel);
    const cardType = Payment.fns.cardType(cardNumber);

    if (selectedTokenId) {
        return {
            "data.cvv": {
                [LOCALE_LABELS.VALIDATION_REQUIRED]: renderCVV && !cvv,
                [ResponseErrorCode.InvalidCVV]: cvv && !Payment.fns.validateCardCVC(cvv),
            },
        };
    }

    return {
        "data.cardholder": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !cardholder,
            [LOCALE_LABELS.VALIDATION_INVALID_CHARACTERS]: cardholder && !hasValidCardHolderNameCharacter(cardholder),
            [LOCALE_LABELS.VALIDATION_INVALID_LENGTH]: cardholder && !hasCardHolderValidLength(cardholder),
            [LOCALE_LABELS.VALIDATION_MISSING_SPACE]: cardholder && requiresFullName && !hasBlankSpace(cardholder),
        },
        "data.cardNumber": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !cardNumber,
            [ResponseErrorCode.CardBrandNotSupported]: !cardBrandSupported,
            [LOCALE_LABELS.VALIDATION_CARD_NUMBER]:
                !!cardNumber &&
                (cardType
                    ? !Payment.fns.validateCardNumber(cardNumber) || !brands?.includes(cardBrandAPIName(cardType))
                    : !supportsPrivateLabel),
        },
        "data.exp": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: !exp,
            [LOCALE_LABELS.VALIDATION_DATE]: (() => {
                if (!exp) {
                    return false;
                }

                const { month, year } = Payment.fns.cardExpiryVal(exp);
                // year < 100 means that one digit is missing so parsing can not kick in
                return !month || !year || month > 12 || year < 100;
            })(),
            [LOCALE_LABELS.VALIDATION_CARD_DATE_EXPIRED]: exp && !Payment.fns.validateCardExpiry(exp),
        },
        "data.cvv": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: renderCVV && !cvv,
            [ResponseErrorCode.InvalidCVV]: cvv && !Payment.fns.validateCardCVC(cvv),
        },
        "data.accept": { [LOCALE_LABELS.VALIDATION_REQUIRED]: isAcceptRequired && !accept },
    };
};

const validate = (values: Partial<FormDataCardData<CardDataRaw>>, props: OwnProps) => {
    const validationObject = generateValidation(values, props);

    return validationToErrors(validationObject);
};

export const normalizeValues = (
    raw: FormDataCard<CardDataRaw>,
    selectedToken?: TransactionTokenItem
): Partial<Omit<FormCheckoutData, "data"> & { data: Partial<FormCheckoutData["data"]> }> => {
    const { data, ...values } = raw;
    const { exp = "", cardholder: rawCardholder, ...rawData } = data || {};
    const cardholder = trimConsecutiveSpaces(rawCardholder);

    if (selectedToken) {
        return {
            ...values,
            data: {
                token: selectedToken.id,
                cardholder,
                ...data,
            },
        };
    }

    const [expMonth, expYear] = exp.split("/");
    const normalizedData = { ...rawData, expMonth, expYear, cardholder };
    return { ...values, data: normalizedData };
};

const Content = compose<OwnProps, OwnProps>(
    connect(stateSelector),
    reduxForm({ form: FORM_CHECKOUT_NAME, validate, destroyOnUnmount: false, forceUnregisterOnUnmount: true })
)(
    ({
        handleSubmit,
        currency,
        subscriptionInitialAmount,
        showInstallmentSelect,
        subscriptionId,
        selectedToken,
        renderCVV,
        errorCVV,
        useConfirmation,
        darkTheme,
        supportsPrivateLabel,
        brands,
        isSubscription,
        isAcceptVisible,
        disableInstallments,
        cardBrand,
        hidePrivacyLink,
    }) => {
        const intl = useIntl();
        const { formatMessage, formatNumber } = intl;

        const {
            subscription: { update: updateSubscription },
            checkout: { process: processCheckout },
        } = useDispatch<Dispatch>();

        const handleSubmitForm = (values: FormDataCard<CardDataRaw>) => {
            const normalizedValues = normalizeValues(values, selectedToken) as FormCheckoutData;

            if (subscriptionId) {
                updateSubscription({ subscriptionId, data: normalizedValues, selectedToken });
            } else {
                processCheckout({ values: normalizedValues });
            }
        };

        const tooltipCvv = (
            <Tooltip id={generate()} className="tooltip-small">
                {formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC_INFO })}
            </Tooltip>
        );

        return (
            <Form noValidate onSubmit={handleSubmit(handleSubmitForm)} className="credit-card data content-form">
                <Row>
                    <Col xs={12}>
                        <StepTitle className="title">
                            <div className="title-labels-wrapper">
                                <div className="title-label">
                                    {formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_CARD_TITLE })}
                                </div>

                                {!selectedToken && supportsPrivateLabel && (
                                    <div
                                        className={classnames("title-subtext-label", {
                                            "dark-theme": darkTheme,
                                        })}>
                                        {formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_CARD_LOCAL_CARDS })}
                                    </div>
                                )}
                            </div>

                            {!selectedToken && getPaymentTypeIcon(brands as PatchedCardBrand[])}
                        </StepTitle>
                    </Col>

                    {selectedToken ? (
                        <Col xs={12}>
                            <div className="card-last-four">{formatTokenData(selectedToken, formatMessage)}</div>
                        </Col>
                    ) : (
                        <Col xs={12}>
                            <FormSection name="data">
                                <TextField
                                    label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL })}
                                    placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARDHOLDER })}
                                    normalize={(value) => value?.replace("　", " ")?.replace(/[0-9]/g, "")}
                                    bsSize="sm"
                                    name="cardholder"
                                    autoComplete="cc-name"
                                    tabIndex={2}
                                    required
                                    isDark={darkTheme}
                                />

                                <TextField
                                    name="cardNumber"
                                    label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL })}
                                    placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CARD_NUMBER })}
                                    bsSize="sm"
                                    tabIndex={3}
                                    componentClass={CardNumberInput}
                                    addons={<FontAwesomeIcon icon={faCreditCard} />}
                                    required
                                    isDark={darkTheme}
                                />
                            </FormSection>
                        </Col>
                    )}

                    <FormSection name="data">
                        {!selectedToken && (
                            <Col xs={12}>
                                <TextField
                                    label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL })}
                                    validationLabel={formatMessage({
                                        id: LOCALE_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION,
                                    })}
                                    bsSize="sm"
                                    name="exp"
                                    tabIndex={4}
                                    width={76}
                                    componentClass={CardExpInput}
                                    showPlaceholder
                                    required
                                    isDark={darkTheme}
                                />
                            </Col>
                        )}

                        {renderCVV && (
                            <Col xs={12}>
                                <TextField
                                    label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC_LABEL })}
                                    placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC })}
                                    bsSize="sm"
                                    name="cvv"
                                    tabIndex={5}
                                    width={64}
                                    componentClass={CardCVVInput}
                                    addons={{
                                        header: (
                                            <OverlayTrigger placement="left" overlay={tooltipCvv}>
                                                <FontAwesomeIcon icon={faInfoCircle} className="ml-2" fixedWidth />
                                            </OverlayTrigger>
                                        ),
                                    }}
                                    required
                                    isDark={darkTheme}
                                />
                            </Col>
                        )}
                    </FormSection>

                    {errorCVV && (
                        <Col xs={12}>
                            <Alert bsStyle="error">
                                {formatMessage({ id: errorLabel(errorCVV, PaymentType.CARD) })}
                            </Alert>
                        </Col>
                    )}

                    {showInstallmentSelect &&
                        (disableInstallments ? (
                            <Col xs={12}>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={generate()} className="tooltip-small">
                                            {formatMessage(
                                                { id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_HELPER },
                                                { brand: cardBrand }
                                            )}
                                        </Tooltip>
                                    }>
                                    <div>
                                        <InstallmentSelectField darkTheme={darkTheme} disabled={disableInstallments} />
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        ) : (
                            <Col xs={12}>
                                <InstallmentSelectField darkTheme={darkTheme} disabled={disableInstallments} />
                            </Col>
                        ))}

                    {isAcceptVisible && (
                        <FormSection name="data">
                            <AcceptField isDark={darkTheme} />
                        </FormSection>
                    )}

                    {isSubscription && !!subscriptionInitialAmount && (
                        <Col xs={12} data-name="subscription-initial-amount" className="text-left">
                            {formatMessage(
                                { id: LOCALE_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT },
                                {
                                    money: formatNumber(amountForCurrency(subscriptionInitialAmount, currency), {
                                        style: "currency",
                                        currency,
                                    }),
                                }
                            )}
                        </Col>
                    )}
                </Row>

                {!hidePrivacyLink && <PrivacyLink isDark={darkTheme} />}

                <Button label={useConfirmation && formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_NEXT })} />
            </Form>
        );
    }
);

export default Content;
