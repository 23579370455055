import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SelectField } from "checkout/ts/components/forms/Select";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { StateShape } from "checkout/ts/redux/store";

type OwnProps = {
    name?: string;
    disabled?: boolean;
    darkTheme: boolean;
    horizontal?: boolean;

    labelStyle?: Record<string, string>;
    errorStyle?: Record<string, string>;
    invalidStyle?: Record<string, string>;
    focusStyle?: Record<string, string>;
    inputStyle?: Record<string, string>;
    style?: Record<string, string>;
};

const stateSelector = (state: StateShape) => ({
    supportedInstallments: state.application.params.params.cardInstallmentOptions,
});

export const InstallmentSelectField = ({
    name = "installmentCycles",
    disabled,
    darkTheme,
    horizontal,
    labelStyle,
    errorStyle,
    invalidStyle,
    focusStyle,
    inputStyle,
    style,
}: OwnProps) => {
    const { formatMessage } = useIntl();

    const { supportedInstallments } = useSelector(stateSelector);

    const installmentOptions = [
        { value: 1, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_ONE_TIME }) },
        { value: 3, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_THREE_TIMES }) },
        { value: 5, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_FIVE_TIMES }) },
        { value: 6, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_SIX_TIMES }) },
        { value: 10, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_TEN_TIMES }) },
        { value: 12, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_TWELVE_TIMES }) },
        { value: 15, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES }) },
        { value: 18, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES }) },
        { value: 20, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_TIMES }) },
        { value: 24, label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES }) },
        { value: "revolving", label: formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_REVOLVING }) },
    ].filter(({ value }) => !supportedInstallments?.length || supportedInstallments.includes(`${value}`));

    return (
        <SelectField
            label={formatMessage({ id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_LABEL })}
            name={name}
            disabled={disabled}
            isDark={darkTheme}
            bsSize="sm"
            labelStyle={labelStyle}
            errorStyle={errorStyle}
            invalidStyle={invalidStyle}
            focusStyle={focusStyle}
            inputStyle={inputStyle}
            style={style}
            isHorizontal={horizontal}
            required>
            {installmentOptions.map(({ value, label }) => (
                <option key={value} value={value}>
                    {label}
                </option>
            ))}
        </SelectField>
    );
};
