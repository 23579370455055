import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import reactCSS from "reactcss";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FailedStatuses } from "checkout/ts/redux/models/checkout";
import { getThemeColors } from "checkout/ts/utils/colors";
import { get, isNil } from "lodash";

import { HEADER_DEFAULT } from "../../../../common/constants";
import { defaultColors } from "../../colors";
import { LOCALE_LABELS } from "../../locale/labels";
import { Dispatch, history, StateShape } from "../../redux/store";

import { DialogToolbarButton } from "./DialogToolbarButton";

const stateSelector = (state: StateShape) => {
    const applicationParamsTheme = isNil(state.application.params?.params?.dark)
        ? null
        : state.application.params?.params?.dark
        ? "dark"
        : "light";

    return {
        header: state.application.params.params.header || HEADER_DEFAULT,
        processing: (state.loading.effects.checkout.process as unknown) as boolean,
        colors: getThemeColors(state.configuration.data, applicationParamsTheme),
        processed: state.checkout.processed,
        status: get(state.checkout.data, "status"),
    };
};

export const DialogHeader = () => {
    const { formatMessage } = useIntl();
    const {
        application: { close },
    } = useDispatch<Dispatch>();
    const { goBack } = useHistory();

    const { header, processing, colors: stateColors, processed, status } = useSelector(stateSelector);

    const handleClickBack = () => goBack();
    const handleClickClose = () => close();

    const { mainColor: backgroundColor, mainText: color } = { ...defaultColors, ...stateColors };
    const styles = reactCSS({ "default": { header: { backgroundColor, color } } });

    return (
        <header style={styles.header}>
            {(!processed || (!!status && FailedStatuses.includes(status))) && history.canGo(-1) && (
                <DialogToolbarButton
                    id="back-button"
                    tabIndex={40}
                    icon={faChevronLeft}
                    onClick={handleClickBack}
                    title={formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_BACK })}
                    disabled={processing}
                />
            )}

            <h3 data-name="frame-header">{header}</h3>

            <DialogToolbarButton
                id="close-button"
                tabIndex={50}
                icon={faTimes}
                onClick={handleClickClose}
                title={formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_CLOSE })}
                disabled={processing}
            />
        </header>
    );
};
