import { CardBrand } from "univapay-node";

/**
 * The "payments" module detects card brands from the number,
 * but their naming is different then what our API uses. Have to
 * map their names to ours to check against our list of forbidden
 * card brands.
 */
export const cardBrandAPIName = (brand: string): CardBrand => {
    switch (brand) {
        case "amex":
            return CardBrand.AMEX;
        case "dinersclub":
            return CardBrand.DINERS;
        default:
            return brand as CardBrand;
    }
};
