import * as VALIDATION_LABELS from "../labels/validation";

export const LABELS: Record<string, string> = {
    [VALIDATION_LABELS.VALIDATION_RULE_ARRAY]: "{name}必须为List。",
    [VALIDATION_LABELS.VALIDATION_RULE_BETWEEN]: "{name}必须在:min和:max之间。",
    [VALIDATION_LABELS.VALIDATION_RULE_BOOLEAN]: "{name}必须为布尔值。",
    [VALIDATION_LABELS.VALIDATION_RULE_DATE]: "{name}请输入正确的日期格式。",
    [VALIDATION_LABELS.VALIDATION_RULE_DIFFERENT]: "{name}和:different不能相同。",
    [VALIDATION_LABELS.VALIDATION_RULE_EMAIL]: "{name}请输入正确的邮件地址。",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_NUMERIC]: "{name}必须小于:max。",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_STRING]: "{name}字数必须小于:max。",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_NUMERIC]: "{name}必须大于:min。",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_STRING]: "{name}字数必须大于:min。",
    [VALIDATION_LABELS.VALIDATION_RULE_NOT_IN]: "已选的{name}无效。",
    [VALIDATION_LABELS.VALIDATION_RULE_NUMERIC]: "{name}请输入数字。",
    [VALIDATION_LABELS.VALIDATION_RULE_REGEX]: "{name}格式有误。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED]: "{name}为必填项。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_IF]: ":other的值为:value时，{name}为必填项。",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_WITH]: ":field的值被设定时，{name}为必填项。",
    [VALIDATION_LABELS.VALIDATION_RULE_SAME]: "{name}和:same不能相同。",
    [VALIDATION_LABELS.VALIDATION_RULE_STRING]: "{name}必须为字符串。",
    [VALIDATION_LABELS.VALIDATION_RULE_URL]: "{name}请输入正确的URL链接。",

    [VALIDATION_LABELS.VALIDATION_DATE]: "{name}请输入正确的日期格式。",
    [VALIDATION_LABELS.VALIDATION_EMAIL]: "{name}请输入正确的邮件地址。",
    [VALIDATION_LABELS.VALIDATION_BANNED_EMAIL]: "Email banned",
    [VALIDATION_LABELS.VALIDATION_REQUIRED]: "{name}为必填项。",

    [VALIDATION_LABELS.VALIDATION_PHONE_NUMBER]: "{name}格式有误",
    [VALIDATION_LABELS.VALIDATION_CARD_NUMBER]: "{name}请输入数字。",
    [VALIDATION_LABELS.VALIDATION_CARD_DATE_EXPIRED]: "{name}输入正确的日期格式。",

    [VALIDATION_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED]: "该字段为必填字段。",

    [VALIDATION_LABELS.VALIDATION_INVALID_CHARACTERS]: "{name}格式有误",
    [VALIDATION_LABELS.VALIDATION_INVALID_LENGTH]: "{name}格式有误",
    [VALIDATION_LABELS.VALIDATION_MISSING_SPACE]: "{name}格式有误",
};

export default LABELS;
