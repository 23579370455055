import React from "react";
import reactCSS from "reactcss";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Color from "color";

import { DARK_COLOR } from "../../../../common/constants";

export type SpinnerProps = React.PropsWithChildren<{
    loading: boolean;
    color?: string;
}>;

export const Spinner = ({ children, color = DARK_COLOR, loading }: SpinnerProps) => {
    const styles = reactCSS({
        default: {
            spinner: {
                color: Color(color).alpha(0.2).toString(),
            },
        },
    });

    const spinner = <FontAwesomeIcon icon={faCircleNotch} spin size="5x" style={styles.spinner} />;

    return <div>{loading ? spinner : children}</div>;
};
