import { ApplicationTimeoutError } from "common/errors/ApplicationTimeoutError";
import ee from "event-emitter";
import { Emitter } from "event-emitter";
import { submit } from "redux-form";
import { ChargeItem } from "univapay-node";

import { LOAD_TIMEOUT } from "../../common/constants";
import { DisplayDetect } from "../../common/DisplayDetect";
import { ConnectorError } from "../../common/errors/ConnectorError";
import {
    EnhancedMessageEvent,
    Message,
    MessageBeforeClosingResponse,
    MessageChargeCreated,
    MessageClose,
    MessageError,
    MessageExecute,
    MessageSubscriptionCreated,
    MessageSuccess,
    MessageTokenCreated,
    MessageType,
    MessageValidationError,
    ResourceType,
} from "../../common/Messages";
import { timeout } from "../../common/timeout";
import { development } from "../../common/utils/development";
import { JSONParse, JSONStringify } from "../../common/utils/json";

import { StateShape, store } from "./redux/store";
import { formatInlineSubmitErrors } from "./utils/errors";

export class Connector {
    private static instance: Connector = null;

    connectorId: string;
    emitter: Emitter;

    private originTarget: string;
    private promiseCallbacks: ((...params: unknown[]) => void)[] = [];
    private beforeClosingCallbacks: ((...params: (MessageBeforeClosingResponse | Error)[]) => boolean | void)[] = [];
    private validationCallbackTimeout = null;

    constructor() {
        this.emitter = ee({});

        this.originTarget =
            window.document.referrer.split(/[?#]/)[0].replace(/\/$/, "") || window.location.ancestorOrigins?.[0];

        window.addEventListener("message", this.handleMessage);
        if (!DisplayDetect.isFramed()) {
            window.onbeforeunload = () => this.sendMessage({ type: MessageType.CLOSED });
        }

        this.emitter.on("checkout:opened", this.handleOpen);
        this.emitter.on("checkout:closed", this.handleClose);
        this.emitter.on("checkout:success", this.handleSuccess);
        this.emitter.on("checkout:pending", this.handlePending);
        this.emitter.on("checkout:error", this.handleError);
        this.emitter.on("checkout:token-created", this.handleTokenCreated);
        this.emitter.on("checkout:charge-created", this.handleChargeCreated);
        this.emitter.on("checkout:subscription-created", this.handleSubscriptionCreated);
        this.emitter.on("checkout:validation-error", this.handleValidationError);
    }

    connect(): Promise<MessageExecute | void> {
        return Promise.race([this.initHandshake(), timeout(LOAD_TIMEOUT, new ApplicationTimeoutError(LOAD_TIMEOUT))]);
    }

    beforeClosing(): Promise<boolean | void> {
        return Promise.race([
            this.handleBeforeClosing(),
            timeout(LOAD_TIMEOUT, new ApplicationTimeoutError(LOAD_TIMEOUT)),
        ]);
    }

    get originDomain(): string {
        const a: HTMLAnchorElement = document.createElement("a");
        a.href = this.originTarget;
        return a.hostname;
    }

    private initHandshake(): Promise<MessageExecute> {
        development(() => console.info("2- Initing handshake"));
        return new Promise((resolve: (...params: unknown[]) => void, reject: (...params: unknown[]) => void) => {
            if (!window.opener && !window.parent) {
                reject(new ConnectorError());
            }

            this.promiseCallbacks = [resolve, reject];

            this.sendMessage({ data: { paired: false }, type: MessageType.HANDSHAKE });
        }).then((data: MessageExecute) => {
            this.promiseCallbacks = [];
            return data;
        });
    }

    private handleMessage = (e: EnhancedMessageEvent) => {
        if (!e?.data || this.originTarget.indexOf(e.origin) === -1) {
            return;
        }

        if (typeof e.data === "object" && e.data.type === "webpackOk") {
            return;
        }

        try {
            const message = JSONParse<Message<{ connectorId: string; paired: boolean }>>(e.data);
            if (!message) {
                return;
            }

            switch (message.type) {
                case MessageType.HANDSHAKE:
                    development(() => console.info("4- Pairing with parent", message));
                    this.connectorId = message.data.connectorId;
                    Connector.instance = this;

                    if (message.data.connectorId === this.connectorId && !message.data.paired) {
                        this.sendMessage({ ...message, data: { ...message.data, paired: true } });
                    }
                    break;

                case MessageType.EXECUTE: {
                    const [resolve] = this.promiseCallbacks;
                    resolve?.(message.data);

                    this.connectorId = message.data.connectorId;
                    break;
                }

                case MessageType.BEFORE_CLOSING_RESPONSE: {
                    const [resolve] = this.beforeClosingCallbacks;
                    resolve?.((message.data as unknown) as MessageBeforeClosingResponse);
                    break;
                }

                case MessageType.SUBMIT_CARD_DATA: {
                    if (message.data.connectorId !== this.connectorId) {
                        return;
                    }

                    const state = store.getState() as StateShape;
                    const activeForm = Object.keys(state.form)[0];
                    if (!activeForm) {
                        return;
                    }

                    store.dispatch(submit(activeForm));

                    let pollInterval;
                    new Promise((resolve, reject) => {
                        pollInterval = setInterval(() => {
                            const state = store.getState() as StateShape;

                            const processingError = state.checkout.error;
                            const chargeError =
                                state.checkout.charge?.error || (state.checkout.data as ChargeItem)?.error;
                            const installmentCount = state.checkout.userInstallmentCount;
                            const error = chargeError || processingError;

                            const token = state.checkout.token?.id;
                            const charge = state.checkout.charge?.id;
                            const formErrors = (state.form[activeForm] as any).syncErrors;

                            const subscription =
                                state.checkout.resourceType === ResourceType.SUBSCRIPTION
                                    ? state.checkout.data?.id
                                    : undefined;

                            // TODO: Find a less hacky way to get the form errors
                            if (state.form[activeForm].submitFailed && formErrors) {
                                reject(formatInlineSubmitErrors(formErrors));
                                return;
                            }

                            if (error) {
                                reject(formatInlineSubmitErrors(error, token, charge, subscription));
                                return;
                            }

                            if (state.checkout.processed) {
                                resolve({
                                    token,
                                    transactionToken: token,
                                    charge,
                                    subscription,
                                    ...(installmentCount ? { installmentCount } : {}),
                                });
                            }
                        }, 200);
                    })
                        .then((data) => {
                            this.sendMessage({ type: MessageType.SUBMITTED, data });
                            clearInterval(pollInterval);
                        })
                        .catch((errors) => {
                            this.sendMessage({ type: MessageType.SUBMITTED, errors });
                            clearInterval(pollInterval);
                        });

                    break;
                }

                default:
                    return;
            }
        } catch (error) {
            throw new ConnectorError();
        }
    };

    private handleOpen = () => {
        this.sendMessage({ type: MessageType.OPENED });
    };

    private handleBeforeClosing = () => {
        return new Promise(
            (resolve: (params: MessageBeforeClosingResponse) => void, reject: (params: Error) => void) => {
                if (!window.opener && !window.parent) {
                    reject(new ConnectorError());
                }

                this.beforeClosingCallbacks = [resolve, reject];
                this.sendMessage({ data: {}, type: MessageType.BEFORE_CLOSING });
            }
        ).then(({ shouldClose }) => {
            this.beforeClosingCallbacks = [];
            return shouldClose;
        });
    };

    private handleClose = (message: MessageClose) => {
        this.sendMessage({ type: MessageType.CLOSED, data: message });
    };

    private handleSuccess = (message: MessageSuccess) => {
        this.sendMessage({ type: MessageType.SUCCESS, data: message });
    };

    private handlePending = (message: MessageSuccess) => {
        this.sendMessage({ type: MessageType.PENDING, data: message });
    };

    private handleError = (message: MessageError) => {
        this.sendMessage({ type: MessageType.ERROR, data: message });
    };

    private handleTokenCreated = (message: MessageTokenCreated) => {
        this.sendMessage({ type: MessageType.TOKEN_CREATED, data: message });
    };

    private handleChargeCreated = (message: MessageChargeCreated) => {
        this.sendMessage({ type: MessageType.CHARGE_CREATED, data: message });
    };

    private handleSubscriptionCreated = (message: MessageSubscriptionCreated) => {
        this.sendMessage({ type: MessageType.SUBSCRIPTION_CREATED, data: message });
    };

    private handleValidationError = (message: MessageValidationError) => {
        clearTimeout(this.validationCallbackTimeout);

        if (Object.keys(message).length) {
            this.validationCallbackTimeout = setTimeout(() => {
                this.sendMessage({ type: MessageType.VALIDATION_ERROR, data: message });
            }, 2000);
        }
    };

    static resizeFrame(height: number) {
        const connector = Connector.instance;

        connector.sendMessage({ type: MessageType.RESIZE, data: { height: Math.ceil(height) } });
    }

    private sendMessage(message: Message<any>): void {
        const origin: Window = window.opener || window.parent;

        if (this.originTarget) {
            origin.postMessage(
                JSONStringify({ ...message, data: { ...message.data, connectorId: this.connectorId } }),
                this.originTarget
            );
        }
    }
}
