import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { CheckoutType } from "common/types";
import { ChargeItem, ResponseError, SubscriptionItem } from "univapay-node";

import { CheckoutResponse, FailedStatuses } from "../redux/models/checkout";
import { StateShape } from "../redux/store";
import { isDarkTheme } from "../utils/colors";

export enum ProcessState {
    PENDING = "pending",
    SUCCESS = "success",
    FAILURE = "failure",
}

export const getProcessState = (
    processed: boolean,
    error: ResponseError,
    data: CheckoutResponse,
    checkoutType: CheckoutType
): ProcessState => {
    const status = (data as ChargeItem | SubscriptionItem)?.status;

    if (!!error || (!!status && FailedStatuses.includes(status))) {
        return ProcessState.FAILURE;
    }

    return (checkoutType !== CheckoutType.PAYMENT || status) && processed ? ProcessState.SUCCESS : ProcessState.PENDING;
};

type ProcessLoaderProps = {
    color?: string;
    size?: number;
    state: ProcessState;
};

const stateSelector = (state: StateShape) => ({
    darkTheme: isDarkTheme(state),
});

export const ProcessLoader = ({ state, color = "#4095bd", size = 34 }: ProcessLoaderProps) => {
    const radius: number = size * 0.45;
    const width: number = size / 2;
    const stroke: number = size * 0.1;
    const pathLength: number = 2 * Math.PI * radius;

    const { darkTheme } = useSelector(stateSelector);

    let dashArray = `${pathLength * 0.25} ${pathLength * 0.75}`;

    if (state !== ProcessState.PENDING) {
        dashArray = `${pathLength} 0`;
    }

    const renderMark = (): React.ReactNode => {
        switch (state) {
            case ProcessState.SUCCESS:
                return (
                    <polyline
                        className="ok"
                        fill="none"
                        strokeWidth={stroke}
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeDasharray={`${size * 0.8}`}
                        points={`${size * 0.22},${size * 0.5} ${size * 0.42},${size * 0.7} ${size * 0.77},${
                            size * 0.35
                        }`}
                    />
                );

            case ProcessState.FAILURE: {
                const start: number = size * 0.3;
                const end: number = size * 0.7;
                const length: number = Math.sqrt(2 * Math.pow(size * 0.4, 2));

                return [
                    <line
                        key="key1"
                        x1={start}
                        y1={start}
                        x2={end}
                        y2={end}
                        strokeDasharray={`${length}`}
                        strokeLinecap="round"
                        strokeWidth={stroke}
                    />,
                    <line
                        key="key2"
                        x1={end}
                        y1={start}
                        x2={start}
                        y2={end}
                        strokeDasharray={`${length}`}
                        strokeLinecap="round"
                        strokeWidth={stroke}
                    />,
                ];
            }

            default:
                return null;
        }
    };

    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            className={classnames("progress-loader", state, { "dark-theme": darkTheme })}>
            <rect x="0" y="0" width={size} height={size} fill="none" />

            <circle
                cx={width}
                cy={width}
                r={radius}
                fill="none"
                stroke={color}
                strokeOpacity={0.4}
                strokeWidth={stroke}
            />

            <circle
                cx={width}
                cy={width}
                r={radius}
                className="stroke"
                fill="none"
                transform={`rotate(-90 ${width} ${width})`}
                strokeLinecap="round"
                stroke={color}
                strokeWidth={stroke}
                strokeDasharray={dashArray}
            />

            {renderMark()}
        </svg>
    );
};
