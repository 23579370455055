import React, { Component, HTMLProps } from "react";
import TextMask from "react-text-mask";
import { fullWidthToHalfWidth } from "checkout/ts/utils/numbers";

const CARD_CVV_MASK: (string | RegExp)[] = [/[0-9０-９]/, /[0-9０-９]/, /[0-9０-９]/, /[0-9０-９]/];

export type CardCVVInputProps = Omit<HTMLProps<React.InputHTMLAttributes<string>>, "onChange"> & {
    onChange?: (value: string) => void;
};

export class CardCVVInput extends Component<CardCVVInputProps> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: eventValue } = event.target as HTMLInputElement;

        const formattedValue = fullWidthToHalfWidth(eventValue);
        this.props.onChange?.(formattedValue);
    };

    render() {
        const { value = "", ...props } = this.props;

        return (
            <TextMask
                {...props}
                onChange={this.handleChange}
                autoComplete="cc-csc"
                type="tel"
                pattern="[0-9０-９]*"
                inputMode="numeric"
                guide={false}
                keepCharPositions={false}
                mask={CARD_CVV_MASK}
                placeholderChar={"\u2000"}
                value={fullWidthToHalfWidth(String(value))}
            />
        );
    }
}
