import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardJCB = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="jcb-card-icon" viewBox="0 0 780 500" {...props}>
        <defs>
            <linearGradient
                id="a"
                data-name="Green"
                x1="488.4"
                y1="274.94"
                x2="646.61"
                y2="274.94"
                gradientTransform="translate(-49.1 -31.99) scale(1.13)"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#015824" />
                <stop offset="0.23" stopColor="#187024" />
                <stop offset="0.75" stopColor="#47a024" />
                <stop offset="1" stopColor="#59b224" />
            </linearGradient>
            <linearGradient id="b" x1="488.4" y1="250.29" x2="646.62" y2="250.29" href="#a" />
            <linearGradient id="c" x1="488.4" y1="223.45" x2="646.61" y2="223.45" href="#a" />
            <linearGradient
                id="d"
                data-name="Bleu"
                x1="134.21"
                y1="250.29"
                x2="294.87"
                y2="250.29"
                gradientTransform="translate(-49.1 -31.99) scale(1.13)"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#0d0157" />
                <stop offset="0.21" stopColor="#0c166a" />
                <stop offset="0.74" stopColor="#0b4694" />
                <stop offset="1" stopColor="#0a58a5" />
            </linearGradient>
            <linearGradient
                id="e"
                data-name="Red"
                x1="310.46"
                y1="250.29"
                x2="466.5"
                y2="250.29"
                gradientTransform="translate(-49.1 -31.99) scale(1.13)"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#4d0000" />
                <stop offset="0.16" stopColor="#6c0007" />
                <stop offset="0.56" stopColor="#b80017" />
                <stop offset="0.86" stopColor="#e90022" />
                <stop offset="1" stopColor="#fb0026" />
            </linearGradient>
        </defs>
        <rect width="780" height="500" fill="none" />
        <g>
            <path
                d="M713.87,398.33A100.76,100.76,0,0,1,613.11,499.09h-547V100.85A100.76,100.76,0,0,1,166.9.09h547Z"
                transform="translate(0)"
                fill="#fff"
            />
            <g>
                <path
                    d="M535.53,296.3h41.66a30.7,30.7,0,0,0,5-.46,19,19,0,0,0,0-37,30.69,30.69,0,0,0-5-.47H535.53Z"
                    transform="translate(0)"
                    fill="url(#a)"
                />
                <path
                    d="M572.32,33.68a72,72,0,0,0-72,72v74.75H602.19c2.36,0,5.25.17,7,.26,23,1.16,40,13,40,33.55,0,16.15-11.62,30-32.76,33.05v.82c23.36,1.62,41.24,14.47,41.24,34.72,0,21.91-19.63,36-45.79,36H500.35V465.5H605.91a72,72,0,0,0,72-72V33.68Z"
                    transform="translate(0)"
                    fill="url(#b)"
                />
                <path
                    d="M591.88,219.4a17.06,17.06,0,0,0-14.49-17.2,30.9,30.9,0,0,0-4.1-.32H535.53v35h37.76a32.7,32.7,0,0,0,4.1-.32A17.08,17.08,0,0,0,591.88,219.4Z"
                    transform="translate(0)"
                    fill="url(#c)"
                />
            </g>
            <path
                d="M174.09,33.68a72,72,0,0,0-72,72V283.38c20.19,9.78,41,16.15,62.26,16.15,24.89,0,38.56-15.19,38.56-35.63V180.4h61.9v83.5c0,32.66-20,58.85-88.85,58.85a309,309,0,0,1-73.87-8.93V465.5H207.68a72,72,0,0,0,72-72V33.68Z"
                transform="translate(0)"
                fill="url(#d)"
            />
            <path
                d="M373.21,33.68a72,72,0,0,0-72,72v94.23c18.23-15.65,49.77-25.42,101-23.11,27.43,1.22,56.51,8.57,56.51,8.57v30.48c-14.56-7.38-32-14.21-54.5-15.92C365.36,197,342,216,342,249.59s23.4,52.63,62.29,49.68c22.49-1.69,40.17-8.71,54.5-15.9v30.46s-29.08,7.35-56.51,8.6c-51.23,2.3-82.77-7.47-101-23.11V465.5H406.79a72,72,0,0,0,72-72V33.68Z"
                transform="translate(0)"
                fill="url(#e)"
            />
        </g>
    </Icon>
);

export default CardJCB;
