import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getProcessState, ProcessState } from "checkout/ts/components/ProcessLoader";
import { errorLabel, getErrorHttpCode } from "checkout/ts/errors/utils";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { CheckoutResponse } from "checkout/ts/redux/models/checkout";
import { StateShape } from "checkout/ts/redux/store";
import { errorCodeLabel } from "common/locale/utils";
import { CheckoutType } from "common/types";
import { ChargeItem, ErrorResponse, PaymentType, ResponseErrorCode, SubError } from "univapay-node";

const getSuccessLabel = (
    checkoutData: CheckoutResponse,
    checkoutType: CheckoutType,
    paymentType: PaymentType,
    isPatchingSubscription: boolean,
    allowCardInstallments: boolean
) => {
    if (isPatchingSubscription) {
        // No payment in done in case of patching at the time of writing
        return LOCALE_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_SUCCESS;
    }

    if (checkoutType !== CheckoutType.PAYMENT) {
        return LOCALE_LABELS.PAYMENT_ALERT_TOKEN_SUCCESS;
    }

    switch (paymentType) {
        case PaymentType.KONBINI:
            return LOCALE_LABELS.PAYMENT_ALERT_KONBINI_SUCCESS;

        default:
            // allowCardInstallments is not available for subscription.
            // Payment with this parameter are not subscriptions in the eye of the user but a payment divided in several cycles
            return "period" in (checkoutData || {}) && !allowCardInstallments
                ? LOCALE_LABELS.PAYMENT_ALERT_SUBSCRIPTION_SUCCESS
                : LOCALE_LABELS.PAYMENT_ALERT_SUCCESS;
    }
};

const stateSelector = (state: StateShape) => ({
    processed: state.checkout.processed,
    error: state.checkout.error,
    transaction: state.checkout.data,
    allowCardInstallments: state.application.params.params.allowCardInstallments,
    checkout: state.checkout,
    isPatchingSubscription: state.subscription.isPatching,
});

export const ConfirmText = ({ paymentType }: { paymentType?: PaymentType }) => {
    const { checkout, isPatchingSubscription, allowCardInstallments, processed, error, transaction } = useSelector(
        stateSelector
    );
    const { checkoutType } = useCheckoutParams();
    const checkoutState = getProcessState(processed, error, transaction, checkoutType);
    const showQr = checkoutState === ProcessState.SUCCESS && checkoutType === CheckoutType.QR;

    switch (checkoutState) {
        case ProcessState.PENDING:
            return (
                <FormattedMessage
                    id={
                        isPatchingSubscription
                            ? LOCALE_LABELS.PAYMENT_ALERT_SUBSCRIPTION_PATCH_TOKEN_PENDING
                            : LOCALE_LABELS.PAYMENT_ALERT_PROCESSING
                    }
                />
            );

        case ProcessState.SUCCESS:
            return (
                !showQr && (
                    <FormattedMessage
                        id={getSuccessLabel(
                            checkout.data,
                            checkoutType,
                            paymentType,
                            isPatchingSubscription,
                            allowCardInstallments
                        )}
                    />
                )
            );

        case ProcessState.FAILURE: {
            const { errors = [], code } = (checkout.error?.errorResponse || {}) as ErrorResponse;

            if (code === ResponseErrorCode.ValidationError) {
                return errors.length === 0 ? (
                    <FormattedMessage id={errorLabel(checkout.error, paymentType)} />
                ) : (
                    <ul>
                        {errors.map((error: SubError, key: number) => (
                            <li key={key}>
                                <FormattedMessage id={errorCodeLabel(error.reason)} />
                            </li>
                        ))}
                    </ul>
                );
            }

            const displayError = (checkout.data as ChargeItem)?.error || checkout.error;
            const errorCode = errorLabel(displayError, paymentType);
            const errorHttpCode = getErrorHttpCode(displayError);

            const isClientError =
                errorCode === ResponseErrorCode.UnknownError && displayError?.message && displayError?.code;

            return isClientError ? (
                <>{`${displayError.code}: ${displayError.message}`}</>
            ) : errorHttpCode ? (
                <>
                    {`${errorHttpCode}: `}

                    <FormattedMessage id={errorCode} />
                </>
            ) : (
                <FormattedMessage id={errorCode} />
            );
        }

        default:
            return null;
    }
};
