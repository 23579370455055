import React from "react";
import { useIntl } from "react-intl";
import { SelectField, SelectProps } from "checkout/ts/components/forms/Select";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { Countries } from "common/locale/constants";

type OwnProps = SelectProps & {
    staticCountry?: keyof typeof Countries;
    isDark?: boolean;
};

export const CountrySelect = ({ staticCountry, isDark, ...selectProps }: OwnProps) => {
    const { formatMessage } = useIntl();

    if (staticCountry) {
        return (
            <div className="paidy-address-country">
                <input type="hidden" name="country" value={staticCountry} />

                {formatMessage({ id: Countries[staticCountry].name })}
            </div>
        );
    }

    return (
        <SelectField
            {...selectProps}
            label={formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_COUNTRY })}
            bsSize="sm"
            autoComplete="country"
            name="country"
            isDark={isDark}>
            <option value="">{formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_FIELDS_COUNTRY })}</option>
            <option disabled>---</option>
            {Object.keys(Countries)
                .map((country: string) => ({
                    value: country,
                    name: formatMessage({ id: Countries[country].name }),
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ value, name }) => (
                    <option key={value} value={value}>
                        {name}
                    </option>
                ))}
        </SelectField>
    );
};
