import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { PaymentType } from "univapay-node";

import { StateShape } from "../../../redux/store";
import flowBankTransfer from "../../flows/bank-transfer";
import flowCard from "../../flows/card";
import flowKonbini from "../../flows/konbini";
import flowOnline from "../../flows/online";
import flowPaidy from "../../flows/paidy";

const stateSelector = (state: StateShape) => ({
    paymentType: state.checkout.paymentType,
});

const defaultStep = (paymentType: PaymentType) => {
    switch (paymentType) {
        case PaymentType.KONBINI:
            return flowKonbini;

        case PaymentType.PAIDY:
            return flowPaidy;

        case PaymentType.ONLINE:
            return flowOnline;

        case PaymentType.BANK_TRANSFER:
            return flowBankTransfer;

        default:
            return flowCard;
    }
};

export const StepPaymentData = () => {
    const { path } = useRouteMatch();
    const { paymentType } = useSelector(stateSelector);

    return (
        <Switch>
            <Route path={`${path}/${PaymentType.CARD}/:key`} component={flowCard} />
            <Route path={`${path}/${PaymentType.KONBINI}/:key`} component={flowKonbini} />
            <Route path={`${path}/${PaymentType.PAIDY}/:key`} component={flowPaidy} />
            <Route path={`${path}/${PaymentType.ONLINE}/:key`} component={flowOnline} />
            <Route path={`${path}/${PaymentType.BANK_TRANSFER}/:key`} component={flowBankTransfer} />

            <Route path={path} exact component={defaultStep(paymentType)} />
        </Switch>
    );
};
