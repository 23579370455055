import React from "react";

export const PayPayOnlineIcon = () => (
    <svg width="20" height="18" viewBox="39 39 161 161" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.3333 0 0 -1.3333 -276.85 720.06)">
            <g transform="translate(339.9 510.04)">
                <path
                    d="m0 0h-84.515c-9.438 0-17.194-7.473-17.694-16.792l-0.049-20.759v-64.706c0-9.758 7.983-17.743 17.743-17.743h84.515c9.758 0 17.741 7.984 17.741 17.744v84.513c0 9.76-7.983 17.743-17.741 17.743"
                    fill="#fff"
                />
            </g>
            <g transform="translate(339.9 510.04)">
                <path
                    d="m0 0h-84.515c-9.438 0-17.194-7.473-17.694-16.792 70.754 2 105.17-22.618 99.769-47.162-4.864-22.097-27.315-30.569-63.796-33.35l-5.454-22.696h71.69c9.758 0 17.741 7.984 17.741 17.744v84.513c0 9.76-7.983 17.743-17.741 17.743m-6.715-64.062c2.814 11.463-15.667 22.066-45.261 26.069l-10.745-44.694c24.802-1.174 52.982 6.294 56.006 18.625m-72.174-55.938h-5.626c-9.76 0-17.743 7.985-17.743 17.743v64.706c15.921 1.608 30.774 1.577 43.799 0.307z"
                    fill="#ee293d"
                />
            </g>
        </g>
    </svg>
);
