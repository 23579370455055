(async () => {
    // This is needed for IE (all versions) and Safari <9
    if (!global.Intl) {
        /* webpackChunkName: "polyfill-intl" */
        await Promise.all([
            import("intl"),
            import("intl/locale-data/jsonp/en-US"),
            import("intl/locale-data/jsonp/ja-JP"),
            import("intl/locale-data/jsonp/zh-Hans"),
            import("intl/locale-data/jsonp/ru-RU"),
            import("intl/locale-data/jsonp/th-TH"),
            import("intl/locale-data/jsonp/ko-KR"),
        ]);
    }
})().then(
    () =>
        import(
            /* webpackMode: "eager" */
            "./Main"
        )
);
