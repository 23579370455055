import React from "react";
import { Alert, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { Form, InjectedFormProps, reduxForm } from "redux-form";
import { PaymentType } from "univapay-node";

import { FORM_CODE_CONFIRM_NAME } from "../../../../../common/constants";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { StepTitle } from "../../common/StepTitle";
import { AuthCodeField } from "../../forms/text/AuthCodeField";
import { DialogButton } from "../../layout/DialogButton";
import { ProcessLoader, ProcessState } from "../../ProcessLoader";

import { getSubmitButtonLabel } from "./FormData";

type OwnProps = InjectedFormProps<OwnProps> & {
    paymentType: PaymentType;
};

const stateSelector = (state: StateShape) => {
    const { application, configuration, product } = state;

    const { isSubscription } = getCheckoutParams(application.params, product.products, configuration.data);

    return {
        processState: state.loading.effects.checkout.confirmCode
            ? ProcessState.PENDING
            : state.checkout.error
            ? ProcessState.FAILURE
            : ProcessState.SUCCESS,
        processing: (state.loading.effects.checkout.confirmCode as unknown) as boolean,
        submitButtonText: state.application.params.params.submitButtonText,
        paymentMethodKey: state.checkout.paymentMethodKey,
        confirmed: state.checkout.confirmed,
        isSubscription,
    };
};

export const Content = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    form: FORM_CODE_CONFIRM_NAME,
})(({ handleSubmit, submitFailed, paymentType }: OwnProps) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { push: redirect } = useHistory();
    const {
        checkout: { confirmCode },
    } = useDispatch<Dispatch>();
    const checkoutParams = useCheckoutParams();

    const { confirmed, processing, processState, submitButtonText, paymentMethodKey, isSubscription } = useSelector(
        stateSelector
    );

    if (confirmed) {
        redirect(getStepUrl(paymentType, paymentMethodKey, StepName.CONFIRM));
    }

    const handleSubmitForm = (values) => confirmCode({ code: values.code });

    return (
        <Col>
            <StepTitle>{formatMessage({ id: LOCALE_LABELS.FORM_CONFIRMATION_TITLE })}</StepTitle>

            <Form noValidate onSubmit={handleSubmit(handleSubmitForm)} autoComplete="off content-form">
                <div>
                    <AuthCodeField name="code" disabled={processing} error={submitFailed} />

                    {processing ? (
                        <ProcessLoader state={processState} />
                    ) : (
                        <Alert bsStyle="info">{formatMessage({ id: LOCALE_LABELS.FORM_CONFIRMATION_CODE })}</Alert>
                    )}
                </div>

                <DialogButton disabled={processing}>
                    {submitButtonText || getSubmitButtonLabel(checkoutParams, isSubscription, intl)}
                </DialogButton>
            </Form>
        </Col>
    );
});

export default Content;
