export const Regexes = {
    Name: new RegExp(
        "^[" +
            // Whitespace (English, half-width and full-width romaji)
            "\\u0020\\u3000" +
            // Hyphen and underscore
            "\\u002D\\u005F\\uFF0D" +
            // Quotes
            "\\u0027\\uFF07" +
            // Period
            "\\u002E\\uFF0E\\uFF61\\u3002" +
            // Hankaku Symbols
            "\\(\\),\\.\\-\\/_'&" +
            // Zenkaku Symbols
            "\\uFF08\\uFF09\\uFF0C\\uFF0F\\uFFE5\\uFF06\\uFF0C\\u2019\\u3001" +
            // Digits (English and Japanese romaji digits)
            "0-9\\uFF10-\\uFF19" +
            // Uppercase letters (English, half-width and full-width romaji)
            "A-Z\\uFF21-\\uFF3A" +
            // Lowercase letters (English, half-width and full-width romaji)
            "a-z\\uFF41-\\uFF5A" +
            // Hiragana
            "\\u3041-\\u3096\\u309D-\\u309F" +
            // Katakana
            "\\u30A1-\\u30FA\\u30FC-\\u30FF\\u31F0-\\u31FF\\u32D0-\\u32FE\\u3300-\\u3357\\uFF66-\\uFF6F\\uFF71-\\uFF9D" +
            // Hankaku Katakana
            "\\uFF65-\\uFF9F" +
            // Kanji
            "\\u2E80-\\u2E99\\u2E9B-\\u2EF3\\u2F00-\\u2FD5\\u3005\\u3007\\u3021-\\u3029\\u3038-\\u303B\\u3400-\\u4DB5\\u4E00-\\u9FEA\\uF900-\\uFA6D\\uFA70-\\uFAD9" +
            // Nakaguro
            "\\u30FB\\uFF65" +
            // Punctuation
            "\\u300C-\\u300F\\uFF62\\uFF63\\uFF07" +
            "]+$"
    ),
    Katakana: new RegExp(
        // Katakana
        "^[\\u30A1-\\u30FA\\u30FC-\\u30FF\\u31F0-\\u31FF\\u32D0-\\u32FE\\u3300-\\u3357\\uFF66-\\uFF6F\\uFF71-\\uFF9D]+$"
    ),
    JapaneseZipCode: new RegExp(/^\d{3}-\d{4}$/),
};
