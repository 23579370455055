import { CURRENCIES_DECIMALS } from "../../../common/constants";

export const amountForCurrency = (amount: number, currency: string): number => {
    if (!currency) {
        return amount;
    }

    let decimal = 2;
    for (const d in CURRENCIES_DECIMALS) {
        if (d in CURRENCIES_DECIMALS && CURRENCIES_DECIMALS[d].indexOf(currency.toLocaleLowerCase()) !== -1) {
            decimal = Number(d);
            break;
        }
    }

    return amount / Math.pow(10, decimal);
};
