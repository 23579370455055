import React from "react";
import { Component, HTMLProps } from "react";
import TextMask from "react-text-mask";
import { fullWidthToHalfWidth } from "checkout/ts/utils/numbers";

export const SEPARATOR = "-";
const DATE_OF_BIRTH_MASK: (string | RegExp)[] = [
    /[1-9１-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    SEPARATOR,
    /[0-9０-９]/,
    /[0-9０-９]/,
    SEPARATOR,
    /[0-9０-９]/,
    /[0-9０-９]/,
];

export type DateOfBirthInputProps = Omit<HTMLProps<React.InputHTMLAttributes<string>>, "onChange"> & {
    onChange?: (value: string) => void;
};

export class DateOfBirthInput extends Component<DateOfBirthInputProps> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: eventValue } = event.target as HTMLInputElement;
        const formattedValue = fullWidthToHalfWidth(eventValue);

        this.props.onChange?.(formattedValue);
    };

    render() {
        const { value = "", ...props } = this.props;

        return (
            <TextMask
                {...props}
                autoComplete="dob"
                type="tel"
                pattern="[0-9０-９]*"
                inputMode="numeric"
                guide={false}
                keepCharPositions={false}
                mask={DATE_OF_BIRTH_MASK}
                placeholderChar={"\u2000"}
                value={fullWidthToHalfWidth(String(value))}
            />
        );
    }
}
