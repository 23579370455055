import { PatchedCheckoutInfo } from "checkout/ts/redux/models/configuration";
import { CheckoutParams } from "common/types";
import { PaymentType } from "univapay-node";

/**
 * Legacy support for old `address` parameter
 */
export const getRequiredFields = (
    paymentType: PaymentType,
    params: Partial<CheckoutParams>,
    checkoutInfo?: PatchedCheckoutInfo
) => {
    const LEGACY_REQUIRE_EMAIL = [PaymentType.ONLINE];
    const LEGACY_REQUIRE_BILLING = [PaymentType.CARD, PaymentType.PAIDY];
    const LEGACY_REQUIRE_PHONE = [PaymentType.KONBINI];
    const {
        address = false,
        requireEmail = false,
        requireBillingAddress = false,
        requirePhoneNumber = false,
        requireName = false,
        requireNameKana = false,

        shippingAddressZip,
        shippingAddressCity,
        shippingAddressCountryCode,
        shippingAddressLine1,
        shippingAddressState,
        phoneNumber: prefilledPhoneNumber,
    } = params;

    const threeDsAddressRequired =
        checkoutInfo?.cardConfiguration?.threeDsRequired && checkoutInfo?.cardConfiguration?.threeDsAddressRequired;

    const hasPrefilledAddress =
        shippingAddressCity &&
        shippingAddressCountryCode &&
        shippingAddressLine1 &&
        shippingAddressState &&
        shippingAddressZip;

    const forceEmail = paymentType === PaymentType.CARD || paymentType === PaymentType.BANK_TRANSFER;
    const forceBilling =
        (paymentType === PaymentType.CARD && threeDsAddressRequired && !hasPrefilledAddress) ||
        (paymentType === PaymentType.PAIDY && (!shippingAddressZip || !shippingAddressCity));
    const forcePhoneNumber =
        paymentType === PaymentType.BANK_TRANSFER ||
        (paymentType === PaymentType.CARD && threeDsAddressRequired && !prefilledPhoneNumber);
    const forceName =
        paymentType === PaymentType.BANK_TRANSFER ||
        paymentType === PaymentType.PAIDY ||
        paymentType === PaymentType.KONBINI;
    const forceNameKana = paymentType === PaymentType.PAIDY;

    return {
        requireEmail: requireEmail || (address && LEGACY_REQUIRE_EMAIL.includes(paymentType)) || forceEmail,
        requirePhoneNumber:
            forcePhoneNumber || requirePhoneNumber || (address && LEGACY_REQUIRE_PHONE.includes(paymentType)),
        requireBillingAddress:
            requireBillingAddress || (address && LEGACY_REQUIRE_BILLING.includes(paymentType)) || forceBilling,
        requireName: requireName || forceName,
        requireNameKana: requireNameKana || forceNameKana,
    };
};
