export function hasOwnProperties(obj: any, properties: string[]): boolean {
    return properties.reduce((has: boolean, property: string) => {
        return !has ? false : property in obj;
    }, true);
}

export function omitKeys(obj: any, keys: string[]): any {
    return Object.keys(obj)
        .filter((k: string) => keys.indexOf(k) === -1)
        .reduce((r: any, k: string) => {
            r[k] = obj[k];
            return r;
        }, {});
}

export function objectPath<R = any>(obj: any, path = "", defaultValue: R = null): R {
    if (!obj) {
        return defaultValue;
    }
    const keys: string[] = path
        .replace(/\[(\w+)\]/g, ".$1")
        .replace(/^\./, "")
        .split(".");
    let record: any = { ...obj };
    for (let i = 0, l: number = keys.length; i < l; i++) {
        if (keys[i] in record) {
            record = record[keys[i]];
        } else {
            return defaultValue;
        }
    }
    return record;
}

export function sortByValues(obj: any, locale?: string): any {
    const sortable: string[][] = Object.keys(obj).map((k: string) => [k, obj[k]]);

    sortable.sort((a: string[], b: string[]) => a[1].localeCompare(b[1], locale));

    return sortable.reduce((r: any, c: string[]) => {
        r[c[0]] = c[1];
        return r;
    }, {});
}
