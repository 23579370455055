import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { getConvenienceStoreLabel } from "checkout/ts/locale/selectors";
import { concatPhoneNumber, parsePhone } from "checkout/ts/redux/utils/intl";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { isDarkTheme } from "checkout/ts/utils/colors";
import { compose } from "recompose";
import { Form, FormSection, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import {
    ConvenienceStore,
    PhoneNumber,
    TransactionTokenConvenienceData,
    TransactionTokenConvenienceDataItem,
    TransactionTokenType,
} from "univapay-node";

import { FORM_CHECKOUT_NAME, FORM_TOKEN_SELECT_NAME } from "../../../../../common/constants";
import { omitKeys } from "../../../../../common/utils/object";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { validationToErrors } from "../../../utils/errors";
import { amountForCurrency } from "../../../utils/money";
import { FormCheckoutData } from "../../checkout/FormCheckout";
import { StepTitle } from "../../common/StepTitle";
import { SelectField } from "../../forms/Select";
import { AcceptField, Button, PrivacyLink } from "../common/FormData";

import { FormDataKonbini } from ".";

export type KonbiniData = TransactionTokenConvenienceData & {
    phoneNumber?: PhoneNumber;
    convenienceStore?: string;
    accept?: boolean;
};

export type SavedTokenData = {
    token?: string;
};

export type FullKonbiniData = KonbiniData & SavedTokenData;

export interface FormDataKonbiniData<Data = FullKonbiniData> {
    email: string;
    data: Data;
}

const stateSelector = (state: StateShape) => {
    const {
        email,
        univapayCustomerId,
        phoneNumber,
        hideRecurringCheckbox,
        hidePrivacyLink,
    } = state.application.params.params;
    const { tokenType, isSubscription } = getCheckoutParams(
        state.application.params,
        state.product.products,
        state.configuration.data
    );

    const customerName = state.userData.name;
    const forceRecurringToken = tokenType === TransactionTokenType.RECURRING;

    return {
        brands:
            state.configuration.paymentMethods.find(({ key }) => key === state.checkout.paymentMethodKey)?.brands || [],
        tokens: state.tokens.tokens,
        selectedTokenId: formValueSelector(FORM_TOKEN_SELECT_NAME)(state, "selectedTokenId"),
        initialValues: {
            email: state.userData.email || email,
            data: {
                phoneNumber: state.userData.phoneNumber?.localNumber
                    ? state.userData.phoneNumber
                    : parsePhone(phoneNumber),
                customerName,
            },
        },
        darkTheme: isDarkTheme(state),
        isAcceptRequired: !hideRecurringCheckbox && forceRecurringToken,
        isAcceptVisible: !hideRecurringCheckbox && (forceRecurringToken || !!univapayCustomerId),
        isSubscription,
        hidePrivacyLink,
    };
};

type OwnProps = ReturnType<typeof stateSelector> & InjectedFormProps<FormDataKonbiniData>;

export const validate = (values: Partial<FormDataKonbiniData>, props: OwnProps) => {
    const { isAcceptRequired } = props;

    const { data } = values;
    const { convenienceStore, accept } = data || {};

    return validationToErrors({
        "data.convenienceStore": { [LOCALE_LABELS.VALIDATION_REQUIRED]: !convenienceStore },
        "data.accept": { [LOCALE_LABELS.VALIDATION_REQUIRED]: isAcceptRequired && !accept },
    });
};

const normalizeValues = (raw: FormDataKonbini<KonbiniData>, token?: string): FormDataKonbini<FullKonbiniData> => {
    const { data, email, ...values } = raw;
    const formattedData: FullKonbiniData = token ? { token: token, ...data } : omitKeys(data, ["accept"]);

    return { ...values, email: email || undefined, data: formattedData };
};

const Content = compose<OwnProps, OwnProps>(
    connect(stateSelector),
    reduxForm({ form: FORM_CHECKOUT_NAME, validate, destroyOnUnmount: false, forceUnregisterOnUnmount: true })
)(
    ({
        handleSubmit,
        tokens: { tokens },
        selectedTokenId,
        darkTheme,
        brands,
        isSubscription,
        isAcceptVisible,
        hidePrivacyLink,
    }) => {
        const {
            currency,
            subscriptionParams: { initialAmount: subscriptionInitialAmount },
        } = useCheckoutParams();

        const { formatMessage, formatNumber } = useIntl();

        const {
            checkout: { process: processCheckout },
        } = useDispatch<Dispatch>();

        const handleSubmitForm = (values: FormDataKonbini<KonbiniData>) => {
            const normalizedValues = normalizeValues(values, selectedTokenId) as FormCheckoutData;

            processCheckout({ values: normalizedValues });
        };

        const createConvenienceStoreOption = (value: ConvenienceStore) => (
            <option key={value} value={value}>
                {formatMessage({ id: getConvenienceStoreLabel(value) })}
            </option>
        );

        const selectedToken = !!selectedTokenId && tokens[selectedTokenId];

        const selectedTokenLabel = (() => {
            if (!selectedToken?.data) {
                return undefined;
            }

            const { phoneNumber, convenienceStore } = selectedToken.data as TransactionTokenConvenienceDataItem;
            const phoneLabel = concatPhoneNumber(phoneNumber);
            const konbiniLabel = formatMessage({ id: getConvenienceStoreLabel(convenienceStore) });

            return `${phoneLabel} (${konbiniLabel})`;
        })();

        return (
            <Form noValidate onSubmit={handleSubmit(handleSubmitForm)} className="konbini data content-form">
                <Row>
                    <Col xs={12}>
                        <StepTitle>{formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_KONBINI_TITLE })}</StepTitle>
                    </Col>

                    {selectedToken && (
                        <Col xs={12} className="konbini-token">
                            <div>{selectedTokenLabel}</div>
                        </Col>
                    )}

                    {!selectedToken && (
                        <>
                            <Col xs={12}>
                                <FormSection name="data">
                                    <SelectField
                                        label={formatMessage({ id: LOCALE_LABELS.PAYMENT_DATA_KONBINI_PAYMENT_SELECT })}
                                        name="convenienceStore"
                                        tabIndex={5}
                                        required
                                        isDark={darkTheme}>
                                        <option disabled value="">
                                            {formatMessage({
                                                id: LOCALE_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT,
                                            })}
                                        </option>

                                        {brands.map(createConvenienceStoreOption)}
                                    </SelectField>
                                </FormSection>
                            </Col>

                            {isAcceptVisible && (
                                <FormSection name="data">
                                    <AcceptField isDark={darkTheme} />
                                </FormSection>
                            )}
                        </>
                    )}

                    {isSubscription &&
                        !!subscriptionInitialAmount &&
                        formatMessage(
                            { id: LOCALE_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT },
                            {
                                money: formatNumber(amountForCurrency(subscriptionInitialAmount, currency), {
                                    style: "currency",
                                    currency,
                                }),
                            }
                        )}
                </Row>

                {!hidePrivacyLink && <PrivacyLink isDark={darkTheme} />}

                <Button />
            </Form>
        );
    }
);

export default Content;
