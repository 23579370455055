export const ERRORS_TITLE = "ERRORS_ALERTS_TITLE";

export const ERRORS_ALERTS_CONNECTOR = "ERRORS_ALERTS_CONNECTOR";
export const ERRORS_ALERTS_APPLICATION_TIMEOUT = "ERRORS_ALERTS_APPLICATION_TIMEOUT";
export const ERRORS_ALERTS_TIMEOUT = "ERRORS_ALERTS_TIMEOUT";
export const ERRORS_ALERTS_SESSION_TIMEOUT = "ERRORS_ALERTS_SESSION_TIMEOUT";
export const ERRORS_ALERTS_PARAMETERS = "ERRORS_ALERTS_PARAMETERS";
export const ERRORS_ALERTS_UNKNOWN = "ERRORS_ALERTS_UNKNOWN";
export const ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE = "ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE";
export const ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE = "ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE";
export const ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE = "ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE";
export const ERRORS_ALERTS_PRIVILEGES = "ERRORS_ALERTS_PRIVILEGES";
export const ERRORS_ALERTS_USAGE = "ERRORS_ALERTS_USAGE";
export const ERRORS_ALERTS_FORBIDDEN_CARD = "ERRORS_ALERTS_FORBIDDEN_CARD";
export const ERRORS_ALERTS_DOMAIN = "ERRORS_ALERTS_DOMAIN";
export const ERRORS_ALERTS_TOKEN_FAILED = "ERRORS_ALERTS_TOKEN_FAILED";
export const ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED = "ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED";
export const ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT = "ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT";

export const ERRORS_ALERTS_SERVICE_UNAVAILABLE = "ERRORS_ALERTS_SERVICE_UNAVAILABLE";
export const ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO = "ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO";

export const ERRORS_ALERTS_CAPTURE_CREDIT_ONLY = "ERRORS_ALERTS_CAPTURE_CREDIT_ONLY";
export const PROCESSING_ERROR = "PROCESSING_ERROR";
export const ERROR_ALERTS_PAIDY_PAYMENT_REJECTED = "ERROR_ALERTS_PAIDY_PAYMENT_REJECTED";
export const EMAIL_EXISTS_FOR_CARD = "EMAIL_EXISTS_FOR_CARD";
export const SERVICE_RESTRICTED = "SERVICE_RESTRICTED";
export const EXPIRATION_DATE_ERROR = "EXPIRATION_DATE_ERROR";

export { ERROR_CHECKOUT_CHARGE } from "../../errors/ChargeError";
export { ERROR_CHECKOUT_SUBSCRIPTION } from "../../errors/SubscriptionError";
export { ERROR_CHECKOUT_PARAMS } from "../../errors/CheckoutParametersError";
