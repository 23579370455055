import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardUnknown = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="unknown-card-icon" viewBox="0 0 780 501" {...props}>
        <g transform="translate(-1 1)">
            <rect width="100%" height="100%" fill="#101f72" rx="55" transform="translate(1 -1)" />
            <path
                fill="#e5aa3b"
                d="M81 103h90a30 30 0 0 1 30 30v60a30 30 0 0 1-30 30H81a30 30 0 0 1-30-30v-60a30 30 0 0 1 30-30z"
            />
            <g fill="#fff">
                <path d="M401 427.5H61a9.5 9.5 0 0 1-9.5-9.5v-20c0-5.2 4.3-9.5 9.5-9.5h340c5.2 0 9.5 4.3 9.5 9.5v20c0 5.2-4.3 9.5-9.5 9.5zm320-85H601a9.5 9.5 0 0 1-9.5-9.5v-30c0-5.2 4.3-9.5 9.5-9.5h120c5.2 0 9.5 4.3 9.5 9.5v30c0 5.2-4.3 9.5-9.5 9.5zm-180 0H421a9.5 9.5 0 0 1-9.5-9.5v-30c0-5.2 4.3-9.5 9.5-9.5h120c5.2 0 9.5 4.3 9.5 9.5v30c0 5.2-4.3 9.5-9.5 9.5zm-180 0H241a9.5 9.5 0 0 1-9.5-9.5v-30c0-5.2 4.3-9.5 9.5-9.5h120c5.2 0 9.5 4.3 9.5 9.5v30c0 5.2-4.3 9.5-9.5 9.5zm-180 0H61a9.5 9.5 0 0 1-9.5-9.5v-30c0-5.2 4.3-9.5 9.5-9.5h120c5.2 0 9.5 4.3 9.5 9.5v30c0 5.2-4.3 9.5-9.5 9.5z" />
                <path
                    fill="#707070"
                    d="M401 427c5 0 9-4 9-9v-20c0-5-4-9-9-9H61c-5 0-9 4-9 9v20c0 5 4 9 9 9h340m320-85c5 0 9-4 9-9v-30c0-5-4-9-9-9H601c-5 0-9 4-9 9v30c0 5 4 9 9 9h120m-180 0c5 0 9-4 9-9v-30c0-5-4-9-9-9H421c-5 0-9 4-9 9v30c0 5 4 9 9 9h120m-180 0c5 0 9-4 9-9v-30c0-5-4-9-9-9H241c-5 0-9 4-9 9v30c0 5 4 9 9 9h120m-180 0c5 0 9-4 9-9v-30c0-5-4-9-9-9H61c-5 0-9 4-9 9v30c0 5 4 9 9 9h120m220 86H61a10 10 0 0 1-10-10v-20a10 10 0 0 1 10-10h340a10 10 0 0 1 10 10v20a10 10 0 0 1-10 10zm320-85H601a10 10 0 0 1-10-10v-30a10 10 0 0 1 10-10h120a10 10 0 0 1 10 10v30a10 10 0 0 1-10 10zm-180 0H421a10 10 0 0 1-10-10v-30a10 10 0 0 1 10-10h120a10 10 0 0 1 10 10v30a10 10 0 0 1-10 10zm-180 0H241a10 10 0 0 1-10-10v-30a10 10 0 0 1 10-10h120a10 10 0 0 1 10 10v30a10 10 0 0 1-10 10zm-180 0H61a10 10 0 0 1-10-10v-30a10 10 0 0 1 10-10h120a10 10 0 0 1 10 10v30a10 10 0 0 1-10 10z"
                />
            </g>
        </g>
    </Icon>
);

export default CardUnknown;
