import React from "react";
import { Component, HTMLProps } from "react";
import TextMask from "react-text-mask";
import { fullWidthToHalfWidth } from "checkout/ts/utils/numbers";
import Payment from "payment";
import { CardBrand } from "univapay-node";

import { cardBrandAPIName } from "../../../utils/cards";

const CARD_NUMBER_BASE_MASK: (string | RegExp)[] = [/[1-9１-９]/, /[0-9０-９]/, /[0-9０-９]/, /[0-9０-９]/, " "];

const CARD_NUMBER_GENERIC_MASK = CARD_NUMBER_BASE_MASK.concat([
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    " ",
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    " ",
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
]);

const CARD_NUMBER_OTHER_MASK = CARD_NUMBER_BASE_MASK.concat([
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    " ",
]);

const CARD_NUMBER_AMEX_MASK = CARD_NUMBER_OTHER_MASK.concat([
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
    /[0-9０-９]/,
]);

const CARD_NUMBER_DINERS_MASK = CARD_NUMBER_OTHER_MASK.concat([/[0-9０-９]/, /[0-9０-９]/, /[0-9０-９]/, /[0-9０-９]/]);

const masks = {
    [CardBrand.AMEX]: CARD_NUMBER_AMEX_MASK,
    [CardBrand.DINERS]: CARD_NUMBER_DINERS_MASK,
};

export type CardNumberInputProps = Omit<HTMLProps<React.InputHTMLAttributes<string>>, "onChange"> & {
    onChange?: (value: string) => void;
};

export class CardNumberInput extends Component<CardNumberInputProps, any> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: eventValue } = event.target as HTMLInputElement;
        const formattedValue = fullWidthToHalfWidth(eventValue);
        this.props.onChange?.(formattedValue);
    };

    render() {
        const { value = "", ...props } = this.props;
        const brand = cardBrandAPIName(Payment.fns.cardType(String(value))) || "unknown";
        const mask = masks[brand] || CARD_NUMBER_GENERIC_MASK;

        return (
            <TextMask
                {...props}
                onChange={this.handleChange}
                data-brand={brand}
                autoComplete="cc-number"
                type="tel"
                pattern="[0-9０-９]*"
                inputMode="numeric"
                guide={false}
                keepCharPositions={false}
                mask={mask}
                placeholderChar={"\u2000"}
                value={fullWidthToHalfWidth(String(value))}
            />
        );
    }
}
