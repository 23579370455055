import { CardBrand, ConvenienceStore } from "univapay-node";

import { LOCALE_LABELS } from "./labels";

export const getConvenienceStoreLabel = (convenienceStore: ConvenienceStore) => {
    switch (convenienceStore) {
        case ConvenienceStore.CIRCLE_K:
            return LOCALE_LABELS.CONVENIENCE_STORE_CIRCLE_K;

        case ConvenienceStore.DAILY_YAMAZAKI:
            return LOCALE_LABELS.CONVENIENCE_STORE_DAILY_YAMAZAKI;

        case ConvenienceStore.FAMILY_MART:
            return LOCALE_LABELS.CONVENIENCE_STORE_FAMILY_MART;

        case ConvenienceStore.LAWSON:
            return LOCALE_LABELS.CONVENIENCE_STORE_LAWSON;

        case ConvenienceStore.MINI_STOP:
            return LOCALE_LABELS.CONVENIENCE_STORE_MINI_STOP;

        case ConvenienceStore.PAY_EASY:
            return LOCALE_LABELS.CONVENIENCE_STORE_PAY_EASY;

        case ConvenienceStore.SEICO_MART:
            return LOCALE_LABELS.CONVENIENCE_STORE_SEICO_MART;

        case ConvenienceStore.SEVEN_ELEVEN:
            return LOCALE_LABELS.CONVENIENCE_STORE_SEVEN_ELEVEN;

        case ConvenienceStore.SUNKUS:
            return LOCALE_LABELS.CONVENIENCE_STORE_SUNKUS;

        case ConvenienceStore.YAMAZAKI_DAILY_STORE:
            return LOCALE_LABELS.CONVENIENCE_STORE_YAMAZAKI_DAILY_STORE;

        default:
            return LOCALE_LABELS.GLOBAL_UNKNOWN;
    }
};

export const getCardLabel = (brand: CardBrand) => {
    switch (brand) {
        case CardBrand.VISA:
            return LOCALE_LABELS.CARD_VISA;

        case CardBrand.AMEX:
            return LOCALE_LABELS.CARD_AMEX;

        case CardBrand.MASTERCARD:
            return LOCALE_LABELS.CARD_MASTERCARD;

        case CardBrand.MAESTRO:
            return LOCALE_LABELS.CARD_MAESTRO;

        case CardBrand.DISCOVER:
            return LOCALE_LABELS.CARD_DISCOVER;

        case CardBrand.JCB:
            return LOCALE_LABELS.CARD_JCB;

        case CardBrand.DINERS:
            return LOCALE_LABELS.CARD_DINERS;

        case CardBrand.UNIONPAY:
            return LOCALE_LABELS.CARD_UNIONPAY;

        default:
            return LOCALE_LABELS.GLOBAL_UNKNOWN;
    }
};
