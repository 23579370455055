import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardDiscover = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="discover-card-icon" viewBox="0 0 780 500" {...props}>
        <defs>
            <linearGradient
                id="linear-gradient"
                x1="440.25"
                y1="-1.81"
                x2="398.61"
                y2="-66.99"
                gradientTransform="translate(-0.79 260)"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#f89f20" />
                <stop offset="0.25" stopColor="#f79a20" />
                <stop offset="0.53" stopColor="#f68d20" />
                <stop offset="0.62" stopColor="#f58720" />
                <stop offset="0.72" stopColor="#f48120" />
                <stop offset="1" stopColor="#f37521" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-2"
                x1="433.68"
                y1="-2.68"
                x2="372.82"
                y2="-121.63"
                gradientTransform="translate(-0.79 260)"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#f58720" />
                <stop offset="0.36" stopColor="#e16f27" />
                <stop offset="0.7" stopColor="#d4602c" />
                <stop offset="0.98" stopColor="#d05b2e" />
            </linearGradient>
        </defs>
        <polygon points="2.67 3.08 777.13 3.08 777.13 497.12 2.87 497.12 2.67 3.08" fill="#fff" />
        <path d="M775.24,277.87S563.62,427.4,176.08,494.45H775.24Z" transform="translate(-0.79)" fill="#f48120" />
        <path
            d="M777.91.21H.79V500h780V0h-2.88Zm-2.67,5.55V494.24H6.53V5.76Z"
            transform="translate(-0.79)"
            fill="#231f20"
        />
        <path
            d="M68.93,153H36.71V266.15H68.93c17,0,29.36-4.12,40.23-13a56.61,56.61,0,0,0,20.53-43.4C129.49,176.26,104.65,153,68.93,153ZM94.8,238.17c-7,6.17-15.81,9.05-30.18,9.05h-6V172.36h6c14.17,0,22.79,2.47,30.18,9,7.59,6.78,12.11,17.27,12.11,28.17S102.39,231.39,94.8,238.17Z"
            transform="translate(-0.79)"
            fill="#231f20"
        />
        <rect x="138.96" y="153.02" width="21.96" height="113.12" fill="#231f20" />
        <path
            d="M215.49,196.42c-13.14-4.94-17-8.23-17-14.19,0-7.2,7-12.55,16.43-12.55,6.56,0,12.11,2.68,17.65,9.26l11.49-15a49.6,49.6,0,0,0-33.25-12.54c-19.91,0-35.31,14-35.31,32.49,0,15.64,7.19,23.66,27.72,31.06,8.62,3.09,12.93,5.14,15.18,6.38A13.74,13.74,0,0,1,224.93,233c0,9.26-7.18,16-17,16-10.47,0-18.89-5.34-24-15l-14.16,13.78c10.06,15,22.37,21.6,39,21.6,22.78,0,39-15.22,39-37.23C248.13,213.9,240.53,205.68,215.49,196.42Z"
            transform="translate(-0.79)"
            fill="#231f20"
        />
        <path
            d="M254.9,209.79c0,33.32,26.07,59,59.53,59,9.44,0,17.65-1.85,27.5-6.58V236.32c-8.82,8.85-16.62,12.34-26.48,12.34-22.16,0-38-16-38-39.08,0-21.8,16.22-38.87,36.95-38.87,10.47,0,18.47,3.7,27.5,12.75V157.55c-9.64-4.94-17.65-7-27.09-7C281.79,150.56,254.9,176.88,254.9,209.79Z"
            transform="translate(-0.79)"
            fill="#231f20"
        />
        <polygon
            points="516.03 229.12 485.86 153.02 461.84 153.02 509.67 269.02 521.57 269.02 570.22 153.02 546.41 153.02 516.03 229.12"
            fill="#231f20"
        />
        <polygon
            points="580.28 266.15 642.68 266.15 642.68 247.02 602.24 247.02 602.24 216.58 641.24 216.58 641.24 197.45 602.24 197.45 602.24 172.36 642.68 172.36 642.68 153.02 580.28 153.02 580.28 266.15"
            fill="#231f20"
        />
        <path
            d="M730.7,186.55c0-21.19-14.57-33.32-40-33.32H658V266.35h22V220.9h2.88l30.37,45.45h27.1l-35.51-47.72C721.46,215.14,730.7,203.83,730.7,186.55Zm-44.34,18.72H680V170.92h6.78c13.75,0,21.14,5.76,21.14,16.86C707.92,199.1,700.53,205.27,686.36,205.27Z"
            transform="translate(-0.79)"
            fill="#231f20"
        />
        <ellipse cx="408.68" cy="210" rx="60.14" ry="60.26" fill="url(#linear-gradient)" />
        <ellipse
            cx="408.68"
            cy="210"
            rx="60.14"
            ry="60.26"
            style={{ isolation: "isolate" }}
            opacity="0.6499999761581421"
            fill="url(#linear-gradient-2)"
        />
        <path
            d="M744.66,157.34c0-2.05-1.44-3.08-3.7-3.08h-3.28v9.87h2.46v-3.91l2.88,3.91h2.87L742.61,160A2.52,2.52,0,0,0,744.66,157.34Zm-4.11,1.44h-.41v-2.67h.41c1.24,0,1.85.41,1.85,1.23C742.4,158.37,741.79,158.78,740.55,158.78Z"
            transform="translate(-0.79)"
            fill="#231f20"
        />
        <path
            d="M741.38,150.56a8.64,8.64,0,1,0,8.62,8.63A8.68,8.68,0,0,0,741.38,150.56Zm0,15.83a7.21,7.21,0,1,1,6.77-7.2A7.1,7.1,0,0,1,741.38,166.39Z"
            transform="translate(-0.79)"
            fill="#231f20"
        />
    </Icon>
);

export default CardDiscover;
