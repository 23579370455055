import React from "react";
import Icon, { IconBaseProps } from "react-icon-base";

const CardDiners = (props: Omit<IconBaseProps, "children" | "ref">) => (
    <Icon data-name="diners-card-icon" viewBox="0 0 780 500" {...props}>
        <path
            d="M775.57,451.76c0,24.81-23.42,44.81-52.33,44.81H56.57c-28.91,0-52.33-20-52.33-44.81V48.24c0-24.81,23.42-44.81,52.33-44.81H723.24c28.91,0,52.33,20,52.33,44.81V451.76Z"
            transform="translate(0.09 0.36)"
            fill="#fff"
        />
        <path
            d="M8.87,48.24V451.51c0,22.53,21.39,40.75,47.7,40.75H723.24c26.6,0,47.7-18.48,47.7-40.75V48.24c0-22.53-21.39-40.76-47.7-40.76H56.57c-26.31,0-47.7,18.23-47.7,40.76m47.7,452.12c-31.22,0-56.66-21.77-56.66-48.6V48.24c0-26.83,25.44-48.6,56.66-48.6H723.24c31.23,0,56.67,21.77,56.67,48.6V451.51c0,26.83-25.44,48.6-56.67,48.6l-666.67.25Z"
            transform="translate(0.09 0.36)"
            fill="#0069aa"
        />
        <path
            d="M113.37,359.2c0,9.35,7.08,10.62,13.38,10.62,27.55,0,36.47-20,36.47-38.17,0-23-15.22-39.44-39.88-39.44a79.8,79.8,0,0,0-10,.51Zm-12.6-57.38c0-11.12-6-10.36-11.8-10.36v-3.29c5,.25,10,.25,15,.25,5.25,0,12.6-.25,22-.25,33.06,0,50.9,21.23,50.9,43,0,12.14-7.35,42.72-52.47,42.72-6.56,0-12.6-.25-18.37-.25s-11.28,0-17,.25v-3.28c7.61-.76,11.28-1,11.8-9.36v-59.4Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M195.23,298.79a6.46,6.46,0,0,1-6.3-6.07c0-3,3.15-5.81,6.3-5.81a6.05,6.05,0,0,1,6.29,5.81,6.27,6.27,0,0,1-6.29,6.07m-13.65,71.79H184c3.67,0,6,0,6-4.05V333.16c0-5.3-1.84-6.06-6.56-8.59v-2c6-1.77,13.12-4.05,13.64-4.3a4.09,4.09,0,0,1,2.37-.5c.52,0,.78.75.78,1.76v47c0,4.05,2.89,4.05,6.3,4.05h2.1v3.28c-4.46,0-8.92-.25-13.38-.25s-9.19,0-13.91.25v-3.28Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M220.94,333.92c0-4.55-1.31-5.81-7.35-8.09v-2.27c5.51-1.77,10.76-3.29,16.8-5.81.26,0,.78.25.78,1.26v8.09c7.35-5.06,13.65-9.1,22-9.1,10.76,0,14.69,7.58,14.69,17.19v31.34c0,4.05,2.89,4.05,6.3,4.05h2.36v3.28c-4.46,0-8.92-.25-13.38-.25s-9.18,0-13.64.25v-3.28h2.36c3.67,0,6,0,6-4.05V334.68c0-7.08-4.46-10.36-11.8-10.36-4.2,0-10.5,3.28-14.7,5.81v36.4c0,4.05,2.89,4.05,6.3,4.05h2.36v3.28c-4.46,0-8.92-.25-13.38-.25s-9.18,0-13.64.25v-3.28h2.36c3.67,0,6,0,6-4.05V333.92Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M308.83,336c3.15,0,3.68-1.52,3.68-3,0-6.32-3.94-11.37-11-11.37-7.87,0-13.12,5.56-14.7,14.41Zm-22.56,4c-.26,1-.26,3,0,7.08.79,11.63,8.66,21.23,18.63,21.23,7.08,0,12.59-3.79,17.32-8.09l1.83,1.77c-5.77,7.59-13.12,13.9-23.61,13.9-20.2,0-24.4-18.95-24.4-26.79,0-24,16.79-31.09,25.71-31.09,10.23,0,21.51,6.32,21.51,19.21v2.27l-1,.76-36-.25Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M326.15,370.58h3.41c3.67,0,6,0,6-4.05V332.15c0-3.79-4.73-4.55-6.56-5.56v-1.77c9.18-3.79,14.43-7.07,15.48-7.07.78,0,1.05.25,1.05,1.51v11.12h.26c3.15-4.8,8.66-12.63,16.27-12.63,3.14,0,7.34,2,7.34,6.57a6.09,6.09,0,0,1-6,6.32c-3.94,0-3.94-3-8.4-3-2.1,0-9.18,2.78-9.18,10.11v28.82c0,4.05,2.62,4.05,6,4.05H359v3.28c-7.09-.25-12.33-.25-17.84-.25-5.25,0-10.5,0-15.22.25v-3.28Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M375.21,356.67c1.58,8.09,6.83,14.92,16,14.92,7.61,0,10.23-4.55,10.23-8.6,0-14.41-27.81-9.86-27.81-29.57,0-6.83,5.77-15.67,19.68-15.67a32.82,32.82,0,0,1,14.43,3.53l.79,12.39h-2.89c-1.31-7.84-5.77-12.13-13.91-12.13-5,0-10,2.78-10,8.09,0,14.4,29.65,9.85,29.65,29.32,0,8.09-6.82,16.68-22,16.68a33.87,33.87,0,0,1-15.48-4.3l-1.31-14.15Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M526.86,310.41h-3.14c-2.37-14.4-13.12-20.22-27.29-20.22-14.69,0-35.95,9.36-35.95,38.93,0,24.77,18.37,42.72,38,42.72,12.59,0,23.09-8.34,25.71-21.23l2.89.76-2.89,17.94c-5.25,3.29-19.68,6.57-28.07,6.57-29.91,0-48.54-18.45-48.54-46.25,0-25.28,23.35-43.23,48.27-43.23,10.24,0,20.21,3.29,30.18,6.57Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M531.59,370.58H534c3.67,0,6,0,6-4.05V298.28c0-8.09-1.83-8.34-6.82-9.6v-2a73.5,73.5,0,0,0,13.12-5.31,8.38,8.38,0,0,1,2.89-1.26c.78,0,1.05.76,1.05,1.77v84.68c0,4.05,2.88,4.05,6.29,4.05h2.1v3.28c-4.2,0-8.66-.25-13.38-.25-4.46,0-9.18,0-13.9.25v-3.28Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M613.18,367c0,2.27,1.32,2.27,3.68,2.27h5v2.53a131.09,131.09,0,0,0-18.36,3.79l-.53-.25v-9.86c-7.61,6.07-13.38,10.36-22.56,10.36-6.82,0-13.91-4.29-13.91-14.66V329.88c0-3.29-.52-6.32-7.61-6.83v-2.27c4.46,0,14.7-.76,16.53-.76,1.32,0,1.32.76,1.32,3.54v31.6c0,3.79,0,14.15,11,14.15,4.2,0,10-3.28,15.48-7.58V328.61c0-2.52-6-3.79-10.75-5.05v-2.28c11.54-.75,18.63-1.77,19.94-1.77,1.05,0,1.05.76,1.05,2.28L613.18,367Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M638.64,360c0,4.55,4.46,12.64,13.11,12.64,13.65,0,19.42-12.89,19.42-23.76,0-13.15-10.5-24.27-20.47-24.27-4.72,0-8.65,3-12.07,5.81V360Zm0-33.37c5-4,12.06-8.84,18.89-8.84,14.69,0,23.61,12.38,23.61,25.78,0,16.18-12.33,32.1-30.44,32.1-9.44,0-14.43-3-17.57-4.3l-3.94,2.79-2.62-1.27a151.83,151.83,0,0,0,1.83-21.74V298.28c0-8.09-1.83-8.34-6.82-9.6v-2a73.5,73.5,0,0,0,13.12-5.31,8.38,8.38,0,0,1,2.89-1.26c.78,0,1,.76,1,1.77Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M89,445.15h1c2.36,0,5.24-.26,5.24-3.8V406.47c0-3.54-2.62-3.79-5.24-3.79H89v-2c2.62,0,6.82.25,10.23.25s7.61-.25,10.76-.25v2h-1c-2.36,0-5.25.25-5.25,3.79v34.88c0,3.54,2.62,3.8,5.25,3.8h1v2c-3.41,0-7.61-.25-11-.25s-7.61.25-10.24.25l.27-2Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M110,446.92V445.4h-.79c-2.36,0-5.51-.51-5.51-4V406.47c0-3.79,2.89-4,5.51-4H110v-1.52c-3.15,0-7.35.25-10.5.25-3.41,0-7.34-.25-10-.25v1.52h.79c2.36,0,5.51.5,5.51,4v34.88c0,3.8-2.89,4-5.51,4h-1v1.52c2.62,0,6.56-.26,10-.26s7.35.26,10.76.26m.26.5c-3.41,0-7.61-.25-11-.25s-7.35.25-10.23.25H88.7v-2.53H90c2.62,0,4.72-.25,4.72-3.54V406.47c0-3.28-2.36-3.54-4.72-3.54H88.7V400.4H89c2.62,0,6.82.26,10.23.26s7.61-.26,10.76-.26h.26v2.53h-1c-2.62,0-4.72.26-4.72,3.54v34.88c0,3.29,2.36,3.54,4.72,3.54h1.31v2.53Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M157.71,434h0l.26-25.27c0-5.56-3.94-6.32-6-6.32h-1.58v-2c3.15,0,6.56.26,9.71.26,2.89,0,5.77-.26,8.4-.26v2h-1.05c-2.89,0-6.3.5-6.3,8.59v30.33a29.14,29.14,0,0,0,.52,6.83H159l-35.68-38.42v27.55c0,5.81,1,7.84,6.56,7.84h1.31v2c-2.89,0-6-.25-8.92-.25-3.15,0-6.3.25-9.45.25v-2h1c4.72,0,6.3-3,6.3-8.35V408.75a6.1,6.1,0,0,0-6.3-6.07h-1v-2c2.63,0,5.25.25,7.87.25,2.1,0,4.2-.25,6.3-.25Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M157.71,433.77Zm0,.25Zm1.57,13.91h2.36a53.61,53.61,0,0,1-.26-6.58V411c0-8.09,3.41-8.85,6.56-8.85h.79v-1.51c-2.63,0-5.51.25-8.14.25-3.14,0-6.29-.25-9.44-.25v1.51h1.31c2.1,0,6.3,1,6.3,6.58V434l-.26.26-.27.25-30.69-33.62c-2.1,0-3.94.25-6.3.25-2.63,0-5.25-.25-7.61-.25v1.52h.52a6.37,6.37,0,0,1,6.56,6.32V436.8c0,5.31-1.57,8.85-6.56,8.85h-.78v1.52c2.88,0,6-.25,9.18-.25,2.89,0,5.77.25,8.66.25v-1.52h-.79c-5.51,0-6.82-2.27-6.82-8.09V409.25Zm2.63.5H159l-35.16-37.66v26.54c0,5.81,1,7.33,6.3,7.58h1.57v2.53h-.52c-2.89,0-6-.25-8.92-.25-3.15,0-6.3.25-9.45.25h-.26v-2.53h1.31c4.46,0,5.77-2.78,6-8.09V408.75a6,6,0,0,0-6-5.82h-1.31V400.4h.26c2.63,0,5.25.26,7.87.26,2.1,0,4.2-.26,6.3-.26l30.44,33.12V409c0-5.31-3.68-6.07-5.78-6.07h-1.83V400.4h.26c3.15,0,6.56.26,9.71.26,2.88,0,5.77-.26,8.39-.26h.27v2.53h-1.32c-2.88,0-5.77.26-5.77,8.09v30.33a26.54,26.54,0,0,0,.53,6.58v.5Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M179,403.94c-5.25,0-5.51,1.27-6.56,6.07h-1.84c.27-1.77.53-3.79.79-5.56a40.22,40.22,0,0,0,.53-5.56h1.57c.53,2,2.36,2,4.2,2h35.68c1.84,0,3.67,0,3.67-2l1.58.25c-.26,1.77-.53,3.54-.79,5.31a36.33,36.33,0,0,0-.26,5.31l-2.1.76c-.26-2.53-.52-6.32-5.25-6.32H198.9v35.64c0,5.05,2.36,5.81,5.77,5.81H206v2c-2.62,0-7.6-.25-11.28-.25-4.2,0-8.92.25-11.8.25v-2h1.31c3.93,0,5.77-.25,5.77-5.56V404.45H179Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M206.25,447.42c-2.63,0-7.61-.25-11.29-.25-4.19,0-8.92.25-11.8.25h-.26v-2.53h1.57c3.94,0,5.25-.25,5.51-5.3V404.2H179v-.51H190.5v35.9c0,5.3-2.36,5.81-6,5.81h-.79v1.52c2.63,0,7.35-.26,11.55-.26,3.67,0,8.13.26,11,.26V445.4H205.2c-3.41,0-6-.76-6-6.07V403.44h11.55c4.72,0,5.24,3.79,5.51,6.32l1.57-.51a36.33,36.33,0,0,1,.26-5.31c.27-1.77.53-3.54.79-5.05l-1.05-.25c-.26,2-2.36,2-3.93,2h-36c-1.57,0-3.41,0-3.93-2h-1a36.74,36.74,0,0,1-.53,5.3q-.39,2.65-.79,5.31h1.58c.79-4.55,1.57-6.06,6.56-6.06v.5c-5.25,0-5,1-6,5.81v.26h-2.62v-.26c.26-1.76.52-3.79.79-5.56a40.2,40.2,0,0,0,.52-5.56v-.25h2.1v.25c.52,1.77,1.84,1.77,3.41,1.77h36c1.83,0,3.41,0,3.41-1.77v-.25h.26l1.84.25v.26q-.41,2.64-.79,5.3v5.56h-.26l-2.37.76v-.5c-.26-2.53-.52-6.07-4.72-6.07h-11.8v35.14c0,5.05,2.09,5.31,5.5,5.56h1.58v2.53h-.26v.5Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M220.94,445.15H222c2.36,0,5.25-.26,5.25-3.8V406.47c0-3.54-2.63-3.79-5.25-3.79h-1.05v-2c4.2,0,11.54.25,17.32.25s13.11-.25,17.84-.25c0,2.78,0,7.33.26,10.11l-2.1.5c-.26-4.29-1-7.83-8.4-7.83h-9.7v17.44h8.13c4.2,0,5-2.28,5.51-5.81h2.1c-.26,2.52-.26,5.3-.26,7.83a74,74,0,0,0,.26,7.59l-2.1.5c-.52-4-.52-6.57-5.51-6.57h-8.4v15.67c0,4.3,3.94,4.3,8.4,4.3,8.4,0,12.07-.51,14.17-8.09l1.84.5c-.79,3.54-1.84,7.08-2.37,10.62-4.46,0-12.33-.25-18.62-.25s-14.43.25-18.37.25v-2.27Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M257.67,446.92A71.17,71.17,0,0,1,260,436.8l-1.31-.25c-2.1,7.59-6,8.09-14.17,8.09-4.46,0-8.66,0-8.66-4.55V424.17h8.66c4.73,0,5.25,2.78,5.78,6.57l1.57-.25a71.64,71.64,0,0,1-.26-7.34,73.94,73.94,0,0,1,.26-7.58h-1.57c-.27,3.54-1.58,5.82-5.78,5.82h-8.66v-18.2h10c7.09,0,8.4,3.53,8.66,7.83l1.58-.5c0-1.77-.26-4.3-.26-6.58v-3.28c-4.73,0-11.81.25-17.32.25-5.77,0-12.6-.25-17.06-.25v1.51h.79c2.36,0,5.51.51,5.51,4.05V441.1c0,3.79-2.88,4-5.51,4h-.79v1.51c3.94,0,12.07-.25,18.11-.25,6,.25,13.64.51,18.1.51m.26.5c-4.46,0-12.33-.25-18.62-.25s-14.43.25-18.37.25h-.26v-2.53H222c2.62,0,4.72-.25,4.72-3.54V406.47c0-3.28-2.36-3.54-4.72-3.54h-1.31V400.4h.26c4.2,0,11.54.26,17.32.26s13.11-.26,17.84-.26h.26v3.8a57.66,57.66,0,0,0,.26,6.82v.25h-.26l-2.36.51v-.25c-.53-4.3-1.05-7.59-8.14-7.59h-9.7v16.94H244c3.93,0,4.72-2,5.25-5.56v-.25h2.62v.25c-.26,2.53-.26,5.31-.26,7.83a74,74,0,0,0,.26,7.59V431h-.26l-2.36.51v-.26c-.53-4-.53-6.32-5-6.32h-8.13v15.17c0,4.05,3.67,4.05,8.13,4.05,8.4,0,11.81-.51,13.91-7.84v-.25h.26l2.1.5v.25c-.79,3.54-1.84,7.08-2.36,10.62Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M278.66,423.15h3.41c6.82,0,10.76-2.52,10.76-10.36,0-5.81-3.94-9.86-10.23-9.86-2.1,0-2.89.26-3.94.26Zm-8.92-15.67c0-4.8-2.89-5.05-5-5.05h-1.32v-2c2.1,0,6.56.26,10.76.26s7.61-.26,11.28-.26c8.66,0,16.53,2.28,16.53,11.89,0,6.06-4.2,9.6-9.71,11.88l11.81,17.18c1.84,2.79,3.41,3.54,6.82,4v2c-2.36,0-4.46-.25-6.82-.25-2.1,0-4.46.25-6.56.25a185.88,185.88,0,0,1-14.43-21.74h-4.46v14.41c0,5.06,2.36,5.31,5.77,5.31h1.32v2c-3.94,0-7.88-.25-11.81-.25-3.41,0-6.56.25-10,.25v-2h1.31c2.62,0,5-1,5-3.54V407.48Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M282.07,422.9c6.82,0,10.23-2.27,10.5-10.11,0-5.81-3.68-9.35-10-9.6-1.84,0-2.63.25-3.68.25V422.9Zm-3.41.51h-.26V402.93h.26c1,0,1.84-.25,3.94-.25,6.29,0,10.49,4,10.49,10.11,0,8.09-3.93,10.62-11,10.62Zm0,1.77h4.72v.25a224.45,224.45,0,0,0,14.17,21.49c2.1,0,4.46-.26,6.56-.26s4.46.26,6.56.26V445.4c-3.15-.51-4.72-1.26-6.56-4L292,423.91l.26-.25c5.51-2,9.45-5.56,9.45-11.37,0-9.36-7.61-11.38-16.27-11.38-3.67,0-7.08.25-11.28.25s-8.13-.25-10.49-.25v1.52h1.05c2.09,0,5.24.25,5.24,5.31v33.87c0,2.78-2.62,3.79-5.24,3.79h-1.05v1.52c3.41,0,6.56-.26,9.7-.26,3.94,0,7.88.26,11.55.26V445.4h-1c-3.15,0-6-.51-6-5.56V425.18Zm32.27,22.24c-2.36,0-4.46-.25-6.82-.25-2.1,0-4.2.25-6.82.25a207,207,0,0,1-14.43-21.48h-4.2v13.9c0,5.05,2.1,5.05,5.25,5.05h1.57v2.53h-.26c-3.93,0-7.87-.25-11.81-.25-3.41,0-6.56.25-10,.25h-.26v-2.53h1.58c2.36,0,4.72-1,4.72-3.28V407.74c0-4.81-2.36-4.81-4.72-4.81h-1.58V400.4h.26c2.37,0,6.56.26,10.76.26s7.35-.26,11.28-.26c8.66,0,16.79,2.28,16.79,12.14,0,6.06-4.19,9.86-9.44,11.88l11.54,16.68c1.84,2.78,3.15,3.54,6.56,3.79h.27v.26l-.27,2.27Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M357.9,434h0V408.75c0-5.56-3.94-6.32-6-6.32h-1.57v-2c3.15,0,6.56.26,9.71.26,2.88,0,5.77-.26,8.65-.26v2h-1c-2.89,0-6.3.5-6.3,8.59v30.33a29.14,29.14,0,0,0,.52,6.83h-2.62l-35.68-38.42v27.55c0,5.81,1.05,7.84,6.56,7.84h1.05v2c-2.89,0-6-.25-8.92-.25-3.15,0-6.3.25-9.45.25v-2h1.05c4.72,0,6.3-3,6.3-8.35V408.75a6.1,6.1,0,0,0-6.3-6.07H313v-2c2.63,0,5.25.25,7.87.25,2.1,0,4.2-.25,6-.25Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M357.9,433.77Zm0,.25Zm1.31,13.91h2.36a53.61,53.61,0,0,1-.26-6.58V411c0-8.09,3.41-8.85,6.56-8.85h.78v-1.51c-2.62,0-5.51.25-8.13.25-3.15,0-6.3-.25-9.44-.25v1.51h1.31c2.1,0,6.29,1,6.29,6.58v25.53l-.26.25-31-33.62c-2.1,0-4.2.25-6.29.25-2.63,0-5.25-.25-7.61-.25v1.52h.52a6.37,6.37,0,0,1,6.56,6.32V436.8c0,5.31-1.57,8.85-6.56,8.85h-.79v1.52c2.89,0,6-.25,9.19-.25,2.88,0,5.77.25,8.66.25v-1.52h-.79c-5.51,0-6.82-2.27-6.82-8.09V409.25Zm2.62.5H359l-35.16-37.66v26.54c0,5.81,1,7.58,6.3,7.58h1.57v2.53h-.26c-2.89,0-6-.25-8.92-.25-3.15,0-6.3.25-9.45.25h-.26v-2.53h1.31c4.46,0,5.77-2.78,6-8.09V408.75a6,6,0,0,0-6-5.82h-1.31V400.4H313c2.63,0,5.25.26,7.87.26,2.1,0,3.94-.26,6.3-.26l30.44,33.12V409c0-5.31-3.68-6.07-5.78-6.07H350V400.4h.26c3.15,0,6.56.26,9.71.26,2.88,0,5.77-.26,8.39-.26h.26v2.53h-1.31c-2.88,0-5.77.26-5.77,8.09v30.33a29.66,29.66,0,0,0,.53,6.83v.25Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M389.91,408h0l-6.56,19H396.2Zm-10,29.57c-.79,2.28-1.58,4.05-1.58,5.31,0,2,2.89,2.28,5.25,2.28h.79v2c-2.89-.25-5.51-.25-8.4-.25-2.62,0-5,0-7.61.25v-2h.53a6.35,6.35,0,0,0,6-4.3l11-30.59c.79-2.52,2.1-5.81,2.63-8.34a49.75,49.75,0,0,0,6.29-2.78c.26,0,.26-.25.53-.25s.26,0,.52.25a3.59,3.59,0,0,1,.53,1.77l12.85,34.88a53.18,53.18,0,0,0,2.63,6.83,4.72,4.72,0,0,0,4.72,2.78h.52v2c-3.15-.25-6.29-.25-9.44-.25-3.41,0-6.82,0-10.5.25v-2H398c1.57,0,4.2-.25,4.2-2a19,19,0,0,0-1.31-4.81l-2.63-7.83H382.56Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M389.64,408Zm-5.77,18.7h12.07l-6-17.94Zm-.78.51,6.55-19.46h.53V408h0v-.25h.26L397,427.2Zm14.16,19.72c3.41,0,6.82-.26,10.24-.26,3.14,0,6.29.26,9.18.26V445.4h0c-2.36,0-3.94-.76-5-2.78-.78-2-1.83-4.55-2.62-6.83L396.2,400.91a3.54,3.54,0,0,1-.52-1.52h-.53a28.27,28.27,0,0,1-6.29,2.78,51.59,51.59,0,0,1-2.63,8.35l-11,30.58a6.66,6.66,0,0,1-6.29,4.55h0v1.52c2.36,0,4.72-.25,7.08-.25,2.62,0,5.51.25,8.13.25v-1.52h-.52c-2.36,0-5.51-.25-5.51-2.53,0-1.26.79-3,1.57-5.3h0l2.37-7.08H398.3l2.89,7.83a18.52,18.52,0,0,1,1.31,4.81c0,2-3.15,2.27-4.72,2.27h-.53v1.27Zm19.68.5c-3.15-.25-6.3-.25-9.44-.25-3.42,0-6.83.25-10.5.25h-.26v-2.53h1c1.57,0,3.93-.25,3.93-1.51a21.46,21.46,0,0,0-1.31-4.81L397.78,431H382.56l-2.1,6.83c-.79,2.27-1.57,4-1.57,5.05,0,1.52,2.62,2,5,2h1.05v2.53h-.26c-2.89-.25-5.51-.25-8.4-.25-2.36,0-5,.25-7.34.25h-.27v-2.53h.79a6.11,6.11,0,0,0,5.77-4l11-30.59c.79-2.52,2.1-5.81,2.63-8.34a49.12,49.12,0,0,0,6.29-2.78c.27,0,.27-.25.79-.25a.74.74,0,0,1,.79.5,4.64,4.64,0,0,1,.52,1.77l12.86,34.89a53.58,53.58,0,0,0,2.62,6.82,4.06,4.06,0,0,0,4.2,2.53h.79v2.53Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M419.82,403.94c-5.25,0-5.51,1.27-6.56,6.07h-2.1c.26-1.77.52-3.79.79-5.56a40.2,40.2,0,0,0,.52-5.56h1.58c.52,2,2.36,2,4.19,2h35.69c1.83,0,3.67,0,3.67-2l1.57.25-.78,5.31a35,35,0,0,0-.27,5.31l-2.1.76c0-2.53-.52-6.32-5.24-6.32H439.5v35.64c0,5.05,2.36,5.81,5.77,5.81h1.31v2c-2.62,0-7.61-.25-11.28-.25-4.2,0-8.92.25-11.81.25v-2h1.31c3.94,0,5.77-.25,5.77-5.56V404.45H419.82v-.51Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M447.1,447.42c-2.62,0-7.6-.25-11.28-.25-4.2,0-8.92.25-11.8.25h-.27v-2.53h1.58c3.93,0,5.24-.25,5.51-5.3V404.2h-11v-.51h11.54v35.9c0,5.3-2.36,5.81-6,5.81h-1.05v1.52c2.62,0,7.34-.26,11.54-.26,3.68,0,8.14.26,11,.26V445.4h-1c-3.41,0-6-.76-6-6.07V403.44H451.3c4.72,0,5.25,3.79,5.51,6.32l1.58-.51a36.33,36.33,0,0,1,.26-5.31c.26-1.77.52-3.54.79-5.05l-1.05-.25c-.27,2-2.37,2-3.94,2H418.51c-1.58,0-3.42,0-3.94-2h-1.05a36.72,36.72,0,0,1-.52,5.3q-.4,2.65-.79,5.31h1.57c.79-4.55,1.58-6.06,6.82-6.06v.5c-5.24,0-5,1-6,5.81v.26H412v-.26c.26-1.76.52-3.79.78-5.56a38.74,38.74,0,0,0,.53-5.56v-.25h2.1v.25c.52,1.77,1.83,1.77,3.41,1.77h35.94c1.84,0,3.41,0,3.41-1.77v-.25h.27l2.09.25v.26l-.78,5.3a36.33,36.33,0,0,0-.26,5.31v.25h-.27l-2.36.76v-.5c-.26-2.53-.52-6.07-4.72-6.07h-11v35.14c0,5.05,2.1,5.31,5.51,5.56h1.57v2.53h-1v.5Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M462.06,445.15h1.05c2.62,0,5.25-.26,5.25-3.8V406.47c0-3.54-2.63-3.79-5.25-3.79h-1.05v-2c2.62,0,6.82.25,10.23.25s7.61-.25,11-.25v2h-1.05c-2.62,0-5.25.25-5.25,3.79v34.88c0,3.54,2.63,3.8,5.25,3.8h1.05v2c-3.41,0-7.61-.25-11-.25s-7.61.25-10.23.25Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M483.05,446.92V445.4h-.53c-2.36,0-5.51-.51-5.51-4V406.47c0-3.79,2.89-4,5.51-4h.53v-1.52c-3.15,0-7.09.25-10.5.25s-7.34-.25-10-.25v1.52h.79c2.36,0,5.51.5,5.51,4v34.88c0,3.8-2.89,4-5.51,4h-.79v1.52c2.63,0,6.56-.26,10-.26,3.15,0,7.09.26,10.5.26m.26.5c-3.41,0-7.61-.25-11-.25s-7.61.25-10.23.25h-.26v-2.53h1.31c2.62,0,4.72-.25,4.72-3.54V406.47c0-3.28-2.36-3.54-4.72-3.54H461.8V400.4h.26c2.62,0,6.82.26,10.23.26s7.61-.26,11-.26h.26v2.53h-1.31c-2.62,0-4.72.26-4.72,3.54v34.88c0,3.29,2.36,3.54,4.72,3.54h1.31v2.53Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M511.91,445.15c13.38,0,15.74-11.38,15.74-21.24s-5.51-21.48-17-21.48c-12.07,0-15.74,10.36-15.74,19.46.26,11.88,6,23.26,17.05,23.26m-1.05-45.5c14.69,0,26.5,8.84,26.5,23,0,15.42-11.54,25.53-26.24,25.53s-26-9.61-26-24c.26-13.91,11.28-24.52,25.71-24.52"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M494.86,421.89c0-9.1,3.67-19.72,16-19.72,11.81,0,17.32,12.14,17.32,22s-2.36,21.48-16,21.48v-.76c13.12,0,15.48-11.12,15.48-21,0-9.6-5.51-21.23-16.79-21.23-11.81,0-15.48,10.11-15.48,19,0,11.88,5.77,23,16.53,23v.51c-11.28.25-17.05-11.38-17.05-23.26m-9.71,2.28c0-14.16,11.28-25,26-25v.51c-14.17,0-25.19,10.61-25.19,24.26,0,14.16,11,23.76,25.72,23.76s26-10.11,26-25.27c0-14.16-11.54-22.75-26.24-22.75v-.51c15,0,26.77,8.85,26.77,23.26,0,15.42-11.55,25.78-26.5,25.78-15.22.25-26.5-9.61-26.5-24"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M584.32,434h0l.27-25.27c0-5.56-3.94-6.32-6-6.32H577v-2c3.15,0,6.56.26,9.71.26,2.88,0,5.77-.26,8.65-.26v2h-1.05c-2.88,0-6.29.5-6.29,8.59v30.33a29.14,29.14,0,0,0,.52,6.83H585.9l-35.68-38.42v27.55c0,5.81,1.31,7.84,6.56,7.84h1.31v2c-2.89,0-6-.25-8.92-.25-3.15,0-6.3.25-9.45.25v-2h1c4.72,0,6.3-3,6.3-8.35V408.75a6.1,6.1,0,0,0-6.3-6.07H540v-2c2.63,0,5.25.25,7.87.25,2.1,0,4.2-.25,6-.25Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M584.06,433.77Zm.26.25Zm1.32,13.91H588a51.64,51.64,0,0,1-.27-6.58V411c0-8.09,3.42-8.85,6.56-8.85h.79v-1.51c-2.62,0-5.51.25-8.13.25-3.15,0-6.3-.25-9.45-.25v1.51h1.31c2.1,0,6.3,1,6.3,6.58V434l-.26.26-.26.25-31-33.62c-2.1,0-4.2.25-6.3.25-2.62,0-5.25-.25-7.61-.25v1.52h.53a6.37,6.37,0,0,1,6.55,6.32V436.8c0,5.31-1.57,8.85-6.55,8.85h-.79v1.52c2.88,0,6-.25,9.18-.25,2.89,0,5.77.25,8.66.25v-1.52h-.79c-5.51,0-6.82-2.27-6.82-8.09V409.25Zm2.62.5h-2.89l-35.15-37.66v26.54c0,5.81,1,7.33,6.29,7.58h1.58v2.53h-.27c-2.88,0-6-.25-8.92-.25-3.14,0-6.29.25-9.44.25h-.26v-2.53h1.31c4.46,0,5.77-2.78,6-8.09V408.75a6,6,0,0,0-6-5.82H539.2V400.4h.26c2.62,0,5.25.26,7.87.26,2.1,0,3.94-.26,6.3-.26l30.43,33.12V409c0-5.31-3.67-6.07-5.77-6.07h-1.84V400.4h.27c3.14,0,6.55.26,9.7.26,2.89,0,5.78-.26,8.66-.26h.26v2.53H594c-2.88,0-5.77.26-5.77,8.09v37.41Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M616.33,408h0l-6.56,19h12.86ZM606.1,437.56c-.79,2.28-1.57,4.05-1.57,5.31,0,2,2.88,2.28,5.24,2.28h.79v2c-2.88-.25-5.51-.25-8.39-.25a69.6,69.6,0,0,0-7.35.25v-2h.52a6.35,6.35,0,0,0,6-4.3l11-30.59c.78-2.52,2.1-5.81,2.62-8.34a49.31,49.31,0,0,0,6.3-2.78c.26,0,.26-.25.52-.25s.27,0,.53.25a3.59,3.59,0,0,1,.52,1.77l12.86,34.88a53.16,53.16,0,0,0,2.62,6.83,4.6,4.6,0,0,0,4.46,2.78h.53v2c-3.15-.25-6.3-.25-9.45-.25-3.41,0-6.82,0-10.49.25v-2h.78c1.58,0,4.2-.25,4.2-2a18.52,18.52,0,0,0-1.31-4.81l-2.62-7.83H608.72Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M616.07,408Zm-6,18.7h12.07l-6-17.94Zm-.79.51,6.56-19.46h.52V408h0v-.25h.27l6.56,19.46Zm14.17,19.72c3.41,0,6.82-.26,10.23-.26,3.15,0,6.3.26,9.18.26V445.4h0c-2.36,0-3.93-.76-4.72-2.78s-1.84-4.55-2.62-6.83l-12.86-34.88a3.54,3.54,0,0,1-.52-1.52h-.53a28.36,28.36,0,0,1-6.3,2.78,50.57,50.57,0,0,1-2.62,8.35l-11,30.58a6.67,6.67,0,0,1-6.3,4.55h0v1.52c2.37,0,4.73-.25,7.09-.25,2.62,0,5.51.25,8.13.25v-1.52H610c-2.36,0-5.51-.25-5.51-2.53,0-1.26.78-3,1.57-5.3h0l2.36-7.08h16.27l2.89,7.83a19,19,0,0,1,1.31,4.81c0,2-2.89,2.27-4.46,2.27h-.53v1.27Zm19.68.5c-3.15,0-6.3-.25-9.45-.25-3.41,0-6.82.25-10.49.25h-.27v-2.53h1.05c1.58,0,3.94-.25,3.94-1.77a22.16,22.16,0,0,0-1.31-4.8l-2.63-7.58H608.72l-2.09,6.82c-.79,2.28-1.58,4.05-1.58,5.06,0,1.52,2.63,2,5,2h1.05v2.53h-.27c-2.88,0-5.51-.25-8.39-.25-2.36,0-5,.25-7.35.25h-.26v-2.28h.79a6.12,6.12,0,0,0,5.77-4l11-30.59c.78-2.52,2.1-5.81,2.62-8.34a49.31,49.31,0,0,0,6.3-2.78c.26,0,.26-.25.79-.25a.73.73,0,0,1,.78.5,3.49,3.49,0,0,1,.53,1.77l12.85,34.89a53.61,53.61,0,0,0,2.63,6.82,4.06,4.06,0,0,0,4.2,2.53h.78v2.53Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M660.67,440.6c0,2.78,1.84,3.54,4.2,3.79a38.06,38.06,0,0,0,9.19-.25,9.66,9.66,0,0,0,6.55-3.54,12.79,12.79,0,0,0,2.1-4.81h2.1c-.78,3.8-1.83,7.59-2.62,11.38-6,0-12.33-.25-18.37-.25s-12.33.25-18.36.25v-2h1c2.62,0,5.24-.26,5.24-4.55V406.22c0-3.54-2.62-3.79-5.24-3.79h-1v-2c3.67,0,7.34.26,11,.26,3.41,0,7.08-.26,10.49-.26v2h-1.84c-2.62,0-4.72,0-4.72,3.54l.26,34.63Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M681.93,446.92c.78-3.54,1.83-7.08,2.36-10.87H683a19.44,19.44,0,0,1-2.1,4.8,11.16,11.16,0,0,1-6.82,3.79,38.86,38.86,0,0,1-5.25.25,20.87,20.87,0,0,1-3.94-.25c-2.36-.25-4.46-1.26-4.46-4V406.22c0-3.79,2.36-3.79,5-3.79h1.31v-1.52c-3.41,0-6.82.25-10.23.25-3.68,0-7.09-.25-10.76-.25v1.52h.79c2.36,0,5.51.5,5.51,4v34.38c0,4.3-2.89,4.8-5.51,4.8h-.79v1.52c6,0,12.07-.25,18.1-.25,6-.26,12.07,0,18.11,0m.26.5c-6,0-12.33-.25-18.37-.25s-12.07.25-18.36.25h-.27v-2.53h1.32c2.62,0,5-.25,5-4.29V406.22c0-3.29-2.36-3.54-5-3.54h-1.32v-2.53h.27c3.67,0,7.34.25,11,.25,3.41,0,7.08-.25,10.49-.25h.26v2.53h-2.1c-2.62,0-4.46,0-4.46,3.29v34.37c0,2.53,1.84,3.29,3.94,3.54h3.94a38.64,38.64,0,0,0,5.24-.25,10.21,10.21,0,0,0,6.56-3.54,19.07,19.07,0,0,0,2.1-4.8V435h2.62v.25c-.78,3.79-1.83,7.58-2.62,11.37l-.26.76Z"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M682.71,405.71h.79c1,0,1.31-.76,1.31-1.77a1.43,1.43,0,0,0-1.31-1.51h-.79Zm-2.36,3.79v-.25c.79,0,1,0,1-.5v-5.31c0-.76,0-1-1-1v-.51H684c1.31,0,2.36.51,2.36,1.77a2.17,2.17,0,0,1-1.84,2l1.31,1.52a12.52,12.52,0,0,0,1.58,1.77v.25h-1.32c-.78,0-1.31-1.26-2.62-3.28h-.79v2.27c0,.51.27.51,1,.51V409h-3.41Zm3.41,1.77a5.94,5.94,0,0,0,5.78-5.81,5.78,5.78,0,1,0-5.78,5.81m0-12.63a7.08,7.08,0,1,1-7.34,7.07,7.19,7.19,0,0,1,7.34-7.07"
            transform="translate(0.09 0.36)"
        />
        <path
            d="M254.26,164.81c0-62.43,52.48-113,117.28-113s117.28,50.56,117.28,113-52.47,113-117.28,113C307,277.81,254.26,227.25,254.26,164.81Z"
            transform="translate(0.09 0.36)"
            fill="#fff"
        />
        <path
            d="M371.8,279.83c-67.16.25-122.52-52.33-122.52-116,0-69.77,55.36-118.3,122.52-118h31.49c66.38,0,127.25,48.28,127.25,118.05,0,64-60.61,116-127.25,116Zm.27-224.47c-61.4,0-111.25,48-111.25,107.18s49.85,107.18,111.25,107.18,111.24-48,111.24-107.18C483.57,103.39,433.72,55.36,372.07,55.36ZM346.88,226V99.09c-26.5,9.86-45.13,34.38-45.39,63.45C301.75,191.61,320.38,216.13,346.88,226Zm95.76-63.45c0-29.07-18.89-53.59-45.39-63.45V226C423.75,216.13,442.64,191.61,442.64,162.54Z"
            transform="translate(0.09 0.36)"
            fill="#0069aa"
        />
    </Icon>
);

export default CardDiners;
