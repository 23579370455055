import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PaymentMethodWithFees } from "checkout/ts/redux/models/configuration";
import { SupportedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { getDefaultFlowStepUrl } from "checkout/ts/utils/StepName";
import { reduxForm } from "redux-form";
import { PaymentType } from "univapay-node";

import { FORM_CHECKOUT_NAME } from "../../../../../common/constants";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { StepTitle } from "../../common/StepTitle";
import { OnlineBrandButton } from "../../flows/online/components/OnlineBrandButton";
import { PaymentMethodButton } from "../../layout/PaymentMethodButton";

const stateSelector = (state: StateShape) => ({
    paymentMethods: state.configuration.paymentMethods,
    currency: state.application.params.params.currency,
});

export const StepPaymentMethods = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    form: FORM_CHECKOUT_NAME,
})(() => {
    const { push: redirect } = useHistory();
    const {
        checkout: { setPaymentMethod },
        online: { setBrand },
    } = useDispatch<Dispatch>();

    const { paymentMethods, currency } = useSelector(stateSelector);

    useEffect(() => {
        setPaymentMethod({ type: null, key: null });
    }, []);

    return (
        <>
            <StepTitle>
                <FormattedMessage id={LOCALE_LABELS.FORM_PAYMENT_TYPE_TITLE} />
            </StepTitle>

            {paymentMethods.map(({ key, brands, hasAllBrands, type, method, fees }: PaymentMethodWithFees) => {
                const handleClick = () => {
                    setPaymentMethod({ type, key });
                    setBrand({ brand: type === PaymentType.ONLINE ? (method as SupportedOnlineBrand) : null });

                    redirect(getDefaultFlowStepUrl(type, key));
                };

                return type === PaymentType.ONLINE ? (
                    <OnlineBrandButton
                        key={key}
                        brand={method as SupportedOnlineBrand}
                        onClick={handleClick}
                        fees={fees}
                        currency={currency}
                    />
                ) : (
                    <PaymentMethodButton
                        key={key}
                        paymentType={type}
                        brands={brands}
                        onClick={handleClick}
                        hasAllBrands={hasAllBrands}
                        fees={fees}
                        currency={currency}
                    />
                );
            })}
        </>
    );
});
