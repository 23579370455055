import React from "react";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { StateShape } from "checkout/ts/redux/store";
import { ApplicationTimeoutError } from "common/errors/ApplicationTimeoutError";
import { RequestResponseBaseError, ResponseErrorCode } from "univapay-node";

import { ConnectorError } from "../../../../../common/errors/ConnectorError";
import { ParametersError } from "../../../../../common/errors/ParametersError";
import { TimeoutError } from "../../../../../common/errors/TimeoutError";
import { CheckoutParametersError } from "../../../errors/CheckoutParametersError";

const getErrorTitle = (error) => {
    if (error instanceof CheckoutParametersError) {
        return <FormattedMessage id={error.code} />;
    }

    if (
        error instanceof RequestResponseBaseError &&
        error.errorResponse.code === ResponseErrorCode.ServiceUnavailable
    ) {
        return <FormattedMessage id={LOCALE_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE} />;
    }

    return <FormattedMessage id={LOCALE_LABELS.ERRORS_TITLE} />;
};

const getErrorMessage = (error) => {
    switch (true) {
        case error instanceof ConnectorError:
            return <FormattedMessage id={LOCALE_LABELS.ERRORS_ALERTS_CONNECTOR} />;

        case error instanceof ApplicationTimeoutError:
            return <FormattedMessage id={LOCALE_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT} />;

        case error instanceof TimeoutError:
            return <FormattedMessage id={error.message || LOCALE_LABELS.ERRORS_ALERTS_TIMEOUT} />;

        case error instanceof ParametersError:
            return <FormattedMessage id={error.message || LOCALE_LABELS.ERRORS_ALERTS_PARAMETERS} />;

        case error instanceof CheckoutParametersError:
            return (
                <ul className="error-list text-left">
                    {error.errors.map((errorCode: string, index: number) => (
                        <li key={index}>
                            <FormattedMessage id={errorCode} />
                        </li>
                    ))}
                </ul>
            );

        case error instanceof RequestResponseBaseError:
            if (error.errorResponse.code === ResponseErrorCode.ServiceUnavailable) {
                return <FormattedMessage id={LOCALE_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO} />;
            } else {
                return <FormattedMessage id={error.errorResponse.code} />;
            }

        default: {
            const errorLabel: string = LOCALE_LABELS[error] || ResponseErrorCode.UnknownError;

            return <FormattedMessage id={errorLabel} />;
        }
    }
};

const stateSelector = (state: StateShape) => ({ error: state.application.globalError || state.configuration.error });

export const ErrorAlert = () => {
    const { error } = useSelector(stateSelector);

    return (
        <div data-name="checkout-error">
            <Alert bsStyle="error">
                <h4 data-name="error-title">{getErrorTitle(error)}</h4>

                <span data-name="error-message">{getErrorMessage(error)}</span>
            </Alert>
        </div>
    );
};
