import reactCSS from "reactcss";
import Color from "color";
import { TEXT_DARK_THEME_COLOR, TEXT_LIGHT_THEME_COLOR } from "common/constants";

import { darkThemeColors, defaultColors } from "../colors";
import { PatchedCheckoutInfo } from "../redux/models/configuration";
import { StateShape } from "../redux/store";

interface LayoutColor {
    textColor: string;
    backgroundColor: string;
    backgroundHoverColor?: string;
}

const highlightColor = (colorHex: string, darkTheme = false): string => {
    const color = Color(colorHex);
    const hoverColor = darkTheme ? color.lighten(0.1) : color.darken(0.1);

    return hoverColor.hex();
};

export const getThemeColors = (data: PatchedCheckoutInfo, applicationParamsTheme?: string) => {
    if (applicationParamsTheme) {
        return applicationParamsTheme === "dark" ? darkThemeColors : defaultColors;
    }

    if (!data) {
        return defaultColors;
    }

    return { ...defaultColors, ...data.theme.colors };
};

export const getStepTitleColors = (data: PatchedCheckoutInfo, applicationParamsTheme: string) => {
    const { baseText } = getThemeColors(data, applicationParamsTheme);

    return { textColor: baseText };
};

/**
 * This function will be replaced by a button color property
 */
const getButtonBaseColors = (
    data: PatchedCheckoutInfo,
    darkTheme: boolean,
    applicationParamsTheme: string
): Partial<LayoutColor> => {
    const colors = getThemeColors(data, applicationParamsTheme);

    const { mainColor, mainBackground, secondaryBackground, mainText, primaryText } = colors;

    if (mainColor !== mainBackground) {
        return { textColor: mainText, backgroundColor: mainColor };
    }

    if (secondaryBackground !== mainBackground) {
        return { textColor: primaryText, backgroundColor: secondaryBackground };
    }

    return { textColor: mainText, backgroundColor: highlightColor(mainColor, darkTheme) };
};

export const getButtonColors = (
    data: PatchedCheckoutInfo,
    darkTheme: boolean,
    applicationParamsTheme: string
): LayoutColor => {
    const { textColor, backgroundColor } = getButtonBaseColors(data, darkTheme, applicationParamsTheme);
    const backgroundHoverColor = highlightColor(backgroundColor);

    return { textColor, backgroundColor, backgroundHoverColor };
};

export const getContentColors = (data: PatchedCheckoutInfo, applicationParamsTheme: string): LayoutColor => {
    const { mainBackground, baseText } = getThemeColors(data, applicationParamsTheme);

    return { textColor: baseText, backgroundColor: mainBackground };
};

export const isDarkTheme = (state: StateShape) => state.application.params.params.dark ?? state.configuration.darkTheme;

export const getPrimaryStyle = ({ textColor, backgroundColor, backgroundHoverColor }: LayoutColor, hover: boolean) =>
    reactCSS(
        {
            default: {
                button: {
                    borderColor: backgroundColor,
                    backgroundColor,
                    color: textColor,
                },
            },
            hover: {
                button: {
                    borderColor: backgroundHoverColor,
                    backgroundColor: backgroundHoverColor,
                    color: textColor,
                },
            },
        },
        { hover }
    );

export const getOutlineStyle = (
    { textColor, backgroundColor, backgroundHoverColor }: LayoutColor,
    hover: boolean,
    darkTheme: boolean
) =>
    reactCSS(
        {
            default: {
                button: {
                    backgroundColor: "transparent",
                    borderColor: backgroundColor,
                    color: darkTheme ? TEXT_DARK_THEME_COLOR : TEXT_LIGHT_THEME_COLOR,
                },
            },
            hover: {
                button: {
                    borderColor: backgroundHoverColor,
                    backgroundColor: backgroundHoverColor,
                    color: textColor,
                },
            },
        },
        { hover }
    );
