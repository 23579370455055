import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router";
import reactCSS from "reactcss";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { SupportedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { getThemeColors } from "checkout/ts/utils/colors";
import { getDefaultFlowStepUrl, getErrorUrl, getStepUrl, StepName } from "checkout/ts/utils/StepName";
import { ParametersError } from "common/errors/ParametersError";
import { TimeoutError } from "common/errors/TimeoutError";
import { isNil } from "lodash";
import { parseJWT, PaymentType } from "univapay-node";

import { Dispatch, StateShape } from "../../redux/store";
import { FormDataCard } from "../flows/card";
import CardRegistration from "../flows/card-registration";
import { FormDataKonbini } from "../flows/konbini";
import { PaidyCheckoutData } from "../flows/paidy";
import { DialogContent } from "../layout/DialogContent";
import { SwitchContent } from "../router/SwitchContent";

import { StepPaymentData } from "./steps/StepPaymentData";
import { StepPaymentMethods } from "./steps/StepPaymentMethod";
import { CheckoutHeader } from "./CheckoutHeader";
import { validatedCheckoutConfig } from "./utils";

type FormDataBankTransfer = { data: { name: string; brand: string } };

export type CheckoutPaymentData = {
    data: {
        /**
         * Force showing the error page on validation error instead of returning to the parameters for the user to fix.
         */
        failsOnValidationError?: boolean;

        /**
         * Selected token from the customer id flow.
         */
        token?: string;

        /**
         * Card only parameter to force recurring token for later usage.
         */
        accept?: boolean;
    };

    /**
     * Front-end installment cycles chose by the user. Only available for card company installments
     */
    installmentCycles?: number | "revolving";

    deleteTokenOnFailure?: boolean;
};

export type FormCheckoutData = { paymentType: PaymentType } & (
    | FormDataCard
    | FormDataKonbini
    | PaidyCheckoutData
    | FormDataBankTransfer
) &
    CheckoutPaymentData;

const stateSelector = (state: StateShape) => {
    const applicationParamsTheme = isNil(state.application.params?.params?.dark)
        ? null
        : state.application.params?.params?.dark
        ? "dark"
        : "light";

    return {
        connector: state.application.connector,
        autoCloseOnError: state.application.params.params.autoCloseOnError,
        processed: state.checkout.processed,
        timeout: state.application.params.params.timeout,
        email: state.application.params.params.email,
        paymentMethods: state.configuration.paymentMethods,
        colors: getThemeColors(state.configuration.data, applicationParamsTheme),
        paymentType: state.checkout.paymentType,
        storeId: parseJWT<{ storeId: string }>(state.application.params.appId)?.storeId,
        instantChargeTokenId: state.application.params.params.instantChargeTokenId,
        applicationError:
            state.application.globalError ??
            validatedCheckoutConfig(state.configuration, state.application.params?.params?.allowCardInstallments),
        merchantCardRegistration: state.application.params?.params?.merchantCardRegistration,
    };
};

export const FormCheckout = () => {
    const { replace: replaceUrl } = useHistory();
    const {
        userData: { setUserData },
        checkout: { setPaymentMethod },
        tokens: { getRecurring: getRecurringToken },
        application: { setError: setApplicationError, autoCloseApplication },
        online: { setBrand },
        checkout: { setToken: setInstantChargeToken, setProcessed, setError: setPaymentError },
    } = useDispatch<Dispatch>();

    const {
        email,
        paymentMethods,
        paymentType,
        colors,
        applicationError,
        storeId,
        instantChargeTokenId,
        timeout,
        connector,
        autoCloseOnError,
        processed,
        merchantCardRegistration,
    } = useSelector(stateSelector);

    useEffect(() => {
        if (!timeout) {
            return;
        }

        const applicationTimeout = setTimeout(() => {
            if (!processed) {
                const error = new TimeoutError(timeout, LOCALE_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT);
                setProcessed();
                setApplicationError({ error });
                setPaymentError({ error });
                connector.emitter.emit("checkout:error", { error });

                if (autoCloseOnError) {
                    autoCloseApplication();
                }
            }
        }, timeout * 1000);

        return () => clearTimeout(applicationTimeout);
    }, []);

    useEffect(() => {
        if (applicationError) {
            setApplicationError({ error: applicationError });
            replaceUrl(getErrorUrl());
        } else if (merchantCardRegistration) {
            // If using the card registration, assume the method is card and skip the payment type select page
            const cardPaymentMethod = paymentMethods.find((method) => method.type === PaymentType.CARD);
            setPaymentMethod({
                type: cardPaymentMethod.type,
                key: cardPaymentMethod.key,
            });
        } else if (paymentMethods.length === 1 && !paymentType) {
            // When only one method is present, set the method and skip the payment type select page
            const [{ type, key, method }] = paymentMethods;

            setPaymentMethod({ type, key });
            setBrand({ brand: type === PaymentType.ONLINE ? (method as SupportedOnlineBrand) : undefined });
            replaceUrl(getDefaultFlowStepUrl(type, key));
        }

        setUserData({ email: email || undefined });

        if (instantChargeTokenId) {
            (async () => {
                const SUPPORTED_INSTANT_CHARGE_TYPES = [
                    PaymentType.CARD,
                    PaymentType.KONBINI,
                    PaymentType.BANK_TRANSFER,
                    PaymentType.PAIDY,
                ];
                const token = await getRecurringToken({ storeId, id: instantChargeTokenId });
                const type = token.paymentType;
                const key = paymentMethods.find(({ type }) => type === token.paymentType)?.key;

                setPaymentMethod({ type, key });
                setInstantChargeToken({ token });

                if (!SUPPORTED_INSTANT_CHARGE_TYPES.includes(type)) {
                    setApplicationError({
                        error: new ParametersError(LOCALE_LABELS.ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE),
                    });
                    replaceUrl(getErrorUrl());
                }

                replaceUrl(getStepUrl(type, key, StepName.TOKEN_REVIEW));
            })();
        }
    }, [paymentMethods.length]);

    const styles = reactCSS({
        "default": {
            content: {
                color: colors.baseText,
            },
        },
    });

    return (
        <div className="dialog-wrapper dialog-checkout" style={styles.content}>
            <CheckoutHeader />

            <DialogContent>
                <SwitchContent>
                    <Route
                        path="/"
                        component={merchantCardRegistration ? CardRegistration : StepPaymentMethods}
                        exact
                    />
                    <Route path="/paymentData" component={StepPaymentData} />
                </SwitchContent>
            </DialogContent>
        </div>
    );
};
