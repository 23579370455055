import React from "react";
import { Col } from "react-bootstrap";
import { IntlShape, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { FormattedCheckoutParams, useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import classnames from "classnames";

import { LOCALE_LABELS } from "../../../locale/labels";
import { StateShape } from "../../../redux/store";
import { CheckboxField } from "../../forms/Checkbox";
import { DialogButton } from "../../layout/DialogButton";

const buttonStateSelector = (state: StateShape) => {
    const { application, configuration, product } = state;

    const { isSubscription } = getCheckoutParams(application.params, product.products, configuration.data);

    return {
        submitButtonText: state.application.params.params?.submitButtonText,
        processing: ((state.loading.models.checkout ||
            state.loading.models.bankTransfer ||
            state.loading.models.online ||
            state.loading.models.paidy) as unknown) as boolean,
        isSubscription,
    };
};

export const getSubmitButtonLabel = (
    checkoutParams: FormattedCheckoutParams,
    isSubscription: boolean,
    intl: IntlShape
) => {
    const {
        amount,
        tokenOnly,
        subscriptionParams: { initialAmount, scheduleSettings },
    } = checkoutParams;

    const hasSubscriptionDeferredCharges = isSubscription && !initialAmount && scheduleSettings?.startOn;

    const hasDeferredPayment = tokenOnly || hasSubscriptionDeferredCharges;

    const id =
        hasDeferredPayment || !amount
            ? LOCALE_LABELS.COMMON_BUTTONS_SUBMIT
            : LOCALE_LABELS.PAYMENT_BUTTONS_PAY_NO_AMOUNT;

    return intl.formatMessage({ id });
};

export const Button = ({ disabled, label, onClick }: { disabled?: boolean; label?: string; onClick?: () => void }) => {
    const intl = useIntl();
    const { submitButtonText, processing, isSubscription } = useSelector(buttonStateSelector);
    const checkoutParams = useCheckoutParams();

    return (
        <DialogButton disabled={disabled || processing} onClick={onClick}>
            {label || submitButtonText || getSubmitButtonLabel(checkoutParams, isSubscription, intl)}
        </DialogButton>
    );
};

export const AcceptField = ({ showErrors = false, isDark }: { showErrors?: boolean; isDark: boolean }) => {
    const { formatMessage } = useIntl();

    return (
        <Col xs={12} className="text-left">
            <CheckboxField name="accept" showError={showErrors} isDark={isDark}>
                {formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_ACCEPT_RECURRING })}
            </CheckboxField>
        </Col>
    );
};

export const PrivacyLink = ({ className, isDark }: { className?: string; isDark: boolean }) => {
    const { formatMessage } = useIntl();

    return (
        <div className={className}>
            <a
                data-name="privacy-link"
                className={classnames("privacy-link", { "dark-theme": isDark })}
                href="https://univapaycast.com/handling02/"
                target="_blank"
                rel="noreferrer">
                {formatMessage({ id: LOCALE_LABELS.PRIVACY_LINK })}
            </a>
        </div>
    );
};
