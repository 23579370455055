import { PaymentError, PaymentType, RequestResponseBaseError, ResponseErrorCode } from "univapay-node";

import { errorCodeLabel } from "../../../common/locale/utils";
import { LOCALE_LABELS } from "../locale/labels";
import {
    ERROR_ALERTS_PAIDY_PAYMENT_REJECTED,
    ERRORS_ALERTS_CAPTURE_CREDIT_ONLY,
    PROCESSING_ERROR,
} from "../locale/labels/errors";

import { TokenError } from "./TokenError";

const convertProcessingErrorCode = (code: number, paymentType: PaymentType) => {
    switch (code) {
        case 301:
            return ResponseErrorCode.CardNumberNotValid;

        case 302:
            return ResponseErrorCode.CardExpirationMonthInvalid;

        case 303:
            return ResponseErrorCode.CardExpirationYearInvalid;

        case 304:
            return ResponseErrorCode.CardExpired;

        case 305:
            return ResponseErrorCode.CardCVVInvalid;

        case 306:
            return paymentType === PaymentType.CARD || paymentType === PaymentType.APPLE_PAY
                ? ResponseErrorCode.CardRejected
                : paymentType === PaymentType.PAIDY
                ? ERROR_ALERTS_PAIDY_PAYMENT_REJECTED
                : PROCESSING_ERROR;

        case 307:
            return ResponseErrorCode.CardInvalid;

        case 308:
            return ResponseErrorCode.ChargeInvalidData;

        case 309:
            return PROCESSING_ERROR;

        case 310:
            return ResponseErrorCode.InvalidUserData;

        case 311:
            return ResponseErrorCode.TooManyChargeRequests;

        case 312:
            return ResponseErrorCode.CancelUnavailable;

        case 313:
            return ResponseErrorCode.ChargeExpired;

        case 314:
            return ResponseErrorCode.SeizeCard;

        case 315:
            return ResponseErrorCode.ContactBank;

        case 316:
            return ResponseErrorCode.LastNameRequired;

        case 317:
            return ResponseErrorCode.PartialCaptureNotSupported;

        case 318:
            return ResponseErrorCode.PartialRefundNotSupported;

        case 319:
            return ResponseErrorCode.FraudSuspected;

        case 320:
            return ResponseErrorCode.BankSystemFailure;

        case 321:
            return ResponseErrorCode.DynamicDescriptorNotSupported;

        case 322:
            return ResponseErrorCode.PaymentCodeInvalid;

        case 323:
            return ResponseErrorCode.PaymentCodeExpired;

        case 324:
            return ResponseErrorCode.PaymentCodeAlreadyUsed;

        case 325:
            return ResponseErrorCode.PaymentCodeStillInUse;

        case 326:
            return ResponseErrorCode.RejectedHighRisk;

        case 327:
            return ResponseErrorCode.ConfirmationPeriodExpired;

        case 328:
            return ResponseErrorCode.RevertFailed;

        case 329:
            return ResponseErrorCode.RefundFailed;

        case 330:
            return ResponseErrorCode.PaymentWalletInsufficientFunds;

        case 331:
            return ResponseErrorCode.InvalidMetadataFieldValue;

        case 332:
            return ResponseErrorCode.CrossBorderNotAcceptedMissingId;

        case 333:
            return ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber;

        case 334:
            return ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod;

        case 335:
            return ResponseErrorCode.CrossBorderNotAcceptedMissingName;

        case 336:
            return ResponseErrorCode.LimitExceededForPaymentType;

        case 337:
            return ResponseErrorCode.LimitExceededForMerchant;

        case 338:
            return ResponseErrorCode.TransactionNotFound;

        case 339:
            return ResponseErrorCode.DuplicateTransaction;

        case 340:
            return ResponseErrorCode.PaymentWalletRejected;

        case 341:
            return ResponseErrorCode.InsufficientMerchantInformation;

        case 342:
            return ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency;

        case 343:
            return ResponseErrorCode.GatewayServerError;

        case 344:
            return ResponseErrorCode.PaymentMethodTemporarilyUnavailable;

        case 345:
            return ResponseErrorCode.PaymentCanceled;

        case 346:
            return ResponseErrorCode.ExceededPendingThreshold;

        case 403:
            return ResponseErrorCode.CardLocked;

        case 420:
            return LOCALE_LABELS.SERVICE_RESTRICTED;

        default:
            return null;
    }
};

type ErrorType = RequestResponseBaseError | TokenError | PaymentError;

export const errorLabel = (error: ErrorType, paymentType: PaymentType): string => {
    if (!error) {
        return ResponseErrorCode.UnknownError;
    }

    if (error instanceof RequestResponseBaseError) {
        const { errorResponse } = error;

        // Handling of custom labels overwrite
        switch (errorResponse.code) {
            case ResponseErrorCode.InvalidCardTypeForCapture:
                return ERRORS_ALERTS_CAPTURE_CREDIT_ONLY;

            default:
                return errorCodeLabel(errorResponse.code);
        }
    }

    if (error instanceof TokenError) {
        return error.message;
    }

    return convertProcessingErrorCode(error.code, paymentType) || ResponseErrorCode.UnknownError;
};

export const getErrorHttpCode = (error: ErrorType): number | null => {
    if (!error) {
        return null;
    }

    return error instanceof RequestResponseBaseError ? error.errorResponse.httpCode : Number(error["code"]) || null;
};
