import { RequestErrorCode, ResponseErrorCode } from "univapay-node";

import * as ERRORS_LABELS from "../labels/errors";

export const LABELS: Record<string, string> = {
    [ERRORS_LABELS.ERRORS_TITLE]: "ошибка",

    [ERRORS_LABELS.ERRORS_ALERTS_CONNECTOR]: "Невозможно подключиться к оформлению заказа из исходного приложения.",
    [ERRORS_LABELS.ERRORS_ALERTS_PARAMETERS]: "Невозможно начать оформление заказа, поскольку параметры недействительны.",
    [ERRORS_LABELS.ERRORS_ALERTS_APPLICATION_TIMEOUT]: "Не удаётся установить соединение. Проверьте связь и начните заново.",
    [ERRORS_LABELS.ERRORS_ALERTS_TIMEOUT]: "Во время внутренней обработки истекло время. Пожалуйста, обратитеь в службу поддержки.",
    [ERRORS_LABELS.ERRORS_ALERTS_SESSION_TIMEOUT]: "Возник тайм-аут.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN_PAYMENT_TYPE]: "Невозможно начатаь оформление заказа, поскольку метод оплаты недействителен.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_PAYMENT_TYPE]: "Этот способ оплаты не поддерживается",
    [ERRORS_LABELS.ERRORS_ALERTS_NO_SUPPORTED_PAYMENT_TYPE]: "Этот способ оплаты не поддерживается",
    [ERRORS_LABELS.ERRORS_ALERTS_UNKNOWN]: "Произошла ошибка. Приносим извинения за неудобства. Повторите попытку позже.",
    [ERRORS_LABELS.ERRORS_ALERTS_PRIVILEGES]: "У учётной записи нет разрешения на использование токенов регулярного выставления счетов.",
    [ERRORS_LABELS.ERRORS_ALERTS_USAGE]: "Токены регулярного выставления счетов требуют ограничений на использование.",
    [ERRORS_LABELS.ERRORS_ALERTS_FORBIDDEN_CARD]: "Этот тип карт не поддерживается.",
    [ERRORS_LABELS.ERRORS_ALERTS_DOMAIN]: "Невозможно выполнить оформление заказа из этого домена",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_FAILED]: "Не удалось создать токен",
    [ERRORS_LABELS.ERRORS_ALERTS_TOKEN_CVV_AUTHORIZATION_FAILED]: "Время ожидания CVV-аутентификации истекло.",
    [ERRORS_LABELS.ERRORS_ALERTS_UNSUPPORTED_CARD_INSTALLMENT]: "Рассрочка платежа не поддерживается.",

    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE]: "Сервис недоступен",
    [ERRORS_LABELS.ERRORS_ALERTS_SERVICE_UNAVAILABLE_INFO]: "На данный момент услуга временно недоступна. Приносим извинения за неудобства. Повторите попытку позже.",

    [ERRORS_LABELS.ERRORS_ALERTS_CAPTURE_CREDIT_ONLY]: "Дебетовые и предоплаченные карты поддерживают только для фактических продаж, но не для предварительных.",

    [ERRORS_LABELS.ERROR_CHECKOUT_CHARGE]: "Обработка платежа не удалась",
    [ERRORS_LABELS.ERROR_CHECKOUT_SUBSCRIPTION]: "Ошибка процесса регулярного выставления счетов.",
    [ERRORS_LABELS.ERROR_CHECKOUT_PARAMS]: "Неверный параметр.",
    [ERRORS_LABELS.PROCESSING_ERROR]: "Ошибка обработки",
    [ERRORS_LABELS.ERROR_ALERTS_PAIDY_PAYMENT_REJECTED]: "Paydy не одобрил. Пожалуйста, свяжитесь с Paydy для получения подробной информации.",
    [ERRORS_LABELS.SERVICE_RESTRICTED]: "Услуга была недоступна в течение определенного периода времени из-за слишком большого количества неудачных попыток оплаты",
    [ERRORS_LABELS.EXPIRATION_DATE_ERROR]: "Expiration date error",

    /* request */
    [RequestErrorCode.RequestError]: "Неверный запрос",

    /* generic */
    [ResponseErrorCode.BadRequest]: "Ошибка. Неверный запрос",
    [ResponseErrorCode.Forbidden]: "Запрещенная операция",
    [ResponseErrorCode.NotFound]: "Ресурс не найден",
    [ResponseErrorCode.NotAllowed]: "Операция не разрешена",
    [ResponseErrorCode.Conflicted]: "Конфликт с другими ресурсами",
    [ResponseErrorCode.TooManyRequests]: "Слишком много запросов к API",
    [ResponseErrorCode.InternalServerError]: "Произошла внутренняя ошибка. (Обратитесь в центр поддержки. 0570-035-672).",
    [ResponseErrorCode.ServiceUnavailable]: "Сервис недоступен",
    [ResponseErrorCode.NotAuthorized]: "Операция не разрешена",

    /* global */
    [ResponseErrorCode.UnknownError]: "Неожиданная ошибка. Свяжитесь со службой поддержки.",
    [ResponseErrorCode.Timeout]: "Время ответа на запрос истекло. Обратитесь в центр поддержки 0570-035-672.",
    [ResponseErrorCode.DBError]: "Внутренняя ошибка базы данных. Свяжитесь со службой поддержки.",
    [ResponseErrorCode.InvalidRequest]: "неверный запрос",
    [ResponseErrorCode.UnableToGetIdempotentResult]: "Был найден кэш, соответствующий клавишам 冪 冪, поэтому запрошенное содержимое не было обработано. Попытка получить кэш результатов предыдущей обработки не удалась.",
    [ResponseErrorCode.ServiceUnavailableTryAgain]: "Сервис временно недоступен. Пожалуйста, повторите попытку позже.",
    [ResponseErrorCode.RateLimitExceeded]: "Превышен лимит запросов API.",

    /* auth */
    [ResponseErrorCode.InvalidIpAddress]: "Неверный IP-адрес",
    [ResponseErrorCode.InvalidDomain]: "Домен в заголовке запрашиваемого Origin не зарегистрирован в домене указанного маркера приложения.",
    [ResponseErrorCode.InvalidCredentials]: "Неверные данные учётной записи.",
    [ResponseErrorCode.AuthHeaderMissing]: "Заголовок авторизации не указан.",
    [ResponseErrorCode.InvalidPermissions]: "Неправильный тип токена приложения или у учетной записи недостаточно прав.",
    [ResponseErrorCode.ImproperAuth]: "Статус авторизации неверен. Пожалуйста, свяжитесь со службой поддержки.",
    [ResponseErrorCode.CouldNotRefreshAuth]: "Не удалось обновить токен входа. Пожалуйста, свяжитесь со службой поддержки.",
    [ResponseErrorCode.UserBanned]: "Этому пользователю запрещено пользоваться сервисом.",
    [ResponseErrorCode.InvalidLoginToken]: "Токен входа недействителен.",
    [ResponseErrorCode.InvalidAppToken]: "Неверный токена приложения.",
    [ResponseErrorCode.ExpiredLoginToken]: "Срок действия вашего токена на вход истек.",
    [ResponseErrorCode.OutdatedAppToken]: "Версия токена приложения устарела. Создайте новый токен.",
    [ResponseErrorCode.ChargeTooQuick]: "Попытка создать несколько платежей в течение указанного периода времени с помощью ограниченного повторяющегося токена.",

    /* file upload */
    [ResponseErrorCode.FileUploadError]: "Ошибка загрузки файла",
    [ResponseErrorCode.FileMaxSizeExceeded]: "Размер загруженного файла слишком велик.",
    [ResponseErrorCode.FileInvalidType]: "Тип загруженного файла MIME неверен.",
    [ResponseErrorCode.FileNotFound]: "файл не найден",

    /* crud actions */
    [ResponseErrorCode.TokenGenerationException]: "Возникло исключение при создании токена",
    [ResponseErrorCode.TokenForWrongStore]: "Хранилище токенов транзакций отличается от хранилища периодических выставления счетов.",
    [ResponseErrorCode.NonSubscriptionPayment]: "При создании платежа укажите одноразовый или повторяющийся токен.",
    [ResponseErrorCode.BankAccountExists]: "Этот банковский счет уже существует",
    [ResponseErrorCode.EmailExists]: "Этот адрес электронной почты уже существует",
    [ResponseErrorCode.StoreExists]: "Этот магазин уже существует",
    [ResponseErrorCode.GatewayCredentialsExists]: "Учетные данные шлюза уже существуют",
    [ResponseErrorCode.NonUniqueRuleForMerchant]: "Правила для торговцев не уникальны",
    [ResponseErrorCode.NonUniqueRuleSetForMerchant]: "Набор правил для продавцов не уникален",
    [ResponseErrorCode.NonUniqueRule]: "Правила не уникальны",
    [ResponseErrorCode.RulePriorityMustBePositive]: "Приоритет правила должен быть положительным.",
    [ResponseErrorCode.NonExistingRule]: "Не существующее правило",
    [ResponseErrorCode.MustContainUniqueElementsOnly]: "Элемент должен быть уникальным",
    [ResponseErrorCode.WebhookURLExists]: "Указанный URL уже зарегистрирован.",
    [ResponseErrorCode.GroupExists]: "Эта группа уже существует",
    [ResponseErrorCode.PrimaryBankAccountNotFound]: "Основной настроенный банковский счет не найден",
    [ResponseErrorCode.MustHaveAPrimaryBankAccount]: "Требуется основной настроенный банковский счет.",
    [ResponseErrorCode.VerificationDataExists]: "Данные для подтверждения уже существуют",
    [ResponseErrorCode.TemplateExists]: "Шаблон уже существует",
    [ResponseErrorCode.StratusCredentialsExists]: "Учетные данные CAFIS уже существуют",
    [ResponseErrorCode.StratusRuleStillInUse]: "Правило CAFIS уже используется",
    [ResponseErrorCode.ResourceLimitReached]: "Достигнут лимит ресурса.",
    [ResponseErrorCode.GatewayConfigurationExists]: "Конфигурация шлюза уже существует",
    [ResponseErrorCode.RequiresValidMerchantGatewayConfig]: "Настройки шлюза не найдены",
    [ResponseErrorCode.MergedConfigurationNotFound]: "Настройки шлюза не найдены",

    /* payments and charges */
    [ResponseErrorCode.InvalidTokenType]: "Неверный тип токена транзакции.",
    [ResponseErrorCode.InvalidToken]: "Недействительный токен",
    [ResponseErrorCode.InvalidCard]: "Недействительная карта",
    [ResponseErrorCode.TransactionTokenIsNotRecurring]: "Токен не является токеном регулярного выставления счетов.",
    [ResponseErrorCode.ForbiddenIP]: "Страна, определенная по IP-адресу запрашивающего, не входит в список разрешенных стран.",
    [ResponseErrorCode.InvalidUserData]: "Пользовательские данные или данные запроса недействительны.",
    [ResponseErrorCode.NonUniqueActiveToken]: "Токен активной транзакции уже существует.",
    [ResponseErrorCode.TransactionAlreadyProcessed]: "Используемые токены транзакции не могут быть указаны.",
    [ResponseErrorCode.TransactionTokenExpired]: "Срок действия токена транзакции истек.",
    [ResponseErrorCode.NoTestCardInLiveMode]: "Тестовые карты нельзя использовать в рабочем режиме.",
    [ResponseErrorCode.ProcessingModeMismatch]: "Несоответствие режима обработки",
    [ResponseErrorCode.PaymentTypeNotAllowed]: "Не поддерживаемый способ оплаты",
    [ResponseErrorCode.RecurringTokenNotAllowed]: "Токен регулярного выставления счетов не разрешен.",
    [ResponseErrorCode.RecurringTokenDisabled]: "Токен транзакции недействителен, или у вашей учетной записи нет разрешения на использование повторяющихся токенов.",
    [ResponseErrorCode.RecurringUsageLimitRequired]: "Параметр Usage_limit является обязательным.",
    [ResponseErrorCode.RecurringUsageRequiresCVV]: "Требуется CVV",
    [ResponseErrorCode.CvvAuthorizationNotCompleted]: "Требуется CVV",
    [ResponseErrorCode.UsageLimitNotApplicable]: "Не возможно указать Usage_limit.",
    [ResponseErrorCode.CardProcessingDisabled]: "Оплата картой отключена.",
    [ResponseErrorCode.QRProcessingDisabled]: "Оплата по QR-код отключена.",
    [ResponseErrorCode.PaidyProcessingDisabled]: "Оплата по Paydy отключена.",
    [ResponseErrorCode.MerchantQRProcessingDisabled]: "Оплата по MerchantQR отключена.",
    [ResponseErrorCode.OnlineProcessingDisabled]: "Оплата онлайн отключена.",
    [ResponseErrorCode.ConvenienceProcessingDisabled]: "Оплата в киоске отключена.",
    [ResponseErrorCode.NotOneTimeToken]: "Не одноразовый токен.",
    [ResponseErrorCode.NotSubscriptionToken]: "Укажите токен периодического выставления счетов или токен повторяющегося платежа.",
    [ResponseErrorCode.NotRecurringToken]: "Это не токен регулярного выставления счетов.",
    [ResponseErrorCode.CurrencyMustMatchCharge]: "Валюта возврата должна совпадать с валютой выставления счета.",
    [ResponseErrorCode.NoDirectCurrencyGateway]: "Не существует настроенного шлюза, который можно было бы использовать без конвертации валюты.",
    [ResponseErrorCode.TokenMustBeConfirmed]: "Неподтверждённый токен",
    [ResponseErrorCode.ConfirmationRequiresEmail]: "Для подтверждения требуется электронная почта",
    [ResponseErrorCode.WrongConfirmationCode]: "Неверный код подтверждения",
    [ResponseErrorCode.RefundNotWithinBounds]: "Сумма возврата не находится в допустимом диапазоне. Должно быть больше 0 и меньше или равно сумме списания.",
    [ResponseErrorCode.PartialRefundNotSupported]: "Частичный возврат средств невозможен.",
    [ResponseErrorCode.InvalidTransfer]: "Недействительная перевод.",
    [ResponseErrorCode.TransferAlreadyExists]: "Перевод уже осуществлен.",
    [ResponseErrorCode.NoLedgers]: "Отсутствует реестр.",
    [ResponseErrorCode.FailedToAssociateLedgers]: "Не удалось найти реестр",
    [ResponseErrorCode.LiveModeNotEnabledWhenUnverified]: "Чтобы использовать рабочий режим, необходимо подтвердить учетную запись.",
    [ResponseErrorCode.SelfTransferNotPermitted]: "Перевод самому себе не разрешён.",
    [ResponseErrorCode.CardLocked]: "Эта карта временно заблокирована, поскольку количество сбоев за определенный период превысило лимит.",
    [ResponseErrorCode.SubscriptionProcessing]: "Обрабатываем регулярное выставление счетов.",
    [ResponseErrorCode.AlreadyCaptured]: "Целевой счет уже захвачен или полномочия не были завершены",
    [ResponseErrorCode.CannotUpdateTransactionToken]: "Невозможно обновить токен транзакции",
    [ResponseErrorCode.CaptureAmountTooLarge]: "Слишком большая сумма захвата",
    [ResponseErrorCode.PartialCaptureNotSupported]: "Частичный захват не поддерживается.",
    [ResponseErrorCode.DebitAuthorizationDisabled]: "Авторизация дебетовой карты отключена",
    [ResponseErrorCode.PrePaidAuthorizationDisabled]: "Авторизация предоплаченной карты отключена",

    [ResponseErrorCode.NoGatewaysAvailable]: "Доступные шлюзы не найдены.",
    [ResponseErrorCode.NoGatewayCredentialsAvailable]: "Нет действительных учетных данных шлюза",
    [ResponseErrorCode.NoGatewayTransactionIdAvailable]: "Нет идентификатора транзакции",
    [ResponseErrorCode.PaymentTypeNotSupportedForCheck]: "Способ оплаты не поддерживается",
    [ResponseErrorCode.NoGatewayCredentialsForSelectedPaymentType]: "Отсутствуют учетные данные шлюза для выбранного способа оплаты.",
    [ResponseErrorCode.DisabledPaymentType]: "Способ оплаты отключен",
    [ResponseErrorCode.CardBrandNotSupported]: "Для карты указанного типа шлюз не настроен.",
    [ResponseErrorCode.CardCountryNotSupported]: "Для указанной страны-эмитента карты не установлен шлюз.",
    [ResponseErrorCode.CVVRequired]: "Требуется CVV.",
    [ResponseErrorCode.LastNameRequired]: "Требуется фамилия владельца карты отделённая пробелом.",
    [ResponseErrorCode.AuthNotSupported]: "Не установлен шлюз, поддерживающий авторизацию.",
    [ResponseErrorCode.GatewayConfigurationRequired]: "Необходимы настройки шлюза",
    [ResponseErrorCode.GatewayConfigurationNotRequired]: "Настройка шлюза не требуется",

    [ResponseErrorCode.InvalidBinRange]: "Номер BIN в недопустимом диапазоне.",
    [ResponseErrorCode.OverlappingStratusTerminalIdRange]: "Повторяющийся идентификатор терминала Stratus",
    [ResponseErrorCode.InvalidStratusTerminalIdRange]: "Неверный идентификатор терминала Stratus",
    [ResponseErrorCode.InvalidCardCompany]: "Карта недействительной компании.",
    [ResponseErrorCode.VerificationRequired]: "Требуется идентификатор скрининга",

    /* validation responses */
    [ResponseErrorCode.ChangeProhibited]: "Изменение этого поля запрещено.",
    [ResponseErrorCode.ForbiddenParameter]: "У вас недостаточно прав или вы не соответствуете требованиям для использования этого параметра.",
    [ResponseErrorCode.ValidationError]: "Ошибка валидации параметров запроса. Подробности смотрите в errors.",
    [ResponseErrorCode.RequiredValue]: "Это обязательный параметр.",
    [ResponseErrorCode.MustBeFutureTime]: "Укажите дату и время в будущем.",
    [ResponseErrorCode.InvalidFormat]: "Неверный формат.",
    [ResponseErrorCode.InvalidPercentFee]: "Неверное значение",
    [ResponseErrorCode.InvalidCardNumber]: "Неверный формат номера карты.",
    [ResponseErrorCode.InvalidCardExpiration]: "Срок действия карты истек.",
    [ResponseErrorCode.InvalidCVV]: "CVV должен представлять собой целое число из 3–5 цифр.",
    [ResponseErrorCode.InvalidFormatLength]: "Неверная длинна формата",
    [ResponseErrorCode.InvalidFormatUUID]: "Неверный формат uuid",
    [ResponseErrorCode.InvalidFormatBase64]: "Неверный формат base64",
    [ResponseErrorCode.InvalidFormatEmail]: "Неверный формат адреса электронной почты.",
    [ResponseErrorCode.InvalidCardDescriptor]: "Неверный формат описания карты.",
    [ResponseErrorCode.InvalidFormatCurrency]: "Неверный код валюты.",
    [ResponseErrorCode.InvalidCurrency]: "Неверная валюта.",
    [ResponseErrorCode.InvalidAmount]: "Сумма должна быть целым числом больше 0.",
    [ResponseErrorCode.InvalidEventForStore]: "Недопустимое событие для этого магазина.",
    [ResponseErrorCode.InvalidEventForPlatform]: "Неверное событие для платформы",
    [ResponseErrorCode.InvalidEventForMerchant]: "Неверное событие для продавца.",
    [ResponseErrorCode.InvalidFormatDomain]: "Неверный формат доменного имени.",
    [ResponseErrorCode.InvalidFormatUrl]: "Недействительный URL-адрес",
    [ResponseErrorCode.InvalidFormatObject]: "Неверный формат объекта",
    [ResponseErrorCode.InvalidFormatCountry]: "Неверный формат страны.",
    [ResponseErrorCode.InvalidPhoneNumber]: "Неверный формат номера телефона.",
    [ResponseErrorCode.InvalidFormatSwiftCode]: "Неверный формат Swift-кода",
    [ResponseErrorCode.InvalidFormatRoutingNumber]: "Неверный формат routing number.",
    [ResponseErrorCode.InvalidFormatRoutingCode]: "Неверный формат routing code.",
    [ResponseErrorCode.InvalidFormatIfcsCode]: "Неверный формат IFCS кода.",
    [ResponseErrorCode.InvalidFormatBankAccountNumber]: "Неверный формат номера банковского счета.",
    [ResponseErrorCode.InvalidPasswords]: "Неверный пароль",
    [ResponseErrorCode.InvalidTimePeriod]: "Неверный формат периода.",
    [ResponseErrorCode.InvalidDayOfWeek]: "Неверный день недели",
    [ResponseErrorCode.InvalidWeekOfMonth]: "Неверное количество недель.",
    [ResponseErrorCode.InvalidDayOfMonth]: "Неправильный день месяца",
    [ResponseErrorCode.InvalidColorsSize]: "Неверный размер цветов",
    [ResponseErrorCode.NestedJsonNotAllowed]: "Вложенные форматы JSON не допускаются.",
    [ResponseErrorCode.InvalidFormatDate]: "Неверный формат даты",
    [ResponseErrorCode.InvalidChargeStatus]: "Неверный статус платежа.",
    [ResponseErrorCode.InvalidQRScanGateway]: "Платежный шлюз QR -кода не установлен или не действителен",
    [ResponseErrorCode.NotQRCharge]: "Это не счёт QR-коду.",
    [ResponseErrorCode.NotOnlineCharge]: "Это не онлайн счёт.",
    [ResponseErrorCode.IssuerTokenEmpty]: "Токен эмитента пуст",
    [ResponseErrorCode.CardLimitExceededForStore]: "Общая сумма платежей по карте за определенный период превысила лимит.",
    [ResponseErrorCode.InvalidLanguage]: "Неверный код языка.",
    [ResponseErrorCode.SubscriptionNotAllowed]: "Регулярные платежи не допускаются.",
    [ResponseErrorCode.OneTimeOnlyAllowed]: "Rазрешены только одноразовые токены.",
    [ResponseErrorCode.AuthExpired]: "Срок действия авторизации истек",
    [ResponseErrorCode.InvalidTemplateKey]: "Ключ шаблона недействителен.",
    [ResponseErrorCode.NonPublicTemplate]: "Hе общедоступный шаблон",
    [ResponseErrorCode.OnlyJapanesePhoneNumberAllowed]: "Поддерживаются только японские номера телефонов.",
    [ResponseErrorCode.ExpirationDateOutOfBounds]: "Срок действия за пределами допустимого диапазона.",
    [ResponseErrorCode.UnsupportedLanguage]: "Это язык не поддерживается",
    [ResponseErrorCode.DefaultLanguageNotSupported]: "Язык по умолчанию не установлен",
    [ResponseErrorCode.OnlyForCardPayment]: "Единственный доступный способ оплаты – карта.",
    [ResponseErrorCode.CaptureOnlyForCardPayment]: "Захват возможен только при оплате картой.",
    [ResponseErrorCode.InvalidCardTypeForCapture]: "Авторизация невозможна, поскольку невозможно определить тип карты.",
    [ResponseErrorCode.InvalidScheduledCaptureDate]: "Дата и время выполнения захвата должны быть в пределах от 1 часа и 7 дней.",
    [ResponseErrorCode.IncoherentDateRange]: "Неверный диапазон дат.",
    [ResponseErrorCode.InvalidMerchantStatus]: "Неверный статус продавца",
    [ResponseErrorCode.MustHaveOneElement]: "Требуется хотя бы один элемент",
    [ResponseErrorCode.MustHaveAtLeastOneElement]: "Требуется хотя бы один элемент",
    [ResponseErrorCode.ThresholdMustBeNull]: "Порог должен быть нулевым",
    [ResponseErrorCode.IllegalNumberOfItems]: "Неверное количество элементов",
    [ResponseErrorCode.UnableToReadCredentials]: "Не удалось загрузить учетные данные",
    [ResponseErrorCode.GatewayError]: "Ошибка шлюза",
    [ResponseErrorCode.GatewayNoLongerSupported]: "Этот шлюз не поддерживает",
    [ResponseErrorCode.InvalidChargeAmountLimit]: "Неверный лимит суммы платежа",
    [ResponseErrorCode.PlatformCurrencyRequiredInCvvAmount]: "Пожалуйста, укажите сумму CVV в валюте платформы.",
    [ResponseErrorCode.ChargeAmountTooLow]: "Сумма платежа меньше минимальной.",
    [ResponseErrorCode.ChargeAmountTooHigh]: "Сумма счета превышает максимальную.",
    [ResponseErrorCode.MustContainPlatformCurrency]: "Пожалуйста, укажите в валюте платформы",
    [ResponseErrorCode.CurrencyMustBeInAmountsList]: "Пожалуйста, укажите валюту",
    [ResponseErrorCode.InvalidJapanesePostalCode]: "Неверный почтовый индекс Японии.",

    [ResponseErrorCode.BrandNotDefined]: "Не существующий бренд.",

    /* Used when creating a new merchant */
    [ResponseErrorCode.OnlyASCII]: "Поддерживаются только символы в Ascii.",
    [ResponseErrorCode.UniqueCharacters]: "Уникальные знаки.",
    [ResponseErrorCode.AtLeastOneDigit]: "Требуется хотя бы одна цыфра.",
    [ResponseErrorCode.AtLeastOneLetter]: "Требуется хотя бы одна буква.",
    [ResponseErrorCode.EmptyRoles]: "Роль не может быть пустой.",
    [ResponseErrorCode.EditOwnRolesNotAllowed]: "Изменение собственной роли не допускается.",
    [ResponseErrorCode.EditOwnStatusNotAllowed]: "Изменение собственной роли не допускается.",
    [ResponseErrorCode.InvalidPathValue]: "Неверный путь",
    [ResponseErrorCode.InvalidCardBrand]: "Неизвестный тип карты.",
    [ResponseErrorCode.UnsupportedCountry]: "Эта страна не поддерживает.",
    [ResponseErrorCode.UnsupportedCurrency]: "Эта валюта не поддерживается.",
    [ResponseErrorCode.CannotBanSelf]: "Не возможно самому выключить свою учётную запись.",
    [ResponseErrorCode.CannotSelfTerminate]: "Невозможно самозавершить",

    [ResponseErrorCode.NoDuplicateCurrencies]: "Повтор валюты не разрешён.",
    [ResponseErrorCode.PlatformNotFound]: "Платформа не найдена",
    [ResponseErrorCode.InvalidPlatform]: "Спецификация платформы неверна.",
    [ResponseErrorCode.InvalidInvoiceFeeConfiguration]: "Неверная настройка сбора по счету",
    [ResponseErrorCode.InvalidPlatformRole]: "Неверная роль платформы",

    /* verification */
    [ResponseErrorCode.DataNotSubmitted]: "Данные не предоставлены.",
    [ResponseErrorCode.NoBankAccount]: "Отсутствует банковскоий счет.",
    [ResponseErrorCode.PercentFeeNotSubmitted]: "Ставка сбора не представлена.",
    [ResponseErrorCode.InsufficientSystemManagerInfo]: "Недостаточно информации о системном администраторе",

    /* gateway credentials */
    [ResponseErrorCode.CredentialsExist]: "Учетные данные уже существуют.",
    [ResponseErrorCode.OnlyOnePaidyCredentialsAllowed]: "Можно использовать только один аккаунт Paidy.",
    [ResponseErrorCode.OnlyOneDBaraiCredentialsAllowed]: "Можно использовать только один аккаунт dPay",
    [ResponseErrorCode.OnlyMerchantOrStoreCredentialsAllowed]: "Требуются учетные данные продавца и магазина.",
    [ResponseErrorCode.RefundExceedsChargeAmount]: "Сумма возврата превышает сумму оплаты.",
    [ResponseErrorCode.CannotRefundUnsuccessfulCharge]: "Сборы за не прошедший платёж не подлежат возврату.",
    [ResponseErrorCode.RefundNotAllowed]: "Способ оплаты не подходит для возврата средств, или возврат не был авторизован.",
    [ResponseErrorCode.RefundPercentageExceeded]: "Лимит возврата превышен.",
    [ResponseErrorCode.CancelNotAllowed]: "Этот способ оплаты не поддерживает отмену, или целевой статус выставления счетов не может быть отменен. ,,",

    [ResponseErrorCode.PaidyShippingAddressNeedsOneOptionalField]: "Для доставки требуется заполнить одно не обязательное поле.",

    [ResponseErrorCode.RevertNotAllowed]: "Возврат не допускается",

    /* apple pay */
    [ResponseErrorCode.ApplePayNotEnabled]: "Apple Pay не поддерживается",
    [ResponseErrorCode.ApplePayAlreadyEnabled]: "Apple Pay уже подключён",
    [ResponseErrorCode.ApplePayCertificateAlreadyUpdated]: "Сертификат Apple Pay уже обновлен.",
    [ResponseErrorCode.ApplePayUnsupportedAlgorithm]: "Алгоритм не поддерживается ApplePay",
    [ResponseErrorCode.ApplePayCertificateNotFound]: "Сертификат Apple Pay не найден",
    [ResponseErrorCode.ApplePayUnableToGenerateCertificateRequest]: "Apple Pay не может создать запрос сертификата",
    [ResponseErrorCode.ApplePayInvalidConfiguration]: "Apple Pay Неверная конфигурация",
    [ResponseErrorCode.ApplePayInvalidCertificate]: "Apple Pay неверный сертификат",
    [ResponseErrorCode.ApplePayInvalidAlgorithm]: "Apple Pay Invalid Algorithm",
    [ResponseErrorCode.ApplePayInvalidCertificateFormat]: "Apple Pay неверный формат сертификата",
    [ResponseErrorCode.ApplePayInvalidSignature]: "Apple Pay неверная подпись",
    [ResponseErrorCode.ApplePayError]: "Apple Pay ошибка",
    [ResponseErrorCode.ApplePayCertificateAlreadySet]: "Сертификат Apple Pay уже был установлен",
    [ResponseErrorCode.ApplePayCertificateStillInUse]: "Сертификат Apple Pay все еще используется",

    /* Charge errors */
    [ResponseErrorCode.CardNumberNotValid]: "Ошибка номера карты",
    [ResponseErrorCode.CardExpirationMonthInvalid]: "Месяц действия карты недействителен.",
    [ResponseErrorCode.CardExpirationYearInvalid]: "Год действия карты недействителен.",
    [ResponseErrorCode.CardExpired]: "Срок действия карты истек",
    [ResponseErrorCode.CardCVVInvalid]: "Неверный код безопасности карты",
    [ResponseErrorCode.CardRejected]: "Отказ по карте (обратитесь к эмитенту карты)",
    [ResponseErrorCode.CardInvalid]: "Карта недействительна",
    [ResponseErrorCode.ChargeInvalidData]: "Неверные данные запроса.",
    [ResponseErrorCode.TooManyChargeRequests]: "За короткий период времени с одной карты поступило слишком много запросов на списание средств. Пожалуйста, повторите попытку позже.",
    [ResponseErrorCode.CancelUnavailable]: "Этот сбор не может быть отменен.",
    [ResponseErrorCode.ChargeExpired]: "Время выполнения платежа истекло",
    [ResponseErrorCode.SeizeCard]: "Ошибка проверки подлинности (карта потеряна, украдена, недействительна, ограничена и т. д.)",
    [ResponseErrorCode.ContactBank]: "Ошибка проверки сертификации (обратитесь к эмитенту карты)",
    [ResponseErrorCode.FraudSuspected]: "Ошибка проверки сертификации (подозрение на несанкционированное использование/ограничения безопасности)",
    [ResponseErrorCode.BankSystemFailure]: "Ошибка транзакции от компании-эмитента карты",
    [ResponseErrorCode.DynamicDescriptorNotSupported]: "Динамические дескрипторы не поддерживаются.",
    [ResponseErrorCode.PaymentCodeInvalid]: "Штрих-код/QR-код недействителен.",
    [ResponseErrorCode.PaymentCodeExpired]: "Срок действия штрих-кода/QR-кода истек",
    [ResponseErrorCode.PaymentCodeAlreadyUsed]: "Штрих-код/QR-код уже использовался",
    [ResponseErrorCode.PaymentCodeStillInUse]: "Штрих-код/QR-код всё ещё используются",
    [ResponseErrorCode.RejectedHighRisk]: "Отклонено из-за превышения порога риска",
    [ResponseErrorCode.ConfirmationPeriodExpired]: "Период подтверждения закончился",
    [ResponseErrorCode.RevertFailed]: "Возврат не удался. Требуется ручная обработка.",
    [ResponseErrorCode.RefundFailed]: "Возврат не удался.",
    [ResponseErrorCode.PaymentWalletInsufficientFunds]: "В кошельке недостаточно средств.",
    [ResponseErrorCode.InvalidMetadataFieldValue]: "Значение поля метаданных недействительно или отсутствует.",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingId]: "Трансграничные операции не допускаются без удостоверения личности.",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingPhoneNumber]: "Трансграничные операции не допускаются без номера телефона.",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedPaymentMethod]: "Трансграничные операции не допускаются с этим способом оплаты.",
    [ResponseErrorCode.CrossBorderNotAcceptedMissingName]: "Трансграничные операции не допускаются без имени.",
    [ResponseErrorCode.LimitExceededForPaymentType]: "Превышен лимит транзакций способа оплаты.",
    [ResponseErrorCode.LimitExceededForMerchant]: "Превышен лимит учётной записи.",
    [ResponseErrorCode.TransactionNotFound]: "Переводов не найдено",
    [ResponseErrorCode.DuplicateTransaction]: "Дупликат перевода",
    [ResponseErrorCode.PaymentWalletRejected]: "Платежный кошелек отклонен подключенной системой",
    [ResponseErrorCode.InsufficientMerchantInformation]: "Учетназ запись не содержит информация, необходимой для подключенния к системе.",
    [ResponseErrorCode.CrossBorderNotAcceptedUnacceptedCurrency]: "Трансграничные операции не допускаются в этой валюте.",
    [ResponseErrorCode.GatewayServerError]: "Ошибка серверного шлюза (обратитесь в центр поддержки. 0570-035-672)",
    [ResponseErrorCode.PaymentMethodTemporarilyUnavailable]: "Выбранный платеж временно недоступен из-за подключенной системы.",
    [ResponseErrorCode.PaymentCanceled]: "Платеж отменен",
    [ResponseErrorCode.ExceededPendingThreshold]: "Запрос на обработку платежа занял слишком много времени.",
    [ResponseErrorCode.InternalError]: "Внутренняя ошибка системы",

    /* online */
    [ResponseErrorCode.AlipayOnlineInvalidSignatureType]: "Неправильный тип подписи AlipayOnline",
    [ResponseErrorCode.PayPayOnlineInvalidPayMethod]: "Неавторизованный способ оплаты PayPayOnline",
    [ResponseErrorCode.PayPayOnlineInvalidMerchantId]: "Неверный код продавца PayPayOnline",

    [ResponseErrorCode.UnableToGenerateCertificateRequest]: "Невозможно запросить сертификат",
    [ResponseErrorCode.InvalidMerchantSettings]: "Неверные настройки партнерского магазина",
    [ResponseErrorCode.UnsupportedAlgorithm]: "Неподдерживаемый алгоритм",
    [ResponseErrorCode.InvalidPaymentToken]: "Неверный токен",
    [ResponseErrorCode.ExpiredPaymentToken]: "Срок действия платежного токена истек",
    [ResponseErrorCode.InvalidCertificate]: "Недействительный сертификат",
    [ResponseErrorCode.InvalidLeafCertificate]: "Неверный листовой сертификат",
    [ResponseErrorCode.InvalidIntermediateCertificate]: "Недействительный временный сертификат",
    [ResponseErrorCode.InvalidChainOfTrust]: "Неверная цепочка доверия.",
    [ResponseErrorCode.InvalidSignature]: "Недействительная подпись",

    /** platform */
    [ResponseErrorCode.PlatformKeyExists]: "Ключ платформы существует",
    [ResponseErrorCode.MerchantConsoleURIExists]: "URL консоли продавца существует.",

    /* idempotency */
    [ResponseErrorCode.IdempotencyKeyConflict]: "Конфликт идемпотентных ключей",

    /* bannedCards */
    [ResponseErrorCode.CardBanned]: "Запрещенные карта",
    [ResponseErrorCode.CardAlreadyBanned]: "Карта уже заблокированы",
    [ResponseErrorCode.CardNotBanned]: "Карта не запрещена",
    [ResponseErrorCode.TestCardCannotBeBanned]: "Тестовые карты не могут быть запрещены.",
    [ResponseErrorCode.InvalidMetric]: "Неверная единица измерения",
    [ResponseErrorCode.InvalidResource]: "Неверный ресурс",

    /* installments */
    [ResponseErrorCode.UseStartOn]: "Используйте дату начала выставления счетов",
    [ResponseErrorCode.PaymentInTimePeriod]: "Оплата по периодам",
    [ResponseErrorCode.SecondChargeAlreadyMade]: "Второй уже сделан.",
    [ResponseErrorCode.NotSupportedByProcessor]: "Не поддерживается обработчиком",
    [ResponseErrorCode.SubscriptionAlreadyCanceled]: "Регулярное выставление счетов отменено",
    [ResponseErrorCode.SubscriptionNotFound]: "Регулярное выставление счетов не найдено",
    [ResponseErrorCode.MustBeLowerThanFullAmount]: "Должно быть меньше общей суммы.",
    [ResponseErrorCode.InstallmentPlanNotFound]: "Оплата в рассрочку не найдена",
    [ResponseErrorCode.InstallmentAlreadySet]: "Регулярное выставление счетов невозможно изменть поскольку оно уже началось.",
    [ResponseErrorCode.InstallmentInvalidPlan]: "Неподдерживаемая план рассрочки.",
    [ResponseErrorCode.InstallmentInvalidPlanType]: "Неверный план рассрочка.",
    [ResponseErrorCode.InstallmentPaymentTypeNotAllowedForPlan]: "Способ оплаты не допускается для оплаты в рассрочку.",
    [ResponseErrorCode.CardProcessorDisabledForInstallmentPlan]: "Компания карты не поддерживает оплату в рассрочку.",
    [ResponseErrorCode.CardProcessorInstallmentFutureDated]: "Компания карты не позволяет вам устанавливать дату начала платежей в рассрочку.",
    [ResponseErrorCode.InstallmentInvalidInitialAmount]: "Неверная первая сумма рассрочки.",
    [ResponseErrorCode.InstallmentMaxPayoutPeriodExceeded]: "Срок оплаты рассрочки превышает максимальный период оплаты.",
    [ResponseErrorCode.InstallmentInsufficientAmountPerCharge]: "Сумма каждого взноса слишком мала",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetInitialAmount]: "При возобновляемом платеже невозможно установить первоначальную сумму платежа.",
    [ResponseErrorCode.InstallmentRevolvingPlanCannotSetSubsequentCyclesStart]: "При возобновляемом платеже невозможно установить дату начала регулярного выставления счетов.",
    [ResponseErrorCode.InstallmentProcessorInitialAmountsNotSupported]: "Указание начальной суммы не поддерживается.",
    [ResponseErrorCode.InstallmentProcessorPeriodNotSupported]: "Шлюз не поддерживает условия рассрочки",
    [ResponseErrorCode.CannotChangeToken]: "Токены транзакций для регулярных платежей в этом состоянии не могут быть изменены.",
    [ResponseErrorCode.SubscriptionNotEnabled]: "Регулярное выставление счетов отключено",
    [ResponseErrorCode.SubscriptionAlreadyEnded]: "Регулярное выставление счетов закончилось",
    [ResponseErrorCode.SubscriptionUnsupportedForInstallment]: "Регулярное выставление счетове не поддерживает оплату в рассрочку.",
    [ResponseErrorCode.InstallmentsNotEnabled]: "Рассрочка платежа не включена",
    [ResponseErrorCode.CannotChangeInstallmentAmount]: "Невозможно изменить сумму оплат по рассрочке.",
    [ResponseErrorCode.CannotChangeToInstallment]: "Невозможно изменить на оплату в рассрочку.",
    [ResponseErrorCode.MustBeMonthBasedToSet]: "Периодичность должна быть ежемесячной.",
    [ResponseErrorCode.MustBeWithinTwoCycles]: "Должно быть не более 2 раз.",
    [ResponseErrorCode.Deprecated]: "Устаревший параметр.",
    [ResponseErrorCode.CannotBeZero]: "Не может быть нулём",
    [ResponseErrorCode.NeedAtLeastTwoCycles]: "Должно быть не менее 2 раз.",
    [ResponseErrorCode.InstallmentInvalidCyclesCount]: "Неверное количестов платежей в рассрочку",

    [ResponseErrorCode.CannotSetInitialAmountToZero]: "Начальная сумма не может быть нулевой.",
    [ResponseErrorCode.CannotSetUntilSubscriptionStarted]: "Настройки невозможно изменить, пока не начнется регулярное выставление счетов.",
    [ResponseErrorCode.CannotSetAfterSubscriptionStarted]: "Настройки невозможно изменить, после начала регулярного выставления счетов.",
    [ResponseErrorCode.CannotChangeCanceledSubscription]: "Отмененное регулярное выставление счетов не может быть изменено.",

    /* Stratus merchant & store configuration */
    [ResponseErrorCode.StratusMerchantAlreadyExists]: "Магазин участник CAFIS уже используется",
    [ResponseErrorCode.StratusStoreAlreadyExists]: "Магазин CAFIS уже используется",
    [ResponseErrorCode.StratusStoreAllocationLimitReached]: "Магазин CAFIS достиг лимита",

    /* path bindables */
    [ResponseErrorCode.InvalidElasticIndex]: "Неверный эластичный индекс",
    [ResponseErrorCode.InvalidDynamoTable]: "Неверная динамо таблица",
    [ResponseErrorCode.InvalidDateHistogramInterval]: "Неверная дата интервал гистограммы",
    [ResponseErrorCode.InvalidSqsEndpointKey]: "Неверная конечная точка SQS",

    /* invalid card errors */
    [ResponseErrorCode.BinNotFound]: "Номер ячейки не найден.",
    [ResponseErrorCode.LuhnCheckFailed]: "Проверка Luhn не удалась.",
    [ResponseErrorCode.InvalidCardNumberLength]: "Неверное количество цифр в номере карты.",
    [ResponseErrorCode.CardPaymentDisabled]: "Оплата картой отключена.",
    [ResponseErrorCode.DebitDisabled]: "Дебетовая карта отключена.",
    [ResponseErrorCode.PrepaidDisabled]: "Предоплаченные карты отключены.",
    [ResponseErrorCode.CountryNotSupported]: "Карта из неподдерживаемой страны.",
    [ResponseErrorCode.Unspecified]: "Неподдерживаемая карта",
};

export default LABELS;
