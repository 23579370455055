import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { StateShape } from "checkout/ts/redux/store";
import { CheckoutType } from "univapay-node";

import { LOCALE_LABELS } from "../../locale/labels";
import { amountForCurrency } from "../../utils/money";

type OwnProps = { style: Record<string, string> };

const stateSelector = (state: StateShape) => ({
    fees: state.configuration.paymentMethods.find(({ key }) => key === state.checkout.paymentMethodKey)?.fees || 0,
});

export const ChargeHeader = ({ style }: OwnProps) => {
    const { formatMessage, formatNumber } = useIntl();
    const { amount, currency, checkoutType } = useCheckoutParams();
    const { fees } = useSelector(stateSelector);

    const hasCharge = checkoutType === CheckoutType.PAYMENT && !!amount && !!currency;

    return (
        hasCharge && (
            <h5 data-name="header-charge" style={style}>
                {formatMessage(
                    { id: LOCALE_LABELS.PAYMENT_BUTTONS_PAY },
                    { money: formatNumber(amountForCurrency(amount + fees, currency), { style: "currency", currency }) }
                )}
            </h5>
        )
    );
};
