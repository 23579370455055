import React, { ReactNode, useMemo } from "react";
import { ControlLabel, FormControlProps, FormGroup, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import classnames from "classnames";
import { Countries } from "common/locale/constants";
import { omit } from "lodash";
import { generate } from "randomstring";
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form";

import { ErrorMessages } from "./components/ErrorMessages";
import { IntlTelInput } from "./components/IntlTelInput";
import { RequiredIndicator } from "./components/RequiredIndicator";
import { formatAddons, TextInputAddons } from "./utils/addons";

type CustomProps = {
    addons?: ReactNode | TextInputAddons;
    showErrors?: boolean;
    validationLabel: string;
    isDark?: boolean;
    isHorizontal?: boolean;
    placeholder?: string;

    labelStyle?: Record<string, string>;
    errorStyle?: Record<string, string>;
    invalidStyle?: Record<string, string>;
    focusStyle?: Record<string, string>;
    inputStyle?: Record<string, string>;

    showPlaceholder?: boolean;
    initialValue?: string;
    className?: string;
    forcedCountryCode?: string;
};

export type PhoneNumberProps = CustomProps & FormControlProps;

export const PhoneNumberInput = ({
    addons,
    input,
    meta: { active, touched, invalid, submitting, error, initial },
    showErrors = true,
    disabled,
    label,
    required,
    labelStyle,
    errorStyle,
    invalidStyle,
    focusStyle,
    inputStyle,
    style,
    width,
    validationLabel = label,
    tabIndex,
    isDark,
    isHorizontal,
    className,
    placeholder = "0912345678",
    forcedCountryCode = null,
}: PhoneNumberProps & WrappedFieldProps) => {
    const { formatMessage } = useIntl();
    const { before, after, header: headerAddon } = formatAddons(addons);

    const isInvalid = (invalid || error) && touched;

    const currentFocusStyle = active ? focusStyle : {};
    const fieldStyle = isInvalid
        ? { width, ...inputStyle, ...invalidStyle, ...currentFocusStyle }
        : { width, ...inputStyle, ...currentFocusStyle };

    const isFullWidth = !fieldStyle.width;

    const value = {
        countryCode: forcedCountryCode || input.value?.countryCode || initial?.countryCode || "81",
        localNumber: input.value?.localNumber || initial?.localNumber || "",
    };

    const i18n = useMemo(
        () => ({
            ...Object.values(Countries).reduce(
                (acc, { iso, name }) => ({ ...acc, [iso]: formatMessage({ id: name }) }),
                {}
            ),

            searchPlaceholder: formatMessage({ id: LOCALE_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER }),
        }),
        []
    );

    return (
        <FormGroup
            controlId={generate()}
            className={classnames({
                "keep-color-on-error": showErrors && isInvalid,
                "dark-theme": isDark,
                "row": isHorizontal,
            })}
            validationState={isInvalid ? "error" : null}
            bsSize="small"
            style={{ ...style }}>
            {label && (
                <div className={classnames({ "col-xs-3 col-sm-2 inline-horizontal-content": isHorizontal })}>
                    <ControlLabel style={{ ...labelStyle }} className={classnames({ "full-width": isFullWidth })}>
                        {label}

                        {headerAddon && <span className="header-addon">{headerAddon}</span>}

                        <RequiredIndicator required={required} />
                    </ControlLabel>
                </div>
            )}

            <div
                className={classnames("phone-number-input", {
                    "col-xs-9 col-sm-10 inline-horizontal-content": isHorizontal && label,
                    "forced-country": forcedCountryCode,
                })}>
                <InputGroup className={active ? "input-group-focus" : ""} style={{ width: fieldStyle.width }}>
                    {before && <InputGroup.Addon>{before}</InputGroup.Addon>}

                    <IntlTelInput
                        initialValue={value}
                        onChange={input.onChange}
                        initOptions={{ showSelectedDialCode: true, nationalMode: false, i18n }}
                        disabled={disabled || submitting}
                        tabIndex={tabIndex}
                        style={style}
                        className={className}
                        value={value}
                        inputProps={{
                            ...omit(input, "value"),
                            onBlur: () => input.onBlur(input.value),
                            placeholder,
                        }}
                    />

                    {after && <InputGroup.Addon>{after}</InputGroup.Addon>}
                </InputGroup>

                {showErrors && isInvalid && (
                    <ErrorMessages fieldLabel={validationLabel} errors={error?.localNumber} style={{ ...errorStyle }} />
                )}
            </div>
        </FormGroup>
    );
};

type FieldProps = BaseFieldProps<PhoneNumberProps>;
type PhoneNumberFieldProps = Partial<Omit<Omit<PhoneNumberProps, "ref">, keyof FieldProps>> & FieldProps;

export const PhoneNumberField = (props: PhoneNumberFieldProps) => <Field component={PhoneNumberInput} {...props} />;
