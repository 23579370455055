import React, { useCallback, useState } from "react";
import { FormControl, FormControlProps, FormGroup } from "react-bootstrap";
import classnames from "classnames";
import { generate } from "randomstring";

export type SelectProps = FormControlProps & {
    inline?: boolean;
    isDark?: boolean;
    isHorizontal?: boolean;
    label?: React.ReactNode;

    labelStyle?: Record<string, string>;
    focusStyle?: Record<string, string>;
    inputStyle?: Record<string, string>;
    style?: Record<string, string>;
    width?: number;
};

export const RegisteredTokenSelect = ({
    value,
    label,
    required,
    inline,
    isDark,
    isHorizontal,
    onChange,
    labelStyle,
    focusStyle,
    inputStyle,
    width,
    style,
    ...props
}: SelectProps) => {
    const [active, setActive] = useState(false);
    const handleFocus = useCallback(() => setActive(true), []);
    const handleBlur = useCallback(() => setActive(false), []);

    return (
        <>
            <span style={{ ...labelStyle }}>{label}</span>

            <FormGroup
                controlId={generate()}
                className={classnames({
                    "dark-theme": isDark,
                    "row": isHorizontal,
                })}>
                <div
                    className={classnames({
                        "col-xs-9 col-sm-10 inline-horizontal-content": inline && isHorizontal,
                    })}>
                    <span className="form-control form-select-wrapper">
                        <FormControl
                            name="registered-token-select"
                            value={value}
                            required={required}
                            componentClass="select"
                            bsClass="form-select"
                            onChange={onChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            style={{ width, ...style, ...inputStyle, ...(active ? focusStyle : {}) }}
                            {...props}
                        />
                    </span>
                </div>
            </FormGroup>
        </>
    );
};
