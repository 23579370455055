import React, { KeyboardEvent, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { PatchedSupportedBrand } from "checkout/ts/redux/models/configuration";
import { cardBrandAPIName } from "checkout/ts/utils/cards";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { validationToErrors } from "checkout/ts/utils/errors";
import { UnivaMetadata } from "checkout/ts/utils/metadata";
import classnames from "classnames";
import { InlineStyleKeys, NewCardBrand } from "common/types";
import { needsCvv } from "common/validation/card";
import { has, isEmpty } from "lodash";
import Payment from "payment";
import { generate } from "randomstring";
import { compose } from "recompose";
import {
    Form,
    FormSection,
    formValueSelector,
    getFormMeta,
    getFormSyncErrors,
    InjectedFormProps,
    reduxForm,
} from "redux-form";
import { PaymentType, TransactionTokenType } from "univapay-node";

import { FORM_CHECKOUT_NAME, FORM_TOKEN_SELECT_NAME } from "../../../../../common/constants";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { amountForCurrency } from "../../../utils/money";
import { FormCheckoutData } from "../../checkout/FormCheckout";
import { SpinnerLoader } from "../../common/SpinnerLoader";
import { CheckboxField } from "../../forms/Checkbox";
import { PhoneNumberField } from "../../forms/PhoneNumberInput";
import { TextField } from "../../forms/Text";
import { CardCVVInput } from "../../forms/text/CardCVVInput";
import { CardExpInput } from "../../forms/text/CardExpInput";
import { CardNumberInput } from "../../forms/text/CardNumberInput";
import { BillingAddressEn } from "../common/FormAddress/BillingAddressEn";
import { BillingAddressJa } from "../common/FormAddress/BillingAddressJa";
import { PrivacyLink } from "../common/FormData";
import { getTokens } from "../common/FormTokenSelector";

import { InstallmentSelectField } from "./components/InstallmentSelectField";
import { validateEmail } from "./utils/validateEmail";
import FormCardSelector from "./FormCardSelector";
import { CardDataRaw, FormDataCardData, generateValidation, normalizeValues } from "./FormData";
import { FormDataCard } from "./index";

const stateSelector = (state: StateShape) => {
    const { application, product, configuration, checkout } = state;
    const {
        address = false,
        requireEmail = address,
        requirePhoneNumber = address,
        requireBillingAddress,
        email,
        inlineStyles,
        inlineBaseFontSize,
        allowCardInstallments,
        dark,
        horizontalInlineLayout,
        hideRecurringCheckbox,
        hidePrivacyLink,

        shippingAddressCity,
        shippingAddressCountryCode,
        shippingAddressLine1,
        shippingAddressState,
        shippingAddressZip,
        phoneNumber: prefilledPhoneNumber,
    } = application.params.params;

    const { tokenType, currency, initialAmount, univapayCustomerId, isSubscription } = getCheckoutParams(
        application.params,
        product.products,
        configuration.data
    );

    const { tokens } = state.tokens;

    const { connector } = application;

    const tokenSelector = formValueSelector(FORM_TOKEN_SELECT_NAME);
    const selectedTokenId = tokenSelector(state, "selectedTokenId");

    const cardInfoSelector = formValueSelector(FORM_CHECKOUT_NAME);
    const cardNumber = cardInfoSelector(state, "data.cardNumber");
    const cardType = cardNumber ? cardBrandAPIName(Payment.fns.cardType(cardNumber)) : null;

    const brands = configuration.paymentMethods.find(({ key }) => key === checkout.paymentMethodKey)?.brands || [];
    const supportsPrivateLabel = brands.some((brand) => brand === NewCardBrand.PRIVATE_LABEL);
    const forceRecurringToken = tokenType === TransactionTokenType.RECURRING;

    const hasInstallmentCapableBrands = ((configuration.data?.supportedBrands as PatchedSupportedBrand[]) || []).some(
        ({ brand, cardBrand, installmentCapable }) => brands.includes(brand || cardBrand) && installmentCapable
    );

    const showInstallmentSelect =
        hasInstallmentCapableBrands &&
        !isSubscription &&
        allowCardInstallments &&
        configuration.data?.installmentsConfiguration?.enabled;

    const errors = getFormSyncErrors(FORM_CHECKOUT_NAME)(state);
    // formMeta is storing touched fields
    const formMeta = getFormMeta(FORM_CHECKOUT_NAME)(state);

    const filterUntouchedValues = (
        values: Record<string, unknown>,
        formMeta: Record<string, { touched: boolean } | Record<string, { touched: boolean }>>
    ) =>
        Object.keys(values).reduce((acc, key) => {
            const value = values[key];
            const keyMetadata = has(formMeta, key) && formMeta[key];

            if (value && typeof value === "object") {
                const values = filterUntouchedValues(
                    value as Record<string, unknown>,
                    keyMetadata as Record<string, { touched: boolean }>
                );

                if (!isEmpty(values)) {
                    acc[key] = values;
                }
            } else if (keyMetadata?.touched) {
                acc[key] = value;
            }

            return acc;
        }, {});

    // picking up the fields that is touched and have validation error
    const validationErrors = filterUntouchedValues(errors as Record<string, unknown>, formMeta) as {
        data?: Record<string, string>;
    };

    const isCurrentBrandInstallmentCapable =
        !cardType ||
        ((configuration.data?.supportedBrands || []).find(
            ({ brand, cardBrand }) => (brand || cardBrand) === cardType
        ) as PatchedSupportedBrand)?.installmentCapable;

    const threeDsAddressRequired =
        configuration.data?.cardConfiguration.threeDsRequired &&
        configuration.data?.cardConfiguration.threeDsAddressRequired;

    const hasPrefilledAddress =
        shippingAddressCity &&
        shippingAddressCountryCode &&
        shippingAddressLine1 &&
        shippingAddressState &&
        shippingAddressZip;

    return {
        cardBrand: cardType,
        disableInstallments: !isCurrentBrandInstallmentCapable,
        requireEmail: requireEmail || !email,
        renderCVV: needsCvv(state),
        currency,
        subscriptionInitialAmount: initialAmount,
        tokenType,
        univapayCustomerId,
        isSubscription,
        tokens,
        selectedTokenId,
        selectedToken: tokens?.[selectedTokenId],
        fetched: !!tokens && !!state.configuration.data,
        inlineStyles,
        inlineBaseFontSize,
        initialValues: { email: email || tokens?.[selectedTokenId]?.email },
        showInstallmentSelect,
        dark,
        horizontalInlineLayout,
        supportsPrivateLabel,
        brands,
        requirePhoneNumber: requirePhoneNumber || (threeDsAddressRequired && !prefilledPhoneNumber),
        requireAddress: address || requireBillingAddress || (threeDsAddressRequired && !hasPrefilledAddress),
        validationErrors,
        connector,
        isAcceptRequired: !hideRecurringCheckbox && forceRecurringToken,
        isAcceptVisible: !hideRecurringCheckbox && (forceRecurringToken || !!univapayCustomerId),
        hidePrivacyLink,
    };
};

type OwnProps = InjectedFormProps<FormDataCardData<CardDataRaw>> & ReturnType<typeof stateSelector>;

const validate = (values: Partial<FormDataCardData<CardDataRaw>>, props: OwnProps) => {
    const baseValidationObject = generateValidation(values, props);

    const { requireEmail, requirePhoneNumber, requireAddress, selectedTokenId } = props;
    const { email, data: { phoneNumber, line1, city, state, zip, country } = {} } = values;

    return validationToErrors({
        ...baseValidationObject,
        "email": !selectedTokenId && validateEmail(email, requireEmail),
        "data.phoneNumber.localNumber": {
            // inline checkout validation support
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requirePhoneNumber && !phoneNumber?.localNumber,
            [LOCALE_LABELS.VALIDATION_PHONE_NUMBER]:
                requirePhoneNumber && phoneNumber?.localNumber && !/^[0-9]{4,}$/.test(phoneNumber?.localNumber),
        },
        "data.line1": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireAddress && isEmpty(line1),
        },
        "data.city": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireAddress && isEmpty(city),
        },
        "data.state": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireAddress && isEmpty(state),
        },
        "data.zip": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireAddress && isEmpty(zip),
            [LOCALE_LABELS.VALIDATION_ZIP]: zip && !/^[-0-9]{7}/.test(zip),
        },
        "data.country": {
            [LOCALE_LABELS.VALIDATION_REQUIRED]: requireAddress && !country,
        },
    });
};

const ADDRESS_REQUIRED_FIELDS = new Set([
    UnivaMetadata.ADDRESS_CITY,
    UnivaMetadata.ADDRESS_COUNTRY,
    UnivaMetadata.ADDRESS_ZIP,
    UnivaMetadata.ADDRESS_STATE,
    UnivaMetadata.ADDRESS_LINE1,
]);

const computeInlineStyle = (
    inlineStyles: Record<InlineStyleKeys, Record<string, string>>,
    label: InlineStyleKeys,
    error: InlineStyleKeys,
    invalid: InlineStyleKeys,
    focus: InlineStyleKeys,
    field: InlineStyleKeys,
    item: InlineStyleKeys
) =>
    inlineStyles
        ? {
              labelStyle: { ...inlineStyles[InlineStyleKeys.ITEM_LABEL], ...(inlineStyles[label] || {}) },
              errorStyle: { ...inlineStyles[InlineStyleKeys.ITEM_ERROR], ...(inlineStyles[error] || {}) },
              invalidStyle: { ...inlineStyles[InlineStyleKeys.FIELD_INVALID], ...(inlineStyles[invalid] || {}) },
              focusStyle: { ...inlineStyles[InlineStyleKeys.FIELD_FOCUS], ...(inlineStyles[focus] || {}) },
              inputStyle: { ...inlineStyles[InlineStyleKeys.FIELD], ...(inlineStyles[field] || {}) },
              style: { ...inlineStyles[InlineStyleKeys.ITEM], ...(inlineStyles[item] || {}) },
          }
        : {};

const Content = compose<OwnProps, OwnProps>(
    connect(stateSelector),
    reduxForm({ form: FORM_CHECKOUT_NAME, validate, destroyOnUnmount: false, forceUnregisterOnUnmount: true })
)(
    ({
        handleSubmit,
        currency,
        subscriptionInitialAmount,
        isSubscription,
        tokens,
        selectedTokenId,
        selectedToken,
        fetched,
        requireEmail,
        inlineStyles,
        inlineBaseFontSize,
        renderCVV,
        showInstallmentSelect,
        dark,
        horizontalInlineLayout,
        requirePhoneNumber,
        requireAddress,
        validationErrors,
        connector,
        isAcceptVisible,
        disableInstallments,
        cardBrand,
        hidePrivacyLink,
    }) => {
        const { locale, formatMessage, formatNumber } = useIntl();
        const {
            checkout: { process: processCheckout },
            userData: { setUserData },
        } = useDispatch<Dispatch>();

        const handleSubmitForm = async (values: FormDataCard<CardDataRaw>) => {
            setUserData({ phoneNumber: values.data?.phoneNumber });

            await processCheckout({
                values: normalizeValues(values, selectedToken) as FormCheckoutData,
                failOnError: true,
            });
        };

        const textFieldCustomStyles = computeInlineStyle(
            inlineStyles,
            InlineStyleKeys.TEXT_ITEM_LABEL,
            InlineStyleKeys.TEXT_ITEM_ERROR,
            InlineStyleKeys.TEXT_FIELD_INVALID,
            InlineStyleKeys.TEXT_FIELD_FOCUS,
            InlineStyleKeys.FIELD,
            InlineStyleKeys.ITEM
        );

        const toggleFieldCustomStyles = computeInlineStyle(
            inlineStyles,
            InlineStyleKeys.TOGGLE_ITEM_LABEL,
            InlineStyleKeys.TOGGLE_ITEM_ERROR,
            InlineStyleKeys.TOGGLE_FIELD_INVALID,
            InlineStyleKeys.TOGGLE_FIELD_FOCUS,
            InlineStyleKeys.TOGGLE_FIELD,
            InlineStyleKeys.TOGGLE_ITEM
        );

        const selectFieldCustomStyles = computeInlineStyle(
            inlineStyles,
            InlineStyleKeys.SELECT_ITEM_LABEL,
            InlineStyleKeys.SELECT_ITEM_ERROR,
            InlineStyleKeys.SELECT_FIELD_INVALID,
            InlineStyleKeys.SELECT_FIELD_FOCUS,
            InlineStyleKeys.SELECT_FIELD,
            InlineStyleKeys.SELECT_ITEM
        );

        const radioFieldCustomStyles = computeInlineStyle(
            inlineStyles,
            InlineStyleKeys.RADIO_ITEM_LABEL,
            null,
            null,
            InlineStyleKeys.RADIO_FIELD_FOCUS,
            InlineStyleKeys.RADIO_ITEM,
            InlineStyleKeys.RADIO_ITEM
        );

        useEffect(() => {
            document.querySelector("html").style.fontSize = inlineBaseFontSize;
        }, [inlineBaseFontSize]);

        useEffect(() => {
            if (Object.keys(validationErrors?.data || {}).length) {
                connector.emitter.emit("checkout:validation-error", validationErrors);
            }
        }, [validationErrors]);

        const handleFormKeyPressed = (event: KeyboardEvent<HTMLFormElement>) => {
            if (event.key === "Enter") {
                event.preventDefault(); //<===== This stops the form from being submitted
                return false;
            }
        };

        const isAddressRegisteredWithToken =
            tokens?.[selectedTokenId] &&
            Object.keys(tokens[selectedTokenId]?.metadata).some((key: UnivaMetadata) =>
                ADDRESS_REQUIRED_FIELDS.has(key)
            );

        const AddressComponent = (() => {
            switch (locale.substring(0, 2).toLowerCase()) {
                case "ja":
                    return BillingAddressJa;

                default:
                    return BillingAddressEn;
            }
        })();

        return (
            <>
                {tokens && getTokens(tokens, PaymentType.CARD).length > 0 && (
                    <FormCardSelector
                        inline
                        inlineStyles={{
                            textFieldCustomStyles,
                            toggleFieldCustomStyles,
                            selectFieldCustomStyles,
                            radioFieldCustomStyles,
                        }}
                    />
                )}

                <Form
                    onSubmit={handleSubmit(handleSubmitForm)}
                    onKeyPress={handleFormKeyPressed}
                    className={classnames("credit-card", "data", "content-form", { "dark-theme": dark })}
                    noValidate>
                    {!fetched ? (
                        <SpinnerLoader />
                    ) : !selectedTokenId ? (
                        <>
                            {requireEmail && (
                                <TextField
                                    name="email"
                                    label={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_EMAIL_LABEL })}
                                    placeholder={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_EMAIL })}
                                    bsSize="sm"
                                    autoComplete="email"
                                    type="email"
                                    tabIndex={1}
                                    {...textFieldCustomStyles}
                                    required
                                    isDark={dark}
                                    isHorizontal={horizontalInlineLayout}
                                />
                            )}

                            <FormSection name="data">
                                {requirePhoneNumber && (
                                    <PhoneNumberField
                                        label={formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL })}
                                        bsSize="sm"
                                        name="phoneNumber"
                                        autoComplete="phone"
                                        type="phone"
                                        tabIndex={2}
                                        required
                                        isDark={dark}
                                        {...textFieldCustomStyles}
                                        isHorizontal={horizontalInlineLayout}
                                    />
                                )}

                                <TextField
                                    name="cardholder"
                                    label={formatMessage({
                                        id: LOCALE_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL,
                                    })}
                                    placeholder={formatMessage({
                                        id: LOCALE_LABELS.FORM_CARD_FIELDS_CARDHOLDER,
                                    })}
                                    bsSize="sm"
                                    autoComplete="cc-name"
                                    tabIndex={3}
                                    normalize={(value) => value?.replace("　", " ")?.replace(/[0-9]/g, "")}
                                    {...textFieldCustomStyles}
                                    required
                                    isDark={dark}
                                    isHorizontal={horizontalInlineLayout}
                                />

                                <TextField
                                    name="cardNumber"
                                    label={formatMessage({
                                        id: LOCALE_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL,
                                    })}
                                    placeholder={formatMessage({
                                        id: LOCALE_LABELS.FORM_CARD_FIELDS_CARD_NUMBER,
                                    })}
                                    bsSize="sm"
                                    tabIndex={4}
                                    componentClass={CardNumberInput}
                                    {...textFieldCustomStyles}
                                    required
                                    isDark={dark}
                                    isHorizontal={horizontalInlineLayout}
                                />

                                <TextField
                                    name="exp"
                                    label={formatMessage({
                                        id: LOCALE_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL,
                                    })}
                                    validationLabel={formatMessage({
                                        id: LOCALE_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION,
                                    })}
                                    bsSize="sm"
                                    tabIndex={5}
                                    width={112}
                                    componentClass={CardExpInput}
                                    {...textFieldCustomStyles}
                                    required
                                    isDark={dark}
                                    isHorizontal={horizontalInlineLayout}
                                />

                                {renderCVV && (
                                    <TextField
                                        name="cvv"
                                        label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC_LABEL })}
                                        placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC })}
                                        bsSize="sm"
                                        tabIndex={6}
                                        width={112}
                                        componentClass={CardCVVInput}
                                        {...textFieldCustomStyles}
                                        required
                                        isDark={dark}
                                        isHorizontal={horizontalInlineLayout}
                                    />
                                )}
                            </FormSection>

                            {showInstallmentSelect &&
                                (disableInstallments ? (
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id={generate()} className="tooltip-small">
                                                {formatMessage(
                                                    { id: LOCALE_LABELS.FORM_INSTALLMENT_SELECT_HELPER },
                                                    { brand: cardBrand }
                                                )}
                                            </Tooltip>
                                        }>
                                        <div>
                                            <InstallmentSelectField
                                                darkTheme={dark}
                                                {...selectFieldCustomStyles}
                                                horizontal={horizontalInlineLayout}
                                                disabled
                                            />
                                        </div>
                                    </OverlayTrigger>
                                ) : (
                                    <InstallmentSelectField
                                        darkTheme={dark}
                                        {...selectFieldCustomStyles}
                                        horizontal={horizontalInlineLayout}
                                    />
                                ))}

                            {requireAddress && (
                                <>
                                    <div className="section-title">
                                        {formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_TITLE })}
                                    </div>
                                    <AddressComponent
                                        staticCountry={undefined}
                                        isDark={dark}
                                        {...textFieldCustomStyles}
                                    />
                                </>
                            )}

                            {isAcceptVisible && (
                                <CheckboxField name="data.accept" {...toggleFieldCustomStyles} isDark={dark}>
                                    {formatMessage({ id: LOCALE_LABELS.FORM_FIELDS_ACCEPT_RECURRING })}
                                </CheckboxField>
                            )}

                            {isSubscription && !!subscriptionInitialAmount && (
                                <div className="text-left">
                                    {formatMessage(
                                        { id: LOCALE_LABELS.SUBSCRIPTIONS_INSTALLMENT_INITIAL_AMOUNT },
                                        {
                                            money: formatNumber(
                                                amountForCurrency(subscriptionInitialAmount, currency),
                                                {
                                                    style: "currency",
                                                    currency,
                                                }
                                            ),
                                        }
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {renderCVV && (
                                <FormSection name="data">
                                    <TextField
                                        name="cvv"
                                        label={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC_LABEL })}
                                        placeholder={formatMessage({ id: LOCALE_LABELS.FORM_CARD_FIELDS_CSC })}
                                        bsSize="sm"
                                        tabIndex={5}
                                        width={112}
                                        componentClass={CardCVVInput}
                                        {...textFieldCustomStyles}
                                        required
                                        isDark={dark}
                                        isHorizontal={horizontalInlineLayout}
                                    />
                                </FormSection>
                            )}

                            {requireAddress && !isAddressRegisteredWithToken && (
                                <>
                                    <div className="section-title">
                                        {formatMessage({ id: LOCALE_LABELS.FORM_ADDRESS_TITLE })}
                                    </div>
                                    <AddressComponent
                                        staticCountry={undefined}
                                        isDark={dark}
                                        {...textFieldCustomStyles}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {!hidePrivacyLink && <PrivacyLink className="inline-form-link" isDark={dark} />}

                    <button type="submit" className="hidden" />
                </Form>
            </>
        );
    }
);

export default Content;
