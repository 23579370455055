import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { sdk } from "checkout/ts/SDK";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { isDarkTheme } from "checkout/ts/utils/colors";
import { ARRAY_PARSE, updateSearchParams } from "checkout/ts/utils/query";
import { StepName } from "checkout/ts/utils/StepName";
import { getOnlineCallMethodParams, isWeChatBrowser } from "common/utils/browser";
import { parse } from "query-string";
import { OnlineBrand, OnlineCallMethod, ProcessingMode } from "univapay-node";

import { getOnlineBrandLabel, LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { isDevelop } from "../../checkout/utils";
import { useOnlineLogo } from "../../common/hooks/useOnlineLogo";
import { SpinnerLoader } from "../../common/SpinnerLoader";
import { StepTitle } from "../../common/StepTitle";
import { getSubmitButtonLabel, PrivacyLink } from "../common/FormData";

import { OnlineBrandButton } from "./components/OnlineBrandButton";
import { getOnlineRoutes, hasUserData } from "./constants";

const stateSelector = (state: StateShape) => {
    const { tokenType, amount, currency, isSubscription } = getCheckoutParams(
        state.application.params,
        state.product.products,
        state.configuration.data
    );

    return {
        amount,
        email: state.userData.email ?? state.application.params.params.email,
        hidePrivacyLink: state.application.params.params.hidePrivacyLink,
        currency,
        tokenType,
        issuerToken: state.online.issuerToken,
        issuerTokenPayload: state.online.issuerTokenPayload,
        brand: state.online.brand,
        processingMode: state.configuration.data.mode,
        processing: ((state.loading.models.checkout || state.loading.models.online) as unknown) as boolean,
        hasUserData: hasUserData(state),
        title: state.configuration.data?.name ?? state.application.params.params.title,
        submitButtonText: state.application.params.params.submitButtonText,
        paymentMethodKey: state.checkout.paymentMethodKey,
        isSubscription,
        storeId: state.online.storeId,
        chargeId: state.online.chargeId,
        weChatAppId: state.online.weChatAppId,
        darkTheme: isDarkTheme(state),
    };
};

export const Content = () => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const { push: redirect } = useHistory();
    const {
        online: { create: createOnlineCharge, getWeChatOpenId },
    } = useDispatch<Dispatch>();

    const {
        tokenType,
        amount,
        brand,
        currency,
        email,
        issuerToken,
        issuerTokenPayload,
        processingMode,
        processing,
        hasUserData,
        title,
        submitButtonText,
        paymentMethodKey,
        isSubscription,
        storeId,
        chargeId,
        weChatAppId,
        hidePrivacyLink,
        darkTheme,
    } = useSelector(stateSelector);

    const checkoutParams = useCheckoutParams();
    const { logo, name } = useOnlineLogo(brand as OnlineBrand);

    const useWeChatOfficialAccount =
        brand === OnlineBrand.WE_CHAT_ONLINE && isDevelop() && isWeChatBrowser() && !!weChatAppId;

    useEffect(() => {
        if (brand && !processing) {
            createOnlineCharge({ tokenType, email, amount, currency, brand });
        }
    }, [brand]);

    useEffect(() => {
        (async () => {
            if (useWeChatOfficialAccount) {
                await getWeChatOpenId({ appId: weChatAppId });
                redirect(getOnlineRoutes(StepName.PROCESSING, paymentMethodKey));
            }
        })();
    }, [brand]);

    const handleClick = () => {
        (document.getElementById("online-checkout") as HTMLFormElement)?.submit();
        redirect(getOnlineRoutes(StepName.PROCESSING, paymentMethodKey));
    };

    const isTestMode = processingMode === ProcessingMode.TEST;

    const { callMethod } = getOnlineCallMethodParams(brand as OnlineBrand) || {};

    const usePostCallMethod = !isTestMode && callMethod === OnlineCallMethod.HTTP_POST;

    const redirectUrl = (() => {
        const url = isTestMode
            ? `/redirect/index.html?apiBase=${sdk.api.endpoint}&storeId=${storeId}&chargeId=${chargeId}&appId=${sdk.api.jwtRaw}&storeName=Test%20charge`
            : issuerToken;

        if (brand !== OnlineBrand.WE_CHAT_ONLINE) {
            return url;
        }

        const [onlineHrefPathname, ...onlineHrefSearch] = url?.split("?") || [];
        const onlineSearch = updateSearchParams(onlineHrefSearch.join("?"), {
            redirect_url: `${window.location.origin}/redirect/index.html?apiBase=${sdk.api.endpoint}&storeId=${storeId}&chargeId=${chargeId}&appId=${sdk.api.jwtRaw}&storeName=${title}`,
        });

        return `${onlineHrefPathname}?${onlineSearch}`;
    })();

    const queryParams = (() => {
        if (issuerTokenPayload && Object.keys(issuerTokenPayload).length) {
            return issuerTokenPayload;
        }

        if (!redirectUrl) {
            return {};
        }

        const queryIndex = redirectUrl.indexOf("?");
        if (queryIndex === -1) {
            return {};
        }

        return parse(redirectUrl.slice(queryIndex + 1), ARRAY_PARSE);
    })();

    return (
        <div>
            <StepTitle>{formatMessage({ id: LOCALE_LABELS.FORM_PAYMENT_METHOD })}</StepTitle>

            {logo ? (
                <div>
                    <img
                        style={{ maxHeight: logo.logoHeight, maxWidth: Math.min(Number(logo.logoWidth), 200) }}
                        src={logo.logoUrl}></img>
                </div>
            ) : (
                name || formatMessage({ id: getOnlineBrandLabel(brand) })
            )}

            {hasUserData && !hidePrivacyLink && <PrivacyLink isDark={darkTheme} />}

            {redirectUrl && brand ? (
                usePostCallMethod ? (
                    <form id="online-checkout" action={redirectUrl} method="post" target="blank">
                        {Object.keys(queryParams).map((key) => (
                            <input key={key} name={key} type="hidden" value={queryParams[key]} />
                        ))}

                        <OnlineBrandButton
                            data-name="open-checkout-button"
                            label={
                                submitButtonText
                                    ? submitButtonText
                                    : logo
                                    ? formatMessage({ id: LOCALE_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO })
                                    : getSubmitButtonLabel(checkoutParams, isSubscription, intl)
                            }
                            brand={brand}
                            onClick={handleClick}
                            disabled={processing || useWeChatOfficialAccount}
                            hideBrandIcon
                            isSubmitButton
                        />
                    </form>
                ) : (
                    <OnlineBrandButton
                        data-name="open-checkout-button"
                        label={
                            submitButtonText
                                ? submitButtonText
                                : logo
                                ? formatMessage({ id: LOCALE_LABELS.PAYMENT_BUTTONS_ONLINE_WITH_LOGO })
                                : getSubmitButtonLabel(checkoutParams, isSubscription, intl)
                        }
                        brand={brand}
                        onClick={handleClick}
                        disabled={processing || useWeChatOfficialAccount}
                        href={redirectUrl}
                        target="blank"
                        hideBrandIcon
                        isSubmitButton
                    />
                )
            ) : (
                <SpinnerLoader />
            )}
        </div>
    );
};

export default Content;
