import React from "react";
import classnames from "classnames";

export type SpinnerLoaderSize = "small" | "medium" | "large" | "sm" | "lg";

export type SpinnerLoaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    className?: string;
    size?: SpinnerLoaderSize;
};

export const SpinnerLoader = ({ className, size = "medium", ...props }: SpinnerLoaderProps) => (
    <div className={classnames("spinner-loader", className, size)} {...props} />
);
