import React from "react";
import { PaymentType } from "univapay-node";

import FormCodeInput from "../common/FormCodeInput";

export const Content = (props) => (
    <div>
        <FormCodeInput {...props} paymentType={PaymentType.KONBINI} />
    </div>
);

export default Content;
