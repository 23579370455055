import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Route, useRouteMatch } from "react-router";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { UserData } from "checkout/ts/redux/models/user-data";
import { Dispatch, StateShape } from "checkout/ts/redux/store";
import { getCheckoutParams } from "checkout/ts/utils/checkout-params";
import { StepName } from "checkout/ts/utils/StepName";
import { BankTransferBrand, ProcessingMode } from "univapay-node";

import { SwitchContent } from "../../router/SwitchContent";
import FormAddress from "../common/FormAddress";
import FormCustomerInfo from "../common/FormCustomerInfo";
import { getSubmitButtonLabel } from "../common/FormData";
import { FormPending } from "../common/FormPending";
import { getRequiredFields } from "../common/utils/customerInfo";

import { FormConfirm } from "./FormConfirm";
import FormTokenReview from "./FormTokenReview";

export type FormDataBankTransfer = UserData;

export const stateSelector = ({ configuration, application, checkout, userData, product }: StateShape) => {
    const { isSubscription } = getCheckoutParams(application.params, product.products, configuration.data);
    const { requireBillingAddress } = getRequiredFields(checkout.paymentType, application.params.params);

    return {
        brand: configuration.data.mode === ProcessingMode.TEST ? BankTransferBrand.TEST : BankTransferBrand.AOZORA_BANK,
        requireBillingAddress,
        submitButtonText: application.params.params.submitButtonText,
        userData,
        isSubscription,
        hidePrivacyLink: application.params.params.hidePrivacyLink,
    };
};

const CustomerInfoForm = () => {
    const intl = useIntl();
    const checkoutParams = useCheckoutParams();
    const { brand, submitButtonText, hidePrivacyLink, requireBillingAddress, isSubscription } = useSelector(
        stateSelector
    );

    const {
        bankTransfer: { create: createBankTransfer },
    } = useDispatch<Dispatch>();

    const onSubmit = (userData: Partial<UserData>) => {
        createBankTransfer({ name: userData.name, brand });
    };

    return (
        <FormCustomerInfo
            nextButtonLabel={submitButtonText || getSubmitButtonLabel(checkoutParams, isSubscription, intl)}
            onSubmit={requireBillingAddress ? null : onSubmit} // Create bank transfer onSubmit since address is not required
            showPrivacyLink={!hidePrivacyLink}
        />
    );
};

const AddressForm = () => {
    const intl = useIntl();
    const { brand, userData, submitButtonText, isSubscription, hidePrivacyLink } = useSelector(stateSelector);
    const checkoutParams = useCheckoutParams();

    const {
        bankTransfer: { create: createBankTransfer },
    } = useDispatch<Dispatch>();

    const onSubmit = () => {
        createBankTransfer({ name: userData.name, brand });
    };

    return (
        <FormAddress
            nextButtonLabel={submitButtonText || getSubmitButtonLabel(checkoutParams, isSubscription, intl)}
            onSubmit={onSubmit}
            showPrivacyLink={!hidePrivacyLink}
        />
    );
};

export const Content = () => {
    const { path } = useRouteMatch();

    return (
        <SwitchContent>
            <Route path={path} exact component={CustomerInfoForm} />
            <Route path={`${path}/${StepName.INFO}`} component={CustomerInfoForm} />
            <Route path={`${path}/${StepName.ADDRESS}`} component={AddressForm} />
            <Route path={`${path}/${StepName.CONFIRM}`} component={FormConfirm} />
            <Route path={`${path}/${StepName.PENDING}`} component={FormPending} />
            <Route path={`${path}/${StepName.TOKEN_REVIEW}`} component={FormTokenReview} />
        </SwitchContent>
    );
};

export default Content;
