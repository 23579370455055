import React from "react";
import ReactCSSTransitionGroup from "react-css-transition-replace";
import { Switch, useLocation } from "react-router";

import { history } from "../../redux/store";

export const SwitchContent = ({ children }) => {
    const location = useLocation();

    const currentHistoryIndex = history.entries.findIndex((entry) => entry.key === history.location.key);
    const isGoingBack = currentHistoryIndex < history.entries.length - 1;

    return (
        <ReactCSSTransitionGroup
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            transitionName={isGoingBack ? "carousel-swap-back" : "carousel-swap"}>
            <div key={location.pathname}>
                <Switch location={location}>{children}</Switch>
            </div>
        </ReactCSSTransitionGroup>
    );
};
