export const polyfillIntl = () => {
    if (!Intl["PluralRules"]) {
        require("@formatjs/intl-pluralrules/polyfill");
        require("@formatjs/intl-pluralrules/locale-data/en");
        require("@formatjs/intl-pluralrules/locale-data/ja");
        require("@formatjs/intl-pluralrules/locale-data/zh");
    }

    if (!Intl["RelativeTimeFormat"]) {
        require("@formatjs/intl-relativetimeformat/polyfill");
    }
};
