import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DialogButton } from "checkout/ts/components/layout/DialogButton";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { Dispatch, StateShape } from "checkout/ts/redux/store";
import { SupportedLocale } from "common/locale/labels";

export const stateSelector = (state: StateShape) => ({
    processing: (state.loading.models.checkout as unknown) as boolean,
});

export const FormPending = () => {
    const { formatMessage, locale } = useIntl();
    const {
        application: { close: closeApplication },
    } = useDispatch<Dispatch>();

    const { processing } = useSelector(stateSelector);
    const handleClick = useCallback(() => closeApplication(), []);

    return (
        <>
            <Row className="confirm content-form">
                <Col xs={12}>
                    <div data-name="message">{formatMessage({ id: LOCALE_LABELS.TRANSACTION_TIMEOUT_MESSAGE })}</div>

                    <br />

                    <div data-name="support-center-info">
                        <div data-name="support-center-first-line">
                            <b>{formatMessage({ id: LOCALE_LABELS.SUPPORT_CENTER_FIRST_LINE })}</b>
                        </div>

                        {locale === SupportedLocale.JA_JP && (
                            <div data-name="support-center-second-line">
                                <b>{formatMessage({ id: LOCALE_LABELS.SUPPORT_CENTER_SECOND_LINE })}</b>
                            </div>
                        )}

                        <div data-name="support-phone-number-within-japan">
                            {formatMessage({ id: LOCALE_LABELS.SUPPORT_PHONE_NUMBER_WITHIN_JAPAN })}
                        </div>

                        <div data-name="support-phone-number-outside-of-japan">
                            {formatMessage({ id: LOCALE_LABELS.SUPPORT_PHONE_NUMBER_OUTSIDE_OF_JAPAN })}
                        </div>

                        <div data-name="support-email">
                            {formatMessage(
                                { id: LOCALE_LABELS.SUPPORT_EMAIL },
                                {
                                    email: (
                                        <a data-name="support-email-link" href="mailto:ips-support@univapay.com">
                                            ips-support@univapay.com
                                        </a>
                                    ),
                                }
                            )}
                        </div>
                    </div>
                </Col>
            </Row>

            <DialogButton disabled={processing} onClick={handleClick}>
                {formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_FINISH })}
            </DialogButton>
        </>
    );
};
