import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import reactCSS from "reactcss";
import { DialogButton } from "checkout/ts/components/layout/DialogButton";
import { getProcessState, ProcessState } from "checkout/ts/components/ProcessLoader";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { Dispatch, StateShape } from "checkout/ts/redux/store";
import { isDarkTheme } from "checkout/ts/utils/colors";
import { amountForCurrency } from "checkout/ts/utils/money";
import { DARK_COLOR, LIGHT_COLOR, TEXT_DARK_THEME_COLOR, TEXT_LIGHT_THEME_COLOR } from "common/constants";
import { pickBy } from "lodash";
import {
    CheckoutType,
    PaymentType,
    ScheduledPaymentItem,
    SubscriptionItem,
    TransactionTokenBankTransferData,
} from "univapay-node";

import { FormattedShortDate } from "../../common/FormattedShortDate";
import { SpinnerLoader } from "../../common/SpinnerLoader";
import { Content } from "../common/FormConfirm";
import { getNextPeriodTick, offsetWithTimeShift } from "../common/utils/period";

export const stateSelector = (state: StateShape) => {
    const { checkout, bankTransfer, expiry, loading } = state;

    return {
        nextPayment: (checkout.data as SubscriptionItem)?.nextPayment || ({} as Partial<ScheduledPaymentItem>),
        processed: checkout.processed,
        error: checkout.error,
        transaction: checkout.data,
        charge: checkout.charge,
        chargeExpiry: expiry.chargeExpiry,
        chargeExpiryLoading: (loading.models.expiry as unknown) as boolean,
        issuerToken: bankTransfer.issuerToken,
        token: checkout.token,
        initialAmount: (checkout.data as SubscriptionItem)?.initialAmount,
        darkTheme: isDarkTheme(state),
        checkoutInfo: state.configuration.data,
    };
};

export const FormConfirm = () => {
    const { formatMessage, formatNumber } = useIntl();
    const {
        application: { close: closeApplication },
        expiry: { get: getExpiry },
    } = useDispatch<Dispatch>();

    const {
        initialAmount,
        token,
        issuerToken,
        processed,
        error,
        transaction,
        charge,
        checkoutInfo,
        darkTheme,
        nextPayment,
        chargeExpiry,
        chargeExpiryLoading,
    } = useSelector(stateSelector);

    const {
        amount,
        checkoutType,
        currency,
        bankTransferExpirationPeriod,
        bankTransferExpirationTimeShift,
    } = useCheckoutParams();
    const checkoutState = getProcessState(processed, error, transaction, checkoutType);

    const data = useMemo(() => {
        const filteredTokenData = pickBy(token?.data || {}, (value) => !!value);
        const filteredIssuerToken = pickBy(issuerToken || {}, (value) => !!value);

        return { ...filteredTokenData, ...filteredIssuerToken } as TransactionTokenBankTransferData;
    }, [token?.data, issuerToken]);

    useEffect(() => {
        if (charge?.id) {
            getExpiry({ id: charge.id, storeId: charge.storeId });
        }
    }, [charge?.id]);

    if (checkoutState === ProcessState.FAILURE || checkoutState === ProcessState.PENDING) {
        return <Content paymentType={PaymentType.BANK_TRANSFER} />;
    }

    const timeShiftedExpiration = chargeExpiry?.expirationDate
        ? new Date(chargeExpiry.expirationDate)
        : charge?.captureAt
        ? new Date(charge.captureAt)
        : offsetWithTimeShift(
              getNextPeriodTick(bankTransferExpirationPeriod || checkoutInfo.bankTransferConfiguration.expiration),
              bankTransferExpirationTimeShift
          );

    const { amount: nextAmount, dueDate: nextPaymentDate } = nextPayment || {};
    const nextExpiration = nextPaymentDate
        ? getNextPeriodTick(checkoutInfo.bankTransferConfiguration.expiration, nextPaymentDate)
        : null;

    const timeShiftedNextExpiration =
        nextExpiration && offsetWithTimeShift(nextExpiration, bankTransferExpirationTimeShift);

    const handleClick = () => closeApplication();

    const styles = reactCSS(
        {
            default: {
                table: { backgroundColor: LIGHT_COLOR, color: TEXT_LIGHT_THEME_COLOR },
            },
            tableDark: {
                table: { backgroundColor: DARK_COLOR, color: TEXT_DARK_THEME_COLOR },
            },
        },
        { tableDark: darkTheme }
    );

    return (
        <div className="confirm bank-transfer">
            {formatMessage({
                id:
                    checkoutType === CheckoutType.TOKEN
                        ? LOCALE_LABELS.BANK_TRANSFER_TOKEN_INSTRUCTION
                        : LOCALE_LABELS.BANK_TRANSFER_INSTRUCTION,
            })}

            <table className="bank-transfer-information table table-sm" style={styles.table}>
                <tbody>
                    <tr data-name="bank-name-row">
                        <td data-name="title">{formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_BANK_NAME })}</td>

                        <td data-name="value">{data.bankName}</td>
                    </tr>

                    <tr data-name="bank-code-row">
                        <td data-name="title">{formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_BANK_CODE })}</td>

                        <td data-name="value">{data.bankCode}</td>
                    </tr>

                    <tr data-name="branch-name-row">
                        <td data-name="title">{formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_BRANCH_NAME })}</td>

                        <td data-name="value">{data.branchName}</td>
                    </tr>

                    <tr data-name="branch-code-row">
                        <td data-name="title">{formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_BRANCH_CODE })}</td>

                        <td data-name="value">{data.branchCode}</td>
                    </tr>

                    <tr data-name="account-number-row">
                        <td data-name="title">{formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_ACCOUNT_NUMBER })}</td>

                        <td data-name="value">{data.accountNumber}</td>
                    </tr>

                    <tr data-name="account-name-row">
                        <td data-name="title">{formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_ACCOUNT_NAME })}</td>

                        <td data-name="value">{data.accountHolderName}</td>
                    </tr>

                    <tr data-name="amount-row">
                        <td data-name="title">
                            {formatMessage({
                                id: nextAmount
                                    ? LOCALE_LABELS.BANK_TRANSFER_FIRST_PAYMENT
                                    : LOCALE_LABELS.BANK_TRANSFER_AMOUNT,
                            })}
                        </td>

                        <td data-name="value">
                            {formatNumber(amountForCurrency(initialAmount ?? amount, currency), {
                                style: "currency",
                                currency,
                            })}
                        </td>
                    </tr>

                    <tr data-name="deadline-row">
                        <td data-name="title">
                            {formatMessage({
                                id: nextExpiration
                                    ? LOCALE_LABELS.BANK_TRANSFER_FIRST_DEADLINE
                                    : LOCALE_LABELS.BANK_TRANSFER_DEADLINE,
                            })}
                        </td>

                        {chargeExpiryLoading ? (
                            <SpinnerLoader size="sm" />
                        ) : (
                            <td data-name="value">
                                <FormattedShortDate dateTime={timeShiftedExpiration} noYear />
                            </td>
                        )}
                    </tr>

                    {nextAmount && (
                        <tr data-name="next-amount-row">
                            <td data-name="title">{formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_NEXT_PAYMENT })}</td>

                            <td data-name="value">
                                {formatNumber(amountForCurrency(nextAmount, currency), {
                                    style: "currency",
                                    currency,
                                })}
                            </td>
                        </tr>
                    )}

                    {timeShiftedNextExpiration && (
                        <tr data-name="initial-deadline-row">
                            <td data-name="title">
                                {formatMessage({ id: LOCALE_LABELS.BANK_TRANSFER_NEXT_DEADLINE })}
                            </td>

                            <td data-name="value">
                                <FormattedShortDate dateTime={timeShiftedNextExpiration} noYear />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <DialogButton onClick={handleClick}>
                {formatMessage({ id: LOCALE_LABELS.COMMON_BUTTONS_FINISH })}
            </DialogButton>
        </div>
    );
};
