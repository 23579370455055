import * as FORM_LABELS from "../labels/form";

export const LABELS: Record<string, string> = {
    [FORM_LABELS.FORM_PAYMENT_TYPE_TITLE]: "Выберите способ оплаты",
    [FORM_LABELS.FORM_PAYMENT_METHOD]: "способ оплаты",
    [FORM_LABELS.FORM_USED_PAYMENT_METHOD]: "Способ оплаты: {method}",

    [FORM_LABELS.FORM_ADDRESS_TITLE]: "Адрес для выставления счета",

    [FORM_LABELS.FORM_CONFIRMATION_TITLE]: "Введите код подтверждения",

    [FORM_LABELS.FORM_FIELDS_NAME]: "Ямада Таро",
    [FORM_LABELS.FORM_FIELDS_EMAIL]: "example@еmail.com",
    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER]: "80 1234 5678",

    [FORM_LABELS.FORM_FIELDS_PHONE_NUMBER_LABEL]: "номер телефона",
    [FORM_LABELS.FORM_FIELDS_EMAIL_LABEL]: "Адрес электронной почты",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_LABEL]: "код безопасности",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL]: "Срок действия (месяц/год)",
    [FORM_LABELS.FORM_CARD_FIELDS_EXPIRATION_DATE_LABEL_VALIDATION]: "Срок действия",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER_LABEL]: "Имя указаное на карте",
    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER_LABEL]: "номер карты",

    [FORM_LABELS.FORM_PAYMENT_TYPE_CARD]: "оплата кредитной картой",
    [FORM_LABELS.FORM_PAYMENT_TYPE_KONBINI]: "Оплата в киоске в круглосуточном магазине",
    [FORM_LABELS.FORM_PAYMENT_TYPE_PAIDY]: "Paidy",
    [FORM_LABELS.FORM_PAYMENT_TYPE_ONLINE]: "Онлайн",
    [FORM_LABELS.FORM_PAYMENT_TYPE_BANK_TRANSFER]: "Банковский перевод",

    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY]: "Alipay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_PAY_PAY]: "PayPay",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_WE_CHAT]: "WeChat",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_ALIPAY_PLUS]: "Alipay+",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_PAY_PAY_ONLINE]: "Paypay (тестовая версия)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_ONLINE]: "Alipay (тестовая версия)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_ALIPAY_PLUS_ONLINE]: "Alipay+ (тестовая версия)",
    [FORM_LABELS.FORM_ONLINE_PAYMENT_TEST_WE_CHAT_ONLINE]: "WeChat (тестовая версия)",

    [FORM_LABELS.FORM_ADDRESS_FIELDS_CITY]: "Город/Населённый пункт",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_COUNTRY]: "Страна",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE1]: "адрес (улица)",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_LINE2]: "адрес (название ЖК / номер квартиры)",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME]: "имя",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_NAME_KANA]: "Имя (Кана)",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_STATE]: "Регион",
    [FORM_LABELS.FORM_ADDRESS_FIELDS_ZIP]: "Почтовый индекс",

    [FORM_LABELS.FORM_CARD_FIELDS_CARD_NUMBER]: "4444 3333 2222 1111",
    [FORM_LABELS.FORM_CARD_FIELDS_CARDHOLDER]: "Таро Ямада",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC]: "123",
    [FORM_LABELS.FORM_CARD_FIELDS_CSC_INFO]: "Цифры (3 или 4), из в верхней части столбца подписи и т. д.",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_MONTH]: "Месяц",
    [FORM_LABELS.FORM_CARD_FIELDS_EXP_YEAR]: "Год",

    [FORM_LABELS.FORM_KONBINI_FIELDS_NAME]: "Таро Сузуки",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND]: "киоск в круглосуточном магазине",
    [FORM_LABELS.FORM_KONBINI_FIELDS_KONBINI_BRAND_SELECT]: "Выберите магазин с киоском",

    [FORM_LABELS.FORM_FIELDS_ACCEPT_RECURRING]: "Согласен(-на) на обработку личной информации",

    [FORM_LABELS.FORM_CARD_SELECTOR_TITLE]: "Выбрать карту",
    [FORM_LABELS.FORM_CARD_FIELDS_NEW_CARD]: "Новая карта",
    [FORM_LABELS.FORM_CARD_SELECTOR_DELETE]: "Удалить информацию о карте",

    [FORM_LABELS.FORM_KONBINI_SELECTOR_TITLE]: "Выберите круглосуточный магазин с киоском",
    [FORM_LABELS.FORM_KONBINI_FIELDS_OTHER]: "другой круглосуточный магазин с киоском",
    [FORM_LABELS.FORM_KONBINI_SELECTOR_DELETE]: "Удалить информацию о магазине(-нах) с киоском",

    [FORM_LABELS.FORM_PAIDY_SELECTOR_TITLE]: "Выберите ранее использованный набор данных:",
    [FORM_LABELS.FORM_PAIDY_FIELDS_NEW]: "Создать",
    [FORM_LABELS.FORM_PAIDY_SELECTOR_DELETE]: "Удалить набор данных",

    [FORM_LABELS.FORM_CONFIRMATION_CODE]: "Введите код подтверждения из SMS или электронного письма,",

    [FORM_LABELS.FORM_INSTALLMENT_SELECT_LABEL]: "Количество платежей",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_ONE_TIME]: "Целиком",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_THREE_TIMES]: "3 раза",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIVE_TIMES]: "5 раз",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_SIX_TIMES]: "6 раз",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TEN_TIMES]: "10 раз",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWELVE_TIMES]: "12 раз",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_FIFTEEN_TIMES]: "15 раз",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_EIGHTEEN_TIMES]: "18 раз",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_TIMES]: "20 раз",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_TWENTY_FOUR_TIMES]: "24 раза",
    [FORM_LABELS.FORM_INSTALLMENT_REVOLVING]: "Регулярно",
    [FORM_LABELS.FORM_INSTALLMENT_SELECT_HELPER]: "Рассрочка платежа для{brand}недоступна.",
};

export default LABELS;
