import React from "react";
import { Route, useRouteMatch } from "react-router";
import { UserData } from "checkout/ts/redux/models/user-data";

import { SwitchContent } from "../../router/SwitchContent";

import FormData, { FormDataCardData, FullCardData } from "./FormData";

export type FormDataCard<Data = FullCardData> = FormDataCardData<Data> & UserData;

export const Content = () => {
    const { path } = useRouteMatch();

    return (
        <SwitchContent>
            <Route path={path} exact component={FormData} />
        </SwitchContent>
    );
};

export default Content;
