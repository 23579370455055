/**
 *  @module Resources/Charges
 */

import { AuthParams, Charges as NodeCharges, DefinedRoute, HTTPMethod, SendData } from "univapay-node";

export type ChargeExpiry = {
    id: string;
    chargeId: string;
    expirationDate: string;
    extensionCount: number;
    createdOn: string;
    updatedOn: string;
};

export class Charges extends NodeCharges {
    private _expiry?: DefinedRoute;
    expiry(id: string, storeId: string, data?: SendData<void>, auth?: AuthParams): Promise<ChargeExpiry> {
        this._expiry = this._expiry ?? this.defineRoute(HTTPMethod.GET, "/stores/:storeId/charges/:id/expiry/latest");
        return this._expiry(data, undefined, auth, { storeId, id });
    }
}
