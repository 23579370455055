import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { isWeChatBrowser } from "common/utils/browser";
import { OnlineBrand } from "univapay-node";

import { getOnlineBrandLabel, LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch, StateShape } from "../../../redux/store";
import { isDevelop } from "../../checkout/utils";

const stateSelector = (state: StateShape) => ({
    brand: state.online.brand,
    storeId: state.online.storeId,
    chargeId: state.online.chargeId,
    weChatAppId: state.configuration.data.supportedBrands.find(({ brand }) => brand === OnlineBrand.WE_CHAT_ONLINE)
        ?.appId,
});

export const Content = () => {
    const { formatMessage } = useIntl();
    const {
        online: { poll: pollOnlineCharge, openWeChatWidget },
    } = useDispatch<Dispatch>();

    const { storeId, chargeId, brand, weChatAppId } = useSelector(stateSelector);

    const useWeChatOfficialAccount =
        brand === OnlineBrand.WE_CHAT_ONLINE && isDevelop() && isWeChatBrowser() && weChatAppId;

    useEffect(() => {
        if (useWeChatOfficialAccount) {
            openWeChatWidget();
        }

        pollOnlineCharge({ storeId, chargeId });
    }, [brand]);

    const brandLabel = formatMessage({ id: getOnlineBrandLabel(brand) });

    return (
        <Alert bsStyle="info">
            {formatMessage({ id: LOCALE_LABELS.ONLINE_PROCESSING_INFO }, { brand: brandLabel })}
        </Alert>
    );
};

export default Content;
