import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { useIntl } from "react-intl";
import { hover, HoverProps } from "reactcss";
import { useOnlineLogo } from "checkout/ts/components/common/hooks/useOnlineLogo";
import { SpinnerLoader } from "checkout/ts/components/common/SpinnerLoader";
import { useButtonOutlinedStyles, useButtonStyles, useTheme } from "checkout/ts/hooks/useButtonStyles";
import { PatchedOnlineBrand, SupportedOnlineBrand } from "checkout/ts/redux/utils/online-constants";
import { amountForCurrency } from "checkout/ts/utils/money";
import classnames from "classnames";
import { OnlineBrand } from "univapay-node";

import { getOnlineBrandLabel } from "../../../../locale/labels";
import { getOnlineBrandIcon } from "../constants";

type OwnProps = ButtonProps &
    HoverProps<void> & {
        brand: SupportedOnlineBrand;
        label?: React.ReactNode;
        hideBrandIcon?: boolean;
        isSubmitButton?: boolean;
        fees?: number;
        currency?: string;
    };

export const OnlineBrandButton = hover(
    ({ label, brand, hover, hideBrandIcon = false, isSubmitButton = false, fees, currency, ...props }: OwnProps) => {
        const { formatMessage, formatNumber } = useIntl();
        const style = isSubmitButton ? useButtonStyles(hover) : useButtonOutlinedStyles(hover);
        const dark = useTheme();

        const { logo, name, loading } = useOnlineLogo(brand as OnlineBrand | PatchedOnlineBrand, hover);

        if (loading) {
            return <SpinnerLoader size="sm" />;
        }

        return (
            <Button
                key={brand}
                data-name={`payment-method-${brand}`}
                name="onlineBrand"
                style={style}
                className={classnames("payment-method", { "submit-button": isSubmitButton })}
                block
                {...props}>
                <div>
                    {!hideBrandIcon && logo ? (
                        <div data-name="payment-method-brands">
                            <img
                                style={{ maxHeight: logo.logoHeight, maxWidth: Math.min(Number(logo.logoWidth), 200) }}
                                src={logo.logoUrl}></img>
                        </div>
                    ) : (
                        label || name || <>{formatMessage({ id: getOnlineBrandLabel(brand) })}</>
                    )}

                    {!!fees && !!currency && (
                        <div className={classnames("payment-method-fees", { dark })} data-name="payment-method-fees">
                            {`+${formatNumber(amountForCurrency(fees, currency), { style: "currency", currency })}`}
                        </div>
                    )}
                </div>

                <div>{!logo && !hideBrandIcon && getOnlineBrandIcon(brand)}</div>
            </Button>
        );
    }
);
