import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { SelectField } from "checkout/ts/components/forms/Select";
import { TextField } from "checkout/ts/components/forms/Text";
import { useCheckoutParams } from "checkout/ts/hooks/use-checkout-params";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { kebabCase } from "lodash";
import { FormSection } from "redux-form";
import { ECFormCustomFieldType } from "univapay-node";

type OwnProps = {
    disabled?: boolean;
    isDark?: boolean;
};

export const CustomFields = ({ disabled, isDark }: OwnProps) => {
    const { formatMessage } = useIntl();
    const { customFields } = useCheckoutParams();

    if (!customFields?.length) {
        return null;
    }

    return (
        <Row data-name="custom-fields" className="inner-row">
            <FormSection name="customFields">
                {customFields.map(({ key, type, label, required = false, options }) => (
                    <Col key={key} xs={12}>
                        {type === ECFormCustomFieldType.STRING ? (
                            <TextField
                                label={label}
                                tabIndex={3}
                                bsSize="sm"
                                name={key}
                                data-name={kebabCase(`custom-field-${key}`)}
                                disabled={disabled}
                                required={required}
                                isDark={isDark}
                            />
                        ) : (
                            <SelectField
                                label={label}
                                tabIndex={3}
                                bsSize="sm"
                                name={key}
                                data-name={kebabCase(`custom-field-${key}`)}
                                disabled={disabled}
                                required={required}
                                isDark={isDark}>
                                <option disabled value="">
                                    {formatMessage({ id: LOCALE_LABELS.COMMON_INPUT_SELECT_PLACEHOLDER })}
                                </option>
                                {options?.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </SelectField>
                        )}
                    </Col>
                ))}
            </FormSection>
        </Row>
    );
};
