import React from "react";
import { useIntl } from "react-intl";
import { FormattedPeriod } from "checkout/ts/components/common/FormattedPeriod";
import { LOCALE_LABELS } from "checkout/ts/locale/labels";
import { amountForCurrency } from "checkout/ts/utils/money";
import { InstallmentPlan } from "univapay-node";

import { convertCyclicalToSubscriptionPeriod } from "../components/checkout/utils";

import { useCheckoutParams } from "./use-checkout-params";

const getSubscriptionPlanDisplayInfoParams = (
    subscriptionPlan: { planType: InstallmentPlan; fixedCycles?: number; fixedCycleAmount?: number },
    amount: number,
    initialAmount: number
) => {
    const totalWithoutInitial = amount - (initialAmount || 0);
    const hasFirstChargeOrCvvAuth = initialAmount || initialAmount === 0;

    switch (subscriptionPlan.planType) {
        case InstallmentPlan.FIXED_CYCLES: {
            const cycles = hasFirstChargeOrCvvAuth ? subscriptionPlan.fixedCycles - 1 : subscriptionPlan.fixedCycles;
            const canAmountBeEquallySplit = cycles && totalWithoutInitial && totalWithoutInitial % cycles === 0;
            const amountPerCycle = canAmountBeEquallySplit ? totalWithoutInitial / cycles : null;

            return { cycles, amountPerCycle, canAmountBeEquallySplit };
        }

        case InstallmentPlan.FIXED_CYCLE_AMOUNT: {
            const canAmountBeEquallySplit =
                subscriptionPlan.fixedCycleAmount &&
                totalWithoutInitial &&
                totalWithoutInitial % subscriptionPlan.fixedCycleAmount === 0;
            const cycles = canAmountBeEquallySplit
                ? totalWithoutInitial / subscriptionPlan.fixedCycleAmount
                : subscriptionPlan.fixedCycleAmount && totalWithoutInitial
                ? Math.floor(totalWithoutInitial / subscriptionPlan.fixedCycleAmount) + 1
                : null;
            const amountPerCycle = canAmountBeEquallySplit ? subscriptionPlan.fixedCycleAmount : null;

            return { cycles, amountPerCycle, canAmountBeEquallySplit };
        }
    }
};

const getInstallmentLabel = (initialAmount: number, canAmountBeEquallySplit: boolean, startOn: string) => {
    switch (true) {
        case canAmountBeEquallySplit && initialAmount === 0: // Card registration
            return LOCALE_LABELS.SUBSCRIPTIONS_INFO_PAY_WITH_CUSTOM_PERIOD;

        case canAmountBeEquallySplit && !!startOn: // Direct first cycle and delayed second cycle
        case canAmountBeEquallySplit && !!initialAmount: // Direct charge and different second cycle
            return LOCALE_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_INITIAL;

        case canAmountBeEquallySplit: // Direct start
            return LOCALE_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_PER_MONTH_NO_INITIAL;

        case !canAmountBeEquallySplit && initialAmount === 0: // Card registration
            return LOCALE_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_NO_INITIAL;

        case !canAmountBeEquallySplit && !!initialAmount: // Direct first cycle and delayed second cycle
        case !canAmountBeEquallySplit && !!startOn: // Direct charge and different second cycle
            return LOCALE_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_FUTURE_CHARGES;

        case !canAmountBeEquallySplit: // Direct start
            return LOCALE_LABELS.SUBSCRIPTIONS_SUBSCRIPTION_PLAN_TOTAL_INITIAL;

        default:
            break;
    }
};

export const useSubscriptionPlan = () => {
    const { formatMessage, formatNumber } = useIntl();

    const {
        amount,
        subscriptionParams: {
            period,
            cyclicalPeriod,
            installmentPlan,
            subscriptionPlan,
            initialAmount,
            scheduleSettings,
        },
        currency,
    } = useCheckoutParams();

    if (!installmentPlan?.planType && !subscriptionPlan?.planType) {
        return null;
    }

    const subscriptionPeriod = period || convertCyclicalToSubscriptionPeriod(cyclicalPeriod);
    const formattedPeriod = subscriptionPeriod ? (
        <>{formatMessage({ id: LOCALE_LABELS[`SUBSCRIPTIONS_PERIOD_${subscriptionPeriod?.toUpperCase()}`] })}</>
    ) : (
        <FormattedPeriod period={cyclicalPeriod} />
    );

    const hasSubscriptionPlan = !!subscriptionPlan;

    const hasFirstCycleNowAndDelayedSecondCycle = !!scheduleSettings?.startOn && initialAmount === undefined;
    const formattedInitialAmount = hasFirstCycleNowAndDelayedSecondCycle
        ? subscriptionPlan.fixedCycles
            ? Math.floor(amount / subscriptionPlan.fixedCycles)
            : subscriptionPlan.fixedCycleAmount
        : initialAmount;

    const { id, amountPerCycle = null, cycles = null } = (() => {
        if (hasSubscriptionPlan) {
            const { cycles, amountPerCycle, canAmountBeEquallySplit } = getSubscriptionPlanDisplayInfoParams(
                subscriptionPlan,
                amount,
                formattedInitialAmount
            );

            return {
                id: getInstallmentLabel(formattedInitialAmount, canAmountBeEquallySplit, scheduleSettings?.startOn),
                cycles,
                amountPerCycle,
            };
        }

        switch (installmentPlan.planType) {
            case InstallmentPlan.FIXED_CYCLES:
                return {
                    id: LOCALE_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_FIXED_CYCLES,
                    cycles: installmentPlan.fixedCycles,
                };

            case InstallmentPlan.REVOLVING:
                return { id: LOCALE_LABELS.SUBSCRIPTIONS_INSTALLMENT_PLAN_REVOLVING };
        }
    })();

    const amountToFormat =
        hasSubscriptionPlan && amountPerCycle ? amountPerCycle : amount - (formattedInitialAmount || 0);
    const formattedAmount = formatNumber(amountForCurrency(amountToFormat, currency), {
        style: "currency",
        currency,
    });

    return {
        label: formatMessage(
            { id },
            {
                money: formattedAmount,
                period: formattedPeriod,
                cycles,
                amountPerCycle,
            }
        ),
        cycles,
        amountPerCycle,
        initialAmount: formattedInitialAmount,
    };
};
