import * as VALIDATION_LABELS from "../labels/validation";

export const LABELS: Record<string, string> = {
    [VALIDATION_LABELS.VALIDATION_RULE_ARRAY]: "{ชื่อ} ต้องเป็นรายการ",
    [VALIDATION_LABELS.VALIDATION_RULE_BETWEEN]: "กรุณากรอก {name} ระหว่าง :min ถึง :max ตัวอักษร",
    [VALIDATION_LABELS.VALIDATION_RULE_BOOLEAN]: "{name} ต้องเป็นค่า boule",
    [VALIDATION_LABELS.VALIDATION_RULE_DATE]: "{ชื่อ} ควรป้อนรูปแบบวันที่ที่ถูกต้อง",
    [VALIDATION_LABELS.VALIDATION_RULE_DIFFERENT]: "{name} และ :different จะต้องไม่เหมือนกัน",
    [VALIDATION_LABELS.VALIDATION_RULE_EMAIL]: "กรุณากรอกที่อยู่อีเมลที่ถูกต้องสำหรับ {name}",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_NUMERIC]: "กรุณากรอก {name} น้อยกว่าหรือเท่ากับ :max",
    [VALIDATION_LABELS.VALIDATION_RULE_MAX_STRING]: "กรุณากรอก {name} โดยมีความยาวไม่เกิน :max ตัวอักษร",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_NUMERIC]: "กรุณากรอก {name} น้อยกว่าหรือเท่ากับ :min",
    [VALIDATION_LABELS.VALIDATION_RULE_MIN_STRING]: "กรุณากรอก {name} โดยมีความยาวไม่เกิน :min ตัวอักษร",
    [VALIDATION_LABELS.VALIDATION_RULE_NOT_IN]: "{name} ที่เลือกไม่ถูกต้อง",
    [VALIDATION_LABELS.VALIDATION_RULE_NUMERIC]: "กรุณากรอก {name} เป็นตัวเลข",
    [VALIDATION_LABELS.VALIDATION_RULE_REGEX]: '{name} value ":value" จะต้องจับคู่กับรูปแบบ ',
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED]: "{name} เป็นสิ่งจำเป็น",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_IF]: "{name} จำเป็นถ้า :other กลายเป็น :value",
    [VALIDATION_LABELS.VALIDATION_RULE_REQUIRED_WITH]: "หากมีการตั้งค่าใน :field จะต้องระบุฟิลด์ {name}",
    [VALIDATION_LABELS.VALIDATION_RULE_SAME]: "{name} และ :same ต้องเหมือนกัน",
    [VALIDATION_LABELS.VALIDATION_RULE_STRING]: "{name} ต้องเป็นสตริง",
    [VALIDATION_LABELS.VALIDATION_RULE_URL]: "โปรดป้อน URL ที่ถูกต้องสำหรับ {name}",

    [VALIDATION_LABELS.VALIDATION_REQUIRED]: "{name} เป็นสิ่งจำเป็น",
    [VALIDATION_LABELS.VALIDATION_EMAIL]: "{name} ควรป้อนที่อยู่อีเมลที่ถูกต้อง",
    [VALIDATION_LABELS.VALIDATION_PHONE_NUMBER]: "{name} ควรป้อนหมายเลขโทรศัพท์ที่ถูกต้อง",
    [VALIDATION_LABELS.VALIDATION_CARD_NUMBER]: "{name} ควรป้อนหมายเลขบัตรที่ถูกต้อง",
    [VALIDATION_LABELS.VALIDATION_ZIP]: "{name} ควรป้อนรหัสไปรษณีย์ที่ถูกต้อง",
    [VALIDATION_LABELS.VALIDATION_DATE]: "{name} ไม่ถูกต้อง",
    [VALIDATION_LABELS.VALIDATION_CARD_DATE_EXPIRED]: "การ์ดที่หมดอายุ",

    [VALIDATION_LABELS.VALIDATION_GENERIC_FIELD_REQUIRED]: "ต้องระบุข้อมูลในช่องนี้",

    [VALIDATION_LABELS.VALIDATION_INVALID_CHARACTERS]: "{name} มีอักขระที่ไม่พร้อมใช้งาน",
    [VALIDATION_LABELS.VALIDATION_INVALID_LENGTH]: "รูปแบบ {name} เป็นการฉ้อโกง",
    [VALIDATION_LABELS.VALIDATION_MISSING_SPACE]: "{name} ต้องใช้อย่างน้อยหนึ่งว่าง",
};

export default LABELS;
