import React from "react";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Form, reduxForm } from "redux-form";

import { FORM_CHECKOUT_NAME } from "../../../../../common/constants";
import { LOCALE_LABELS } from "../../../locale/labels";
import { Dispatch } from "../../../redux/store";
import { Button } from "../common/FormData";

export const ContentPaidy = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    form: FORM_CHECKOUT_NAME,
})(({ handleSubmit, submitting }) => {
    const {
        paidy: { createPaidyTransaction },
    } = useDispatch<Dispatch>();

    const handleFormSubmit = () => createPaidyTransaction();

    return (
        <Form onSubmit={handleSubmit(handleFormSubmit)} className="content-form">
            <Alert bsStyle="success">
                <FormattedMessage id={LOCALE_LABELS.PAIDY_CONFIRM_CHARGE} />
            </Alert>

            <Button disabled={submitting} />
        </Form>
    );
});

export default ContentPaidy;
