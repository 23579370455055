import React from "react";
import { Route, Switch } from "react-router";
import { getErrorUrl } from "checkout/ts/utils/StepName";

import { FormCheckout } from "../checkout/FormCheckout";

import { CheckoutError } from "./CheckoutError";
import { DialogHeader } from "./DialogHeader";
import { ErrorBoundary } from "./ErrorBoundary";

export const Dialog = () => (
    <div className="dialog">
        <DialogHeader />

        <ErrorBoundary>
            <Switch>
                <Route path={getErrorUrl()} component={CheckoutError} />
                <Route component={FormCheckout} />
            </Switch>
        </ErrorBoundary>
    </div>
);
