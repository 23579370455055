import { IntlShape } from "react-intl";
import {
    PaymentType,
    TransactionTokenCardDataItem,
    TransactionTokenConvenienceDataItem,
    TransactionTokenItem,
} from "univapay-node";

import { getCardLabel, getConvenienceStoreLabel } from "../locale/selectors";
import { concatPhoneNumber } from "../redux/utils/intl";

export const formatTokenData = (token: TransactionTokenItem, formatMessage: IntlShape["formatMessage"]) => {
    switch (token.paymentType) {
        case PaymentType.CARD: {
            const {
                card: { lastFour, brand, cardholder },
            } = token.data as TransactionTokenCardDataItem;
            const cardBrand = formatMessage({ id: getCardLabel(brand) }) || "";
            const formattedLastFour = lastFour ? lastFour.padStart(8, "*") : "";
            const formattedBrand = cardBrand ? `${cardBrand}` : "";
            const formattedCardholder = cardholder ? `(${cardholder})` : "";

            return `${formattedBrand} ${formattedLastFour} ${formattedCardholder}`;
        }

        case PaymentType.KONBINI: {
            const { phoneNumber, convenienceStore } = token.data as TransactionTokenConvenienceDataItem;
            const formattedPhoneNumber = concatPhoneNumber(phoneNumber);
            const formattedStore = `(${formatMessage({ id: getConvenienceStoreLabel(convenienceStore) })})`;

            return `${formattedPhoneNumber} ${formattedStore}`;
        }

        case PaymentType.PAIDY:
            return token.email;

        default:
            console.warn(`Payment type '${token.paymentType}' not supported`);
            return null;
    }
};
