import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { hover, HoverProps } from "reactcss";
import { useButtonStyles } from "checkout/ts/hooks/useButtonStyles";
import classnames from "classnames";

type OwnProps = ButtonProps & HoverProps<void>;

export const DialogButton = hover(({ className, hover, ...props }: OwnProps) => {
    const buttonStyle = useButtonStyles(hover);

    return (
        <Button
            {...props}
            type="submit"
            tabIndex={30}
            data-name="dialog-button"
            className={classnames(className, "dialog-button")}
            style={{ ...props.style, ...buttonStyle }}
            block
        />
    );
});
