import { NewCardBrand } from "common/types";
import Payment from "payment";
import { CardBrand, ProcessingMode, SupportedBrand } from "univapay-node";

import { StateShape, store } from "../../../../redux/store";
import { cardBrandAPIName } from "../../../../utils/cards";
import { CardDataRaw, FormDataCardData } from "../FormData";

export interface CardBrandConfiguration {
    cardBrandSupported: boolean;
    requiresCVV: boolean;
    requiresFullName: boolean;
}

export const SUPPORTED_CARD_GATEWAYS = [
    CardBrand.VISA,
    CardBrand.MASTERCARD,
    CardBrand.JCB,
    CardBrand.AMEX,
    CardBrand.DINERS,
    CardBrand.UNIONPAY,
    CardBrand.DISCOVER,
    CardBrand.MAESTRO,
    NewCardBrand.PRIVATE_LABEL,
];

export const getCardBrandConfiguration = (
    values: Partial<FormDataCardData<CardDataRaw>>,
    supportsPrivateLabel: boolean
): CardBrandConfiguration => {
    const { application, configuration } = store.getState() as StateShape;
    const { currency, descriptor, ignoreDescriptorOnError } = application.params.params;
    const { cardConfiguration: cardConfigurationState, supportedBrands = [], mode } = configuration?.data || {};

    const brand = cardBrandAPIName(Payment.fns.cardType(values.data?.cardNumber));
    // In case private label is supported we assume unrecognize card are from this category
    const patchedBrand = supportsPrivateLabel ? brand || NewCardBrand.PRIVATE_LABEL : brand;
    const cardConfiguration = supportedBrands.find((config: SupportedBrand) => config.cardBrand === patchedBrand);

    const cardBrandSupported = (() => {
        if (mode === ProcessingMode.TEST) {
            return true;
        }

        if (!cardConfiguration) {
            return false;
        }

        const { supportedCurrencies, supportDynamicDescriptor } = cardConfiguration;
        const hasValidCurrency =
            !cardConfigurationState.onlyDirectCurrency ||
            !currency ||
            supportedCurrencies.includes(currency.toUpperCase());
        const hasValidDescriptor = !!ignoreDescriptorOnError || !descriptor || supportDynamicDescriptor;

        return hasValidCurrency && hasValidDescriptor;
    })();

    const requiresCVV = cardConfiguration?.requiresCvv;
    const requiresFullName = cardConfiguration?.requiresFullName;

    return {
        cardBrandSupported,
        requiresCVV,
        requiresFullName,
    };
};
